import Button from "components/common/ui/Button/Button";
import styled from "styled-components";
import { remCalc } from "utilities/styles";

export interface StyledButtonProps {
	width?: number;
}

export const StyledButtonToggle = styled(Button)<StyledButtonProps>`
	width: ${(props) => (props.width ? remCalc(props.width) : "fit-content")};
	font-size: ${remCalc(24)};
`;
