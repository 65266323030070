import { Body } from "components/common/ui/Typography/Typography.Styled";
import { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { red } from "utilities/variables/colors";

interface LabelProps {
  label?: string | ReactElement;
  required?: boolean;
  children?: any;
  htmlFor?: string;
  className?: any;
}

export const StyledLabel = styled(Body as "label").attrs((props) => ({
  htmlFor: props.htmlFor,
}))`
  margin: auto 0px;
`;

export const Asterisk = styled.span`
  &::after {
    content: "*";
    color: ${red};
  }
`;

const Label: FunctionComponent<LabelProps> = ({
  label,
  required,
  children,
  htmlFor,
  className = ""
}) => {
  if (!label && !children) {
    return null;
  }

  return (
    <StyledLabel as={"label"} htmlFor={htmlFor} className={`input-label ${className}`}>
      {label ? label : children} {required && <Asterisk />}
    </StyledLabel>
  );
};

export default Label;
