import styled from "styled-components";

import WishlistListItem from "./WishlistItem/WishlistListItem";

import { remCalc } from "utilities/styles";
import {
	accountDarkGrey,
	dark,
	darkGrey,
	grey,
	lightGrey,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const WishlistListContainer = styled.div`
	.wishlist-list__cards {
		display: block;

		@media ${aboveMedium} {
			display: none;
		}
	}

	.wishlist-list__table {
		display: none;
		border-collapse: collapse;
		width: 100%;
		margin: 0 auto;

		@media ${aboveMedium} {
			display: table;
		}

		.action-buttons__container {
			display: flex;
			justify-content: flex-end;
			gap: ${remCalc(16)};
		}

		th,
		td {
			min-width: ${remCalc(150)};
			white-space: nowrap;
			text-align: center;

			&:first-child,
			&:last-child {
				text-align: left;
			}

			&.spacer {
				width: 100%;
				min-width: auto;
			}
		}

		th {
			border-bottom: 1px solid ${grey};
		}

		td {
			border-bottom: 1px solid ${lightGrey};
		}

		thead {
			th {
				padding: ${remCalc(16)} 0;

				font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${accountDarkGrey};
			}
		}

		tbody {
			td {
				padding: ${remCalc(24)} ${remCalc(8)} ${remCalc(24)} 0;

				&:last-child {
					padding: ${remCalc(24)} 0;
				}

				font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${dark};

				.link {
					font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
					color: ${dark};
					text-decoration: none;

					&:hover {
						color: ${darkGrey};
						text-decoration: underline;
					}
				}
			}
		}
	}
`;

export const StyledWishlistListItem = styled(WishlistListItem)`
	padding: ${remCalc(24)} 0;

	&:first-child {
		padding: 0 0 ${remCalc(24)};
	}
`;
