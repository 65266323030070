import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputText } from "../../Input/Input";
import InputSelect from "../../InputSelect/InputSelect";
import { SelectOption, Street, Town } from "ts/types";
import addressApi from "app/api/rs_address/rsAddressApi";
import { SelectSearch } from "./RSAddressForm.styled";

type AddressSchema = {
	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	country_code: string;
};

interface Props {
	form: UseFormReturn<AddressSchema & any>;
	countryOptions: SelectOption[];
}

const RSAddressForm: React.FC<Props> = ({ form, countryOptions }: Props) => {
	const { t } = useTranslation();

	const [getTownList, townList] = addressApi.useLazyGetTownsQuery();
	const [getStreetList, streetList] = addressApi.useLazyGetStreetsQuery();
	const [showResults, setShowResults] = useState<boolean>(false);

	const [towns, setTowns] = useState<Town[]>([] as Town[]);
	const [streets, setStreets] = useState<Street[]>([] as Street[]);

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		control,
	} = form;

	useEffect(() => {
		getTownList({});
	}, []);

	useEffect(() => {
		GetTowns();
	}, [townList]);

	useEffect(() => {
		setStreets(
			streetList.data && streetList.data.length ? streetList.data : [],
		);
	}, [streetList]);

	const GetTowns = () => {
		if (townList.data) {
			setTowns(townList.data);
			const values = getValues();
			if (values.city) {
				let town: Town | undefined = GetTown(values.city);
				if (town) getStreetList(town.id);
			}
		}
	};

	const GetTown = (townDisplayName: string): Town | undefined => {
		return (townList.data as Town[]).find(
			(town: Town) => town.display_name === townDisplayName,
		);
	};

	const GetStreets = (value: any) => {
		if (value) {
			setValue("city", value);
			setValue("address1", "");
			let town: Town | undefined = GetTown(value);
			if (town) {
				getStreetList(town.id);
				setValue("postal_code", `${town.ptt_no}`);
			}
		}
	};

	const GetError = (field: string): string => {
		const error = errors[field];
		return error && error.message ? t(error.message.toString()) : "";
	};
	useEffect(() => {
        if(countryOptions.length === 1){
            setValue("country_code", countryOptions[0].value)
        }
    }, [])

	return (
		<>
			<h4 className="form__heading">{t("address_details")}</h4>

			<div className="form__fields">
				<div className="form__row">
					<Controller
						control={control}
						name="city"
						render={({ field }) => (
							<InputSelect
								{...field}
								label={t("form.registerTown") as string}
								required
								options={towns.map((town: Town) => {
									return {
										value: town.display_name,
										label: town.display_name,
									} as SelectOption;
								})}
								isSearchable
								onChange={(value) => GetStreets(value)}
								errorMsg={GetError("city")}
							/>
						)}
					/>

					<Controller
						control={control}
						name="address1"
						render={({ field }) => (
							<SelectSearch>
								<>
									<InputText
										label={t("form.registerAddressLine1") as string}
										{...field}
										required
										errorMsg={GetError("address1")}
										onFocus={() => setShowResults(true)}
										onBlur={() =>
											setTimeout(() => {
												setShowResults(false);
											}, 250)
										}
									/>
									<div
										className="input-select__indicators"
										onClick={() => setShowResults(!showResults)}
									>
										<div
											className="input-select__indicator input-select__dropdown-indicator"
											aria-hidden="true"
										>
											<svg
												height="20"
												width="20"
												viewBox="0 0 20 20"
												aria-hidden="true"
												focusable="false"
												className="css-tj5bde-Svg"
											>
												<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
											</svg>
										</div>
									</div>
								</>
								{showResults && (
									<div className="form-select-search-results">
										{streets
											.filter((street) =>
												street.name
													.toLowerCase()
													.includes(field.value.toLowerCase()),
											)
											.map((street) => {
												return (
													<button
														key={street.id}
														type="button"
														onClick={() => setValue("address1", street.name)}
													>
														{street.name}
													</button>
												);
											})}
									</div>
								)}
							</SelectSearch>
						)}
					/>
				</div>
				<div className="form__row">
					<InputText
						label={t("form.registerAddressLine2") as string}
						required
						{...register("address2")}
						errorMsg={GetError("address2")}
					/>
					<Controller
						control={control}
						name="country_code"
						render={({ field }) => (
							<InputSelect
								{...field}
								value={field.value}
								label={t("form.registerCountry") as string}
								required
								options={countryOptions}
								isSearchable
								errorMsg={GetError("country_code")}
							/>
						)}
					/>
				</div>
				<div className="form__row">
					<InputText
						label={t("form.registerZipCode") as string}
						required
						{...register("postal_code")}
						errorMsg={GetError("postal_code")}
						disabled={true}
					/>
				</div>
			</div>
		</>
	);
};

export default RSAddressForm;
