import IconAdd from "components/common/ui/Icons/IconAdd";

import { AddAddressLinkContainer } from "./AddAddressLink.Styled";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

const AddAddressLink = () => {

	const { t } = useTranslation();

	return (
		<AddAddressLinkContainer to={t(PathTranslationKey.ACCOUNT_ADDRESSES_NEW).toString()}>
			<div className="icon__container">
				<IconAdd newAddress />
			</div>
			{t("new_address")}
		</AddAddressLinkContainer>
	);
};

export default AddAddressLink;
