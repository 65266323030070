import { boolean, object, ref, string } from "yup";

import { Mod11_10 } from "@konfirm/iso7064";
import { isValidPhoneNumber } from "libphonenumber-js";
/* eslint-disable no-template-curly-in-string */
import { yupResolver } from "@hookform/resolvers/yup";

export type RegistrationFormScheme = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	company: string;
	legal_entity_id: string;
	userType: number;

	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	state_or_province: string;
	country_code: string;

	password: string;
	passwordConfirmation: string;

	privacyPolicy: boolean;
	newsletterPreference: string;
	loyaltyProgram: boolean;
	accountType: string;
};

export const defaultValues = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	company: "",
	legal_entity_id: "",
	userType: 1,

	address1: "",
	address2: "",
	city: "",
	postal_code: "",
	state_or_province: "",
	country_code: "",

	password: "",
	passwordConfirmation: "",

	privacyPolicy: false,
	newsletterPreference: "",
	loyaltyProgram: false,
	accountType: "email",
} as RegistrationFormScheme;

const passwordRegex =
	/^(?=.*[a-zA-Z])(?=.*[\d])[a-zA-Z\d!@#$%^&*()_+={}|[\]\\';:\/?,.~`-]{7,}$/;

export const registrationSchema = object().shape({
	userType: string()
		.label("User type")
		.test({
			message: "form.validation.phone.required",
			test: () => {
				return true;
			},
		}),
	firstName: string()
		.label("First Name")
		.required("form.validation.first_name.required")
		.min(2, "form.validation.first_name.min"),
	lastName: string()
		.label("Last Name")
		.required("form.validation.last_name.required")
		.min(2, "form.validation.last_name.min"),
	email: string()
		.label("Email")
		.email("form.validation.email.email")
		.required("form.validation.email.required"),
	phone: string()
		.label("Phone")
		.test({
			message: "form.validation.phone.required",
			test: (value) => {
				if (!value) {
					return false;
				}

				return value.length > 3;
			},
		})
		.test({
			message: "form.validation.phone.invalid_country",
			test: (value = "", ctx) => {
				if (!ctx.parent.country_code) {
					return false;
				}

				try {
					return isValidPhoneNumber(value, ctx.parent.country_code);
				} catch {
					return false;
				}
			},
		})
		.required("form.validation.phone.required"),
	company: string()
		.label("Company")
		.when("userType", {
			is: "2",
			then: (schema) =>
				schema
					.required("form.validation.company.required")
					.min(2, "form.validation.company.min"),
			otherwise: (schema) => schema.notRequired(),
		}),
	legal_entity_id: string()
		.label("Legal Entity ID")
		.when("userType", {
			is: "2",
			then: (schema) =>
				schema
					.required("form.validation.legal_entity_id.required")
					.test(
						"isLegalEntityIDRightLength",
						"form.validation.legal_entity_id.length",
						(value) => {
							if (!value) return false;
							return value.length === 9;
						},
					)
					.test(
						"isLegalEntityIDCorrectValue",
						"form.validation.legal_entity_id.invalidNumber",
						(value) => {
							if (!value) return false;
							const ordinalNumber = value.substring(0, 8);
							return !(parseInt(ordinalNumber, 10) < 10000001);
						},
					)
					.test(
						"isLegalEntityIDCorrectControlNumber",
						"form.validation.legal_entity_id.invalidValueControlNumber",
						(value) => {
							if (!value) return false;
							const control = value[8];
							const controlNumber = parseInt(control, 10);
							return !!controlNumber;
						},
					)
					.test(
						"isLegalEntityIDInvalidControlNumber",
						"form.validation.legal_entity_id.invalidControlNumber",
						(value) => {
							if (!value) return false;
							return Mod11_10.validate(value);
						},
					),
			otherwise: (schema) => schema.notRequired(),
		}),
	address1: string()
		.label("Address Line 1")
		.required("form.validation.address1.required"),
	address2: string().required("form.validation.address2.required"),
	city: string().label("Town/City").required("form.validation.city.required"),
	country_code: string()
		.label("Country")
		.required("form.validation.country.required"),
	postal_code: string()
		.label("ZIP Code")
		.required("form.validation.postal_code.required"),

	password: string()
		.label("Password")
		.required("form.validation.password.required")
		.test("isValidPassword", "form.validation.password.alpha_num", (value) => {
			if (!value) {
				return false;
			}
			return passwordRegex.test(value);
		})
		.test("isPasswordShort", "form.validation.password.min", (value) => {
			if (!value) {
				return false;
			}
			return value.length >= 7;
		}),

	passwordConfirmation: string()
		.label("Confirm Password")
		.oneOf([ref("password")], "form.validation.password_confirmation.invalid")
		.required("form.validation.password.confirmed"),
	privacyPolicy: boolean()
		.label("Privacy Policy")
		.required("form.validation.privacy_policy.required")
		.oneOf([true], "form.validation.privacy_policy.required"),
	loyaltyProgram: boolean().oneOf([true, false], ""),
});

export const registrationSchemeResolver = yupResolver(registrationSchema);
