import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark, lightGrey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { menuZIndex } from "utilities/variables/zIndex";

export const PdpWishlistDropdownContainer = styled.div`
	position: relative;

	.pdp-wishlist__button {
		width: ${remCalc(40)};
		height: ${remCalc(40)};
		padding: 0;
		display: grid;
		place-items: center;
		border-color: ${lightGrey};
		background-color: ${white};

		&.active,
		&:hover,
		&:active {
			border-color: ${dark};
		}

		i {
			font-size: ${remCalc(32)};
		}
	}

	.pdp-wishlist__menu {
		z-index: ${menuZIndex};
		position: absolute;
		right: 0;
		display: flex;
		flex-direction: column;
		box-shadow: 0px 3px 6px #21252940;

		& > * {
			width: 100%;
			height: fit-content;

			border-bottom: 1px solid ${lightGrey};
			padding: ${remCalc(24)} ${remCalc(32 + 40)} ${remCalc(16)} ${remCalc(32)};
			background-color: ${white};

			white-space: nowrap;
			text-align: start;

			font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
			letter-spacing: ${remCalc(0.8)};
			text-transform: uppercase;

			&:hover,
			&:active {
				background-color: ${white};
				border-color: ${white};
				border-bottom-color: ${lightGrey};
				color: ${dark};
				text-decoration: underline;
			}

			&:last-child {
				border: none;
				padding-top: ${remCalc(16)};
				padding-bottom: ${remCalc(24)};
			}

			&:first-child {
				padding-bottom: ${remCalc(16)};
			}
		}
	}
`;
