import Button from "components/common/ui/Button/Button";
import styled from "styled-components";

const IOSOnlyButton = styled(Button)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    margin-left: -10px;

    i {
        width: 100%;
        height: 100%;
        margin: 0;

        svg {
            width: inherit;
            height: inherit;
        }
    }
`;

export {
    IOSOnlyButton
}