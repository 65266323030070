import { useGetPointsBalanceQuery } from "app/api/customer/loyaltyApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";

const useCalculateLoyaltyTier = () => {
	const [returnedTier, setReturnedTier] = useState<any>();
	const [progress, setProgress] = useState<number>(0);

	const { customer } = useSelector((state) => state.auth);

	const {
		data: pointsBalanceData,
		isLoading: pointsBalanceIsLoading,
		isUninitialized,
		isError: pointsBalanceError,
	} = useGetPointsBalanceQuery(
		{ cardNumber: customer?.loyaltyCardBarcode },
		{ skip: !customer?.hasLoyaltyCard || !customer.loyaltyCardBarcode },
	);

	const {
		data: thresholdData,
		isLoading: thresholdIsLoading,
		isError: thresholdError,
	} = wordPressApi.useGetLoyaltyCardThresholdsQuery();

	useEffect(() => {
		if (
			isUninitialized ||
			pointsBalanceIsLoading ||
			thresholdIsLoading ||
			!thresholdData.length ||
			!pointsBalanceData
		)
			return;

		const { amount } = pointsBalanceData;
		let thresholdStartPoint = 0;

		const tempData = thresholdData
			.map(({ tier }: any, index: number) => {
				if (amount >= thresholdStartPoint && amount <= tier?.breakAmount) {
					const nextIndex = index + 1;
					const isTopTier = index + 1 === thresholdData.length;
					const tierData = {
						current: {
							name: tier?.displayName,
							discount: pointsBalanceData?.discount,
							thresholds: {
								start: thresholdStartPoint,
								finish: tier?.breakAmount,
							},
						},
						next: {
							name: isTopTier
								? "top tier"
								: thresholdData[nextIndex]?.tier?.displayName,
							discount: thresholdData[nextIndex]?.tier?.discountPercentage,
						},
					};
					thresholdStartPoint = tier?.breakAmount;
					return tierData;
				}
				thresholdStartPoint = tier?.breakAmount;
			})
			.filter((item: any) => item);
		setReturnedTier(tempData[0]);

		if (!tempData.length) return;

		const { start, finish } = tempData[0].current?.thresholds;

		const progressInPoints = amount - start;
		const pointsToNextTier = finish - start;
		const progressInPercent = Math.round(
			(progressInPoints / pointsToNextTier) * 100,
		);

		setProgress(progressInPercent);
	}, [pointsBalanceData, thresholdData]);

	if (pointsBalanceIsLoading || thresholdIsLoading || isUninitialized)
		return { isLoading: true };

	if (thresholdError || pointsBalanceError) return { error: true };

	const { loyalty_card_barcode, category } = pointsBalanceData;

	return {
		loyalty_card_barcode,
		category,
		tier: returnedTier,
		progress: progress,
		isLoading: pointsBalanceIsLoading || thresholdIsLoading,
	};
};

export default useCalculateLoyaltyTier;
