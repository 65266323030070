import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Filter } from "./Filters.svg";


const IconFilter: FunctionComponent = () => {
  return <SvgIcon svg={<Filter />} />;
};

export default IconFilter;
