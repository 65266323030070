import styled from "styled-components";
import { dark, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledWrapper = styled.div`
  display: flex;
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 32px;
  border: 1px solid ${grey};
  border-right: none;
  box-sizing: border-box;
  font: bold 16px/20px ${Roboto};
  color: ${grey};
  cursor: pointer;

  &:last-of-type {
    border-right: 1px solid ${grey};
  }

  &.active {
    border: 1px solid ${grey};
    border-color: ${dark};
    color: ${dark};

    &+ label {
      border-left: none;
    }
  }
`;

export const StyledInput = styled.input`
  display: none;
`;
