import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://get.geojs.io",
});

export const locationService = createApi({
  reducerPath: "locationService",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    locationFromIp: builder.query<any, void>({
      query: () => `/v1/ip/country.json?t=${Date.now()}`,
    }),
  }),
});