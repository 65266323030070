import { isSandbox } from "app/helpers/helper";
import Cookies from "js-cookie";
import { FC, useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import {
	OuterWrapper,
	StyledIconClose,
	InnerWrapper,
} from "./EnvironmentNotification.Styled";
import Typography from "components/common/ui/Typography/Typography";
import Button from "components/common/ui/Button/Button";

const EnvironmentNotification: FC = () => {
	const COOKIE_NAME = "hide-env-notification";

	const { store } = useSelector((state) => state.bcStore);

	const [show, setShow] = useState(false);

	useEffect(() => {
		const cookie = Cookies.get("COOKIE_NAME") || "";

		if (cookie !== "true") {
			setShow(true);
		}
	}, []);

  const handleClose = () => {
    Cookies.set(COOKIE_NAME, 'true');
    setShow(false);
  }

	if (!isSandbox() || !show) {
		return null;
	}

	return (
		<OuterWrapper>
			<InnerWrapper>
				<div className="text">
					<Typography variant="body" size="small">
						Env: <span>Sandbox</span>
					</Typography>
					<Typography variant="body" size="small">
						Store: <span>{store?.hash}</span>
					</Typography>
				</div>

				<Button icon={<StyledIconClose />} onClick={handleClose}/>
			</InnerWrapper>
		</OuterWrapper>
	);
};

export default EnvironmentNotification;
