import styled from "styled-components";

import Aside from "components/common/ui/Aside/Aside";

import Button from "components/common/ui/Button/Button";
import { darkGrey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium, aboveSmall } from "utilities/variables/media";
import { remCalc } from "utilities/styles";

export const StyledAside = styled(Aside)`
	@media ${aboveSmall} {
		width: 25.75rem;
	}

	padding-top: calc(8px + env(safe-area-inset-top, 0));

	.btn-close {
		top: calc(${remCalc(16)} + env(safe-area-inset-top, 0));
		right: calc(${remCalc(16)} + env(safe-area-inset-right, 0));

		@media ${aboveMedium} {
			top: calc(${remCalc(24)} + env(safe-area-inset-top, 0));
			right: calc(${remCalc(24)} + env(safe-area-inset-right, 0));
		}
	}

	.AsideHeader {
		padding: 1rem;
		@media ${aboveMedium} {
			padding: 1.5rem;
		}
	}

	.AsideFooter {
		border: none;
	}
`;

export const StyledBody = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	flex: 1;
	padding-inline: 1.5rem;
`;

export const LoadingSpinnerOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	opacity: 0.5;

	ion-spinner {
		color: ${white} !important;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		height: 40px;
	}
`;

export const EmptyCart = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;
	text-align: center;

	a {
		text-decoration: underline;
		margin-top: ${remCalc(12)};
	}
`;

export const StyledFooter = styled.div`
	background-color: #f8f9fa;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	padding-bottom: calc(16px + env(safe-area-inset-bottom, 0));

	@media ${aboveMedium} {
		padding: 24px;
	}

	.footer__price--container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			font: bold 16px/20px ${Roboto};
		}
	}

	.footer__button--container {
		padding-bottom: calc(8px + env(safe-area-inset-bottom, 0));
		display: flex;
		align-items: center;
		gap: 8px;

		a {
			flex: 1;
		}
	}
`;

export const StyledButton = styled(Button)`
	&:hover,
	&:active {
		.cart-count {
			color: ${darkGrey};
		}
	}
`;
interface sizeIncrease {
	doubleSize?: boolean;
}

export const StyledFooterButton = styled(Button)<sizeIncrease>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${(props) => (props.doubleSize ? remCalc(59) : "auto")};

	&:hover,
	&:active {
		.cart-count {
			color: ${darkGrey};
		}
	}
`;
