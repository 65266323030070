import { gql } from "graphql-request";

export const wordPressGetPageTemplates = {
	query: gql`
		query {
			pages(first: 100) {
				nodes {
					databaseId
					title
					uri
					slug
					template {
						templateName
					}
				}
			}
		}
	`,
};
