import styled from "styled-components";

import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledFooter = styled.footer`
  --ion-item-background: #f8f9fa;
  --ion-background-color: #f8f9fa;
  --ion-item-color: ${dark};
  --ion-font-family: ${Roboto};

  background-color: #f8f9fa;

  ion-icon {
    color: ${dark};
  }
`;

export const StyledCardsAndDisclaimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 20px;
  background-color: #f8f9fa;
`;
