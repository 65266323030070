
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledCarouselWrapper = styled.section`
	color: black;

	.swiper-slide {
		min-height: ${remCalc(552)};
		overflow: hidden;

		img {
			position: absolute;
			object-fit: cover;
			height: 100%;
			width: 100%;
			aspect-ratio: 16 / 9;
		}

		@media ${aboveMedium} {
			& {
				min-height: ${remCalc(486)};
				aspect-ratio: 16 / 6;
			}
		}
	}
`;

export const StyledCarouselVideoWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	height: 34.5rem;

	iframe {
		min-width: 130%;
		min-height: 130% !important;
		z-index: -5;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		border: none;
		aspect-ratio: 16 / 6;
		height: inherit;

		@media ${aboveMedium} {
			height: -webkit-fill-available;
			top: -17%;
		}

		@media ${aboveLarge} {
			top: -34%;
			aspect-ratio: 16 / 5;
		}

		html,
		body {
			touch-action: inherit;
		}
	}

	@media ${aboveMedium} {
		min-height: 100%;
	}
`;

export const StyledPaginationWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50% !important;
	top: 40% !important;
	bottom: unset !important;
	transform: translate(-50%, -50%);
	width: fit-content !important;

	.pagination-dot {
		width: 0.5rem;
		height: 0.5rem;
		position: relative;
		display: block;
		margin-inline: 0.5rem;
		background-color: white;
		border: 1px solid black;
		border-radius: 100px;
		z-index: 10;

		&--active {
			background-color: black;
		}
	}
`;

export const StyledCarouselNavigationWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 1rem 0 2rem;

	button {
		background-color: transparent;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		font-weight: 600;

		i {
			height: unset;
		}

		p {
			display: none;

			@media ${aboveMedium} {
				display: block;
			}
		}

		span {
			display: none;

			@media ${aboveMedium} {
				display: block;
			}
		}
	}
`;

export const StyledCarouselCtaWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: absolute;
	bottom: 1rem;

	@media ${aboveMedium} {
		flex-direction: row;
	}

	& > a {
		margin-block: 0.5rem;
		width: 90%;
		text-align: center;

		@media ${aboveMedium} {
			margin-inline: 0.5rem;
			width: fit-content;
		}
	}
`;
