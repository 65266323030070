import React from "react";
import {
	StyledInput,
	StyledLabel,
	StyledWrapper,
} from "./ButtonRadioGroup.Styled";

type Option = {
	label: string;
	value: string;
};

type Props = {
	name: string;
	options: Option[];
	defaultValue?: string | null;
	onChange?: (value: string) => void;
};

const ButtonRadioGroup = ({
	name,
	options,
	defaultValue = null,
	onChange,
}: Props) => {
	const [value, setValue] = React.useState(defaultValue);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);

		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<StyledWrapper>
			{options.map((option) => (
				<StyledLabel
					key={option.value}
					className={`${option.value === value && "active"}`}
				>
					<StyledInput
						type="radio"
						name={name}
						value={option.value}
						onChange={handleChange}
					/>
					{option.label}
				</StyledLabel>
			))}
		</StyledWrapper>
	);
};

export default ButtonRadioGroup;
