export const dark = "#212529";
export const white = "#ffffff";

export const accountLightGrey = '#F8F9FA';
export const lightestGrey = "#dbdbdb";
export const lightGrey = "#e3e3e3";
export const grey = "#adb5bd";
export const darkGrey = "#525a62";
export const accountDarkGrey = "#495057";

export const red = "#d42623";
export const green = "#28c43f";
export const disabled = "#aaa";