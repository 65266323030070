import { App, URLOpenListenerEvent } from "@capacitor/app";
import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AppUrlListener: FunctionComponent<any> = () => {
	const navigate = useNavigate();

	// alert(window.location.toString());
	useEffect(() => {
		App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
			// Example url: https://monaonline.com"tabs/tab2
			// slug = /tabs/tab2
			const slug = event.url.split("monaonline.com").pop();
			if (slug) {
				navigate(slug);
			}
			// If no match, do nothing - let regular routing
			// logic take over
		});

		App.addListener("backButton", (event) => {
			const isHomepage = window.location.pathname.split("/").length === 2;

			if (isHomepage || !event.canGoBack) {
				App.exitApp();
			} else {
				navigate(-1);
			}
		});
	}, []);

	return null;
};

export default AppUrlListener;
