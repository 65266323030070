import styled from "styled-components";

import { remCalc } from "utilities/styles";
import {
	accountDarkGrey,
	dark,
	darkGrey,
	lightGrey,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const WishlistListItemContainer = styled.div`
	padding-bottom: ${remCalc(24)};
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: -${remCalc(16)};
		bottom: 0;
		width: 100vw;
		border-bottom: 1px solid ${lightGrey};
	}

	&,
	.details__container,
	.buttons__container {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};
	}

	.details__container {
		gap: ${remCalc(24)};

		.details__row {
			display: flex;
			justify-content: space-between;
			gap: ${remCalc(16)};

			.details__title {
				margin: 0;
				font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${accountDarkGrey};
			}

			.details__detail {
				margin: 0;
				font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${dark};
			}

			a {
				text-decoration: none;

				&:hover {
					text-decoration: underline;
					color: ${darkGrey};
				}
			}
		}
	}

	.buttons__container {
		& > * {
			width: 100%;
		}
	}
`;
