import { gql } from "graphql-request";

const wordPressGetCurrentOpportunitiesPage = (uri: String) => {
	return {
		operationName: 'GetCurrentOpportunitiesPage',
		variables: {
			uri,
		},
		query: gql`
			query GetCurrentOpportunitiesPage($uri: String!) {
				pageBy(uri: $uri) {
					id
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
						}
					currentOpportunities {
						title
						text
					}
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
					title
				}
			}
		`,
	};
};

export default wordPressGetCurrentOpportunitiesPage;
