export enum DataSource {
  BC = "BigCommerce",
  WP = "WordPress",
}

/**
 * Page types as per BigCommerce
 * https://developer.bigcommerce.com/theme-objects/c2NoOjg3NzUwMDc-page-type
 */
export enum PageType {
  DEFAULT = "Default Page",
  LANDING = "Home Page",
  PAGE = "Page",
  CATEGORY = "Category Page",
  PRODUCT = "Product Page",
  CART = "Basket Page",
  MINICART = "Mini Cart",
  ERROR = "Error Page",
  SEARCH = "Search Page"
}

export enum ScreenSize {
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
}

export enum Breakpoint {
  XSMALL = 0,
  SMALL = 320,
  MEDIUM = 551,
  LARGE = 801,
  XLARGE = 1260,
}

export enum CustomerAccountType {
  EMAIL = "email",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  APPLE = "apple",
}
