import styled from "styled-components";
import { remCalc } from "utilities/styles";
import Typography from "../Typography/Typography";
import { FunctionComponent } from "react";

export const Wrapper = styled.div`
	text-align: center;
  min-height: 30vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: ${remCalc(64)} ${remCalc(16)};

	p {
		margin-top: ${remCalc(32)};
	}
`;

interface ErrorContentProps {
	heading: string;
	children: any;
	className?: any;
}

const ErrorContent: FunctionComponent<ErrorContentProps> = ({
	heading,
	children,
	className,
}) => {
	return (
		<Wrapper className={className}>
			<Typography variant="heading" size="large">
				{heading}
			</Typography>
			<Typography>{children}</Typography>
		</Wrapper>
	);
};

export default ErrorContent;
