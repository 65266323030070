import { integrationLayer } from "app/services/integrationLayerService";

import { Wishlist } from "components/customer/AccountWishlist/AccountWishlistOverview/AccountWishlistOverview";

type WishlistItem = {
	product_id: number;
};

export type CreateWishlistPayload = {
	customer_id: number;
	name: string;
	is_public: boolean;
	items: WishlistItem[];
};

export type AddWishlistItemPayload = {
	wishlistId: string;
	items: WishlistItem[];
};

const wishlistApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		// Wishlist
		createWishlist: builder.mutation({
			query: (body: CreateWishlistPayload) => ({
				url: "/bc/wishlist",
				method: "POST",
				body,
			}),
		}),
		editWishlist: builder.mutation({
			query: (wishlist: Wishlist) => ({
				url: `/bc/wishlist/${wishlist.id}`,
				method: "PUT",
				body: {
					items: [],
					is_public: wishlist.is_public,
					name: wishlist.name,
				},
			}),
		}),
		deleteWishlist: builder.mutation({
			query: (id) => ({
				url: `/bc/wishlist/${id}`,
				method: "DELETE",
			}),
		}),
		getWishlist: builder.query({
			query: (id) => ({
				url: `/bc/wishlist/${id}`,
				method: "GET",
			}),
		}),
		getWishlists: builder.query({
			query: (args: void) => ({
				url: "/bc/wishlists",
				method: "GET",
			}),
		}),
		getSharedWishlist: builder.query({
			query: (id_hash) => ({
				url: `/bc/wishlist/shared/${id_hash}`,
				method: "GET",
			}),
		}),
		// Wishlist Item
		addWishlistItem: builder.mutation({
			query: ({ wishlistId, items }: AddWishlistItemPayload) => ({
				url: `/bc/wishlist/${wishlistId}/items`,
				method: "POST",
				body: { items },
			}),
		}),
		deleteWishlistItem: builder.mutation({
			query: ({ wishlistId, itemId }) => ({
				url: `/bc/wishlist/${wishlistId}/item/${itemId}`,
				method: "DELETE",
			}),
		}),
	}),
});

export default wishlistApi;
