import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

interface TopArticle {
	vaild: boolean;
}

export const ArticleContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media ${aboveMedium} {
		flex-direction: row;
	}
	img {
		position: relative;
		object-fit: cover;
		min-height: ${remCalc(360)};
		height: ${remCalc(464)};
		@media ${aboveMedium} {
			width: 50%;
		}
	}
	.rightlist {
		border-top:  1px solid #E3E3E3 ;

		width: 100%;
		h3{
			padding-left:${remCalc(16)}; 
			padding-right:${remCalc(16)};
		}
		h4{
			padding-left:${remCalc(16)}; 
			padding-right:${remCalc(16)};
		}

		@media ${aboveMedium} {
			margin-left: 2rem;
		}
	}
`;

export const ArticleItem = styled.div<TopArticle>`
    height: 100%;
    min-height: ${remCalc(116)};
    visibility: ${({ vaild }) => (vaild === true ? "visible" : "hidden")};
    height: ${({ vaild }) => (vaild === true ? "100%" : 0)};
    min-height: ${({ vaild }) => (vaild === true ? remCalc(116) : 0)};
    
    @media ${aboveMedium} {
        visibility: visible;
        min-height: ${ remCalc(116)};
    }
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-bottom: 1px solid #e3e3e3;
	text-transform: uppercase;
	h4 {
		font-size: 1rem;
		margin: 0;
		margin-bottom: 0.5rem;
		font-weight: 700;
	}
	h3 {
		font-weight: 700;
		font-size: 1.25rem;
		margin: 0;
		margin-top: 0.5rem;

	}
`;
