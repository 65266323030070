import {
	klaviyoApiEndpoints,
	useLazyKlaviyoGetProfilesQuery
} from "app/api/klayivo/klayivoApi";
import { useEffect } from "react";
import { useSelector } from "redux/hooks";

const useKlaviyo = () => {
	const customerId = useSelector(
		(state) => state.auth.customer?.id || undefined,
	);

	const { marketingListId, signUpFormId } = useSelector(
		(state) => state.settings.klaviyo,
	);

	const [getProfileTrigger, getProfileResult] =
		useLazyKlaviyoGetProfilesQuery();

	const [subscribeProfile] =
		klaviyoApiEndpoints.useKlaviyoSubscribeProfileMutation();
	const [unsubscribeProfile] =
		klaviyoApiEndpoints.useKlaviyoUnsubscribeProfileMutation();

	const [updatePreference] =
		klaviyoApiEndpoints.useKlaviyoUpdateNewsletterPreferenceMutation();

	const getProfile = () => {
		if (customerId) {
			return getProfileTrigger();
		}
	};

	useEffect(() => {
		getProfile();
	}, [customerId]);

	const updateNewsletterPreference = async (preference: string) => {
		await updatePreference(preference);

		await getProfile();

		return Promise<void>;
	};

	const subscribe = async (identifier = "") => {
		if (marketingListId) {
			await subscribeProfile({
				list_id: marketingListId,
				identifier,
			});

			await getProfile();
		}

		return Promise<void>;
	};

	const unsubscribe = async () => {
		if (marketingListId) {
			await unsubscribeProfile({
				list_id: marketingListId,
			});

			await getProfile();
		}

		return Promise<void>;
	};

	return {
		klaviyoProfile: getProfileResult.data,
		updateNewsletterPreference,
		subscribe,
		unsubscribe,
	};
};

export default useKlaviyo;
