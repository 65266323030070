import styled from "styled-components";

import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { aboveSmall } from "utilities/variables/media";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  min-height: 50px;

  @media ${aboveSmall} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledCardRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const SkeletonCards = styled(Skeleton)`
  margin: 0;
  width: 204px;
  height: 24px;
`;

export const SkeletonBadges = styled(Skeleton)`
  margin: 0;
  width: 189px;
  height: 32px;
`;
