import styled from "styled-components";
import GoogleAutocompleteFooter from './GoogleAutocompleteFooter.png'

export const GoogleAutocompleteWrapper = styled.div`
    width: 100%;

    .co-googleAutocomplete-footer {
        background-color: #f7f8f9;
        background-image: url(${GoogleAutocompleteFooter});
        background-position-x: 0.75rem;
        background-position-y: center;
        background-repeat: no-repeat;
        border-top: 1px solid #e1e6eb;
        box-sizing: content-box;
        color: #556273;
        height: 18px;
        padding: 0.75rem;
    }
`