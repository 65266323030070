import { DataSource } from "ts/enums";

export const convertWordPressData = (nodes: any) => {
  return nodes.map((node: any) => {
    return {
      id: node.id.toString(),
      parentId: node.parentId,
      label: node.label,
      path: String(node.path).toLowerCase(),
      dataSource: DataSource.WP,
      children: node.childItems
        ? convertWordPressData(node.childItems.nodes)
        : [],
    };
  });
};
