import { css } from "styled-components";

export const FormStyle = css`
  margin-top: 32px;
  div.input {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  button {
    margin: 24px 0px;
  }
`;