import {
    FieldError,
    FormField,
    SelectField as SelectFieldType,
} from "types/GravityForm";
import { StyledFieldContainer } from "../GravityForm.Styled";
import InputSelect from "components/common/form/InputSelect/InputSelect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "redux/hooks";
import { updateSelectFieldValue } from "redux/gravityForm/gravityFormSlice";
import { useTranslation } from "react-i18next";
import React from "react";
import { RootState } from "redux/store";
import useStores from "hooks/useStores";
import { countryMap } from "utilities/countryMap";

interface Props {
	field: FormField & Partial<SelectFieldType>;
	fieldErrors: FieldError[];
}

const SelectField = ({ field, fieldErrors }: Props) => {
    const { id } = field;
	const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data,  getForCountries } = useStores();
	const [countries, setCountries] = React.useState<string[] | null>(null);
	const userCountry = useSelector((state: RootState) => state.bcStore.store?.code) as string;
    const selectFieldValue = useSelector((state: RootState) => state.gravityForm.fieldValues.find(fieldValue => fieldValue.id === id));

	
	
	const storesByCountry = React.useMemo(() => {
		return getForCountries(countries || []);
	}, [countries, data]);

    const filteredStores = React.useMemo(() => {
        const userCountryName = countryMap[userCountry as keyof typeof countryMap];
        const filteredStores = storesByCountry.filter(store => store.storeInformation.address.country === userCountryName);
        
    
        return filteredStores;
    }, [userCountry, storesByCountry]);

	const [selectedStoreValue, setSelectedStoreValue] = useState("");

    const updateValue = React.useCallback(
		(value: string) => {
			dispatch(updateSelectFieldValue({ id, value }));
		},
		[dispatch, id],
	);
   
	const handleChange = (value: string) => {
        setSelectedStoreValue(value);
        updateValue(value);
    };

    useEffect(() => {
        if (!selectFieldValue) {
            setSelectedStoreValue('');
            updateValue('');
        }
    }, [selectFieldValue, id, dispatch]);

    return <StyledFieldContainer
        layoutGridColumnSpan={field.layoutGridColumnSpan}
        isError={Boolean(fieldErrors.length)}
        visibility={field.visibility}
    >
        {field.cssClass === 'wp-store-selector' ? (
                <InputSelect
                    name={String(field.id)}
                    label={field.label}
                    required={field.isRequired}
                    options={filteredStores.map((choice) => ({
                        label: choice.title,
                        value: choice.title
                    }))}
                    errorMsg={
                        fieldErrors[0]?.message.includes("required")
                            ? t('form.validation.field.required') || ''
                            : fieldErrors[0]?.message || ''
                    } 
                    onChange={handleChange}
					value={selectedStoreValue}
                    isSearchable
                />
            ) : (
                <InputSelect
                    name={String(field.id)}
                    label={field.label}
                    required={field.isRequired}
                    options={field.choices?.map(
                        (choice) => {
                            return {
                                label: choice.value,
                                value: choice.value
                            }
                        }
            ) || []}
            errorMsg={
                fieldErrors[0]?.message.includes("required")
                    ? t('form.validation.field.required') ?? ''
                    : fieldErrors[0]?.message
            }
            value={selectedStoreValue}
            onChange={handleChange}
            isSearchable
        />
            )}
    </StyledFieldContainer>
};

export default SelectField;
