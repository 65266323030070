import { gql } from "graphql-request";

const wordPressGetColleaguesPage = () => {
	return {
		operationName: "GetColleaguesPage",
		query: gql`
			query GetColleaguesPage {
				colleagues(first: 20) {
					edges {
						node {
							title
							uri
							colleague {
								jobTitle
								shortDescription
								portrait {
									altText
									sourceUrl
								}
							}
							seo {
								metaKeywords
								metaRobotsNofollow
							}
						}
					}
				}
			}
		`,
	};
};

export default wordPressGetColleaguesPage;
