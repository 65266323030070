import BaseForm from "components/common/form/Form/Form";
import Link from "components/common/ui/Link/Link";
import { Body } from "components/common/ui/Typography/Typography.Styled";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark } from "utilities/variables/colors";
import { darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const Form = styled(BaseForm)`
	margin-top: 48px;
`;

export const FormSection = styled.section`
	margin-top: ${remCalc(32)};
`;

export const SectionTitle = styled(Body)`
	font-weight: bold;
	margin-bottom: 8px;
`;

export const SectionText = styled(Body)`
	color: ${darkGrey};
	font-size: 14px;
`;

export const InputRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-bottom: 24px;
	flex-wrap: wrap;

	&:first-of-type {
		margin-top: 24px;
	}

	&:last-of-type {
		margin-bottom: 36px;
	}

	div.input {
		width: 100%;
	}

	@media ${aboveMedium} {
		flex-wrap: nowrap;
		div.input {
			/* width: 50%; */
		}
	}
`;

export const InputCol = styled(InputRow)`
	flex-direction: column;
`;

export const RegistrationFormLink = styled(Link)`

	&:hover {
		color: ${dark} !important;
	}
`;

export const SigninSection = styled(FormSection)`
	margin-top: 24px;

	a {
		color: ${darkGrey};
	}
`;
