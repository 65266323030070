import { gql } from 'graphql-request';

export const wordPressGetBlogContentBreaks = (blogPageTitle: string) => {
  return {
    query: gql`
			query GetBlogContentBreaks {
          pages(where: {title: "${blogPageTitle}"}) {
            edges {
              node {
                blogTopPage {
                  contentBreak1 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                  contentBreak2 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
		`,
  };
};
