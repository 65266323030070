import { createSlice } from "@reduxjs/toolkit";
import { getCartTotalItems } from "app/utils/cartUtil";
import { Cart, CartErrorType, PhysicalItem } from "types/CartTypes";

const initialState = {
	cart: undefined as undefined | Cart,
	totalItems: 0,
	lastItemAdded: undefined as undefined | PhysicalItem,
	isLoading: false,
	cartError: undefined as undefined | CartErrorType,
};

export const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		resetCart: (state) => {
			state.cart = undefined;
			state.totalItems = 0;
			state.lastItemAdded = undefined;
			state.isLoading = false;
		},
		updateCart: (state, { payload }) => {
			if (payload) {
				state.cart = { redirect_urls: state.cart?.redirect_urls, ...payload };
			} else {
				state.cart = undefined;
			}

			state.totalItems = getCartTotalItems(payload);
			state.isLoading = false;
		},
		setLastAddedItem: (state, action) => {
			state.lastItemAdded = action.payload;
		},
		setCartError: (state, { payload }) => {
			state.cartError = payload;
		},
		clearCart: () => initialState,
		setCartIsLoading: (state, { payload }) => {
			if (state.isLoading !== payload) {
				state.isLoading = payload;
			}
		},
	},
});

export const {
	setLastAddedItem,
	clearCart,
	setCartIsLoading,
	updateCart,
	setCartError,
} = cartSlice.actions;

export default cartSlice.reducer;
