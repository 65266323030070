import styled from "styled-components";

import { dark } from "../../../utilities/variables/colors";
import IconBasket from "components/common/ui/Icons/IconBasket/IconBasket";
import useCart from "hooks/cart/useCart";
import { FunctionComponent } from "react";

const StyledDiv = styled.div`
	position: relative;
`;

const StyledCount = styled.div`
	color: ${dark};
	font-size: 12px;
	font-family: "Roboto";
	font-weight: bold;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.cart-count__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		> span {
			margin-top: 5px;
		}
	}
`;

const ShoppingCart: FunctionComponent = () => {
	const { meta: { totalItems } } = useCart();

	return (
		<StyledDiv>
			<IconBasket />
			{totalItems > 0 && (
				<StyledCount>
					<div className="cart-count__wrapper">
						<span className="cart-count">{totalItems}</span>
					</div>
				</StyledCount>
			)}
		</StyledDiv>
	);
};

export default ShoppingCart;
