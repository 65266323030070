import styled from "styled-components";

import BaseForm from "components/common/form/Form/Form";
import RadioButtonGroup from "components/common/form/RadioButtonGroup/RadioButtonGroup";
import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";

import { remCalc } from "utilities/styles";
import { Roboto } from "utilities/variables/fonts";
import {
	accountDarkGrey,
	dark,
	darkGrey,
	red,
} from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";
import Alert from "components/common/ui/Alert/Alert";

export const StyledAlert = styled(Alert)`
	margin-bottom: ${remCalc(24)};
`

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		flex-direction: row;
	}
`;

export const StyledForm = styled(BaseForm)`
	margin: 0;

	.form-heading {
		margin: 0;
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
	}

	.form-description {
		margin: ${remCalc(8)} 0 0;
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${accountDarkGrey};
	}

	.form-section {
		margin: ${remCalc(24)} 0 ${remCalc(32)};
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		&:last-of-type {
			margin: ${remCalc(24)} 0;
		}

		@media ${aboveMedium} {
			margin: ${remCalc(24)} 0;
		}
	}

	.form-row {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		& > * {
			flex: 1;
		}

		@media ${aboveMedium} {
			flex-direction: row;
			gap: ${remCalc(32)};

			& > *:only-child {
				flex: 0.5;
				margin-right: ${remCalc(32)};
			}
		}
	}

	.form-column {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(12)};
		margin-bottom: ${remCalc(8)};

		.form-description {
			margin: 0;
		}
	}

	.error-message {
		margin: 0 0 ${remCalc(24)};
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${red};
	}

	button {
		width: 100%;

		@media ${aboveMedium} {
			width: auto;
		}
	}
`;

export const StyledRadioGroup = styled(RadioButtonGroup)`
	display: flex;
	flex-direction: row;
	gap: ${remCalc(24)};

	.input_radio-btn {
		padding: 0;

		input {
			margin-right: ${remCalc(8)};
		}
	}
`;

export const StyledLabel = styled.p`
	&,
	a {
		margin: 0;
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
	}

	a:hover {
		color: ${darkGrey};
	}
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
	align-items: center;

	div {
		margin: 0 ${remCalc(8)} 0 0;
	}

	label {
		margin: 0;
	}

	span {
		margin-top: ${remCalc(8)};
	}
`;
