import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const MarkerTooltipContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(12)};
	padding: ${remCalc(18)} ${remCalc(16)} ${remCalc(16)};
	background-color: white;

	p,
	a {
		margin: 0;
		white-space: nowrap;
		font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
		color: ${dark};
	}

	a {
		text-decoration: none;

		&:hover,
		&:active {
			text-decoration: underline;
			color: ${darkGrey};
		}
	}

	.name {
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
		letter-spacing: ${remCalc(0.8)};
		text-transform: uppercase;
		margin-bottom: ${remCalc(6)};
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${remCalc(8)};
	}
`;
