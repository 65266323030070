import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const Container = styled.div`

	margin: 0 ${remCalc(16)};

	@media ${aboveMedium} {
		max-width: ${remCalc(392)};
		margin: 0 auto;
	}
`;
