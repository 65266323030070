import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as ClosedEye } from "./invisible.svg";

interface Props {
	className?: string;
}

const IconClosedEye: FunctionComponent<Props> = ({ className = "" }) => {
	return <SvgIcon className={className} svg={<ClosedEye />} />;
};

export default IconClosedEye;
