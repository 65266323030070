import { gql } from 'graphql-request';

const wordPressGetBlogPage = (title: string) => {
  return {
    operationName: "GetBlogPage",
    query: gql`
			query GetBlogPage {
          pages(where: {title: "${title}"}) {
            edges {
              node {
                id
                seo {
                  metaRobotsNofollow
                  metaRobotsNoindex
                  metaKeywords
                  metaDesc
                  title
                }
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                title
                blogTopPage {
                  contentBreak1 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                  contentBreak2 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
		`,
  };
};

export default wordPressGetBlogPage;
