import styled from "styled-components";

import Markdown from "components/common/ui/Markdown/Markdown";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledMarkdown = styled(Markdown)`
  overflow: auto;

  &,
  p,
  li,
  a {
    font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
    color: ${accountDarkGrey};
  }

  a:hover {
    color: ${darkGrey};
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 ${remCalc(16)};
    font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
    color: ${dark};
    text-transform: uppercase;
  }

  p + p {
    margin: ${remCalc(16)} 0;
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: ${remCalc(40)};
  }
`;
