import { gql } from "graphql-request";

const wordPressGetAboutUsPage = (pageId: number) => {
	return {
		operationName: "GetAboutUsPage",
		query: gql`
			query GetAboutUsPage {
				pages(where: { id: ${pageId}}) {
					edges {
						node {
							title
							uri
							featuredImage {
								node {
									...MediaItemFragment
								}
							}
							aboutUs {
								additionalLinks {
									fieldGroupName
									title
									image {
										desktopImage {
											title
											sourceUrl
										}
										mobileImage {
											title
											sourceUrl
										}
									}
									link {
										target
										title
										url
									}
								}
								companyvalues {
									fieldGroupName
									title
									values {
										text
										title
										icon {
											altText
											title
											sourceUrl
										}
									}
								}
								figures {
									backgroundImage {
										...MediaItemFragment
									}
									figure1 {
										fieldGroupName
										number
										text
									}
									figure2 {
										fieldGroupName
										number
										text
									}
									figure3 {
										fieldGroupName
										number
										text
									}
									figure4 {
										fieldGroupName
										number
										text
									}
									figure5 {
										fieldGroupName
										number
										text
									}
								}
								introduction {
									fieldGroupName
									heading
									text
									sectionImage {
										...MediaItemFragment
									}
								}
								missionsStatement {
									fieldGroupName
									header
									text
									image {
										...MediaItemFragment
									}
								}
								timeline {
									fieldGroupName
									year
									link {
										target
										title
										url
									}
									image {
										...MediaItemFragment
									}
								}
							}
							seo {
								metaDesc
								metaKeywords
								metaRobotsNoindex
								metaRobotsNofollow
								title
							}
						}
					}
				}
			}
			fragment MediaItemFragment on MediaItem {
				altText
				title
				sourceUrl
			}
		`,
	};
};

export default wordPressGetAboutUsPage;
