import { FunctionComponent, useEffect, useState } from "react";
import clip from "text-clipper";
import {
	Description,
	InnerWrapper,
	Name,
	SeeMoreButton,
	Wrapper,
} from "./PlpDetails.Styled";

interface PlpDetailsProps {
	loading?: boolean;
	name: string;
	description: string;
}

const PlpDetails: FunctionComponent<PlpDetailsProps> = ({
	loading = false,
	name,
	description,
}) => {
	const [expanded, setExpanded] = useState(false);

	const [desc, setDesc] = useState({
		org: description,
		clipped: clip(description, 210, { html: true, maxLines: 2 }),
	});

	useEffect(() => {
		setDesc({
			org: description,
			clipped: clip(description, 210, { html: true, maxLines: 2 }),
		});
	}, [description]);

	const handleSeeMoreClick = () => {
		setExpanded(!expanded);
	};

	const showSeeMore = desc.org !== desc.clipped;

	return (
		<Wrapper>
			{!loading && (
				<InnerWrapper>
					<Name variant="heading" size="small">
						{name}
					</Name>
					<Description html={expanded ? desc.org : desc.clipped} />
					{showSeeMore && (
						<SeeMoreButton onClick={handleSeeMoreClick}>
							{expanded ? "See Less" : "See More"}
						</SeeMoreButton>
					)}
				</InnerWrapper>
			)}
		</Wrapper>
	);
};

export default PlpDetails;
