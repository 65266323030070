import { isPlatform } from "@ionic/react";
import {
	getLanguageSelectOption,
	getStoreCodeFromCountryCode,
} from "app/bigcommerce/helpers/BcConfigHelper";
import { locationService } from "app/services/locationService";
import { useEffect } from "react";
import { useLocation, useMatch, useNavigate } from "react-router";
import { useDispatch, useSelector } from "redux/hooks";
import {
	setDefaultLocation,
	setPreferredLocation,
	setSelectedLocale,
} from "redux/location/locationSlice";
import i18n from "./app/i18n";

const LocaleManager = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { defaultLocation, preferredLocation, selectedLocale } = useSelector(
		(state) => state.location,
	);
	const bcStore = useSelector((state) => state.bcStore.store);

	const [getLocation] = locationService.useLazyLocationFromIpQuery();

	const pathMatch = useMatch("/:locale/*");

	useEffect(() => {
		if (!pathMatch?.params.locale) {
			return;
		}

		setPreferredLocation(pathMatch.params.locale);
	}, [pathMatch]);

	useEffect(() => {
		// only call to get location if there isn't one set
		// otherwise sends too many calls
		if (!defaultLocation) {
			getLocation()
				.unwrap()
				.then((response) => {
					const codeByIp = getStoreCodeFromCountryCode(response.country);
					const codeFromPath = getStoreCodeFromCountryCode(location.pathname.split("/")?.[1]);
					const pathWithoutLocale = location.pathname.split("/").slice(2).join("/");

					if (codeByIp) {
						dispatch(setDefaultLocation(codeByIp));
						navigate(`/${codeByIp}/${pathWithoutLocale}`);

						return;
					}

					if (codeFromPath) {
						dispatch(setDefaultLocation(codeFromPath));
						navigate(`/${codeFromPath}/${pathWithoutLocale}`);

						return;
					}

					dispatch(setDefaultLocation(response.country));
					navigate(`/en/${pathWithoutLocale}`);
				})
				.catch((error) => {
					if (error.status && error.status === "FETCH_ERROR") {
						dispatch(setDefaultLocation("rs"));
						navigate(`/rs`);
					}
				});
		}
	}, []);

	useEffect(() => {
		if (pathMatch) {
			const pathLocale = pathMatch.params.locale;

			// if the locale in the path doesn't match the preferred location
			if (pathLocale !== preferredLocation) {
				// check to see if the path locale is valid based on BC stores
				const validLocale = getLanguageSelectOption().find((option) => {
					return option.value === pathLocale;
				});

				if (validLocale) {
					// dispatch to change preferred location
					dispatch(setPreferredLocation(pathLocale));
				} else if (!isPlatform("capacitor")) {
					// navigate(
					// 	`/${preferredLocation ?? "rs"}/${pathMatch.params["*"]}${
					// 		location.search
					// 	}`,
					// );
				}
			}
		}
	}, [pathMatch]);

	useEffect(() => {
		if (selectedLocale) {
			i18n.changeLanguage(
				selectedLocale === "sr-Latn-RS"
					? "sr_RS"
					: selectedLocale.replace("-", "_"),
			);

			const breakOut = selectedLocale?.split("-");

			var language = breakOut.join("-") === "hr" ? "hr-HR" : breakOut.join("-");

			document.documentElement.setAttribute("lang", language);
		}

		if (bcStore) {
			// if (pathMatch) {
			// 	return;
			// }

			if (selectedLocale !== bcStore.locales[0]) {
				dispatch(setSelectedLocale(bcStore.locales[0]));

				dispatch(setSelectedLocale(bcStore.locales[0]));
				if (pathMatch) {
					// navigate(
					// 	`/${bcStore.code ?? "rs"}/${pathMatch.params["*"]}${
					// 		location.search
					// 	}`,
					// );
				} else {
					navigate(`/${bcStore.code ?? "rs"}`);
				}
			}
		}
	}, [selectedLocale, bcStore, pathMatch]);

	return <>{children}</>;
};

export default LocaleManager;
