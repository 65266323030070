import Link from "components/common/ui/Link/Link";
import { StyledColleaguesItemCard } from "./ColleaguesCarouselCard.Styled";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
	colleagueObject: any;
};

const ColleaguesCarouselCard = ({ colleagueObject }: Props) => {
	const { t, ready } = useTranslation();
	const { colleague, uri, title } = colleagueObject?.node;

	const link = useMemo(() => {
		if (!uri) {
			return undefined;
		}

		const uriSplit = uri.split("/").filter((t: string) => t) as string[];

		if (uriSplit.length < 1) {
			return undefined;
		}

		return `${t("url.colleague")}/${uriSplit[1]}`;
	}, [uri, ready]);

	return (
		<StyledColleaguesItemCard className="ItemCard">
			<div>
				<img
					src={colleague?.portrait?.sourceUrl}
					alt={colleague?.portrait?.altText}
				/>
			</div>
			<h1>{title}</h1>
			<h3 className="job-title">{colleague.jobTitle}</h3>
			<p>{colleague?.shortDescription}</p>
			{link && <Link to={link}>{t("myStory")}</Link>}
		</StyledColleaguesItemCard>
	);
};

export default ColleaguesCarouselCard;
