import { addLocaleCode, removeTrailingSlash } from "app/utils/urlUtils";
import uuid from "react-uuid";

import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { useLazyGetCompanyPageQuery } from "app/api/company/CompanyPageApi";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import ArticleCard from "components/common/ui/ArticleCard/ArticleCard";
import Button from "components/common/ui/Button/Button";
import IconSocial from "components/common/ui/Icons/IconSocial/IconSocial";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import * as DOMPurify from "dompurify";

import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import useBreakpoints from "hooks/useBreakpoints";
import { useEffect, useState } from "react";
import { useMatch } from "react-router";
import { useSelector } from "redux/hooks";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "../CompanyPages.Styled";
import {
	StyledHrDetailWrapper,
	StyledLinksContainer,
	StyledRelatedArticles,
} from "./HrDetail.Styled";
import { useTranslation } from "react-i18next";

interface RelatedArticles {
	fieldGroupName: string;
	heading: string;
	image: {
		altText: string;
		sourceUrl: string;
	};
	link: {
		title: string;
		url: string;
	};
	text: string;
}
const HrDetail = () => {
	const path = useMatch("/:locale/*")?.params["*"];
	const { t } = useTranslation();

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [triggerPageData, { data }] = useLazyGetCompanyPageQuery();
	const [triggerAdditionalLinks, { data: hrData }] =
		useLazyGetCompanyPageQuery();

	useEffect(() => {
		if (path) {
			const parentPage = wordpressTemplates.find(
				(template) => template.template === WordpressTemplateEnum.HrAdvice,
			);
			if (parentPage) {
				triggerAdditionalLinks({
					pageId: parentPage.id,
					pageType: "hr",
				});
			}

			const page = wordpressTemplates.find((template) =>
				template.uri.includes(path),
			);
			if (page) {
				triggerPageData({
					pageId: page.id,
					pageType: "hrDetail",
				});
			}
		}
	}, [path, wordpressTemplates]);

	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	// Create array of all other HR Detail pages
	let relatedArticles: RelatedArticles[] = [];
	let numRelatedArticlesToShow =
		process.env.REACT_APP_OPPORTUNITIES_PER_PAGE || 4;

	const { large } = useBreakpoints();
	const { sanitize }: any = DOMPurify;

	hrData?.pageData?.hrAdvice?.contentBlock?.forEach(
		(content: any, index: any) => {
			// Handle number of articles that show on Desktop view
			let indexLimit = large
				? numRelatedArticlesToShow
				: hrData?.pageData?.hrAdvice?.contentBlock?.length + 1;

			// Remove current article
			if (
				String(content.heading).toLowerCase() !==
					String(data?.pageData.title).toLowerCase() &&
				index < indexLimit
			) {
				relatedArticles.push(content);
			}
		},
	);

	const imageData = [
		{
			image: {
				desktopImage: data?.pageData?.featuredImage?.node.sourceUrl,
				mobileImage: data?.pageData?.featuredImage?.node.sourceUrl,
				alt: data?.pageData?.featuredImage?.node.altText,
			},
			ctaLink: "",
			ctaText: "",
		},
	];

	const origin = removeTrailingSlash(process.env.REACT_APP_PUBLIC_DOMAIN || "");
	let url = window.location.pathname;

	url = addLocaleCode(url);

	if (!url.startsWith(origin)) {
		url = `${origin}${url}`;
	}

	const links = [
		{
			icon: <IconSocial type="whatsapp" />,
			href: `whatsapp://send?text=${url}`,
			action: "share/whatsapp/share",
		},
		{
			icon: <IconSocial type="twitter" />,
			href: `https://twitter.com/intent/tweet?text=${url}`,
		},
		{
			icon: <IconSocial type="facebook" />,
			href: `https://facebook.com/sharer/sharer.php?u=${url}`,
		},
		{
			icon: <IconSocial type="linkedin" />,
			href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
		},
		{
			icon: <IconSocial type="email" />,
			href: `mailto:body=${url}`,
		},
		{
			icon: <IconSocial type="telephone" />,
			href: `viber://forward?text=${url}`,
		},
	];

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={data?.pageData?.seo?.title}
				description={data?.pageData?.seo?.metaDesc}
				keywords={data?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					data?.pageData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledHrDetailWrapper className="StyledJobDescriptionWrapper">
				<StyledMainImageBanner className="main-image-banner">
					{loading ? (
						<Skeleton width={"100%"} />
					) : imageData[0]?.image?.desktopImage ? (
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={false}
							isLoading={loading}
						/>
					) : (
						""
					)}
				</StyledMainImageBanner>

				<div className="container">
					{loading ? (
						<>
							<Skeleton className="skeleton-title" />
							<Skeleton
								className="skeleton-paragraph"
								style={{ marginBottom: "32px" }}
							/>
							<Skeleton className="skeleton-title" />
							<Skeleton
								className="skeleton-paragraph"
								style={{ marginBottom: "32px" }}
							/>
						</>
					) : (
						<>
							<h2 className="title">{data?.pageData?.title}</h2>
							<div
								className="content"
								dangerouslySetInnerHTML={{
									__html: sanitize(data?.pageData?.content),
								}}
							/>
						</>
					)}

					<StyledLinksContainer className="StyledLinksContainer">
						<hr />
						<div className="social-links">
							{links.map((link) => (
								<a
									key={uuid()}
									href={link.href}
									{...(link.action && { "data-action": link.action })}
									target="_blank"
									rel="noreferrer"
								>
									<Button icon={link.icon} />
								</a>
							))}
						</div>
						<hr />
					</StyledLinksContainer>

					{loading ? (
						<Skeleton className="skeleton-articles" />
					) : (
						<>
							<h1>{t("similarArticles")}</h1>
							<StyledRelatedArticles className="StyledRelatedArticles">
								{relatedArticles?.map((article) => (
									<ArticleCard
										image={article?.image.sourceUrl}
										altText={article?.image.altText}
										isLoading={loading}
										articleLink={article?.link?.url}
										buttonText={article?.link?.title}
										heading={article?.heading}
										key={uuid()}
									/>
								))}
							</StyledRelatedArticles>
						</>
					)}
				</div>

				{hrData?.pageData?.hrAdvice?.additionalLinks?.map((link: any) => {
					let ctaLink = undefined;

					if (link?.link?.url) {
						ctaLink = !(link.link.url as string).startsWith("http")
							? link.link.url
							: new URL(link.link.url).pathname;
					}

					return (
						<StyledImageBanner
							orientation="landscape"
							images={[
								{
									image: {
										desktopImage: link?.images.desktopImage.sourceUrl,
										mobileImage:
											link?.images.mobileImage?.sourceUrl ||
											link?.images.desktopImage.sourceUrl,
										alt: link?.images.desktopImage.altText,
									},
									ctaLink: ctaLink,
									ctaText: link?.link?.title,
								},
							]}
							showButton={link?.link?.title ? true : false}
							showText={true}
							imageText={link?.title}
							isLoading={loading}
							key={uuid()}
							isBottomBanner={true}
						/>
					);
				})}
			</StyledHrDetailWrapper>
		</Page>
	);
};

export default HrDetail;
