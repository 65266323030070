import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Sort } from "./Sort by.svg";


const IconSort: FunctionComponent = () => {
  return <SvgIcon svg={<Sort />} />;
};

export default IconSort;
