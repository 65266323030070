import { useEffect, useMemo, useState } from "react";
import uuid from "react-uuid";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { companyValues } from "./CompanyValuesGrid";
import CompanyValuesItem from "./CompanyValuesItem";

import CarouselControls from "components/common/ui/carouselControls/CarouselControls";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ColleaguesCarouselCard from "../colleagues/ColleaguesCarouselCard";

type Props = {
	companyValues:
		| {
				values: companyValues[];
				pageTitle?: string;
				numberOfColleagues?: Number;
		  }
		| any;
};

const CompanyValuesCarousel = ({ companyValues }: Props) => {
	const [slidesToShow, setSlidesToShow] = useState<number>(1);

	useEffect(() => {
		const handleResize = () => {
			const pageWidth = window.innerWidth;
			let newSlidesToShow: number;
			if (pageWidth >= 1260) {
				newSlidesToShow = 4;
			} else if (pageWidth >= 801) {
				newSlidesToShow = 3;
			} else if (pageWidth >= 551) {
				newSlidesToShow = 2;
			} else {
				newSlidesToShow = 1;
			}
			setSlidesToShow(newSlidesToShow);
		};

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const slides = useMemo(() => {
		if (!companyValues) {
			return undefined;
		}

		let slideData = [] as any[];

		let type = "";

		if (companyValues.colleagueData) {
			type = "colleague";
			slideData = companyValues.colleagueData;
		} else if (companyValues.companyvalues?.values) {
			type = "company";
			slideData = companyValues.companyvalues.values;
		} else if (companyValues) {
			type = "company";
			slideData = companyValues;
		}

		return slideData.map((value: any) => (
			<SwiperSlide key={uuid()}>
				{type === "colleague" ? (
					<ColleaguesCarouselCard colleagueObject={value} />
				) : (
					<CompanyValuesItem valueData={value} />
				)}
			</SwiperSlide>
		));
	}, [companyValues]);

	return (
		<Swiper
			slidesPerView={slidesToShow}
			slidesPerGroup={1}
			observer={true}
			observeParents={true}
			modules={[Pagination, Navigation]}
			pagination={{
				el: ".StyledPaginationWrapper",
				bulletClass: "pagination-dot",
				bulletActiveClass: "pagination-dot--active",
			}}
			navigation={{
				nextEl: ".carousel-navigation--next",
				prevEl: ".carousel-navigation--prev",
			}}
			loop={true}
		>
			{slides}
			<CarouselControls compact />
		</Swiper>
	);
};

export default CompanyValuesCarousel;
