import { klaviyoService } from "app/services/klaviyoService";
import { klaviyoProfile } from "app/types/klaviyo";

export type KlaviyoApiCreateProfileArgs = {
	email: string;
	phone_number: string;
	first_name: string;
	last_name: string;
	external_id: string;
	location: {
		address1: string;
		address2: string;
		city: string;
		country: string;
		zip: string;
	};
	properties: {
		product_preference: string;
	};
};

export const klaviyoApiEndpoints = klaviyoService.injectEndpoints({
	endpoints: (builder) => ({
		klaviyoGetProfiles: builder.query<any, void>({
			query: () => ({
				url: `/profile/me`,
				method: "GET",
			}),
			transformResponse: ({ data }: any) => {
				if (!data) {
					return undefined;
				}

				const id = data.id;
				const preference = data.attributes.properties.product_preference || "";
				const isSubscribed = !preference
					? false
					: data.attributes.subscriptions.email?.marketing?.consent ===
							"SUBSCRIBED" || false;

				return {
					id,
					marketingPreferences: {
						preference: preference,
						isSubscribed,
					},
				} as klaviyoProfile;
			},
		}),

		klaviyoCreateProfile: builder.mutation({
			query: (args: { data: any; identifier: string }) => ({
				url: `/profile`,
				method: "POST",
				body: args.data,
				headers: {
					"X-BC-Customer": args.identifier,
				},
			}),
			transformResponse: ({ data }: any) => {
				if (!data) {
					return undefined;
				}

				return data;
			},
		}),

		klaviyoUpdateNewsletterPreference: builder.mutation({
			query: (args) => ({
				url: `/profile/me`,
				method: "PUT",
				body: {
					properties: {
						product_preference: args,
					},
				},
			}),
			transformResponse: ({ data }: any) => {
				if (!data) {
					return undefined;
				}

				return data;
			},
		}),

		klaviyoSubscribeProfile: builder.mutation({
			query: (args: { list_id: string; identifier: string }) => ({
				url: `/profile/subscribe/me`,
				method: "POST",
				body: {
					list_id: args.list_id,
					channels: ["email"],
				},
				headers: {
					"X-BC-Customer": args.identifier,
				},
			}),
			transformResponse: ({ data }: any) => {
				if (!data) {
					return undefined;
				}

				return data;
			},
		}),

		klaviyoUnsubscribeProfile: builder.mutation({
			query: (args: { list_id: string }) => ({
				url: `/profile/unsubscribe/me`,
				method: "DELETE",
				body: {
					list_id: args.list_id,
					channels: ["email"],
				},
			}),
			transformResponse: ({ data }: any) => {
				if (!data) {
					return undefined;
				}

				return data;
			},
		}),
	}),
});

export const { useLazyKlaviyoGetProfilesQuery } = klaviyoApiEndpoints;
