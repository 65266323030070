import styled from "styled-components";

import Typography from "components/common/ui/Typography/Typography";

import { remCalc } from "utilities/styles";
import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const AccordionBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(12)};

	button > p {
		font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	}

	.store-address {
		&:hover {
			text-decoration: underline;
			color: ${darkGrey};
		}
	}
`;

export const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${remCalc(8)};
`;

export const StyledLink = styled.a`
	font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	color: ${dark};
	text-decoration: none;

	&:hover,
	&:active {
		text-decoration: underline;
		color: ${darkGrey};
	}
`;

export const StyledBodyText = styled(Typography)`
	font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	color: ${dark};
	cursor: pointer;
`;
