import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;

	.heading {
		font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
		color: ${dark};
		margin: 0;

		@media ${aboveMedium} {
			margin-top: ${remCalc(16)};
		}
	}
`;
