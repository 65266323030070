import { capitalize, isEmpty, isEqual, uniqWith } from "lodash";
import uuid from "react-uuid";
import {
	BreadcrumbType,
	Category,
	Image,
	Price,
	Product,
	ProductCustomField,
	ProductOption,
	ProductVariant,
	ProductVariantOption,
} from "ts/types";

export const convertBigCommerceCategory = (data: any): Category | undefined => {
	if (!data) {
		return undefined;
	}

	return {
		id: data.id,
		entityId: data.entityId,
		name: capitalize(data.name),
		description: data.description,
		customRowBreaks: data.seo.metaKeywords,
		seo: {
			pageTitle: data.seo.pageTitle,
			metaDescription: data.seo.metaDescription,
			metaKeywords: data.seo.metaKeywords,
		},
		breadcrumbs: data.breadcrumbs.edges.map((edge: any) => {
			return {
				entityId: edge.node.entityId,
				name: edge.node.name,
			} as BreadcrumbType;
		}),
	} as Category;
};

export const convertBigCommerceApiProduct = (
	data: any,
): Product | undefined => {
	if (!data) {
		return undefined;
	}

	const productOptions = uniqWith(
		data.variants
			.map((option: any) => {
				return option.option_values.map((value: any) => {
					return {
						entityId: value.option_id,
						isSelected: false,
						isDefault: false,
						name: value.option_display_name,
					} as ProductOption;
				});
			})
			.flat() || [],
		isEqual,
	);

	return {
		id: uuid(),
		entityId: data.id,
		name: data.name,
		sku: data.sku,
		path: `${data.custom_url.url}`,
		price: {
			currencyCode: "",
			value: data.sale_price ?? data.price,
			formatted: data.sale_price ?? data.price,
		} as Price,
		description: data?.description,
		customFields: data?.custom_fields.map((field: any) => ({
			name: field.name,
			value: field.value,
		})) as ProductCustomField[],
		images: data?.images.map((image: any) => {
			return {
				url: image.url_standard,
				urlOriginal: image.url_standard,
				isDefault: image.is_thumbnail,
				altText: "",
			};
		}) as Image[],
		seo: {
			metaDescription: data.meta_description,
			metaKeywords: data.meta_keywords,
			pageTitle: data.name,
		},
		variants: productOptions.length
			? parseApiProductVariants(data.variants)
			: [],
		productOptions,
		mpn: data?.mpn,
		brand: {
			name: "Mona",
		},
		condition: data?.condition,
		availability: data?.availability,
		plainTextDescription: data?.description.replace(/<\/?[^>]+(>|$)/g, ""),
		inventory: data.inventory_level,
	};
};

export const convertBigCommerceProduct = (data: any): Product | undefined => {
	if (!data) {
		return undefined;
	}

	const node = data.node ? data.node : data;
	const categoryNode = node.categories.edges.length
		? node.categories.edges[0].node
		: undefined;

	const hasPrices = !isEmpty(node?.prices)

	const product = {
		id: node.id,
		entityId: node.entityId,
		name: node.name,
		sku: node.sku,
		path: `${node.path}`,
		...hasPrices && node?.prices.basePrice && {
			basePrice: {
				currencyCode: node?.prices.basePrice.currencyCode,
				value: node?.prices.basePrice.value,
				formatted: node.prices.basePrice.formatted,
			} as Price,
		},
		...hasPrices && node?.prices.salePrice && {
			salePrice: {
				currencyCode: node.prices.salePrice.currencyCode,
				value: node.prices.salePrice.value,
				formatted: node.prices.salePrice.formatted,
			} as Price,
		},
		...hasPrices && node?.prices.price && {
			price: {
				currencyCode: node?.prices.price.currencyCode,
				value: node?.prices.price.value,
				formatted: node?.prices.price.formatted,
			} as Price,
		},
		categories: categoryNode
			? {
					name: categoryNode.name,
					path: categoryNode.path,
					breadcrumbs: categoryNode.breadcrumbs.edges.map((edge: any) => {
						return {
							entityId: edge.node.entityId,
							name: edge.node.name,
						} as BreadcrumbType;
					}),
			  }
			: undefined,
		description: node.description,
		customFields: node?.customFields.edges.map((field: any) => ({
			name: field.node.name,
			value: field.node.value,
		})) as ProductCustomField[],
		images: node?.images.edges.map((edge: any) => edge.node) as Image[],
		variants: parseProductVariants(node),
		seo: node?.seo,
		productOptions:
			node?.productOptions?.edges.map((edge: any) => {
				return {
					...edge.node,
				} as ProductOption;
			}) || [],
		mpn: node?.mpn,
		brand: node?.brand,
		condition: node?.condition,
		availability: node?.availabilityV2.status,
		plainTextDescription: node?.plainTextDescription,
		inventory: node.inventory,
	} as Product;

	return product;
};

const parseApiProductVariants = (variants: any) => {
	if (!variants || !variants.length) {
		return [];
	}

	const getBaseVariant = (data: any) => {
		return {
			entityId: data.id,
			sku: data.sku,
			defaultImage: data.image_url,
			isPurchasable: !data.purchasing_disabled,
		};
	};

	//get the product variant option from the data node
	const getProductVariantOption = (data: any) => {
		const optionNode =
			(data?.option_values as any[]).length === 0
				? undefined
				: data.option_values[0];

		if (!optionNode) {
			return undefined;
		}

		return {
			displayName: optionNode.option_display_name,
			label: optionNode.label,
		} as ProductVariantOption;
	};

	return variants
		.map((variant: any) => {
			return {
				...getBaseVariant(variant),
				option: getProductVariantOption(variant),
				inventory: {
					isInStock: variant.inventory_level > 0,
				},
				propertyToSortBy: getProductVariantOption(variant)?.label,
			};
		})
		.sort(
			(x: any, y: any) =>
				parseInt(x.propertyToSortBy, 10) - parseInt(y.propertyToSortBy, 10),
		);
};

const parseProductVariants = (node: any) => {
	if (!node || !node?.variants?.edges) {
		return [];
	}

	const getBaseVariant = (data: any) => {
		return {
			entityId: data.node.entityId,
			sku: data.node.sku,
			defaultImage: data.node.defaultImage,
			isPurchasable: data.node.isPurchasable,
		};
	};

	//get the product variant option from the data node
	const getProductVariantOption = (data: any) => {
		const optionNode =
			(data?.node?.productOptions?.edges as any[]).length === 0
				? undefined
				: data.node.productOptions.edges[0].node;

		if (!optionNode) {
			return undefined;
		}

		const optionValue = optionNode.values.edges?.[0].node || undefined;

		if (!optionValue) {
			return undefined;
		}

		return {
			displayName: optionNode.displayName,
			label: optionValue.label,
			isDefault: optionValue.isDefault,
		} as ProductVariantOption;
	};

	const getInventory = (data: any) => {
		return {
			...data.node.inventory,
		};
	};

	return (
		(node.variants.edges as any[])
			.map((edge: any) => {
				const option = getProductVariantOption(edge);
				const inventory = getInventory(edge);

				return {
					...getBaseVariant(edge),
					option,
					inventory,
				} as ProductVariant;
			})
			// remove any undefined values form the return
			.filter((variant: any) => variant && variant.option !== undefined)
	);
};

export const getProductPathFromURL = (url: string) => {
	const _url = new URL(url);
	return _url.pathname;
};
