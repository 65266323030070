import styled, { css } from "styled-components";
import { remCalc } from "../../utilities/styles";
import { Breakpoint } from "../../ts/enums";
import {
	accountDarkGrey,
	dark,
	darkGrey,
} from "../../utilities/variables/colors";

const Section = styled.section<{
	gap: number;
	gapMobile?: number;
	padding?: number;
}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	gap: ${(props) => (props.gap ? remCalc(props.gap) : remCalc(12))};

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		padding: ${(props) => (props.padding ? remCalc(props.padding) : 0)};
		gap: ${(props) => props.gapMobile && remCalc(props.gapMobile)};
	}
`;
const SignUpContainer = styled.div<{
	padding?: number;
}>`
	padding: ${(props) => (props.padding ? remCalc(props.padding) : 0)} 0;
	background: #f8f9fa;
	width: 100%;
	margin: 0 0 ${remCalc(64)};

	.sign-up-content {
		color: #495057;
	}

	.signup-btn {
		a {
			color: white;
			font-weight: bold;
			font-size: ${remCalc(16)};
		}

		@media screen and (max-width: ${Breakpoint.LARGE}px) {
			width: 100%;
		}
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		text-align: center;
	}
`;

const Container = styled.div<{
	gap?: number;
	gapMobile?: number;
	padding?: number;
}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	gap: ${(props) => (props.gap ? remCalc(props.gap) : remCalc(12))};

	@media screen and (max-width: ${Breakpoint.XLARGE}px) {
		padding: 0 ${(props) => (props.padding ? remCalc(props.padding) : 0)};
		gap: ${(props) => remCalc(props.gapMobile)};
	}
`;

const FeaturedImage = styled.div<{
	imageLink?: string;
}>`
	width: 100%;
	margin: 0 auto;
	height: ${remCalc(608)};
	background-image: ${(props) =>
		props.imageLink && "url('" + props.imageLink + "')"};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	object-fit: contain;

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		height: ${remCalc(250)};
	}
`;

const RelatedCategories = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${remCalc(32)};

	p {
		font-weight: bold;
		text-transform: uppercase;
	}

	div {
		@media screen and (max-width: ${Breakpoint.LARGE}px) {
			flex-direction: row;
		}
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		flex-direction: column;
		align-items: flex-start;
		gap: ${remCalc(10)};
	}
`;

const UtilityContainer = styled.div<{
	padding?: number;
	flexDirection?: string;
	gap?: number;
	alignItems?: string;
	justifyContent?: string;
	alignItemsMobile?: string;
}>`
	display: flex;
	flex-direction: ${(props) => props.flexDirection && props.flexDirection};
	justify-content: ${(props) => props.justifyContent && props.justifyContent};
	align-items: ${(props) => props.alignItems && props.alignItems};
	gap: ${(props) => props.gap && remCalc(props.gap)};
	padding: 0 ${(props) => props.padding && remCalc(props.padding)};
	width: 100%;
	position: relative;

	.link {
		margin: ${remCalc(12)} 0 ${remCalc(64)};

		p {
			font-weight: bold;
			text-transform: uppercase;
		}
		p:hover {
			color: ${darkGrey};
			text-decoration: underline;
			text-decoration-color: ${dark};
		}
	}

	.link:hover {
		text-decoration-color: ${darkGrey};
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		.link {
			margin: ${remCalc(12)} 0 ${remCalc(32)};
		}
		align-items: ${(props) => props.alignItemsMobile && props.alignItemsMobile};
	}
`;

const SwipeContainerUponOverFlow = styled.div`
	position: relative;
	width: 100%;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const ContainerMedium = styled.div`
	max-width: 816px;
	margin: 0 auto;
	display: flex;
	align-content: flex-start;

	tr:nth-child(1) {
		display: flex;
		align-items: center;
		gap: ${remCalc(16)};
		margin: 0 0 ${remCalc(16)};
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		margin: 0 0 ${remCalc(32)};
	}

	article p,
	figure:nth-last-child(1) {
		margin-bottom: 0;
	}
`;

const LineBreak = styled.hr`
	border-top: 1px solid #e3e3e3;
	width: 100%;
	margin: 0;
`;

const PostContent = styled.div`
	color: ${accountDarkGrey};
	font-size: ${remCalc(16)};
	line-height: ${remCalc(24)};

	img {
		height: unset;
	}

	p,
	figure {
		margin: ${remCalc(32 * 2)} 0;

		img {
			object-fit: cover;
		}
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		p,
		figure {
			margin: 32px 0;
		}
	}
`;

const defaultDimensions = css`
	height: 584px;

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		height: 480px;
	}
`;
const BreakImageOutSlightly = styled.img`
	${defaultDimensions};
	max-width: 140%;
	position: relative;
	left: -20%;
`;

const commonStyles = css`
	figure {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
	}

	figure div,
	figure img {
		height: ${remCalc(584)};
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		height: inherit;
		grid-template-columns: 1fr;
		min-height: 480px;
	}
`;
const BreakImageOutSlightlyContainer = styled.div`
	width: 140%;
	position: relative;
	left: -20%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 32px;

	${commonStyles};
`;

const BreakImageOutSlightlyCarouselContainer = styled.div`
	width: 140%;
	position: relative;
	left: -20%;
	gap: 32px;

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		width: 100%;
		left: 0;
		min-height: 480px;
	}
`;

const ImageSideBySideWithinContainer = styled.div<{
	width?: number;
}>`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: ${remCalc(16)};

	figure {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0;
		margin-inline-end: 0;
		margin-right: 0;
		width: ${(props) => props.width && Math.floor(100 / props.width)}%;
		height: ${remCalc(192)};
	}

	figure div,
	figure a,
	figure img {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		height: inherit;
		display: grid;
		grid-template-columns: ${(props) =>
			props.width === 3 ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};

		figure {
			width: 100%;
		}

		figure div {
			width: 100%;
			height: 100%;
		}
	}
`;
const FullBlogWidthImage = styled.img`
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
	max-height: 480px;
`;

const CarouselContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	max-height: 122px;

	.swiper .swiper-slide {
		width: 180px !important;
	}
`;
const CarouselContainerBreakOut = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	gap: 12px;
	max-height: 122px;
`;

const ChevronButton = styled.button`
	background: transparent;
	outline: none;
	height: 100%;
	display: block;
`;

export {
	Section,
	FeaturedImage,
	ContainerMedium,
	LineBreak,
	Container,
	RelatedCategories,
	UtilityContainer,
	SwipeContainerUponOverFlow,
	SignUpContainer,
	PostContent,
	BreakImageOutSlightly,
	FullBlogWidthImage,
	BreakImageOutSlightlyContainer,
	ImageSideBySideWithinContainer,
	CarouselContainer,
	ChevronButton,
	CarouselContainerBreakOut,
	BreakImageOutSlightlyCarouselContainer,
};
