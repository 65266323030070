import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Icon } from "./Basket.svg";


const IconBasket: FunctionComponent = () => {
  return <SvgIcon svg={<Icon />} />;
};

export default IconBasket;
