import styled from "styled-components";

import Map from "components/common/ui/Map/Map";
import ClosestStores from "components/supportingPages/customerService/stores/ClosestStores/ClosestStores";
import CountrySelection from "components/supportingPages/customerService/stores/CountrySelection/CountrySelection";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const StyledCountrySelection = styled(CountrySelection)`
	margin-top: ${remCalc(40)};
`;

export const StyledMap = styled(Map)`
	margin: 1em calc(50% - 50vw);
	margin-top: ${remCalc(24)};

	@media ${aboveMedium} {
		margin: 0;
		margin-top: ${remCalc(24)};
	}
`;

export const StyledClosestStores = styled(ClosestStores)`
	margin-top: ${remCalc(40)};
`;

export const ContentWrapper = styled.div`
	margin: ${remCalc(48)} ${remCalc(16)} ${remCalc(64)};
	max-width: ${remCalc(710)};

	.helper-text {
		margin-bottom: ${remCalc(24)};
	}
`;
