import { StyledWrapper } from "./PdpMarkdown.Styled";
import * as DOMPurify from "dompurify";

type Props = {
  children: string;
};

const PdpMarkdown = ({ children }: Props) => {
	const { sanitize }: any = DOMPurify;

  return <StyledWrapper dangerouslySetInnerHTML={{ __html: sanitize(children) }} />;
};

export default PdpMarkdown;
