import React, { useEffect, useState } from "react";
import { InputProps } from "../Input/Input";
import {
  RadioButtoncContainer,
  RadioButton
} from "./InputRadioButton.Styled";
import Label from "../Label/Label";

interface InputRadioButtonProps extends InputProps{
  value: string,
  checked: boolean;
}

const InputRadioButton = React.forwardRef<
  HTMLInputElement, InputRadioButtonProps
>(({ label, required = false, ...props }, ref) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked]);

  const handleOnClick = () => {
    setChecked(!checked);
    if (props.onChange) {
      props.onChange(props.value);
    }
  };

  const name = `radio_${props.value}`;

  return (
    <RadioButtoncContainer className="input_radio-btn">
      <RadioButton
        id={name}
        name={name}
        value={props.value}
        checked={checked}
        onChange={handleOnClick}
      />
      <Label label={label} htmlFor={name} />
    </RadioButtoncContainer>
  );
});

export default InputRadioButton;
