import { isValid } from "date-fns";
import { dateToLocalFormat } from "date-format-ms";
import React from "react";
import { useSelector } from "redux/hooks";

type Props = {
	date: Date;
	extendedDateFormat?: boolean;
};

const FormattedDate = ({ date, extendedDateFormat = false }: Props) => {
	const dateFormat = useSelector((state) => state.bcStore.settings?.display);

	const formattedDate = React.useMemo(() => {
		if (!dateFormat) return "";

		const dateObj = new Date(date);
		const isValidDate = isValid(dateObj);

		if (!isValidDate) return "";

		if (extendedDateFormat) {
			return dateToLocalFormat(dateObj, dateFormat.extendedDateFormat);
		}

		return dateToLocalFormat(dateObj, dateFormat.shortDateFormat);
	}, [date, dateFormat, extendedDateFormat]);

	return <>{formattedDate}</>;
};

export default FormattedDate;
