import { integrationLayerASW } from "app/services/integrationLayerAswService";

export const aswApi = integrationLayerASW.injectEndpoints({
	endpoints: (builder) => ({
		checkStockLevels: builder.query({
			query: (args: { skus: string[]; stores: string[] }) => ({
				url: `/stock/levels?group_by=sku`,
				method: "POST",
				body: { ...args },
			}),
		}),
	}),
});

export const { useLazyCheckStockLevelsQuery } = aswApi;
