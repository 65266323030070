import styled from "styled-components";
import { pageContentContainer } from "theme/StyledMixins";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, lightGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledHrDetailWrapper = styled.div`

	.container {
		${pageContentContainer}

		h2, h1 {
			color: ${dark};
			margin-bottom: ${remCalc(16)};
			margin-top: ${remCalc(64)};
			font-weight: 700;
		}

		.title {
			letter-spacing: 1.2px;
		}

		p,
		li {
			color: ${accountDarkGrey};
			line-height: ${remCalc(24)};
			margin-bottom: 0;
			margin-top: ${remCalc(16)};

			strong {
				color: ${dark};
				margin-top: ${remCalc(32)};
				display: inline-block;
				font-size: ${remCalc(20)};
			}
		}

		h1 {
			margin-top: ${remCalc(64)};
			margin-bottom: 0;
			font-size: ${remCalc(24)};
			letter-spacing: 1.2px;
			padding-left: ${remCalc(11)};

			@media ${aboveMedium} {
				text-align: center;
				padding: 0;
			}
		}

		.skeleton-title {
			margin-top: ${remCalc(48)};
			margin-bottom: ${remCalc(16)};
			height: ${remCalc(58)};

			@media ${aboveMedium} {
				margin-top: ${remCalc(64)};
				margin-bottom: ${remCalc(32)};
			}
		}

		.skeleton-paragraph {
			min-height: ${remCalc(300)};
		}

		.skeleton-container {
			display: flex;
			justify-content: center;
		}

		.skeleton-articles {
			width: 100%;
			height: ${remCalc(318)};
			margin-top: ${remCalc(64)};
			margin-bottom: ${remCalc(64)};

			@media ${aboveMedium} {
				width: ${remCalc(816)};
			}
		}
	}
`;

export const StyledLinksContainer = styled.div`
	margin-top: ${remCalc(64)};

	.social-links {
		display: grid;
		grid-auto-flow: column;
		gap: ${remCalc(8)};
		justify-content: center;
		padding: ${remCalc(20)} ${remCalc(44)};

		@media ${aboveMedium} {
			padding: ${remCalc(28)} ${remCalc(44)};
		}
	}

	hr {
		background-color: ${lightGrey};
		max-width: ${remCalc(816)};
		margin: 0 auto;
	}
`;

export const StyledRelatedArticles = styled.div`
	padding: ${remCalc(15)} ${remCalc(11)} ${remCalc(48)};

	@media (min-width: 680px) {
		display: grid;
		grid-auto-flow: column;
		gap: ${remCalc(17)};
		justify-content: center;
		margin-bottom: ${remCalc(40)};
		padding: ${remCalc(22)} 0 ${remCalc(64)} 0;
	}

	.ArticleCardWrapper {
		margin-bottom: ${remCalc(32)};

		@media ${aboveMedium} {
			margin-bottom: 0;
		}
	}

	h2 {
		letter-spacing: 0;
	}
`;
