import React, { useEffect } from "react";
import { ActionMeta, components, MenuListProps } from "react-select";
import { SelectOption } from "ts/types";
import { InputProps } from "../Input/Input";
import ErrorMessage from "../Input/InputError/InputError";
import InputWrapper from "../Input/InputWrapper";
import Label from "../Label/Label";
import { StyledSelect } from "./InputSelect.Styled";

interface SelectProps {
	options: SelectOption[];
	value: string | number;
	isSearchable?: boolean;
}

const MenuList = (props: MenuListProps) => {
	return <components.MenuList {...props}>{props.children}</components.MenuList>;
};

const InputSelect = React.forwardRef<
	HTMLInputElement,
	SelectProps & InputProps
>(({ className, options, value, isSearchable = false, ...props }, ref) => {
	const handleOnChange = (option: unknown, actionMeta: ActionMeta<unknown>) => {
		if (option) {
			if (props.onChange) {
				props.onChange((option as SelectOption).value as string);
			}
		}
	};

	return (
		<InputWrapper className={className}>
			{props.label && <Label required={props.required}>{props.label}</Label>}
			<StyledSelect
				value={value ? options.find((option) => option.value === value) : {
					value: "",
					label: ""
				} as SelectOption}
				placeholder=""
				menuPlacement="auto"
				options={options}
				classNamePrefix="input-select"
				components={{
					MenuList,
				}}
				onChange={handleOnChange}
				isSearchable={isSearchable}
			/>
			<ErrorMessage message={props.errorMsg} />
		</InputWrapper>
	);
});

export default InputSelect;
