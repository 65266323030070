import { createSlice } from "@reduxjs/toolkit";
import { setStore } from "redux/bigcommerce/bcStoreSlice";

const getStorageName = (hash?: string | null) =>
	hash ? `recently_viewed_products_${hash}` : "";

export const recentlyViewedSlice = createSlice({
	name: "recentlyViewed",
	initialState: {
		hash: null as string | null,
		skuList: [] as string[],
	},
	reducers: {
		addSku: (state, action: { payload: string }) => {
			const sku = action.payload;

			const withoutThisSku = state.skuList.filter((item) => item !== sku);
			const skuList = [sku, ...withoutThisSku].slice(0, 12);
			const skuListString = JSON.stringify(skuList);

			localStorage.setItem(getStorageName(state.hash), skuListString);

			return { ...state, skuList };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setStore, (state, action) => {
			const hash = action.payload.hash;

			if (hash) {
				const storageContent = localStorage.getItem(getStorageName(hash));
				const skuList = storageContent ? JSON.parse(storageContent) : [];

				return { ...state, hash, skuList };
			}
		});
	},
});

export const { addSku } = recentlyViewedSlice.actions;

export default recentlyViewedSlice.reducer;
