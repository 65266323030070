import Link from 'components/common/ui/Link/Link';
import { StyledCompanyValuesItemCard, StyledCompanyValuesItemCardLink } from './companyValues.Styled';
import { companyValues } from './CompanyValuesGrid';
import * as DOMPurify from "dompurify";

type Props = {
	includeContainerLink?: boolean;
	valueData?: companyValues;
};

const CompanyValuesItem = ({ includeContainerLink, valueData }: Props) => {

	const { sanitize }: any = DOMPurify;

	const renderContent = () => {
		return <>
			<img src={valueData?.icon?.sourceUrl} alt={valueData?.icon?.altText} />
			<h3>{valueData?.title}</h3>
			{valueData?.text && (
				<p dangerouslySetInnerHTML={{ __html: sanitize(valueData?.text) }} />
			)}

			{valueData?.link && valueData?.linkText && (
				<Link to={valueData?.link}>{valueData?.linkText}</Link>
			)}
		</>
	}

	return <StyledCompanyValuesItemCard className='ItemCard'>
		{includeContainerLink && valueData?.link ? (
			<StyledCompanyValuesItemCardLink to={valueData.link}></StyledCompanyValuesItemCardLink>
		) : null}
		{renderContent()}
	</StyledCompanyValuesItemCard>
};

export default CompanyValuesItem;
