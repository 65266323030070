import {
	showSizeGuideCalc
} from "app/utils/bigcommerceProductUtil";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { Product } from "ts/types";
import { StyledSpaceBetween, StyledWrapper } from "./PdpSizeActions.Styled";
import PdpSizeCalculator from "./PdpSizeCalculator/PdpSizeCalculator";
import PdpSizeGuide from "./PdpSizeGuide/PdpSizeGuide";

type Props = {
	loading?: boolean;
	product?: Product;
};

const PdpSizeActions = ({ loading, product, ...props }: Props) => {
	if (loading) {
		return (
			<StyledWrapper className="PdpSizeActions__loading" {...props}>
				<StyledSpaceBetween>
					<Skeleton width={100} height={12} />
					<Skeleton width={70} height={12} />
				</StyledSpaceBetween>
			</StyledWrapper>
		);
	}

	if (!product) {
		return null;
	}

	const showSizeActions = showSizeGuideCalc(product);

	if (!showSizeActions) {
		return null;
	}

	return (
		<StyledWrapper className="PdpSizeActions" {...props}>
			<StyledSpaceBetween>
				<PdpSizeCalculator product={product} />
				<PdpSizeGuide product={product} />
			</StyledSpaceBetween>
		</StyledWrapper>
	);
};

export default PdpSizeActions;
