import GravityForm from "components/common/GravityForm/GravityForm";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountLightGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const OuterWrapper = styled.div`
	background-color: ${accountLightGrey};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: ${remCalc(64)} ${remCalc(16)};

	.form-title {
		margin: auto;
	}
`;

export const FormWrapper = styled.div`
	max-width: ${remCalc(604)};
	width: 100%;
	margin: auto;
	margin-top: ${remCalc(32)};

	.input {
		flex: 1;
	}
`;

export const StyledGravityForm = styled(GravityForm)`
	button[type="submit"] {
		width: 100%;
	}
	@media ${aboveMedium} {
		button[type="submit"] {
			width: fit-content;
		}
	}
`;
