import { EvenContainer } from "../BlogContainer.styles";
import ImageItemCommon from "../ImageItemCommon/ImageItemCommonBase";
import { MidGridWrapper } from "./PostGroupMid.styles";

interface DataArray {
	data: any[];
}

export const PostGroupMid = ({ data }: DataArray) => {
	// if no items, act as a spacer between the banners
	if (data.length < 1) {
		return <EvenContainer/>;
	}
	return (
		<EvenContainer top={'2rem'} >
			<MidGridWrapper count={data.length}>
				<div className="grid-container">
					{data.map((item, index) => {
						return (
							<div key={"mid-" + index} className={`item${index} ${item.uri?'':'hiddenItem'} `}>
								<ImageItemCommon data={item} />
							</div>
						);
					})}
				</div>
			</MidGridWrapper>
		</EvenContainer>
	);
};
