import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import { DataSource } from "ts/enums";
import CategoryTree3LevelsDeep from "app/gql/queries/bigcommerceCategoryTree";

const convertBigCommereceData = (dataArray) => {
  return dataArray.map((data) => {
    const children = data.children
      ? convertBigCommereceData(data.children)
      : [];

    return {
      id: data.entityId.toString(),
      label: data.name,
      path: data.path,
      dataSource: DataSource.BC,
      children,
    };
  });
};

export const bigCommerceMenuDataApi = bigCommerceGQL.injectEndpoints({
  endpoints: (builder) => ({
    getMenuItems: builder.query({
      query: () => ({
        method: "POST",
        body: {
          ...CategoryTree3LevelsDeep,
        },
      }),
      transformResponse: (res) => {
        const categoryTree = res.data.site.categoryTree;
        return convertBigCommereceData(categoryTree);
      },
    }),
  }),
});