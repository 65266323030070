import Skeleton from 'components/common/ui/Skeleton/Skeleton';
import { StyledBlogPageSkeletonWrapper } from './BlogPageSkeleton.Styled';

const BlogPageSkeleton = () => {
    return (
        <StyledBlogPageSkeletonWrapper className='StyledBlogPageSkeletonWrapper'>
            <div className='one-col-wrapper'>
                    <Skeleton
                        style={{
                            aspectRatio: '11/6',
                        }}
                    />
            </div>

            
        </StyledBlogPageSkeletonWrapper>
    );
};

export default BlogPageSkeleton;