
export enum WordpressTemplateEnum {
  SocialResponsibilityDetail = "Social Responsibility Detail",
  AboutUs = "About Us",
  ApplicationPage = "Application Page",
  AwardsAndPrizes = "Awards And Prizes",
  BlogHomepage = "Blog Homepage",
  Careers = "Careers",
  ContactUs = "Contact Us",
  RateUs = "Rate Us",
  CoverTemplate = "Cover Template",
  CurrentOpportunities = "Current Opportunities",
  CustomerServiceContentPage = "Customer Service Content Page",
  FAQS = "FAQs",
  HrAdvice = "HR Advice",
  HrAdviceDetail = "HR Advice Detail",
  Homepage = "Homepage",
  JobApplicationPage = "Job Application Page",
  Legal = "Legal",
  LoyaltyPage = "Loyalty Page",
  OrderConfirmation = "Order Confirmation",
  SocialResponsibility = "Social Responsibility",
  Stores = "Stores"
};

export const getTemplate = (template: string): WordpressTemplateEnum | undefined => {
  const enumValue = Object.keys(WordpressTemplateEnum).find((key) => WordpressTemplateEnum[key as keyof typeof WordpressTemplateEnum] === template) || undefined;

  if(!enumValue) {
    return undefined;
  }

  return WordpressTemplateEnum[enumValue as keyof typeof WordpressTemplateEnum];
}
