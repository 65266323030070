import Typography from "components/common/ui/Typography/Typography";
import { StyledHeader } from "./AsideHeader.Styled";
import { FunctionComponent } from "react";

export interface AsideHeaderProps {
  heading?: string
}

const AsideHeader: FunctionComponent<AsideHeaderProps> = ({ heading }) => {

  if(!heading) {
    return null;
  }

  return (
    <StyledHeader className="AsideHeader">
      <Typography variant="heading" size="small">
        {heading}
      </Typography>
    </StyledHeader>
  );
};

export default AsideHeader;
