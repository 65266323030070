import React, { InputHTMLAttributes, useState } from "react";
import { InputProps } from "../Input/Input";
import InputWrapper from "../Input/InputWrapper";
import Label from "../Label/Label";
import { InputLabel, StyledInput } from "./FileUpload.Styled";
import ErrorMessage from "../Input/InputError/InputError";

type FileUploadProps = {
	maxFileSize?: number;
};

export const FileUpload = React.forwardRef<
	HTMLInputElement,
	InputProps & FileUploadProps & InputHTMLAttributes<{}>
>(({ className, label, required = false, filesAccepted, ...props }, ref) => {
	// Remove spaces & add '.' for each file type
	const acceptedFilesArray = filesAccepted?.map((fileType: string) => {
		let formattedFileType = fileType.replaceAll(" ", "");
		return `.${formattedFileType}`;
	});

	const [filename, setFilename] = useState("");
	const [errorMsg, setErrorMsg] = useState(props.errorMsg);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setErrorMsg("");

		if (e.target.files) {
			if (props.maxFileSize) {
				// Convert upload into MB
				const fileSize = e.target.files[0]?.size;
				const fileSizeMb = fileSize / 1024 ** 2;

				if (fileSizeMb >= props.maxFileSize) {
					setErrorMsg("File size too big");

					// Prevents user select files that are over the file limit
					return;
				}

				setFilename(e.target.files[0].name);
				props.onChange?.(e);
			} else {
				// no file size limit, set name and pass to onChange
				setFilename(e.target.files[0].name);
				props.onChange?.(e);
			}
		}
	};

	return (
		<InputWrapper className={className}>
			<Label required={required} htmlFor={props.name}>
				{label}
			</Label>
			<InputLabel>
				{filename ? filename : "Choose File"}
				{
					<StyledInput
						ref={ref}
						{...props}
						type={"file"}
						accept={acceptedFilesArray?.join(",")}
						onChange={handleChange}
						onBlur={props.onBlur}
						name={props.name}
					/>
				}
			</InputLabel>

			<ErrorMessage message={errorMsg} />
		</InputWrapper>
	);
});

export default FileUpload;
