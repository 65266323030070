import Link from "components/common/ui/Link/Link";
import { ImagePlaceholder } from "../ImageGrids/PostGridUtil";
import { ImageItemCommonWrapper } from "./ImageItemCommon.styles";
import { useMatch } from "react-router";

interface info {
	featuredImage: string;
	title: string;
	categories: string[];
	uri: string;
}
interface Props {
	data: info;
}

const ImageItemCommon = ({ data }: Props) => {

	const path = useMatch("/:locale/*")?.params["*"] || "";

	if (!data){
		return<></>
	}
	const { featuredImage, title, categories } = data;
	const valid = title.length > 0 && categories.length > 0;
	return (
		<ImageItemCommonWrapper valid={valid} className="varible-padding">
			<Link to={`${path}/${data.uri}`}>
				<img src={featuredImage || ImagePlaceholder().src} alt="blogImg" />

				<div>
					<h4>{categories}</h4>
					<h3>{title}</h3>
				</div>
			</Link>
		</ImageItemCommonWrapper>
	);
};

export default ImageItemCommon;
