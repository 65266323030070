import React, { FunctionComponent } from 'react';
import { StyledPopover } from './Popover.Styled';


export interface PopoverProps {
    children: React.ReactNode;
}

const Popover: FunctionComponent<PopoverProps> = ({ children }) => {
    return <StyledPopover className="popover">{children}</StyledPopover>;
};

export default Popover;
