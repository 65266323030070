import Button from "components/common/ui/Button/Button";
import IconMenu from "components/common/ui/Icons/IconMenu";
import { FunctionComponent, useEffect, useState } from "react";
import SideMenuContent from "./SideMenuContent/SideMenuContent";
import SideMenuFooter from "./SideMenuFooter/SideMenuFooter";

import { useLocation } from "react-router";
import { Spacer, StyledAside } from "./SideMenu.Styled";

const SideMenu: FunctionComponent<{}> = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<Button
				icon={<IconMenu />}
				onClick={() => setIsOpen(true)}
				className="menu-btn"
			/>

			<SideMenuOverlay isOpen={isOpen} handleClose={handleClose} />
		</>
	);
};

const SideMenuOverlay: FunctionComponent<{
	handleClose: () => void;
	isOpen: boolean;
}> = ({ handleClose, isOpen }) => {
	return (
		<StyledAside
			position="left"
			isOpen={isOpen}
			close={handleClose}
			header={<Spacer />}
			footer={<SideMenuFooter />}
		>
			<SideMenuContent />
		</StyledAside>
	);
};

export default SideMenu;
