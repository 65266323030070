import styled from 'styled-components';
import { pageContentContainer } from 'theme/StyledMixins';
import { accountLightGrey, dark } from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledCustomerAccountContainer = styled.div`
    ${pageContentContainer}
`;

export const StyledAccountOutletWrapper = styled.section`
    padding: 3rem 0 4rem;

    @media ${aboveMedium} {
        padding-top: 4rem;
    }
`;

export const StyledAccountPageBanner = styled.div`
    background-color: ${accountLightGrey};
    color: ${dark};

    h1 {
        margin: 1.5rem 0;
        padding: 0;
        font-size: 1.25em;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;

        @media ${aboveMedium} {
            text-align: left;
        }
    }
`;
