import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { Roboto } from "utilities/variables/fonts";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

export const StyledLabel = styled(Typography)`
  font: bold 14px/17px ${Roboto};
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledItemLabel = styled(Typography)`
  font: normal 14px/17px ${Roboto};
  text-transform: lowercase;
  
  &::first-letter {
    text-transform: uppercase;
  }
`;
