import { StyledNav } from "./Navbar.Styled";

import NavUser from "../NavUser/NavUser";
import Logo from "../Logo/Logo";
import SideMenu from "components/SideMenu/SideMenu";
import IOSBackButton from "../IOSBackButton/IOSBackButton";

const Navbar = () => {
	return (
		<StyledNav className="StyledNav">
			<IOSBackButton />
			<SideMenu />
			<Logo />
			<NavUser />
		</StyledNav>
	);
};

export default Navbar;
