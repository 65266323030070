import * as yup from "yup";

export type LoyaltyFormType = {
	loyaltyCardNumber: number;
};

export const loyaltyFormDefaultData = {} as LoyaltyFormType;

export const LoyaltyFormSchema = yup.object({
	loyaltyCardNumber: yup.number().typeError("form.validation.loyalty_card_no.required").label("Loyalty Card Number").required("form.validation.loyalty_card_no.required"),
});
