import { useLazyGetProductBySkuQuery } from "app/api/product/productApi";
import ProductCard from "components/ProductCard/ProductCard";
import useBreakpoints from "hooks/useBreakpoints";
import { useEffect, useMemo, useState } from "react";
import uuid from "react-uuid";
import { SwiperSlide } from "swiper/react";
import { Product, ProductCustomField } from "ts/types";
import {
	CarouselWrapper,
	ProductCardWrapper,
	StyledHeading,
	StyledSwiper,
	StyledWrapper
} from "./PdpCompleteTheLook.Styled";
import useDataLayer from "datalayer/useDataLayer";
import { useTranslation } from "react-i18next";

type Props = {
	productPageSku: string;
	field: ProductCustomField | undefined;
};

const PdpCompleteTheLook = ({ productPageSku, field }: Props) => {
	const { t } = useTranslation();
	const { large } = useBreakpoints();
	const { selectItem } = useDataLayer();

	const [products, setProducts] = useState<Product[]>([] as Product[]);
	const [skus, setSkus] = useState<string[]>();

	const [isLoading, setIsLoading] = useState(false);

	const [getProduct] = useLazyGetProductBySkuQuery();

	useEffect(() => {
		if (field && !isLoading) {
			setIsLoading(true);

			let skus = field.value
				.split(",")
				.map((sku) => sku.trim())
				.filter((sku) => sku.length);

			skus = [...new Set(skus)];
			skus = skus.filter((sku) => {
				return sku !== productPageSku;
			});
			setSkus(skus);
		}
	}, [field]);

	useEffect(() => {
		if (skus) {
			const getData = async () => {
				const products = [];

				for (let i = 0; i < skus.length; i++) {
					const product = await getProduct(skus[i]).unwrap();

					if (product) {
						products.push(product);
					}
				}

				setProducts(products);
				setIsLoading(false);
			};
			getData();
		}
	}, [skus]);

	const handleProductClick = (product: Product) => {
		selectItem(product, "complete-the-look", "Complete The Look");
	};

	const slides = useMemo(() => {
		const memoSlides = [];

		if (products.length && skus) {
			const getProductForSku = (sku: string) => {
				return products.find((product) => product.sku === sku);
			};

			const productCards = skus
				.map((sku) => {
					if (!products.length) {
						return <ProductCard
							triggeredFrom="PRODUCT_VIEW"
							key={sku}
							loading={true}
						/>;
					}

					const product = getProductForSku(sku);

					if (product) {
						return (
							<ProductCard
								triggeredFrom="PRODUCT_VIEW"
								key={sku}
								loading={false}
								product={product}
								hide={{
									sisterSkus: false,
								}}
								onClick={() => handleProductClick(product)}
							/>
						);
					}
				})
				.filter((productCard) => productCard);

			if (large) {
				for (let i = 0; i < productCards.length; ) {
					const productsForSlide =
						productCards.length - i > 4 ? 4 : productCards.length - i;

					const cards = productCards.slice(i, i + productsForSlide);

					memoSlides.push(
						<SwiperSlide key={uuid()} className="pdp-ctl_swiper-slide">
							<ProductCardWrapper>{cards}</ProductCardWrapper>
						</SwiperSlide>,
					);

					i += productsForSlide;
				}
			} else {
				productCards.forEach((card) => {
					memoSlides.push(
						<SwiperSlide key={uuid()} className="pdp-ctl_swiper-slide">
							{card}
						</SwiperSlide>,
					);
				});
			}
		}

		return memoSlides;
	}, [products, setProducts, products.length, large]);

	if (!skus || (!isLoading && !products.length)) {
		return null;
	}

	return (
		<StyledWrapper className="PdpCompleteTheLook">
			<StyledHeading variant="heading" size="small">
				{t("completeTheLook")}
			</StyledHeading>
			<CarouselWrapper className="pdp-clt_carousel-wrapper">
				<StyledSwiper
					className="PdpCompleteTheLook"
					slidesPerView={large ? 1 : "auto"}
				>
					{isLoading && <LoadingSlide />}
					{!isLoading && slides}
				</StyledSwiper>
			</CarouselWrapper>
		</StyledWrapper>
	);
};

const LoadingSlide = () => {
	return (
		<SwiperSlide>
			<ProductCardWrapper>
				<ProductCard
							triggeredFrom="PRODUCT_VIEW" loading={true} />
				<ProductCard
							triggeredFrom="PRODUCT_VIEW" loading={true} />
				<ProductCard
							triggeredFrom="PRODUCT_VIEW" loading={true} />
				<ProductCard
							triggeredFrom="PRODUCT_VIEW" loading={true} />
			</ProductCardWrapper>
		</SwiperSlide>
	);
};

export default PdpCompleteTheLook;
