import styled from "styled-components";
import { remCalc } from "utilities/styles";

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const MenuScroll = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: ${remCalc(24)} 0px;
`

export const MenuWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

