import useBreakpoints from "hooks/useBreakpoints";

import { ScreenSize } from "ts/enums";

import { SkeletonDisclaimer, StyledText, StyledWrapper } from "./Styled";

const Disclaimer = ({ loading, text }) => {
  const { screenSize } = useBreakpoints();

  if (loading) {
    let count = 2;

    switch (screenSize) {
      case ScreenSize.XSMALL:
        count = 5;
        break;
      case ScreenSize.SMALL:
        count = 5;
        break;
      case ScreenSize.MEDIUM:
        count = 4;
        break;
      case ScreenSize.LARGE:
        count = 3;
        break;
      default:
        count = 1;
        break;
    }

    return (
      <StyledWrapper>
        <SkeletonDisclaimer count={count} />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledText>{text}</StyledText>
    </StyledWrapper>
  );
};

export default Disclaimer;
