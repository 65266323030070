import styled from "styled-components";

import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { remCalc } from "utilities/styles";

export const StyledWrapper = styled.div`
	overflow: auto;
	margin: ${remCalc(48)} ${remCalc(16)} ${remCalc(64)};
	max-width: ${remCalc(710)};
`;

export const StyledSkeletonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};
`;

export const StyledFirstHeadingSkeleton = styled(Skeleton)`
	.skeleton-container {
		margin-bottom: ${remCalc(24)};
	}
`;

export const StyledHeadingSkeleton = styled(Skeleton)`
	.skeleton-container {
		margin: ${remCalc(24)} 0;
	}
`;

export const StyledSkeleton = styled(Skeleton)`
	.skeleton-container {
		gap: ${remCalc(4)};
	}
`;
