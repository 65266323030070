import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";

import { StyledWrapper } from "./SpTitle.Styled";

type Props = {
  className?: string;
  isLoading?: boolean;
  title?: string;
};

const SpTitle = ({ isLoading, title = "", ...props }: Props) => {
  if (isLoading) {
    return (
      <StyledWrapper {...props}>
        <Skeleton height={24} />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper {...props}>
      <Typography variant="heading" size="small">
        {title}
      </Typography>
    </StyledWrapper>
  );
};

export default SpTitle;
