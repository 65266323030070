import { isPlatform } from "@ionic/react";
import { IOSOnlyButton } from "./IOSBackButton.Styled";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IOSView } from "react-device-detect";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";

type FunctionReturnType<E = JSX.Element | boolean> = E;

const IOSBackButton = (): FunctionReturnType => {

    let isIOS: boolean = isPlatform('ios');
    const [showIOSBackButton, setShowIOSBackButton] = useState<boolean>(false);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { location } = useSelector(state => state) as any;

    useEffect(() => {

        if(
            pathname === `/${location.preferredLocation}`
        ){
            setShowIOSBackButton(!true);
        }
        else {
            setShowIOSBackButton(true);
        }

    }, [pathname, location])

    return (showIOSBackButton && isIOS) && (
        <IOSView>
            <IOSOnlyButton
                onClick={() => navigate(-1)}
            >
                <IconChevron direction="left" />
            </IOSOnlyButton>
        </IOSView>
    );
}

export default IOSBackButton;
