import Button from "components/common/ui/Button/Button";
import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAddToCartButton = styled(Button)`
  flex: 1;
  height: 40px;
`;

export const StyledIconButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 40px;
  height: 40px;

  &:hover,
  &:active {
    .icon {
      color: #fff;
      fill: red;
    }
  }
`;

export const StyledSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
