import { useState } from 'react';

interface Options {
	delay?: number;
	initialValue?: boolean;
}

const useLoadingDebounce = (options?: Options) => {
	const _options = {
		delay: 400,
		initialValue: false,
		...(options && options),
	};

	const [loading, setLoading] = useState(_options.initialValue);
	const [debounceLoading, setDebounceLoading] = useState(loading);
	const [debounce, setDebounce] = useState<NodeJS.Timeout>();

	const _setLoading = (newLoading: boolean) => {
		if (debounce) {
			clearTimeout(debounce);
		}

		if (newLoading) {
			setLoading(true);
			setDebounceLoading(true);
		}

		if (!newLoading) {
			setLoading(false);
			if (_options.delay === 0) {
				setDebounceLoading(false);
			} else {
				setDebounce(() => {
					return setTimeout(() => {
						setDebounceLoading(false);
					}, _options.delay);
				});
			}
		}
	};

	return {
		loading,
		debounceLoading,
		setLoading: _setLoading,
	};
};

export default useLoadingDebounce;
