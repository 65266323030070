import bigcommerceGetProductBySku from "app/gql/queries/bigcommerceGetProductBySku";
import bigcommerceGetProductsBySkus from "app/gql/queries/bigcommerceGetProductsBySkus";
import { bigcommerceObjectByUrl } from "app/gql/queries/bigcommerceObjectByUrl";
import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import { convertBigCommerceProduct } from "app/utils/bigcommerceApiUtil";
import { Product } from "ts/types";

export const productApi = bigCommerceGQL.injectEndpoints({
	endpoints: (builder) => ({
		getProductBySku: builder.query({
			query: (sku: string) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetProductBySku(sku.trim()),
				},
			}),
			transformResponse: (res: any): Product | undefined => {
				if (!res.data) {
					return undefined;
				}

				return convertBigCommerceProduct(res.data.site.product);
			},
		}),
		getProductsBySkus: builder.query({
			query: ({skus, nextProductSetCursor}: {skus: string[], nextProductSetCursor?: string}) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetProductsBySkus(skus, nextProductSetCursor),
				},
			}),
			transformResponse: (res: any): {
				nextProductSetCursor?: string;
				products: Product[];
			} | undefined => {
				if (!res.data) {
					return undefined;
				}

				const productArray = res.data?.site?.products?.edges || [];

				return {
					...(res.data?.site?.products?.pageInfo?.hasNextPage && {
						nextProductSetCursor: res.data?.site?.products?.pageInfo?.endCursor || '',
					}),
					products: productArray
						.map((product: any) => convertBigCommerceProduct(product))
						.filter((product: any) => product) as Product[]
				}
			},
		}),
		getProductByUrl: builder.query({
			query: (path: string) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceObjectByUrl(path.trim()),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				return convertBigCommerceProduct(res.data.site.route.node);
			},
		}),
		fetchProductBySku: builder.mutation({
			query: (sku: string) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetProductBySku(sku.trim()),
				},
			}),
			transformResponse: (res: any): Product | undefined => {
				if (!res.data) {
					return undefined;
				}

				return convertBigCommerceProduct(res.data.site.product);
			},
		}),
	}),
});

export const {
	useLazyGetProductsBySkusQuery,
	useLazyGetProductBySkuQuery,
	useGetProductBySkuQuery,
	useGetProductsBySkusQuery,
	useLazyGetProductByUrlQuery,
	useGetProductByUrlQuery,
} = productApi;
