import { FunctionComponent, ReactElement, SVGProps } from "react";
import styled from "styled-components";

interface Props {
	svg: ReactElement<SVGProps<SVGAElement>>;
	additionalStyles?: any;
	id?: any;
	className?: string;
}

const SvgIcon: FunctionComponent<Props> = ({
	id,
	svg,
	additionalStyles = "",
	className,
}) => {
	return (
		<Icon id={id} additionStyles={additionalStyles} className={className}>
			{svg}
		</Icon>
	);
};

const Icon = styled.i<{
	additionStyles: any;
}>`
	position: relative;
	${(props) => props.additionStyles}

	svg {
		vertical-align: top;
	}
`;

export default SvgIcon;
