import { FunctionComponent } from "react";
import * as RLS from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Skeleton: FunctionComponent<RLS.SkeletonProps> = ({
	containerClassName = "",
	className = "",
	...props
}) => {
	return (
		// eslint-disable-next-line react/jsx-pascal-case
		<RLS.default
			{...props}
			containerClassName={`skeleton-container ${containerClassName}`}
			className={`skeleton ${className}`}
		/>
	);
};

export default Skeleton;
