import Skeleton from 'react-loading-skeleton';
import Button from '../Button/Button';
import { ArticleCardWrapper } from './ArticleCard.Styled';
import usePageContent from 'hooks/usePageContent';

type Props = {
	image: string;
	altText?: string;
	articleLink: string;
	isLoading: boolean;
	heading: string;
	buttonText: string;
};

const ArticleCard = ({
	image,
	altText,
	buttonText,
	articleLink,
	isLoading,
	heading,
}: Props) => {

	const { scrollToTop } = usePageContent();

	return (
		<ArticleCardWrapper className='ArticleCardWrapper'>
			{isLoading ? (
				<Skeleton width='100%' />
			) : (
				<>
					<picture>
						<source media='(max-width:551px)' srcSet={image} />
						<img src={image} alt={altText} />
					</picture>
					<h2 className='heading'>{heading}</h2>
					<Button
						variant='primary_01'
						link={{ to: `${articleLink}` }}
						onClick={scrollToTop}
					>
						{buttonText}
					</Button>
				</>
			)}
		</ArticleCardWrapper>
	);
};

export default ArticleCard;
