import Link from "components/common/ui/Link/Link";
import { ImagePlaceholder } from "../ImageGrids/PostGridUtil";
import { ImageItemFirstWrapper } from "./ImageItemCommon.styles";
import { useMatch } from "react-router";

interface info {
	featuredImage: string;
	categories: string[];
	title: string;
	uri: string;
}
interface Props {
	data: info;
}

const ImageItemFirst = ({ data }: Props) => {

	const path = useMatch("/:locale/*")?.params["*"] || "";

	const { featuredImage, categories, title, uri } = data;
	return (
		<ImageItemFirstWrapper>
			<Link to={`/${path}/${uri}`}>
				<div>
					<h4>{categories}</h4>
					<h3>{title}</h3>
				</div>
				{featuredImage ? (
					<img src={featuredImage} alt="blogFirstImg" />
				) : (
					<img src={ImagePlaceholder().src} alt="blogFirstImgPH" />
				)}
			</Link>
		</ImageItemFirstWrapper>
	);
};

export default ImageItemFirst;
