import Link from 'components/common/ui/Link/Link';
import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { aboveLarge, aboveMedium, aboveSmall } from 'utilities/variables/media';

export const StyledCompanyValuesGridWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 3.5rem;
	column-gap: ${remCalc(30)};
	padding: 0 ${remCalc(20)};
`;
export const StyledCompanyValuesCarouselWrapper = styled.div``;

export const StyledCompanyValuesItemCard = styled.div`
	color: ${dark};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	padding: 0 ${remCalc(49)};
	text-decoration: none;

	img {
		width: ${remCalc(128)};
		height: ${remCalc(128)};
	}

	h3 {
		color: #9d9d9c;
		font-weight: 700;
		text-transform: uppercase;
		font-size: ${remCalc(20)};
		margin-bottom: ${remCalc(4)};
		margin-top: 0;

		@media ${aboveLarge} { margin-bottom: ${remCalc(8)}; }
	}

	p {
		color: ${accountDarkGrey};
		font-size: ${remCalc(14)};
		margin-top: 0;

		@media ${aboveLarge} { margin-bottom: 0; }
	}

	a {
		text-decoration: underline;
	}

	@media ${aboveSmall} {
		padding: 0 ${remCalc(20)};
	}

	@media ${aboveMedium} {
		padding: 0 0;
		max-width: ${remCalc(300)};
	}

	@media ${aboveLarge} {
		flex-basis: 20%;
		max-width: unset;
	}
`;

export const StyledCompanyValuesItemCardLink = styled(Link)`
	display: block;

    width: 100%;
    height: 100%;
`