import React, {
	Fragment,
	useEffect,
	useMemo,
	useState
} from "react";

import wishlistApi from "app/api/customer/wishlistApi";

import Button from "components/common/ui/Button/Button";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";

import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import { WishlistItem } from "types/Customer";

import WishlistDeleteModal from "./WishlistDeleteModal/WishlistDeleteModal";
import WishlistList from "./WishlistList/WishlistList";

import { AccountWishlistsContainer } from "./AccountWishlistOverview.Styled";

import useDataLayer from "datalayer/useDataLayer";
import { useSelector } from "redux/hooks";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

export type Wishlist = {
	customer_id: number;
	id: number;
	is_public: boolean;
	items: WishlistItem[] | [];
	name: string;
	token: string;
	share_url?: string;
};

const AccountWishlistOverview = () => {
	const dispatch = useDispatch();
	const datalayer = useDataLayer();
	const { currencies } = useSelector((state) => state.bcStore);

	const { t } = useTranslation();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Wishlists"));
	}, [dispatch]);

	const [getWishlistsTrigger, getWishlistsResult] =
		wishlistApi.useLazyGetWishlistsQuery();
	const [deleteWishlist] =
		wishlistApi.useDeleteWishlistMutation();

	const [loading, setLoading] = useState(true);
	const [deleteAll, setDeleteAll] = useState(false);

	useEffect(() => {
		getWishlistsTrigger();
	}, []);

	useEffect(() => {
		if (getWishlistsResult.data) {
			if (loading) {
				setLoading(false);
			}
		}
	}, [getWishlistsResult]);

	const wishlists = getWishlistsResult.data?.data || undefined;

	const currency = useMemo(() => {
		return currencies[0]?.code;
	}, [currencies]);

	const onDelete = async (id: Wishlist[keyof Wishlist]) => {
		await deleteWishlist(id)
			.unwrap()
			.then((data) => {
				if (data.success && data.data.deleted_items) {
					datalayer.removeWishListItem(
						data.data.deleted_items.map((item: any) => item.product),
						currency,
					);
				}
				getWishlistsTrigger();
			});
	};

	const onDeleteAll = async () => {
		setDeleteAll(true);
		if (wishlists.length !== 0) {
			const deletePromises = getWishlistsResult.data?.data.map(
				(wishlist: Wishlist) => deleteWishlist(wishlist.id).unwrap(),
			);
			await Promise.all(deletePromises);
			getWishlistsTrigger();
		}

		setDeleteAll(false);
	};

	return (
		<Fragment>
			{loading && <LoadingSpinner />}

			{!loading && (
				<AccountWishlistsContainer className="account-wishlists">
					<WishlistList
						wishlists={getWishlistsResult.data?.data}
						onDelete={onDelete}
						isLoading={getWishlistsResult.isLoading}
					/>
					<div className="account-wishlists__buttons">
						<Button
							variant="primary_01"
							link={{
								to: t(PathTranslationKey.ACCOUNT_WISHLIST_NEW).toString(),
							}}
						>
							{t("wishlists.newList")}
						</Button>
						{wishlists.length !== 0 && (
							<WishlistDeleteModal
								heading={t('wishlist.deleteWishlist')}
								description={t('wishlist.deleteWishlistConfirmation')}
								variant="primary_03"
								textLight
								isLoading={deleteAll}
								onDelete={onDeleteAll}
							>
								{t("wishlists.deleteAll")}
							</WishlistDeleteModal>
						)}
					</div>
				</AccountWishlistsContainer>
			)}
		</Fragment>
	);
};

export default AccountWishlistOverview;
