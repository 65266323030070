import { FunctionComponent } from "react";
import Typography from "../Typography/Typography";
import { AlertWrapper } from "./Alert.Styled";

export enum AlertType {
	SUCCESS,
	ERROR,
}

interface AlertProps {
	type: AlertType;
	title?: string;
	messages?: string[];
	className?: string;
}

const Alert: FunctionComponent<AlertProps> = ({
	type,
	title = "",
	messages,
	className = "",
}) => {
	if (!messages || messages.length === 0) {
		return null;
	}

	return (
		<AlertWrapper alertType={type} className={`Alert ${className}`}>
			{title && (
				<Typography variant="heading" size="small" narrow>
					{title}
				</Typography>
			)}
			{messages.map((message, index) => {
				return (
					<Typography key={index} variant="body" size="small" narrow>
						{message}
					</Typography>
				);
			})}
		</AlertWrapper>
	);
};

export default Alert;
