import useOrdersPagination from "hooks/useOrdersPagination";
import React, { useEffect } from "react";
import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import OrderList from "./OrderList/OrderList";

import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import Pagination from "components/common/ui/Pagination/Pagination";
import { useTranslation } from "react-i18next";

const AccountOrders = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Orders"));
	}, [dispatch]);

	// pagination
	const pagination = useOrdersPagination();
	const { t } = useTranslation();

	useEffect(() => {
		if (!pagination.uninitialized && !pagination.loading) {
			dispatch({
				type: "set_loading",
				payload: false,
			});
		}
	}, [pagination.loading]);

	if (pagination.showNoOrdersError) {
		return (
			<ErrorContent heading="No Products Found">
				{t("account.ordersNoProducts")}
			</ErrorContent>
		);
	}

	return (
		<div className="account-orders">
			{!pagination.loading && pagination.showNoPageError && (
				<ErrorContent heading="Invalid Page">
					{t("account.ordersNoOrdersFound")}
				</ErrorContent>
			)}
			<OrderList orders={pagination.getPage()} />
			<Pagination
				totalPages={pagination.totalPages}
				onChange={pagination.handlePageChange}
			/>
		</div>
	);
};

export default AccountOrders;
