import styled from "styled-components";
import { darkGrey, lightGrey, white } from "utilities/variables/colors";

export const CheckboxContainer = styled.div`
	position: relative;
	display: inline-flex;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const Checkbox = styled.div<{
	checked: boolean;
	colour: string;
  disabled?: boolean
}>`
	width: 24px;
	height: 24px;
	outline: 1px solid ${(props) => (props.checked ? darkGrey : lightGrey)};
  padding:3px;
  margin:8px;
  margin-left:1px;

	div {
    width: 100%;
	  height: 100%; 
    background-color: ${(props) => (props.colour || white)};
    opacity: ${(props) => (props.disabled?0.2:1)};

	}
`;
