import wordPressGetAboutUsPage from "app/gql/wordPressGetAboutUsPage";
import { wordPressGQL } from "app/services/wordPressGqlService";

const basicPagesApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getAboutUsPage: builder.query({
			query: (pageId: number) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetAboutUsPage(pageId),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;
				const {
					featuredImage: { node: mainBanner },
					aboutUs: {
						figures,
						additionalLinks,
						companyvalues,
						timeline,
						introduction,
						missionsStatement,
					},
					title: pageTitle,
					seo,
				} = res.data.pages.edges[0].node;

				let amendedFigures: any = {
					backgroundImage: figures.backgroundImage,
					content: [],
				};

				for (const [key, value] of Object.entries(figures)) {
					if (key !== "backgroundImage") {
						amendedFigures.content.push(value);
					}
				}

				return {
					mainBanner,
					pageTitle,
					figures: amendedFigures,
					timeline,
					additionalLinks,
					companyvalues,
					introduction,
					missionsStatement,
					seo
				};
			},
		}),
	}),
});

export const { useGetAboutUsPageQuery, useLazyGetAboutUsPageQuery } = basicPagesApi;
