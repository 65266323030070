import styled, { css } from "styled-components";
import { white, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const BreadcrumbNav = styled.nav`
  background-color: ${white};
  font-family: ${Roboto};
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
`;

export const BreadcrumbItem = styled.li<{
  active?: boolean;
}>`
  a {
    color: ${dark};
    letter-spacing: 0.7px;
    line-height: 17px;
    text-decoration: none;
    position: relative;

      
    &:hover:after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid ${dark};
    }

    ${({ active }) =>
      active &&
      css`
        font-weight: 700;
      `}
  }
`;

export const BreadcrumbWrapper = styled.div`
  padding: 16px;
  
  @media ${aboveMedium} {
    padding: 20px
  }
`;
