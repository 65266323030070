import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 392px;
  margin: 32px auto;
  padding: 0px 16px;

  @media ${aboveMedium} {
    margin: 64px auto;
    padding: 0;
  }
`;