import { useLazyGetMeQuery } from "app/api/customer/customerApi";
import useLogout from "hooks/auth/useLogout";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Fragment, FunctionComponent, ReactElement, useEffect } from "react";
import { useLocation } from "react-router";
import { setLoginRedirect, setState } from "redux/auth/authSlice";
import { useDispatch, useSelector } from "redux/hooks";

interface RequireAuthProps {
	children: ReactElement;
}

const RequireAuth: FunctionComponent<RequireAuthProps> = ({ children }) => {
	const store = useSelector((state) => state.bcStore.store);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useLocaleNavigate();

	const logout = useLogout(true);
	const { loginRedirect, customer, identifier, state } = useSelector(
		(state) => state.auth,
	);

	const [me] = useLazyGetMeQuery();

	useEffect(() => {
		if (store && identifier) {
			if (!identifier.storeHash && customer.id) {
				getCustomerDetails();
			}

			if (identifier.storeHash && !customer.id) {
				getCustomerDetails();
			}

			if ((identifier.storeHash || customer.id) && location.pathname.includes("account")) {
				getCustomerDetails();
			}

			if (!identifier.storeHash) {
				dispatch(setLoginRedirect(location.pathname));
				dispatch(setState(location.state));
				logout(store.hash, false);
			}
		}
	}, [store]);

	const getCustomerDetails = async () => {
		await me()
			.unwrap()
			.then((res) => {
				if (!res.success || !res.data) {
					logout();
				}
			})
			.catch((error: any) => {
				logout();
			});
	};

	if (!identifier.storeHash || !customer) {
		return null;
	}

	if (loginRedirect) {
		const redirect = loginRedirect;

		dispatch(setLoginRedirect(undefined));

		navigate(redirect, { state });

		return null;
	}

	return <Fragment>{children}</Fragment>;
};

export default RequireAuth;
