import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { white } from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledFiguresSection = styled.section`
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: ${white};

	@media ${aboveMedium} {
		min-height: ${remCalc(243)};
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	ul {
		margin: 0;
		padding: 0 2rem;

		@media (min-width: 850px) {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			padding: 2rem 0;
			list-style: none;
		}
	}

	li {
		display: block;
		text-align: center;
		font-size: ${remCalc(24)};
		font-weight: 700;
		text-transform: uppercase;
		padding: 1rem 1rem;
		border-bottom: solid 2px white;
		min-width: 0;

		&:last-of-type {
			border: none;
		}

		@media (min-width: 850px) {
			border-bottom: none;
			border-right: solid 2px white;
			padding: ${remCalc(32)} 1rem;
		}

		span {
			display: block;
			margin-top: ${remCalc(16)};
			font-size: 1.5rem;
			padding: 0 ${remCalc(47)};

			@media ${aboveMedium} {
				margin-top: ${remCalc(36)};
			}
		}
	}
`;
