import React from "react";

const useOutsideClick = (callback, controllerRef) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleClick = (event) => {
      if(controllerRef && controllerRef.current) {
        if(controllerRef.current.contains(event.target)) {
          return;
        }
      }

      const eventDataTarget = event.target.dataset.target || "";

      if(ref.current && eventDataTarget) {
        const refId = ref.current.id;
        if(eventDataTarget === refId) {
          return;
        } 
      }


      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [callback, ref]);

  return ref;
};

export default useOutsideClick;
