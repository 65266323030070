import AccordionItem from "components/common/ui/Accordion/AccordionItem";
import styled, { css } from "styled-components";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, grey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledAccordionItem = styled(AccordionItem)<{
	hasStock: boolean;
}>`
	.accordion__title {
		padding: ${remCalc(16)};
		background: unset;
		font-size: ${remCalc(16)};
		text-transform: uppercase;

		@media ${aboveMedium} {
			padding: ${remCalc(16)} 0;
		}
	}

	.accordion__body {
		padding: 0 ${remCalc(16)};

		.store-address{
			cursor: default;
			&:hover {
				text-decoration: none;
			}
		}

		@media ${aboveMedium} {
			padding: 0;
		}
	}

	${(props) =>
		!props.hasStock &&
		css`
			.accordion__title {
				font-weight: 500;
			}
			p,
			a,
			i {
				color: ${grey};
			}
			svg {
				fill: ${grey};
			}

			@media ${aboveMedium} {
				p,
				a,
				i {
					color: ${accountDarkGrey};
				}
				svg {
					fill: ${accountDarkGrey};
				}
			}
		`}

	${(props) =>
		props.hasStock &&
		css`
			p,
			a {
				color: ${accountDarkGrey};
			}

			svg {
				fill: ${accountDarkGrey};
			}

			.accordion__title {
				font-weight: 700;
			}
		`}
`;
