import styled from "styled-components";
import { darkGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledWrapper = styled.div`
  font: normal 14px/17px ${Roboto};
  color: ${darkGrey};

  p {
    margin: 0;
  }

  a {
    color: ${darkGrey};

    &:hover {
      color: ${dark};
    }
  }
`;
