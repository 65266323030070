import Page from "components/common/ui/Page/Page";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const StyledPage = styled(Page)`
	.PageContainer {
		justify-content: center;

		@media ${aboveMedium} {
			justify-content: start;
			padding-top: ${remCalc(32)};
		}
	}
`;
