import { useEffect } from "react";

/**
 * CheckoutEventData type
 * Represents the data structure of the event data received via postMessage from the checkout page
 */
export type CheckoutEventData<T> = {
	type: "sgfObject" | "segmentifyDataLayer";
	payload: T;
};

/**
 * Extends the Window interface to include the Segmentify object
 */
declare global {
	interface Window {
		/**
		 * Segmentify object
		 * Used to store Segmentify object received via postMessage
		 */
		sgfObject: any;
		segmentifyDataLayer: any[];
	}
}

/**
 * Pushes the dataLayer event to the Segmentify dataLayer
 * @param dataLayerEvent The dataLayer event to push
 */
export const pushSegmentifyDataLayer = (dataLayerEvent: any) => {
	console.debug("pushSegmentifyDataLayer", dataLayerEvent);

	if (window.segmentifyDataLayer?.push) {
		window.segmentifyDataLayer.push(dataLayerEvent);
	} else window.segmentifyDataLayer = [dataLayerEvent];
};

/**
 * PostMessageHandlers component
 * Handles messages received via window.postMessage
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
const PostMessageHandlers = () => {
	useEffect(() => {
		const mainEventListener = (event: MessageEvent) => {
			console.debug("PostMessageHandlers event", event.origin, event);

			const checkoutOriginPattern =
				process.env.REACT_APP_CHECKOUT_ORIGIN_PATTERN;
			if (!checkoutOriginPattern) {
				console.error("REACT_APP_CHECKOUT_ORIGIN_PATTERN is not set");
				return;
			}

			if (event.origin.match(checkoutOriginPattern)) {
				console.log("Checkout event detected", event.origin, event);

				const data = event.data as CheckoutEventData<any>;
				if (data.type === "sgfObject") {
					console.log(
						"Segmentify object detected. Setting to window",
						data.payload,
					);

					window.sgfObject = data.payload;
				} else if (data.type === "segmentifyDataLayer") {
					console.log(
						"Segmentify dataLayer event detected. Pushing to dataLayer",
						data.payload,
					);

					pushSegmentifyDataLayer(data.payload);
				} else console.error("Event data type is not handled", data.type);
			} else {
				console.debug("Event origin does not match checkout origin pattern", {
					origin: event.origin,
					pattern: checkoutOriginPattern,
				});
			}
		};

		window.addEventListener("message", mainEventListener);

		return () => {
			window.removeEventListener("message", mainEventListener);
		};
	}, []);

	return (
		<span aria-label="post-message-handlers" style={{ display: "none" }}></span>
	);
};

export default PostMessageHandlers;
