import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledCareersPageWrapper = styled.section`
	h1 {
		color: black;
		text-align: center;
	}


	.container {
		@media ${aboveMedium} {
			margin-bottom: ${remCalc(64)};
		}
	}

	h3 {
		padding-right: ${remCalc(20)};
		margin-bottom: ${remCalc(16)};
	}

	.text {
		margin: auto 0;
	}

	.skeleton-container {
		height: 100%;
		display: block;
	}

	.skeleton-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		flex-wrap: wrap;
		margin-top: ${remCalc(64)};
	}

	.skeleton-text {
		padding: 0 ${remCalc(30)};
		width: 100%;
		margin-top: ${remCalc(48)};

		@media ${aboveMedium} {
			padding: 0 ${remCalc(64)};
			width: 50%;
		}
	}

	.skeleton-carousel-wrapper {
		margin: ${remCalc(64)} ${remCalc(20)} 0 ${remCalc(109)};

		@media ${aboveMedium} {
			margin: ${remCalc(96)} ${remCalc(20)} 0 ${remCalc(109)};
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 40px;
		}

		span {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.skeleton-carousel {
			display: flex;
			flex-direction: column;
			align-items: center;

			&.desktop {
				display: none;

				@media ${aboveLarge} {
					display: flex;
				}
			}
			&.tablet {
				display: none;

				@media ${aboveMedium} {
					display: flex;
				}
			}
		}
	}

	.swiper-slide {
		height: auto;
	}
`;

export const SkeletonImageSplitWrapper = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	@media ${aboveMedium} {
		width: 50%;
	}

	.skeleton-container {
		width: 100%;
	}

	// Style image
	.react-loading-skeleton {
		width: 100%;
		height: ${remCalc(230)};

		@media ${aboveMedium} {
			height: ${remCalc(590)};
		}

		@media ${aboveLarge} {
			height: ${remCalc(640)};
		}
	}
`;

export const StyledMainImageBannerCareers = styled.div`
	&.main-image-banner--careers {

		.SingleImageWithCta {
			height: ${remCalc(231)};
			margin-bottom: ${remCalc(64)};

			@media ${aboveMedium} {
				height: ${remCalc(435)};
			}
		}

		.styled-image-cta-container {
			margin-bottom: 0;
		}
	}
`;

export const StyledImageBanner = styled(ImageBanner)`
	.SingleImageWithCta {
		height: ${remCalc(295)} !important;
		img {
			height: ${remCalc(295)};
		}

		@media ${aboveMedium} {
			height: ${remCalc(359)} !important;
			img {
				height: ${remCalc(359)};
			}
		}
		&:first-of-type {
			margin-top: ${remCalc(64)};

			@media ${aboveMedium} {
				margin-top: ${remCalc(30)};
			}
		}
		&:last-of-type {
			margin-bottom: ${remCalc(64)};

			@media ${aboveMedium} {
				margin-bottom: ${remCalc(30)};
			}
		}
	}

	.StyledTextWrapper {
		position: relative;
		margin-bottom: ${remCalc(28)};
		margin-top: ${remCalc(32)};
	}
	.StyledCtaWrapper {
		position: relative;
		margin-top: ${remCalc(28)};
	}

	a {
		height: ${remCalc(40)};
		text-transform: none;
		font-size: ${remCalc(16)};
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
