import React from "react";

import AccordionItem from "./AccordionItem";

import { StyledContainer } from "./Accordion.Styled";

type Props = {
  children: React.ReactNode;
  className?: string
};

const Accordion = ({ children, className = "", ...props }: Props) => {
  return (
    <StyledContainer className={`accordion ${className}`} {...props}>
      {children}
    </StyledContainer>
  );
};

Accordion.Item = AccordionItem;

export default Accordion;
