import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "redux/hooks";

import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { KlaviyoSettings } from "app/types/klaviyo";
import { WordpressPageTemplate } from "app/types/wordpress";
import {
	setKlaviyoSettings,
	setWordpressTemplates,
} from "redux/slices/settingsSlice";
import { setPhoneNumber } from "redux/UI/uiSlice";
import { MenuSlug } from "app/constants/menuSlugs";

const WordPressManager = (props: any) => {
	const dispatch = useDispatch();
	const { store } = useSelector((state) => state.bcStore);

	const [currentContentFor, setCurrentContentFor] = useState("");

	const [getGlobalStoreSettings] =
		wordPressApi.useLazyGetGlobalStoreSettingsQuery();

	const [getPageTemplates, getPageTemplatesResult] =
		wordPressApi.useLazyGetPageTemplatesQuery();

	const [getPhoneNumber] = wordPressApi.useLazyGetPhoneNumberQuery();

	const prefetchCartNeedHelpLinks =
		wordPressApi.usePrefetch("getMenuItemsBySlug");

	useEffect(() => {
		if (store) {
			getGlobalStoreSettings()
				.unwrap()
				.then((settings) => {
					const globalStoreSettings =
						settings?.storeGeneralSettings?.globalStoreSettings;

					const klaviyoSettings = {
						marketingListId:
							globalStoreSettings?.accountcreationEmaillistId || "",
						signUpFormId: globalStoreSettings?.signUpFormId || "",
					} as KlaviyoSettings;

					dispatch(setKlaviyoSettings(klaviyoSettings));
				});

			getPhoneNumber()
				.unwrap()
				.then((data) => {
					dispatch(setPhoneNumber(data));
				});

			prefetchCartNeedHelpLinks(MenuSlug.CART_NEED_HELP, { force: true });
		}
	}, [dispatch, getGlobalStoreSettings, store]);

	useEffect(() => {
		if (store && store.code !== currentContentFor) {
			setCurrentContentFor(store.code);
		}
	}, [dispatch, getPageTemplates, store]);

	useEffect(() => {
		if (currentContentFor) {
			getPageTemplates()
				.unwrap()
				.then((data) => {
					if (!data) {
						return;
					}

					dispatch(
						setWordpressTemplates(
							data.filter(
								(obj: WordpressPageTemplate) => obj.template !== undefined,
							),
						),
					);
				});
		}
	}, [currentContentFor]);

	if (
		getPageTemplatesResult.isUninitialized ||
		getPageTemplatesResult.isLoading
	) {
		return null;
	}

	return <>{props.children}</>;
	
};

export default WordPressManager;
