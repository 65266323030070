import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AddressInput, EmailInput, NameInput } from "types/GravityForm";

export interface FieldValue {
	id: number;
}

export interface StringFieldValue extends FieldValue {
	value: string;
}

export interface NumberFieldValue extends FieldValue {
	value: number;
}

export interface EmailFieldValue extends FieldValue {
	emailValues: EmailInput;
}

export interface NameFieldValue extends FieldValue {
	nameValues: NameInput;
}

export interface AddressFieldValue extends FieldValue {
	addressValues: AddressInput;
}

// set this to number as the boolean will return a number value of 0 / 1
export interface ConsentFieldValue extends FieldValue {
	value: string;
}

export interface SelectFieldValue extends FieldValue {
	value: string;
}

export interface CheckboxFieldValue extends FieldValue {
	checkboxValues: {
		value: string,
		inputId: number,
	}[]
}
export interface RadioFieldValue extends FieldValue {
	radioValue: string | null
}

export interface RecaptchaFieldValue extends FieldValue {
	value: string | null;
}

export interface FileUploadFieldValue extends FieldValue {
	fileUploadValues: File[];
}

export interface SurveyFieldValue extends FieldValue {
	value: string;
}

export type FieldValueUnion =
	| SurveyFieldValue
	| StringFieldValue
	| NumberFieldValue
	| NameFieldValue
	| EmailFieldValue
	| AddressFieldValue
	| ConsentFieldValue
	| FileUploadFieldValue
	| RecaptchaFieldValue
	| SelectFieldValue
	| RadioFieldValue
	| CheckboxFieldValue;

interface GravityFormState {
	fieldValues: FieldValueUnion[];
}

const initialState: GravityFormState = {
	fieldValues: [],
};

// A helper function that filters out the field value to update from the state
const getOtherFieldValues = (state: GravityFormState, id: number) =>
	state.fieldValues.filter((fieldValue) => fieldValue.id !== id);

const gravityFormSlice = createSlice({
	name: "gravityForm",
	initialState,
	reducers: {
		updateStringFieldValue: (
			state,
			action: PayloadAction<StringFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateSurveyFieldValue: (
			state,
			action: PayloadAction<StringFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateNumberFieldValue: (
			state,
			action: PayloadAction<NumberFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateNameFieldValue: (state, action: PayloadAction<NameFieldValue>) => {
			const { id, nameValues } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, nameValues }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateRecaptchaFieldValue: (
			state,
			action: PayloadAction<RecaptchaFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateEmailFieldValue: (state, action: PayloadAction<EmailFieldValue>) => {
			const { id, emailValues } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, emailValues }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateAddressFieldValue: (
			state,
			action: PayloadAction<AddressFieldValue>,
		) => {
			const { id, addressValues } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, addressValues }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateConsentFieldValue: (
			state,
			action: PayloadAction<ConsentFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateSelectFieldValue: (
			state,
			action: PayloadAction<SelectFieldValue>,
		) => {
			const { id, value } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, value }];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateCheckboxFieldValue: (
			state,
			action: PayloadAction<CheckboxFieldValue>,
		) => {
			const { id, checkboxValues } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, checkboxValues}];

			return { ...state, fieldValues: nextFieldValues };
		},
		updateRadioFieldValue: (
			state,
			action: PayloadAction<RadioFieldValue>,
		) => {
			const { id, radioValue } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, radioValue }];
		
			return { ...state, fieldValues: nextFieldValues };
		},
		updateFileUploadFieldValue: (
			state,
			action: PayloadAction<FileUploadFieldValue>,
		) => {
			const { id, fileUploadValues } = action.payload;
			const otherFieldValues = getOtherFieldValues(state, id);
			const nextFieldValues = [...otherFieldValues, { id, fileUploadValues }];

			return { ...state, fieldValues: nextFieldValues };
		},
		// 0 args resets all fields
		// A fieldId or an array of fieldIds resets only the given field/s
		resetFieldValues: (
			state,
			action: PayloadAction<number | number[] | undefined>,
		) => {
			if (action.payload === undefined) {
				return initialState;
			}

			if (Number.isInteger(action.payload)) {
				const nextFieldValues = state.fieldValues.filter(
					(fieldValue) => fieldValue.id !== action.payload,
				);

				return { ...state, fieldValues: nextFieldValues };
			}

			if (Array.isArray(action.payload)) {
				const fieldIdArray = action.payload.map((id) => String(id));

				const nextFieldValues = state.fieldValues.filter(
					(fieldValue) => !fieldIdArray.includes(String(fieldValue.id)),
				);

				return { ...state, fieldValues: nextFieldValues };
			}
		},
	},
});

export const {
	updateStringFieldValue,
	updateNumberFieldValue,
	updateNameFieldValue,
	updateEmailFieldValue,
	updateAddressFieldValue,
	updateConsentFieldValue,
	updateSelectFieldValue,
	updateCheckboxFieldValue,
	updateRadioFieldValue,
	updateFileUploadFieldValue,
	updateRecaptchaFieldValue,
	resetFieldValues,
	updateSurveyFieldValue,
} = gravityFormSlice.actions;

export default gravityFormSlice.reducer;
