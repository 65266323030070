import { FunctionComponent, useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";

const WashingInstructionIcon: FunctionComponent<{
	code: string;
}> = ({ code }) => {
	const [element, setElement] = useState<JSX.Element | null>(null);

	useEffect(() => {

		if(code === "/") {
			code = "AB"
		}

		if(code.replace(/(?![A-Z])./g, '').length === 0 && !code.endsWith("1")) {
			code = `${code}1`;
		}

		const src = `/assets/svg/WI__${code}.svg`;

		setElement(<IonIcon src={src} />);

	}, [code]);

	return element;
};

export default WashingInstructionIcon;
