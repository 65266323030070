import styled from "styled-components";
import { IonAccordionGroup, IonItem, IonLabel } from "@ionic/react";

import Link from "components/common/ui/Link/Link";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { Breakpoint } from "ts/enums";

import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledAccordionGroup = styled(IonAccordionGroup)`
  display: flex;
  flex-direction: column;
  --ion-border-color: #adb5bd;

  > .accordion-collapsed:last-of-type ion-item[slot="header"] {
    --border-width: 0 0 1px 0;
  }

  > .accordion-expanded,
  .accordion-expanding {
    border-bottom: 1px solid var(--ion-border-color);
  }
`;

export const StyledLabelLink = styled(Link)`
  font: bold 16px/20px ${Roboto};
  letter-spacing: 0.8px;
  color: ${dark};
  text-transform: uppercase;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${darkGrey};
  }
`;

export const StyledLabel = styled(IonLabel)`
  font: bold 16px/20px ${Roboto};
  letter-spacing: 0.8px;
  color: ${dark};
  text-transform: uppercase;

  &:hover,
  &:active {
    color: ${darkGrey};
  }
`;

export const StyledLink = styled(Link)`
  font: normal 14px/17px ${Roboto};
  letter-spacing: 0px;
  color: ${dark};
  text-decoration: none;

  &:hover,
  &:active {
    color: ${darkGrey};
    text-decoration: underline;
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 6px 20px;
  list-style: none;
`;

export const StyledItem = styled(IonItem)`
  --padding-start: 20px;
`;

export const StyledMenu = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto 18px;

  gap: 12px;
  padding: 0px 12px;

  @media (min-width: ${Breakpoint.LARGE}px) and (max-width: 851px) {
    gap: 8px;
    padding: 0px 8px;
  }

  button {
    width: min-content;
  }

  @media ${aboveLarge} {
    gap: 24px;
    padding: 0px 24px;

    button {
      width: unset;
    }
  }
`;

export const StyledMenuItem = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;

  &:hover,
  &:active {
    ion-label {
      color: ${darkGrey};
    }
  }
`;

export const StyledMenuContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px 20px;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #adb5bd;
`;

export const SkeletonMenuItem = styled(Skeleton)`
  width: 180px;

  @media ${aboveMedium} {
    width: 90px;
  }

  @media ${aboveLarge} {
    width: 110px;
  }

  .react-loading-skeleton {
    width: 100%;
    height: 20px;
  }
`;
