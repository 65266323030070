import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";

export const StyledBlogPageSkeletonWrapper = styled.section`
	.one-col-wrapper {
		@media ${aboveMedium} {
			display: grid;
			grid-template-columns: 1;
			grid-gap: 2rem;
			margin-bottom: 6rem;
		}
	}
	.three-col-wrapper {
		@media ${aboveMedium} {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 2rem;
			margin-bottom: 6rem;
		}
	}

	.two-col-portrait-wrapper {
		@media ${aboveMedium} {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 2rem;
			width: 90%;
			margin-inline: auto;
		}
	}
`;
