import styled from "styled-components";

import { remCalc } from "utilities/styles";
import {
    accountDarkGrey,
    dark,
    darkGrey,
    grey,
    lightGrey
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const OrderListContainer = styled.div`
	p {
		margin: 0;
	}

	.order-list__cards {
		display: block;

		@media ${aboveMedium} {
			display: none;
		}

		.order-list-card {
			display: grid;
			grid-template-columns: auto 1fr;
			row-gap: ${remCalc(8)};
			padding: ${remCalc(16)} 0;
			border-bottom: 1px solid ${lightGrey};

			&:first-of-type {
				padding-top: 0;
			}

			&:last-of-type {
				border: none;
				padding-bottom: 0;
			}

			.label {
				font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${accountDarkGrey};
				width: fit-content;
				margin-right: ${remCalc(8)}
			}

			.value {
				font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${dark};
			}

			a.value {
				text-decoration: none;

				&:hover {
					color: ${darkGrey};
					text-decoration: underline;
				}
			}

			a.button {
				grid-column: span 2;
				margin-top: ${remCalc(8)};
				width: 100%;
			}
		}
	}

	.order-list__table {
		display: none;
		border-collapse: collapse;
		width: 100%;
		margin: 0 auto;
		table-layout: fixed;

		.value {
			color: ${dark};
			text-decoration: none;

			&:hover {
				color: ${darkGrey};
				text-decoration: underline;
			}
		}

		@media ${aboveMedium} {
			display: table;
		}

		th,
		td {
			white-space: nowrap;
			text-align: left;
			padding: ${remCalc(16)} 0;
		}

		td {
			min-height: 4.5rem;
		}

		thead > tr {
			border-bottom: 1px solid ${grey};
		}

		tbody > tr {
			border-bottom: 1px solid ${lightGrey};

			&:last-child {
				border: none;
			}
		}

		th {
			font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
			color: ${accountDarkGrey};
		}

		td {
			font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
			color: ${dark};

			&:last-child {
				display: flex;
				justify-content: flex-end;
			}
		}
	}

	.button {
		text-transform: capitalize;
	}
`;

export const StyledErrorMessage = styled.div`
	p {
		text-align: center;
		padding: 0 ${remCalc(20)};
	}
`;