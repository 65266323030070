import styled from "styled-components";
import { lightGrey, white } from "utilities/variables/colors";

export const CheckboxContainer = styled.div`
	position: relative;
	display: inline-flex;
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const Checkbox = styled.div<{
  checked: boolean;
  disabled: boolean
}>`
  width: 24px;
  height: 24px;
  outline: 1px solid ${lightGrey};
  margin: 8px;
  margin-left: 0;
  background-color: ${(props) => !props.disabled ? white : "#f0efef"};

  svg {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    fill: ${(props) => props.disabled && "#aaa"};
  }
`;

