import { Capacitor } from "@capacitor/core";
import {
	AppTrackingStatus,
	AppTrackingTransparency,
} from "capacitor-plugin-app-tracking-transparency";
import { useEffect, useState } from "react";

const useIosTrackingConsent = () => {
	const isIOS = Capacitor.getPlatform() === "ios";

	const [status, setStatus] = useState<AppTrackingStatus>(
		isIOS ? "notDetermined" : "authorized",
	);

	useEffect(() => {
		if (!isIOS) {
			return;
		}

		AppTrackingTransparency.getStatus().then((res) => {
			if (res.status !== status) {
				setStatus(res.status);
			}
		});
	}, []);

	useEffect(() => {
		if (!isIOS && status !== "notDetermined") {
			return;
		}

		AppTrackingTransparency.requestPermission().then((res) => {
			if (res.status !== status) {
				setStatus(res.status);
			}
		});
	}, [status, isIOS]);

	useEffect(() => {}, []);

	return {
		isIOS,
		isAllow: status === "authorized",
		status,
	};
};

export default useIosTrackingConsent;
