import { useNavigate, useSearchParams } from "react-router-dom";
import { OddContainer } from "../BlogContainer.styles";
import { StyledButtonToggle } from "./BlogTagToggleButton.styles";
import { useTranslation } from "react-i18next";

const getTagFromParams = (searchParams: URLSearchParams) => {
	const tagParam = searchParams.get("tag");

	const tag = !tagParam ? "" : tagParam;

	return tag;
};
interface Props {
	setFilter: Function;
}
export const BlogTagSelector = ({ setFilter }: Props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const handleSearchParams = (value: string) => {
		const tagParam = getTagFromParams(searchParams);
		if (tagParam !== value) {
			setFilter(value);
			navigate("?tag=" + value);
		} else {
			setFilter("");
			navigate("");
		}
	};

	const { t }  = useTranslation();

	return (
		<OddContainer>
			<div className="tagFilterContainer">

				<StyledButtonToggle
					onClick={() => handleSearchParams(t("women"))}
					variant={getTagFromParams(searchParams) === t("women") ? "primary_01":"primary_03"}
					width={160}
				>
					{t("women")}
				</StyledButtonToggle>
				<StyledButtonToggle
					variant={getTagFromParams(searchParams) === t("men") ? "primary_01":"primary_03"}
					onClick={() => handleSearchParams(t("men"))}
					width={160}
				>
					{t("men")}
				</StyledButtonToggle>
			</div>
		</OddContainer>
	);
};
