import { PageContainer } from "components/common/ui/Page/Page.styled";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

interface Padding {
	top?: string;
	bot?: string;
}

export const ContentWrapper = styled.div`
	.Pagination {
		margin-top: ${remCalc(48)};
		margin-bottom: ${remCalc(68)};
	}

	@media ${aboveMedium} {
		.Pagination {
			margin-top: ${remCalc(64)};
		}
	}
`;

export const EvenContainer = styled(PageContainer)<Padding>`
	background: white;
	width: 100%;
	flex-grow: 1;
	display: flex;
	justify-content: center;

	padding-top: ${({ top }) => top || 0};
	padding-bottom: ${({ bot }) => bot || 0};

	@media ${aboveMedium} {
		padding-top: ${({ top }) => top || remCalc(64)};
		padding-bottom: ${({ bot }) => bot || remCalc(32)};
	}

	@media ${aboveMedium} {
		padding-left: 9%;
		padding-right: 9%;
	}

	.footer {
		padding-bottom: 3rem;
		padding-top: ${remCalc(13.5)};
		@media ${aboveMedium} {
			padding-bottom: 0;
			padding-top: 2rem;
		}
	}
`;
export const OddContainer = styled.section`
	.tagFilterContainer {
		padding: 1rem;
		width: ${remCalc(360)};
		@media ${aboveMedium} {
			width: ${remCalc(376)};
		}
		display: flex;
		justify-content: space-between;
	}
	background: #f8f9fa;
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
`;
