import styled from 'styled-components';
import { pageContentContainer } from 'theme/StyledMixins';
import { remCalc } from 'utilities/styles';
import {
	accountDarkGrey,
	accountLightGrey,
	dark,
} from 'utilities/variables/colors';
import { Roboto } from 'utilities/variables/fonts';
import { aboveMedium } from 'utilities/variables/media';

export const StyledAwardsPageWrapper = styled.div`
	* {
		font-family: ${Roboto};
	}

	.awards-content {
		${pageContentContainer};
		padding: ${remCalc(64)} 0;
		text-align: center;

		@media ${aboveMedium} {
			padding: ${remCalc(96)} ${remCalc(24)};
			max-width: ${remCalc(604)};
		}

		p {
			font-height: ${remCalc(16)};
			color: ${accountDarkGrey};
		}

		p:first-of-type {
			letter-spacing: 1.2px;
			font-size: ${remCalc(24)};
			color: ${dark};
			margin-bottom: ${remCalc(16)};
		}
	}

	.skeleton-content {
		height: ${remCalc(262)};
		margin: ${remCalc(64)} auto;
		max-width: calc(100% - 44px);
		display: flex;

		@media ${aboveMedium} {
			height: ${remCalc(150)};
			max-width: ${remCalc(604)};
		}
	}

	.text-image-grid {
		margin-bottom: ${remCalc(64)};

		@media ${aboveMedium} {
			gap: ${remCalc(8)};
			margin-bottom: ${remCalc(96)};
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.StyledImageTextGrid {
			@media ${aboveMedium} {
				min-height: ${remCalc(307)};
			}

			.text-container {
				background-color: ${accountLightGrey};
				padding: ${remCalc(48)} ${remCalc(32)} ${remCalc(56)};
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.image {
				min-height: ${remCalc(307)};
			}
		}

		h3 {
			font-size: ${remCalc(16)};
			letter-spacing: 0.8px;
			font-weight: 700;
			color: ${dark};
			margin: 0 0 ${remCalc(8)};
			text-transform: uppercase;
		}

		p {
			font-height: ${remCalc(16)};
			color: ${accountDarkGrey};
			margin: 0;
		}

		.skeleton-grid {
			height: ${remCalc(436)};
			margin: ${remCalc(8)} 0;

			@media ${aboveMedium} {
				height: ${remCalc(307)};
				margin: 0;
			}
		}
	}
`;
