export const ValidWashingInstructionCodes = [
	"A",
	"a",
	"/",
	"B",
	"b",
	"C",
	"c",
	"D",
	"d",
	"E",
	"e",
	"F",
	"f",
	"G",
	"g",
	"H",
	"h",
	"I",
	"i",
	"J",
	"j",
	"K",
	"k",
	"L",
	"l",
	"M",
	"m",
	"N",
	"n",
	"O",
	"o",
	"P",
	"p",
	"Q",
	"q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
];
