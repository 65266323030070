import React, { useEffect, useState } from "react";

import { ReactComponent as StoreAddress } from "assets/images/svg/Store Address.svg";
import { ReactComponent as StoreOpeningTimes } from "assets/images/svg/Store Opening Times.svg";
import { ReactComponent as StorePhoneNumber } from "assets/images/svg/Store Phone Number.svg";
import { ReactComponent as StoreEmail } from "assets/images/svg/Store Email.svg";

import { Store } from "ts/types";

import {
	AccordionBodyContainer,
	StyledBodyText,
	StyledLink,
	StyledRow,
} from "./AccordionBody.Styled";
import { useTranslation } from "react-i18next";

type Props = {
	store: Store;
	storeAddressClick?: () => void;
};



const AccordionBody = ({ store, storeAddressClick }: Props) => {

	const [phoneNumbers, setPhoneNumbers] = useState(['']);
	const { t } = useTranslation();

	const openingTimes = React.useMemo(() => {
		const days = [
			"monday",
			"tuesday",
			"wednesday",
			"thursday",
			"friday",
			"saturday",
			"sunday",
		];

		const openingTimes = [];

		for (const day of days) {
			const { closed, open, close } =
				store.storeInformation.storeOpeningTimes[day];

			if (closed) {
				openingTimes.push({
					day: t(day),
					open,
					close,
					closed,
				});
			}

			if (!closed && open && close) {
				openingTimes.push({
					day: t(day),
					open,
					close,
					closed,
				});
			}
		}

		return openingTimes;
	}, [store.storeInformation.storeOpeningTimes, t]);

	useEffect(() => {

        let inputPhoneNumber = store.storeInformation.phoneNumber;
		const separatedPhoneNumbers  = inputPhoneNumber.split(';');
		setPhoneNumbers(separatedPhoneNumbers);
		
	}, [])

	return (
		<AccordionBodyContainer>
			<StyledRow>
				<StoreAddress />
				<StyledBodyText className="store-address" onClick={storeAddressClick}>
					{[
						store.storeInformation.address.addressLine1,
						store.storeInformation.address.addressLine2,
					]
						.filter((i) => Boolean(i))
						.join(", ")}
				</StyledBodyText>
				{store.storeInformation.storeLocation.googleMapsLink && (
					<StyledLink
						href={store.storeInformation.storeLocation.googleMapsLink}
						target="_blank"
						rel="noreferrer"
					>
						({t("google-maps-link")})
					</StyledLink>
				)}
			</StyledRow>
			{phoneNumbers.map((phoneNumber, index) => 
				<StyledRow key={index}>
					<StorePhoneNumber />
					<StyledLink href={`tel:${phoneNumber}`}>
						{phoneNumber}
					</StyledLink>
				</StyledRow>
				)
			}
			{store.storeInformation.emailAddress && (
				<StyledRow>
					<StoreEmail />
					<StyledBodyText>{store.storeInformation.emailAddress}</StyledBodyText>
				</StyledRow>
				)
			}
			{openingTimes.map(({ day, open, close, closed }) => (
				<StyledRow key={day}>
					<StoreOpeningTimes />
					{closed ? (
						<StyledBodyText>{`${day}: ${t("closed")}`}</StyledBodyText>
					) : (
						<StyledBodyText>{`${day}: ${open} - ${close}`}</StyledBodyText>
					)}
				</StyledRow>
			))}
		</AccordionBodyContainer>
	);
};

export default AccordionBody;
