import ButtonGroup from "components/common/ui/ButtonGroup/ButtonGroup";
import Link from "components/common/ui/Link/Link";
import styled from "styled-components";
import { accountDarkGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import Markdown from "components/common/ui/Markdown/Markdown";
import { aboveMedium } from "utilities/variables/media";
import { remCalc } from "utilities/styles";

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  font: normal 14px/17px ${Roboto};
  color: ${accountDarkGrey};
  text-underline-offset: 2px;

  &:hover {
    color: ${dark};
  }
`;

export const StyledWideLink = styled(StyledLink)`
  text-transform: uppercase;
  letter-spacing: 0.7px;
  text-align: center;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  margin: 32px 0px;
`;

export const StyledText = styled.p`
  font: bold 14px/17px ${Roboto};
  color: ${dark};
  text-transform: uppercase;
  margin: 8px 16px;
`;

export const SocialLoginButtonGroup = styled(ButtonGroup)`
  button {
    flex: 1 1 0px;
  }
`;

export const RegisterSection = styled.div`
  display: flex;
  flex-direction: column;
  button,
  .btn {
    margin-top: 24px;
  }
`;

export const CMSContent = styled(Markdown)`
  margin-top: 8px;
`;

export const SigninPageWrapper = styled.div`
  width: 100%;
  max-width: 392px;
  margin: 32px auto;
  padding: 0px 16px;

  @media ${aboveMedium} {
    margin: 64px auto;
    padding: 0;
  }

  .Alert {
    margin-bottom: ${remCalc(24)};
  }
`;
