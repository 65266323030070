import { HeadingSmall } from "components/common/ui/Typography/Typography.Styled";
import styled from "styled-components";
import { dark, darkGrey } from "utilities/variables/colors";

export const LocationButton = styled.button`
    background-color: transparent;
    display: flex;
    align-items: center;
    color: ${dark};
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;

    &:hover {
      color: ${darkGrey};
    }
`;

export const ModalTitle = styled(HeadingSmall)`
  display: flex;
  align-items: center;

  text-transform: none;
  font-size: 16px;
  letter-spacing: 0px;

  i.icon {
    font-size: 22px;
    vertical-align: bottom;
  }
`
