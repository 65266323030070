import { gql } from "graphql-request";

const bigcommerceGetStoreSettings = () => ({
	operationName: "GetStoreSettings",
	query: gql`
		query GetStoreSettings {
			site {
				currencies {
					edges {
						node {
							name
							isActive
							code
							exchangeRate
							display {
								symbol
								symbolPlacement
								decimalToken
								thousandsToken
								decimalPlaces
							}
						}
					}
				}
				settings {
					url {
						checkoutUrl
					}
					display {
						shortDateFormat
						extendedDateFormat
					}
					inventory {
						productOutOfStockBehavior
						optionOutOfStockBehavior
					}
				}
			}
		}
	`,
});

export default bigcommerceGetStoreSettings;
