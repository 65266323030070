import { gql } from "graphql-request";
import { ProductOnProduct } from "../fragments/product";

export const bigcommerceObjectByUrl = (path: string) => {
	return {
		operationName: "GetObjectByUrl",
		variables: {
			path,
		},
		query: gql`
			query GetObjectByUrl($path: String!) {
				site {
					route(path: $path) {
						node {
							... on Category {
								id
								entityId
								name
								description
								breadcrumbs(depth: 4) {
									edges {
										node {
											entityId
											name
										}
									}
								}
								seo {
									pageTitle
									metaDescription
									metaKeywords
								}
							}
							... on Product {
								...Product
							}
						}
					}
				}
			}
			${ProductOnProduct}
		`,
	};
};
