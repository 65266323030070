import { gql } from "graphql-request";
import { ProductOnProductEdge } from "../fragments/product";

export const bigcommerceGetCategoryProductPage = (
  entityId: number,
  pageSize: number,
  cursor?: string
) => {
  return {
    operationName: "GetCategoryProductPage",
    variables: {
      entityId,
      pageSize,
      cursor,
    },
    query: gql`
      query GetCategoryProductPage(
        $entityId: Int!
        $pageSize: Int
        $cursor: String
      ) {
        site {
          category(entityId: $entityId) {
            products(first: $pageSize, after: $cursor) {
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
              edges {
                ...Product
              }
            }
          }
        }
      }

      ${ProductOnProductEdge}
    `,
  };
};
