import styled from "styled-components";

export const MenuList = styled.ul`
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
	}
`;
