import { ReactComponent as Orders } from "assets/images/svg/Overview - Orders.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconOrders({className}: Props): ReactElement {
    return <Icon className={className} icon={<Orders />} />;
}

export default IconOrders;