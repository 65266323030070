import { createApi } from "@reduxjs/toolkit/query/react";
import { bigCommerceStoreBaseQuery } from "./integrationLayerService";

const bigCommerceStoreGQL = async (args, api, extraOptions) => {
	const adjustedArgs = {
		...args,
		url: "/bc/graphql",
		body: {
			gql: {
				...args.body,
				query: args.body.query.replace(/\s+/g, " ").trim(),
			},
		},
	};

	// provide the amended url and other params to the raw base query
	return bigCommerceStoreBaseQuery(adjustedArgs, api, extraOptions);
};

export const bigCommerceGQL = createApi({
	reducerPath: "bigCommerceGQL",
	baseQuery: bigCommerceStoreGQL,
	endpoints: (builder) => ({}),
});
