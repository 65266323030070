import React, { useEffect, useState } from "react";

import {
	FieldValue,
	StringFieldValue,
	updateSurveyFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
	FieldError,
	FormField,
	SurveyField as SurveyFieldType,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputRating, StyledInputText } from "../GravityForm.Styled";
import { useTranslation } from "react-i18next";


interface Props {
	field: FormField & Partial<SurveyFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: string;
}


const SurveyField = ({ field, fieldErrors, defaultValue }: Props) => {
	const { id } = field;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isFieldRequired, setIsFieldRequired] = useState(false);
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);
	

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as StringFieldValue | undefined;

	const textValue = fieldValue?.value || defaultValue;

	const updateValue = React.useCallback(
		(value: string) => {
			dispatch(updateSurveyFieldValue({ id, value }));
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		
		if (typeof event === 'number') {
			
			updateValue(String(event));
		} else {
			
			const newValue = event.target.value;
			updateValue(newValue);
		}
	};

	React.useEffect(() => {
		if (textValue) updateValue(textValue);
	}, [textValue, updateValue]);

	useEffect(() => {
		if (field.cssClass && field.cssClass.includes("required")) {
		  setIsFieldRequired(true); 
		} else {
		  setIsFieldRequired(false);
		}
	  }, [field.cssClass]);


	return (
		<>		
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>		
			{field.inputType === 'RATING' ? (
			<StyledInputRating 
			  label={field.adminLabel}
			  value={Number(textValue) || 0}
			  required={isFieldRequired}
			  onChange={handleChange} 
			  max={5} 
			  isError={Boolean(fieldErrors.length)}
			  errorMsg={fieldErrors[0]?.message.includes("required")
			  ? t('form.validation.field.required') || ''
			  : fieldErrors[0]?.message || ''}		  
			/>	
		  ) : (
			<StyledInputText
				label={field.adminLabel}
				required={isFieldRequired}
				isError={Boolean(fieldErrors.length)}
				errorMsg={fieldErrors[0]?.message.includes("required")
				? t('form.validation.field.required') || ''
				: fieldErrors[0]?.message || ''}	
				onChange={handleChange}
				value={textValue || ""}
			/>
		  )}				
		</StyledFieldContainer>
		</>
	);
};

export default SurveyField;
