import { addLeadingSlash, validateUrlSlashes } from "app/utils/urlUtils";
import { FunctionComponent, HTMLAttributeReferrerPolicy } from "react";
import { useSelector } from "redux/hooks";
import { CustomiseComponent } from "ts/interfaces";
import { StyledA, StyledLink } from "./Link.Styled";

export interface LinkData {
	to?: string;
	state?: any;
	prefix?: string;
}

export interface LinkProps {
	children?: any;
	rel?: string | undefined;
	referrerPolicy?: HTMLAttributeReferrerPolicy | undefined;
}

const Link: FunctionComponent<LinkData & LinkProps & CustomiseComponent> = ({
	to,
	state = {},
	prefix = "",
	children,
	className,
	...props
}) => {
	const bcStore = useSelector((state) => state.bcStore.store);

	if (!to) {
		return <>{children}</>;
	}

	if (!to.startsWith("http")) {
		let path = addLeadingSlash(to); // Check for starting forward-slash

		// Add prefix is one is supplied
		if (prefix.length) {
			path = addLeadingSlash(prefix) + path;
		}

		// Add bc store code if it exists
		if (
			bcStore &&
			bcStore.code &&
			path &&
			!path.startsWith(`/${bcStore.code}`)
		) {
			path = addLeadingSlash(bcStore.code) + path;
		}

		to = validateUrlSlashes(path);
	}

	const linkProps = {
		to,
		state,
		referrerPolicy: props.referrerPolicy,
		rel: props.rel,
	};

	if (!to.startsWith("http")) {
		return (
			<StyledLink
				onMouseEnter={props.onMouseEnter}
				onClick={props.onClick}
				{...linkProps}
				className={className}
			>
				{children}
			</StyledLink>
		);
	} else {
		return (
			<StyledA
				href={linkProps.to}
				{...linkProps}
				className={className}
			>
				{children}
			</StyledA>
		);
	}
};

export default Link;
