import { gql } from "graphql-request";

const wordPressGetMenuBySlug = (slug: string) => {
  return {
    variables: {
      id: slug,
    },
    query: gql`
      query ($id: ID!) {
        menu(id: $id, idType: SLUG) {
          menuItems(first: 50) {
            nodes {
              id
              parentId
              label
              path
              childItems {
                nodes {
                  id
                  parentId
                  label
                  path
                }
              }
            }
          }
        }
      }
    `,
  };
};

export default wordPressGetMenuBySlug;
