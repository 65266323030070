import { MarkdownProps } from "components/common/ui/Markdown/Markdown";

import SpMarkdown from "../SpMarkdown/SpMarkdown";

import {
	StyledFirstHeadingSkeleton,
	StyledHeadingSkeleton,
	StyledSkeleton,
	StyledSkeletonContainer,
	StyledWrapper,
} from "./SpContent.Styled";

type Props = {
	className?: string;
	isLoading?: boolean;
	content?: string;
} & MarkdownProps;

const SpContent = ({
	isLoading,
	content = "",
	options,
	safeRender,
	...props
}: Props) => {
	return (
		<StyledWrapper {...props}>
			{isLoading || !content ? (
				<StyledSkeletonContainer>
					<StyledFirstHeadingSkeleton height={24} />
					<StyledSkeleton count={5} />
					<StyledSkeleton count={2} />
					<StyledSkeleton count={4} />
					<StyledHeadingSkeleton height={24} />
					<StyledSkeleton count={5} />
					<StyledHeadingSkeleton height={24} />
					<StyledSkeleton count={5} />
				</StyledSkeletonContainer>
			) : (
				<SpMarkdown html={content} options={options} safeRender={safeRender} />
			)}
		</StyledWrapper>
	);
};

export default SpContent;
