import { gql } from "graphql-request";
import { ProductOnProductEdge } from "../fragments/product";
import { PRODUCTS_PER_PAGE_FALLBACK } from "utilities/consts";

export const bigcommerceGetCategoryViaSearch = ({
	categoryEntityIds = [],
	searchSubCategories = false,
	productAttributes,
	nextProductSetCursor = "",
	price,
	sort,
}: {
	categoryEntityIds?: string | number[];
	searchSubCategories: boolean;
	productAttributes?: {
		attribute: string;
		values: string[];
	}[];
	nextProductSetCursor?: string;
	price?: {
		minPrice?: number;
		maxPrice?: number;
	};
	sort: string;
}) => {
	const pageSize = Number(
		process.env.REACT_APP_CATEGORY_PRODUCTS_PER_PAGE ||
			PRODUCTS_PER_PAGE_FALLBACK,
	);

	return {
		operationName: "GetCategoryViaSearch",
		variables: {
			searchSubCategories,
			categoryEntityIds,
			hideOutOfStock: true,
			productAttributes,
			nextProductSetCursor,
			price,
			sort,
		},
		query: gql`
			query GetCategoryViaSearch(
				$categoryEntityIds: [Int!]
				$searchSubCategories: Boolean
				$hideOutOfStock: Boolean
				$productAttributes: [ProductAttributeSearchFilterInput!]
				$nextProductSetCursor: String
				$price: PriceSearchFilterInput
				$sort: SearchProductsSortInput
			) {
				site {
					search {
						searchProducts(
							filters: {
								categoryEntityIds: $categoryEntityIds
								searchSubCategories: $searchSubCategories
								hideOutOfStock: $hideOutOfStock
								productAttributes: $productAttributes
								price: $price
							}
							sort: $sort
						) {
							products(after: $nextProductSetCursor, first: ${pageSize}) {
								pageInfo {
									startCursor
									endCursor
									hasNextPage
									hasPreviousPage
								}
								edges {
									...Product
								}
							}
							filters {
								pageInfo {
									startCursor
									endCursor
									hasNextPage
									hasPreviousPage
								}
								edges {
									node {
										name
										isCollapsedByDefault
										__typename
										... on CategorySearchFilter {
											__typename
											displayProductCount
											name
											isCollapsedByDefault
											categories {
												edges {
													node {
														entityId
														name
														productCount
														isSelected
														subCategories {
															edges {
																node {
																	entityId
																	name
																	productCount
																	isSelected
																	subCategories {
																		edges {
																			node {
																				entityId
																				name
																				productCount
																				isSelected
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
										... on ProductAttributeSearchFilter {
											__typename
											displayProductCount
											filterName
											name
											isCollapsedByDefault
											attributes {
												pageInfo {
													startCursor
													endCursor
													hasNextPage
													hasPreviousPage
												}
												edges {
													node {
														value
														isSelected
														productCount
													}
												}
											}
										}
										... on PriceSearchFilter {
											__typename
											selected {
												maxPrice
												minPrice
											}
											name
											isCollapsedByDefault
										}
									}
								}
							}
						}
					}
				}
			}

			${ProductOnProductEdge}
		`,
	};
};
