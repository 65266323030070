import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import Skeleton from "components/common/ui/Skeleton/Skeleton";
import CustomerAccountCard from "../CustomerAccountCard/CustomerAccountCard";

import {
	CMSContent,
	StyledAccountLandingSection,
	StyledLinkCards,
} from "./Styled";

import { ReactComponent as Address } from "../../../assets/images/svg/Overview - Addresses.svg";
import { ReactComponent as Loyalty } from "../../../assets/images/svg/Overview - Loyalty Program.svg";
import { ReactComponent as Orders } from "../../../assets/images/svg/Overview - Orders.svg";
import { ReactComponent as Profile } from "../../../assets/images/svg/Overview - Profile.svg";
import { ReactComponent as Wishlist } from "../../../assets/images/svg/Overview - Wishlists.svg";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

const AccountLanding = () => {
	const dispatch = useDispatch();
	const customer: any = useSelector((state) => state.auth.customer);
	const { t } = useTranslation();

	const [cmsContent, setCmsContent] = useState("");

	const accountsOverviewContent = wordPressApi.useGetPageContentByUriQuery(
		"/customer-account-overview-page/",
	) as any;

	const [isLoading, setIsLoading] = useState(customer !== undefined);

	const links = useMemo(() => {
		return [
			{
				address: t(PathTranslationKey.ACCOUNT_ORDERS),
				text: t("account.orders"),
				icon: <Orders />,
			},
			{
				address: t(PathTranslationKey.ACCOUNT_LOYALTY),
				text: t("loyalty_program"),
				icon: <Loyalty />,
			},
			{
				address: t(PathTranslationKey.ACCOUNT_WISHLIST),
				text: t("account.wishlists"),
				icon: <Wishlist />,
			},
			{
				address: t(PathTranslationKey.ACCOUNT_PROFILE),
				text: t("account.profile"),
				icon: <Profile />,
			},
			{
				address: t(PathTranslationKey.ACCOUNT_ADDRESSES),
				text: t("account.addresses"),
				icon: <Address />,
			},
		];
	}, [t]);

	useEffect(() => {
		dispatch(setAccountActiveLink("Overview"));
	}, []);

	useEffect(() => {
		if (customer)
			if (customer.id && isLoading) {
				setIsLoading(false);
			} else if (!customer.id && !isLoading) {
				setIsLoading(true);
			}
	}, [customer]);

	useEffect(() => {
		if (accountsOverviewContent.data?.content) {
			setCmsContent(accountsOverviewContent.data.content);
		}
	}, [accountsOverviewContent]);

	const customerName = customer.id
		? `${customer.first_name} ${customer.last_name}`
		: undefined;

	return (
		<StyledAccountLandingSection>
			<h2>
				{customerName ? (
					`${t("account.greeting")}, ${customerName}`
				) : (
					<Skeleton count={0.3} />
				)}
			</h2>
			<CMSContent html={cmsContent} />
			<StyledLinkCards>
				{links.map((link) => (
					<CustomerAccountCard
						key={link.text}
						linkData={link}
						loading={isLoading}
					/>
				))}
			</StyledLinkCards>
		</StyledAccountLandingSection>
	);
};

export default AccountLanding;
