import { useEffect, useMemo, useState } from "react";

import ProductVariants from "components/ProductVariants/ProductVariants";
import Button from "components/common/ui/Button/Button";
import Price from "components/common/ui/Price/Price";
import Typography from "components/common/ui/Typography/Typography";
import useBreakpoints from "hooks/useBreakpoints";
import { useTranslation } from "react-i18next";
import { Product, ProductVariant } from "ts/types";
import {
	BelowVariantWrapper,
	Content,
	InputWrapper,
	ProductCard,
	StyledDivider,
	StyledModal,
	Title,
	VariantWrapper,
} from "../PdpFindInStore.Styled";
import ResultStep from "./Steps/ResultStep";

const PdpFindInStoreModal = ({
	isOpen,
	handleClose,
	product,
}: {
	isOpen: boolean;
	handleClose: () => void;
	product: Product;
}) => {
	const { large } = useBreakpoints();
	const { t } = useTranslation();

	const [productSku, setProductSku] = useState<string | undefined>();

	const [showResults, setShowResults] = useState(false);

	useEffect(() => {
		if (product.variants?.length === 0) {
			setProductSku(product.sku);
		}
	}, [product]);

	const productHasVariants = useMemo(() => {
		return product.variants?.length !== 0;
	}, [product]);

	useEffect(() => {
		setShowResults(!productHasVariants);
	}, [productHasVariants]);

	const handleVariantClick = (variant: ProductVariant) => {
		setProductSku(variant.sku);

		if (large) {
			setShowResults(true);
		}
	};

	const handleCheckAvailabiltyClick = () => {
		if (productSku) {
			setShowResults(true);
		}
	};

	const handleStartAgain = () => {
		if (productHasVariants) {
			setProductSku(undefined);
			setShowResults(false);
		}
		setShowResults(false);
	};

	const close = () => {
		if (productHasVariants) {
			setProductSku(undefined);
			setShowResults(false);
		}
		handleClose();
	};

	const thumbnail = product.images
		? product.images.find((image) => image.isDefault)
		: product.images[0];

	if (!isOpen) {
		return null;
	}

	const productCustomFields = product.customFields?.find((item) => item.name === "GroupID");
	const groupID = productCustomFields?.value;

	return (
		<StyledModal
			onClose={close}
			options={{
				overlay: true,
				showCloseButton: true,
				portalId: "portal__modal",
			}}
		>
			<Content>
				{(large || (!large && !showResults)) && (
					<InputWrapper>
						<Title variant="heading" size="small" className="input_title">
							{t("findInStore.title")}
						</Title>
						<StyledDivider margin={16} />
						<ProductCard>
							<div className="thumbnail">
								<img src={thumbnail?.urlOriginal} alt={thumbnail?.altText} />
							</div>
							<div className="details">
								<Typography variant="heading" size="small" className="name">
									{product.name}
								</Typography>
								<Typography variant="body" size="small" className="sku">
									{product.sku}
								</Typography>
								<Typography variant="heading" size="small" className="price">
									<Price price={product.price.value} />
								</Typography>
							</div>
						</ProductCard>
						<StyledDivider margin={large ? 24 : 16} />
						{productHasVariants && (
							<>
								<VariantWrapper>
									<Typography
										variant="body"
										size="small"
										className="variant-helper-text"
									>
										{t("findInStore.whatSize")}
									</Typography>
									<ProductVariants
										variants={product.variants || []}
										groupId={groupID}
										onChange={handleVariantClick}
										defaultValue={product.variants?.find(
											(variant) => variant.sku === productSku,
										)}
									/>
								</VariantWrapper>
								<StyledDivider margin={16} />
							</>
						)}

						<BelowVariantWrapper>
							<Typography
								variant="body"
								size="small"
								className="below-variants-text"
							>
								{t("findInStore.belowVariantText")}
							</Typography>
							{!large && (
								<Button
									variant="primary_01"
									fullWidth
									className="btn_check-availabilty"
									onClick={handleCheckAvailabiltyClick}
								>
									{t("findInStore.checkAvailability")}
								</Button>
							)}
						</BelowVariantWrapper>
					</InputWrapper>
				)}

				{(large || (!large && showResults)) && (
					<ResultStep
						productSku={productSku}
						showStartAgain={productHasVariants}
						startAgain={handleStartAgain}
					/>
				)}
			</Content>
		</StyledModal>
	);
};

export default PdpFindInStoreModal;
