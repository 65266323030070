import { useLazyGetProductsBySkusQuery } from "app/api/product/productApi";
import ProductCard from "components/ProductCard/ProductCard";
import { InputText } from "components/common/form/Input/Input";
import useBreakpoints from "hooks/useBreakpoints";
import React, { useState } from "react";
import { Portal } from "react-portal";
import { useLocation } from "react-router";
import { useSelector } from "redux/hooks";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import Button from "../Button/Button";
import IconClose from "../Icons/IconClose/IconClose";
import IconSearch from "../Icons/IconSearch/IconSearch";

import {
	Overlay,
	SearchModalContainer,
	StyledLink,
} from "./SearchModal.Styled";

import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

const SearchModal = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	const onClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<Button icon={<IconSearch />} onClick={() => setIsOpen(true)} />
			{isOpen && (
				<Portal node={document && document.getElementById("potal__modal")}>
					<Search isOpen={isOpen} onClose={onClose} />
				</Portal>
			)}
		</>
	);
};

interface SearchInterface {
	isOpen: boolean;
	onClose: () => void;
}

const Search = ({ isOpen, onClose: onCloseProp }: SearchInterface) => {
	const {
		location: {
			selectedLocale,
		},
		bcStore: {
			currencies
		}
	} = useSelector(state => state);

	const [isBeforeSearch, setIsBeforeSearch] = useState(true);
	const [isSearchLoading, setIsSearchLoading] = useState(true);

	const { large } = useBreakpoints();
	const [searchQuery, setSearchQuery] = React.useState("");
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [keyWords, setKeyWords] = React.useState<string[]>([]);
	const { t } = useTranslation();
	const navigate = useLocaleNavigate();

	const searchQueryHandler = (searchText: string) => {
		setSearchLoading(true);
		setSearchQuery(searchText);
	};

	const customer: any = useSelector((state) => state.auth.customer);

	const searchQueryDebounce = useDebounce(searchQuery, 300);

	const [getProductsBySkus, getProductsBySkusResult] =
		useLazyGetProductsBySkusQuery();

	let segmentifyObj = {
		customer: customer,
		sessionId: sessionStorage.getItem("sessionId"),
		lang: selectedLocale ?? 'RS',
		currencyCode: currencies[0]?.code ?? 'EUR',
	}

	React.useEffect(() => {
		setTimeout(() => setIsSearchLoading(false), 1500)
	}, [])

	// request segmentify data based on search string
	React.useMemo(() => {
		if (searchQuery.length < 3) {
			return;
		}
	}, [searchQueryDebounce]);

	//compare if segmentify gets the same result twice
	const [currentIDs, setCurrentIDs] = useState([]);
	const newIDs: any[] = [];

	const productIDsUnchanged = () => {
		return (
			currentIDs.length == newIDs.length &&
			currentIDs.every(function (element, index) {
				return element === newIDs[index];
			})
		);
	};

	// find out what result screen to show
	const validSearch = Boolean((isBeforeSearch || searchQuery.length > 2));
	const isNowLoading = Boolean((searchLoading && searchQuery.length > 2) || isSearchLoading);

	const onClose = () => {
		setSearchQuery("");
		onCloseProp();
	};

	const isAndroid = window.navigator.userAgent.toLowerCase().includes('android')

	React.useEffect(() => {
		if (inputRef && inputRef.current) inputRef.current.focus();
	});


	const searchOnEnter = (event:any)=>{
		if(event.key === 'Enter') {
			navigate(`${t(PathTranslationKey.SEARCH)}?q=${searchQuery}`)
		}
	}
	const inputRef = React.useRef<HTMLInputElement>(null);
	const ref = React.useRef(null);
	useOnClickOutside(ref, () => large && onClose());

	return (
		<>
		 {!isAndroid && (
        <div>
          <Overlay isOpen={isOpen} />
			<SearchModalContainer ref={ref} isOpen={isOpen} hasResults={false}>
				<div className="search__header">
					<div className="search__input">
						<InputText
							onKeyDown={searchOnEnter}
							ref={inputRef}
							value={searchQuery}
							onChange={(e) => searchQueryHandler(e.target.value)}
							placeholder={t("search.placeholder") as string}
						/>
						<Button
							className={searchQuery.length ? "" : "hidden"}
							onClick={() => setSearchQuery("")}
						>
							{t("filterAsideClear")}
						</Button>
					</div>
					<div className="search__close">
						<Button icon={<IconClose />} onClick={onClose} />
					</div>
				</div>
				{validSearch && (
					<div className="search__body">
						{keyWords.length > 0 && (
							<div className="search__suggestions"></div>
						)}
						<div className="search__products">
							<div className="products__header">
								<p className="heading">{t("search.products")}</p>
								<StyledLink to={`${t(PathTranslationKey.SEARCH)}?q=${searchQuery}`}>
									{t("search.see_all")}
								</StyledLink>
							</div>
                            <div className={`products${isNowLoading ? ' products--skeleton' : ''}`}>
                                <ProductCard
                                    triggeredFrom="SEARCH"
                                    onAddToCart={onClose}
                                    key={nanoid()}
                                    layout={1}
                                    loading={true}
                                    product={undefined}
                                />
                                <ProductCard
                                    triggeredFrom="SEARCH"
                                    onAddToCart={onClose}
                                    key={nanoid()}
                                    layout={1}
                                    loading={true}
                                    product={undefined}
                                />
                            </div>
						</div>
					</div>
				)}
			</SearchModalContainer>
        </div>
      )}
			
		</>
	);
};

export default SearchModal;
