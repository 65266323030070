import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, lightGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const AccountOrderContainer = styled.section`
	h1,
	h4 {
		margin: 0;
	}

	h1 {
		font: normal ${remCalc(24)} / ${remCalc(29)} ${Roboto};
		color: ${dark};
	}

	h4 {
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${accountDarkGrey};
		position: relative;
		padding-bottom: ${remCalc(16)};

		@media ${aboveMedium} {
			border-bottom: 2px solid ${lightGrey};
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: -${remCalc(16)};
			width: 100vw;
			height: 2px;
			background-color: ${lightGrey};

			@media ${aboveMedium} {
				display: none;
			}
		}
	}

	.order {
		margin-top: ${remCalc(32)};
		display: flex;
		flex-direction: column;
		gap: ${remCalc(40)};

		@media ${aboveMedium} {
			flex-direction: row;
			gap: ${remCalc(32)};
		}

		&--left {
			flex: 1;
			min-width: 50%;

			.order-total {
				@media ${aboveMedium} {
					width: 50%;
					margin-left: auto;
				}

				&__field {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: ${remCalc(24)} 0;
					border-bottom: 1px solid ${lightGrey};

					&:last-child {
						border: none;
					}

					p {
						margin: 0;
						color: ${dark};
						font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
					}

					.bold {
						font-weight: bold;
					}
				}
			}
		}

		&--right {
			display: flex;
			flex-direction: column;
			gap: ${remCalc(24)};

			@media ${aboveMedium} {
				width: ${remCalc(392)};
				gap: ${remCalc(48)};
			}

			.order-details {
				display: flex;
				flex-direction: column;

				&__field {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: ${remCalc(24)} 0;
					border-bottom: 1px solid ${lightGrey};

					p {
						margin: 0;
						color: ${dark};
						font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
					}

					.bold {
						font-weight: bold;
					}
				}

				button {
					margin-top: ${remCalc(24)};
					align-self: flex-end;
					width: 100%;

					@media ${aboveMedium} {
						width: auto;
					}
				}
			}

			.delivery-details {
				display: flex;
				flex-direction: column;
				border-bottom: 1px solid ${lightGrey};
				margin-top: ${remCalc(40)};

				@media ${aboveMedium} {
					margin-top: 0;
				}

				p {
					margin: 0;
					color: ${dark};
					font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};

					&:first-of-type {
						margin: ${remCalc(24)} 0 ${remCalc(16)};
					}

					&:last-of-type {
						margin: ${remCalc(16)} 0 ${remCalc(24)};
					}
				}

				.bold {
					font-weight: bold;
				}
			}

			.shipping-details {
				display: flex;
				flex-direction: column;
				gap: ${remCalc(12)};
				margin-top: ${remCalc(24)};

				@media ${aboveMedium} {
					margin-top: 0;
				}

				&__field {
					display: flex;
					gap: ${remCalc(16)};

					&:first-of-type {
						padding-top: ${remCalc(12)};
					}

					&:last-of-type {
						padding-bottom: ${remCalc(4)};

						@media ${aboveMedium} {
							padding-bottom: ${remCalc(12)};
						}
					}

					p {
						margin: 0;
						color: ${dark};
						font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
					}

					.bold {
						font-weight: bold;
					}
				}

				button {
					width: 100%;

					@media ${aboveMedium} {
						width: fit-content;
					}
				}
			}

			.actions {
				display: flex;
				flex-direction: column;
				gap: ${remCalc(24)};

				button {
					width: 100%;

					@media ${aboveMedium} {
						width: fit-content;
					}
				}
			}
		}
	}

	.item-card-loading {
		display: flex;
		justify-content: flex-start;
		gap: 1rem;
		padding-top: 0.75rem;

		.content-placeholder {
			flex: 3;
		}

		.image-placeholder {
			display: flex;
			flex: 1;
		}

		div:nth-of-type(3) {
			& > span {
				width: 100%;
				display: block;
				& > span {
					display: block;
					margin-left: auto;
				}
			}
		}
	}
`;
