import styled from "styled-components";

import ProductCard from "components/ProductCard/ProductCard";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledProductCard = styled(ProductCard)`
	width: 100%;
`;

export const StyledButtonText = styled.button`
	padding: 0 ${remCalc(20)};
	background-color: transparent;
	font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	color: ${accountDarkGrey};

	&:hover {
		color: ${grey};
		text-decoration: underline;
	}

	@media ${aboveMedium} {
		padding: 0;
	}
`;
