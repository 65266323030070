import { useParams } from "react-router";
import uuid from "react-uuid";
import { useWindowSize } from "usehooks-ts";

import {
	useLazyGetCompanyPageQuery
} from "app/api/company/CompanyPageApi";
import { useGetStaffStoryPageQuery } from "app/api/company/staffStoryApi";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import { StyledImageBanner } from "./CompanyPages.Styled";
import {
	SkeletonDesktopWrapper,
	SkeletonMobileWrapper,
	StyledStaffStoryPageWrapper,
	StyledStaffStoryPageWrapperDesktop,
	StyledStaffStoryPageWrapperMobile,
} from "./StaffStory.Styled";
import * as DOMPurify from "dompurify";
import React from "react";

const StaffStory = () => {
	let { colleagueName } = useParams();

	let colleagueUrl = `/colleagues/${colleagueName}`;

	// Get Screen width so we can generate content based on device size
	interface Size {
		width: number | undefined;
		height: number | undefined;
	}
	let { width: screenWidth } = useWindowSize();

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [additionalLinksIsLoading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
		const template = wordpressTemplates.find(
			(template) => template.template === WordpressTemplateEnum.Careers,
		);

		if (template) {
			trigger({
				pageId: template.id,
				pageType: "careers",
			});
		}
	}, [wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const { data: colleagueData, isLoading: staffDataisLoading } =
		useGetStaffStoryPageQuery(colleagueUrl);

	const { sanitize }: any = DOMPurify;

	// import additional links from Careers page

	const formatLineBreaks = (paragraph: string): any => {
		if (!paragraph) {
			return;
		}

		// Fix line break issues and filter out empty paragraphs .split returns a length of 1 if empty. Not empty string.
		return paragraph
			.split("\n")
			.filter((element) => element.length !== 1)
			.map((paragraph) => {
				// Remove empty paragraphs
				if (paragraph.length === 0) {
					return;
				}

				return (
					<div
						dangerouslySetInnerHTML={{ __html: sanitize(paragraph) }}
						key={uuid()}
						className={screenWidth < 801 ? "paragraph-mobile" : ""}
					></div>
				);
			});
	};
	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={colleagueData?.seo?.title}
				description={colleagueData?.seo?.metaDesc}
				keywords={colleagueData?.seo?.metaKeywords}
				robots={getRobotsContent([
					colleagueData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledStaffStoryPageWrapper>
				{screenWidth > 801 ? (
					<StyledStaffStoryPageWrapperDesktop className="staff-story-page--desktop">
						<div className="staff-story--container">
							{staffDataisLoading ? (
								<SkeletonDesktopWrapper className="images">
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</SkeletonDesktopWrapper>
							) : (
								<div className="images-container">
									{colleagueData?.images?.map((image: any) => (
										<picture key={uuid()}>
											<source srcSet={image?.sourceUrl} />
											<img src={image?.sourceUrl} alt={image?.altText} />
										</picture>
									))}
								</div>
							)}
							{staffDataisLoading ? (
								<SkeletonDesktopWrapper className="text">
									<Skeleton height={"29px"} style={{ marginTop: "46px" }} />
									<Skeleton
										height={"24px"}
										style={{ marginTop: "16px", marginBottom: "32px" }}
									/>
									<Skeleton height={"500px"} />
								</SkeletonDesktopWrapper>
							) : (
								<div className="text-container">
									<h2>{colleagueData?.colleagueName}</h2>
									<h3>{colleagueData?.colleagueData?.jobTitle}</h3>
									<div className="paragraphs">
										<>
											{formatLineBreaks(
												colleagueData?.colleagueData?.textSection1,
											)}

											{formatLineBreaks(
												colleagueData?.colleagueData?.textSection2,
											)}

											{formatLineBreaks(
												colleagueData?.colleagueData?.textSection3,
											)}
										</>
									</div>
								</div>
							)}
						</div>
					</StyledStaffStoryPageWrapperDesktop>
				) : (
					<StyledStaffStoryPageWrapperMobile className="staff-story-page--mobile">
						<div className="staff-story--container">
							{staffDataisLoading ? (
								<>
									<Skeleton className="skeleton-mobile-image" />
									<SkeletonMobileWrapper>
										<Skeleton height={"29px"} style={{ marginTop: "46px" }} />
										<Skeleton
											height={"24px"}
											style={{ marginTop: "16px", marginBottom: "32px" }}
										/>
										<Skeleton count={7} />
										<Skeleton style={{ marginBottom: "32px" }} />
									</SkeletonMobileWrapper>
									<Skeleton
										className="skeleton-mobile-image"
										style={{ marginBottom: "32px" }}
									/>
								</>
							) : (
								<>
								{colleagueData?.images.map((image: any, index: number) => (
										<React.Fragment key={index}>
											{index !== 0 && <hr />}
											{image && (
												<picture>
													<source srcSet={image.sourceUrl} type={image.type} />
													<img
														src={image.sourceUrl}
														alt={image.altText}
													/>
												</picture>
											)}
											{index === 0 && (
												<>
													<h2>{colleagueData?.colleagueName}</h2>
													<h3>{colleagueData?.colleagueData?.jobTitle}</h3>
												</>
											)}
											{formatLineBreaks(
												colleagueData?.colleagueData?.[
													`textSection${index + 1}`
												],
											)}
										</React.Fragment>
									))}
								</>
							)}
						</div>
					</StyledStaffStoryPageWrapperMobile>
				)}

				{pageData?.careersAdditionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage: link?.images.desktopImage.sourceUrl,
									mobileImage:
										link?.images.mobileImage?.sourceUrl ||
										link?.images.desktopImage.sourceUrl,
									alt: link?.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.link?.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={additionalLinksIsLoading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledStaffStoryPageWrapper>
		</Page>
	);
};

export default StaffStory;
