import styled from 'styled-components';
import { pageContentContainer } from 'theme/StyledMixins';
import { remCalc } from 'utilities/styles';
import {
	accountLightGrey,
	dark,
	grey,
	lightGrey,
	white,
} from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledLoyaltyPageWrapper = styled.div`
	* {
		color: ${dark};
	}

	.loyalty-content {
		${pageContentContainer};
		margin: ${remCalc(64)} auto ${remCalc(64)};
		max-width: ${remCalc(816)};

		@media ${aboveMedium} {
			margin: ${remCalc(96)} auto ${remCalc(80)};
		}

		* > img {
			width: 100%;
		}
		p {
			font-size: ${remCalc(16)};
			margin-bottom: ${remCalc(32)};
			line-height: ${remCalc(24)};

			strong {
				letter-spacing: 1px;
				font-size: ${remCalc(20)};
				margin-bottom: ${remCalc(16)};
				text-transform: uppercase;
			}
		}

		p:first-of-type strong {
			letter-spacing: 1.2px;
			font-size: ${remCalc(24)};
		}

		table {
			border-top: 2px solid ${grey};
			width: 100% !important;
			margin-bottom: ${remCalc(40)};

			th > * > * {
				margin: 0;
			}

			tr {
				text-align: left;
				height: ${remCalc(52)};
				border-bottom: 2px solid ${lightGrey};
			}
		}

		img {
			object-fit: contain;
			max-height: ${remCalc(220)};

			@media ${aboveMedium} {
				max-height: unset;
			}
		}
	}

	.create-account-container {
		background-color: ${accountLightGrey};
		text-align: center;
		padding: ${remCalc(48)} ${remCalc(24)};
		margin-bottom: ${remCalc(64)};

		.create-account-text,
		.skeleton-account {
			margin: 0 auto;

			@media ${aboveMedium} {
				max-width: ${remCalc(498)};
			}
		}

		.skeleton-account {
			height: ${remCalc(205)};

			@media ${aboveMedium} {
				height: ${remCalc(157)};
			}
		}

		h2 {
			letter-spacing: 1.2px;
			font-size: ${remCalc(24)};
			font-weight: 700;
			text-transform: uppercase;
			margin: 0 0 ${remCalc(12)};
		}

		p {
			font-size: ${remCalc(16)};
			line-height: ${remCalc(24)};
			margin-bottom: ${remCalc(32)};
		}

		a {
			color: ${white};
			width: 100%;
			display: block;
			margin: 0 auto;

			@media ${aboveMedium} {
				width: max-content;
			}
		}
	}

	.skeleton-content {
		margin: ${remCalc(64)} auto ${remCalc(64)};
		max-width: ${remCalc(816)};
		display: flex;

		@media ${aboveMedium} {
			margin: ${remCalc(96)} auto ${remCalc(80)};
		}
	}
`;
