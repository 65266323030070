import React from "react";

import { Portal } from "react-portal";
import { useLocation } from "react-router";


import Button from "components/common/ui/Button/Button";
import TextButton from "components/common/ui/Button/TextButton";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";

import useOutsideClick from "hooks/useOutsideClick";

import { useSelector } from "redux/hooks";

import { Product } from "ts/types";
import { FormFieldTypeEnum } from "types/GravityForm";

import { gravityFormApi } from "app/api/wordpress/gravityFormApi";
import {
	StyledContainer,
	StyledContent,
	StyledGravityForm,
	StyledHeader,
	StyledHeading,
	StyledOverlay,
	StyledWrapper,
} from "./PdpAskModal.Styled";
import { useTranslation } from "react-i18next";

interface Props {
	product?: Product;
}

const PdpAskModal = ({ product }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const { t } = useTranslation();

	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<TextButton onClick={() => setIsOpen(true)}>{t("pdp.askQuestion")}</TextButton>
			<Portal node={document && document.getElementById("portal__modal")}>
				<Modal isOpen={isOpen} handleClose={handleClose} product={product} />
			</Portal>
		</>
	);
};

interface ModalInterface {
	isOpen: boolean;
	handleClose: () => void;
	product?: Product;
}

const Modal = ({ isOpen, handleClose, product }: ModalInterface) => {
	const ref = useOutsideClick(handleClose);
	const gravityFormQuery = gravityFormApi.useGetGravityFormByIdQuery(3);
	const customer: any = useSelector((state) => state.auth.customer);
	const { t } = useTranslation();

	if (!isOpen) {
		return null;
	}

	const customerName = customer?.first_name ? `${customer.first_name} ${customer.last_name}` : "";

	return (
		<StyledWrapper className="modal__wrapper">
			<StyledOverlay className="modal__overlay">
				<StyledContainer className="modal__container" ref={ref}>
					<StyledHeader className="modal__header">
						<StyledHeading>{t("pdp.askQuestion")}</StyledHeading>
						<Button icon={<IconClose />} onClick={handleClose} />
					</StyledHeader>
					<StyledContent className="modal__content">
						<StyledGravityForm
							form={gravityFormQuery.data}
							isLoading={gravityFormQuery.isLoading}
							showLegend={false}
							defaultValue={{
								[FormFieldTypeEnum.NAME]: {
									first: customerName,
								},
								[FormFieldTypeEnum.EMAIL]: customer?.email,
								[FormFieldTypeEnum.TEXT]: product?.sku,
							}}
						/>
					</StyledContent>
				</StyledContainer>
			</StyledOverlay>
		</StyledWrapper>
	);
};

export default PdpAskModal;
