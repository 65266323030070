import InputSelect from "components/common/form/InputSelect/InputSelect";
import { getLanguageSelectOption } from "app/bigcommerce/helpers/BcConfigHelper";

const LanguageSelect = ({ value, onChange }) => {
  const options = getLanguageSelectOption();

  return (
    <InputSelect
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default LanguageSelect;
