import { useParams } from "react-router";

import { Product } from "ts/types";

import { StyledButtonText, StyledProductCard } from "./WishlistItem.Styled";
import { useTranslation } from "react-i18next";

type Props = {
	product?: Product;
	onRemove: () => void;
};

const WishlistItem = ({ product, onRemove }: Props) => {
	const { id } = useParams();
	const { t } = useTranslation();

	return (
		<div className="wishlist-item">
			{Boolean(id) && (
				<StyledButtonText onClick={onRemove}>{t("remove")}</StyledButtonText>
			)}
			<StyledProductCard triggeredFrom="WISHLIST" layout={0} product={product} />
		</div>
	);
};

export default WishlistItem;
