import {
	FormWrapper,
	OuterWrapper,
	StyledGravityForm,
} from "./ApplicationForm.Stlyed";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Geolocation, Position } from "@capacitor/geolocation";

import { CustomerData } from "types/Customer";
import { FormFieldTypeEnum } from "types/GravityForm";
import Geocode from "react-geocode";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import { gravityFormApi } from "app/api/wordpress/gravityFormApi";
import { useSelector } from "redux/hooks";

const ApplicationForm: FunctionComponent = () => {
	const gravityFormQuery = gravityFormApi.useGetGravityFormByIdQuery(2);

	const customer = useSelector((state) => state.auth.customer) as
		| CustomerData
		| undefined;

	const location = useSelector((state) => state.location);

	const [currentLocale, setCurrentLocale] = useState("");

	const form = useMemo(() => {
		return gravityFormQuery.data;
	}, [gravityFormQuery.data]);

	useEffect(() => {
		const getCurrentLocale = async () => {
			if (location.selectedLocale !== "en") {
				const locationParts = (location.selectedLocale ?? "rs").split("-");
				setCurrentLocale(locationParts[locationParts.length - 1] ?? "rs");

				return;
			}

			const position: Position = await Geolocation.getCurrentPosition();
			/* TODO this has been commented out 
			to reduce the number of Google Cloud API calls
			the previous value of `apiKey` was `process.env.REACT_APP_GOOGLE_API_KEY || ""`

			see https://app.activecollab.com/226056/projects/762/tasks/99829
			*/
			const apiKey = "";

			Geocode.setApiKey(apiKey);

			const { results } = (await Geocode.fromLatLng(
				position.coords.latitude.toString(),
				position.coords.longitude.toString(),
			)) || {
				results: [],
			};

			if (results) {
				setCurrentLocale(
					results[0].address_components.find(
						(field: any) => field.types.indexOf("country") !== -1,
					)["short_name"],
				);
			}
		};
		getCurrentLocale();
	}, [currentLocale]);

	if (!currentLocale) {
		return <LoadingSpinner />;
	}

	return (
		<OuterWrapper id="application-form" className="ApplicationForm">
			<Typography variant="heading" className="form-title">
				{gravityFormQuery.isLoading ? <Skeleton /> : form?.title}
			</Typography>
			<FormWrapper>
				<StyledGravityForm
					successScrollTargetId="application-form"
					form={form}
					isLoading={gravityFormQuery.isLoading}
					showLegend={false}
					defaultValue={{
						[FormFieldTypeEnum.NAME]: {
							first: customer?.first_name || "",
							last: customer?.first_name || "",
						},
						[FormFieldTypeEnum.EMAIL]: customer?.email || "",
						[FormFieldTypeEnum.PHONE]: customer?.phone || "",
						[FormFieldTypeEnum.ADDRESS]: {
							city: "",
							country: currentLocale,
						},
						[FormFieldTypeEnum.TEXT]: "",
						[FormFieldTypeEnum.TEXTAREA]: "",
						[FormFieldTypeEnum.CONSENT]: { value: "0" },
					}}
				/>
			</FormWrapper>
		</OuterWrapper>
	);
};

export default ApplicationForm;
