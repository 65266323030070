import React, { useEffect } from "react";
import { getStoreForCode } from "app/bigcommerce/helpers/BcConfigHelper";

import {
	setCurrencies,
	setSettings,
	setStore,
} from "redux/bigcommerce/bcStoreSlice";
import { useDispatch, useSelector } from "redux/hooks";

import useLogout from "hooks/auth/useLogout";
import Cookies from "js-cookie";
import {
	COOKIE_CUSTOMER_IDENTIFIER,
	COOKIE_SHOP_TOKEN,
} from "utilities/consts";
import bigcommerceApi from "app/api/bigcommerce/bigcommerceApi";
import { Currencies, Settings } from "types/BigCommerce";
import { useMatch } from "react-router";

const BcStoreManager = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useDispatch();
	const logout = useLogout(false, "/");

	const { defaultLocation, preferredLocation } = useSelector(
		(state) => state.location,
	);

	const { store } = useSelector((state) => state.bcStore);
	const { identifier } = useSelector((state) => state.auth);

	const pathMatch = useMatch("/:locale/*");

	useEffect(() => {
		if (!pathMatch) {
			// update the selected store
			if (defaultLocation) {
				let locationCode = defaultLocation;
				if (preferredLocation && preferredLocation != defaultLocation) {
					locationCode = preferredLocation;
				}
				const storeForLocation = getStoreForCode(locationCode);
				if (storeForLocation?.code !== store?.code) {
					dispatch(setStore(storeForLocation));
				}
			}
		}
	}, [pathMatch, defaultLocation, preferredLocation]);

	useEffect(() => {
		//update the selected store

		if (pathMatch) {
			const storeForLocation = getStoreForCode(pathMatch?.params.locale);

			if (storeForLocation?.code !== store?.code) {
				// remove on store change
				window.sessionStorage.removeItem('category_cache')
				window.sessionStorage.removeItem('last_viewed_category');
				dispatch(setStore(storeForLocation));
			}
		}
	}, [pathMatch]);

	useEffect(() => {
		if (store && identifier) {
			//force a logout if no identifier to clear cookies
			if (!identifier.storeHash) {
				const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;

				Cookies.remove(COOKIE_CUSTOMER_IDENTIFIER, {
					domain: host,
				});
				Cookies.remove(COOKIE_SHOP_TOKEN);
				return;
			}

			// remove on store change
			window.sessionStorage.removeItem('category_cache')
			window.sessionStorage.removeItem('last_viewed_category');

			// if there is a store and a identifier
			// check to see if the store hashes match
			// if the don't, log out
			if (identifier.storeHash !== store.hash) {
				logout(identifier.storeHash);
			}
		}
	}, [store, identifier]);

	const [getStoreSettings] = bigcommerceApi.useLazyGetStoreSettingsQuery();

	useEffect(() => {
		if (store) {
			try {
				getStoreSettings()
					.unwrap()
					.then((storeSettings) => {
						const { site } = storeSettings;
						const currencies: Currencies = site.currencies.edges.map(
							({ node }) => ({ ...node }),
						);
						const settings: Settings = site.settings;
						dispatch(setCurrencies(currencies));
						dispatch(setSettings(settings));
					})
					.catch((error) => {
						console.log(error);
					});
			} catch {
				console.error('Error getting store settings');
			}
		}
	}, [dispatch, getStoreSettings, store]);

	if (!preferredLocation && !store) {
		return null; //this should be some sort of loader?
	} else {
		return <>{store && children}</>;
	}
};

export default BcStoreManager;
