import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";


interface ItemCount{
	count:number;
}

export const BotGridWrapper = styled.section<ItemCount>`
	.item0 {
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-top: ${remCalc(32)};
		padding-bottom: ${remCalc(32)};

		img{
			height: ${remCalc(240)};
		}
		@media ${aboveMedium} {
			padding: 0;
			padding-bottom: ${remCalc(36)};
			img {
				height: ${remCalc(322)};
			}
		}
	}


	.grid-container {

		@media ${aboveMedium} {
			display: grid;
			grid-template-columns:  ${({count}) =>'auto '.repeat(Number(count)).substring(0,15)};
			gap: 2rem;
		}

		height: 100%;
		overflow: hidden;
		position: relative;
	}
	.grid-container-lower {

		@media ${aboveMedium} {
			display: grid;
			grid-template-columns:  ${({count}) =>'auto '.repeat(Number(count)).substring(15)};
			gap: 2rem;
		}

		height: 100%;
		overflow: hidden;
		position: relative;
	}
`;
