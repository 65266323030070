import wordPressGetCurrentOpportunitiesPage from 'app/gql/wordPressGetCurrentOpportunitiesPage';
import wordPressGetListOfCurrentOpportunities from 'app/gql/wordPressGetListOfCurrentOpportunities';
import { wordPressGQL } from 'app/services/wordPressGqlService';

const currentOpportunitiesApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getListOfCurrentOpportunities: builder.query({
			query: (args: { pageSize: number; cursor?: string }) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetListOfCurrentOpportunities(args.pageSize, args.cursor),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				const pageInfo = { ...res.data.jobs.pageInfo };
				const jobData = res.data.jobs.nodes;

				return { jobData, pageInfo };
			},
		}),

		getCurrentOpportunitiesPage: builder.query({
			query: (uri) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetCurrentOpportunitiesPage(uri),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				const pageData = res.data.pageBy;

				return { pageData };
			},
		}),
	}),
});

export const {
	useGetListOfCurrentOpportunitiesQuery,
	useLazyGetListOfCurrentOpportunitiesQuery,
	useGetCurrentOpportunitiesPageQuery,
} = currentOpportunitiesApi;
