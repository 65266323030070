import React from "react";

import { ReactComponent as StoreAddress } from "assets/images/svg/Store Address.svg";
import { ReactComponent as StoreOpeningTimes } from "assets/images/svg/Store Opening Times.svg";
import { ReactComponent as StorePhoneNumber } from "assets/images/svg/Store Phone Number.svg";

import { Store } from "ts/types";

import { MarkerTooltipContainer } from "./MarkerTooltip.Styled";
import { useTranslation } from "react-i18next";

type Props = {
	store: Store;
};

const MarkerTooltip = ({ store }: Props) => {
	const { t } = useTranslation();

	const openingTimes = React.useMemo(() => {
		if (!store) {
			return [];
		}

		const days = [
			"monday",
			"tuesday",
			"wednesday",
			"thursday",
			"friday",
			"saturday",
			"sunday",
		];

		const openingTimes = [];

		for (const day of days) {
			const { closed, open, close } =
				store.storeInformation.storeOpeningTimes[day];

			if (closed) {
				openingTimes.push({
					day: t(day),
					open,
					close,
					closed,
				});
			}

			if (!closed && open && close) {
				openingTimes.push({
					day: t(day),
					open,
					close,
					closed,
				});
			}
		}

		return openingTimes;
	}, [store]);

	return (
		<MarkerTooltipContainer>
			<div className="row">
				<p className="name">{store.title}</p>
			</div>
			<div className="row">
				<StoreAddress />
				<p>
					{[
						store.storeInformation.address.addressLine1,
						store.storeInformation.address.addressLine2,
					]
						.filter((i) => Boolean(i))
						.join(", ")}
				</p>
				{store.storeInformation.storeLocation.googleMapsLink && (
					<a
						href={store.storeInformation.storeLocation.googleMapsLink}
						target="_blank"
						rel="noreferrer"
					>
						(Google Map Link)
					</a>
				)}
			</div>
			<div className="row">
				<StorePhoneNumber />
				<a href={`tel:${store.storeInformation.phoneNumber}`}>
					{store.storeInformation.phoneNumber}
				</a>
			</div>
			{openingTimes.map(({ day, open, close, closed }) => (
				<div className="row" key={day}>
					<StoreOpeningTimes />
					{closed ? (
						<p>{`${day}: ${t("closed")}`}</p>
					) : (
						<p>{`${day}: ${open} - ${close}`}</p>
					)}
				</div>
			))}
		</MarkerTooltipContainer>
	);
};

export default MarkerTooltip;
