import { Cart, LineItem } from "types/CartTypes";

export const getCartTotalItems = (cart: Cart | undefined) => {
	if (!cart?.line_items?.physical_items) {
		return 0;
	}

	return cart.line_items.physical_items
		.map((item) => item.quantity)
		.reduce((prevVal, curVal) => prevVal + curVal, 0);
};

export const cartContainsItem = (
	cart: Cart | undefined,
	lineItem: LineItem,
) => {
	if (!cart) {
		return false;
	}

	return (
		cart.line_items.physical_items.find((item) => {
			if (!lineItem.id) {
				return (
					item.product_id === lineItem.product_id &&
					item.variant_id === lineItem.variant_id
				);
			} else {
				return item.id === lineItem.id;
			}
		}) !== undefined
	);
};
