import wordPressGetStaffStoryPage from 'app/gql/wordPressGetStaffStoryByUrl';
import { wordPressGQL } from 'app/services/wordPressGqlService';

const staffStoryApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getStaffStoryPage: builder.query({
			query: (colleagueName) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetStaffStoryPage(colleagueName),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				const { colleague: colleagueData, seo } = res.data.colleague;

				// Images into array so we can loop over to generate HTML together
				let images: any = [];
				let colleagueArray = Object.entries(res.data.colleague.colleague);
				colleagueArray.forEach(([key, value]) => {
					if (key.includes('image') && value) {
						images.push(value);
					  }
				});

				const colleagueName = res.data.colleague.title;

				return { colleagueData, images, colleagueName,seo };
			},
		}),
	}),
});

export const { useGetStaffStoryPageQuery } = staffStoryApi;
