import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useLocation, useParams } from "react-router";

import { useLazyGetProductByUrlQuery } from "app/api/product/productApi";
import {
	getCompleteTheLook,
	getGender,
} from "app/utils/bigcommerceProductUtil";

import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { BreadcrumbWrapper } from "components/Breadcrumb/Breadcrumb.Styled";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import StructuredData from "components/common/ui/StructuredData/StructuredData";
import PdpCompleteTheLook from "components/pdp/PdpCompleteTheLook/PdpCompleteTheLook";
import PdpDetailsSection from "components/pdp/PdpDetails/PdpDetails";
import PdpFeatureImages from "components/pdp/PdpFeatureImages/PdpFeatureImages";
import "swiper/css";

import usePageContent from "hooks/usePageContent";

import { useDispatch, useSelector } from "redux/hooks";
import { addSku } from "redux/recentlyViewed/recentlyViewedSlice";

import { PageType } from "ts/enums";
import { Product } from "ts/types";

import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import { Gender } from "components/pdp/PdpDetails/PdpSizeActions/PdpSizeGuide/PdpSizeGuide";
import useDataLayer from "datalayer/useDataLayer";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Brand } from "hooks/useBrand";
import { useTranslation } from "react-i18next";
import { setBrand } from "redux/UI/uiSlice";
import PathTranslationKey from "utilities/paths";
import { ContentWrapper } from "./ProductDetailPage.Styled";
import useLoadingDebounce from "hooks/useLoadingDebounce";

const ProductDetailPage = () => {
	const productOutOfStockBehavior = useSelector(
		(state) => state.bcStore.settings?.inventory.productOutOfStockBehavior,
	);
	const brand = useSelector((state) => state.ui.brand);

	const { debounceLoading, setLoading, loading } = useLoadingDebounce();

	const page = usePageContent();

	const { t } = useTranslation();

	const location = useLocation();
	const params = useParams<{ product: string }>();
	const datalayer = useDataLayer();
	const redirect = useLocaleNavigate();

	const [getProductTrigger, getProductResult] = useLazyGetProductByUrlQuery();

	const dispatch = useDispatch();

	const storeHash = useSelector((state) => state.bcStore.store?.hash);

	const [product, setProduct] = useState<Product | undefined>(undefined);

	const [showNoProductError, setShowNoProductError] = useState<boolean>(false);

	useEffect(() => {
		if (product) {
			const gender = getGender(product);
			if (gender) {
				let active = Brand.Global;

				switch (gender) {
					case Gender.MALE:
						active = Brand.Men;
						break;
					case Gender.FEMALE:
						active = Brand.Women;
						break;
				}

				brand !== active && dispatch(setBrand(active));
			}

			sessionStorage.setItem("last_viewed_product", JSON.stringify(product.id));
		}
	}, [product]);

	const breadcrumbs = React.useMemo(() => {
		const categories = product?.categories;

		if (categories) {
			const path = categories?.path;
			const breadcrumbs = location.state?.breadcrumbs || categories.breadcrumbs;
			const pathArray = path?.split("/").filter((path: string) => path !== "");

			return (
				breadcrumbs?.map((breadcrumb: any, index: number) => ({
					name: breadcrumb.name,
					path: "/" + pathArray.slice(0, index + 1).join("/"),
				})) || []
			);
		}
	}, [product]);

	// Custom field data for "complete the look"
	const completeTheLook = useMemo(() => {
		if (!product) {
			return undefined;
		}
		return getCompleteTheLook(product);
	}, [product]);

	useEffect(() => {
		setLoading(true);
	}, [location]);

	useEffect(() => {
		page.scrollToTop();
		if (loading) {
			const stateProduct = (location.state?.product as Product) || undefined;

			if (stateProduct) {
				if (!product || product.entityId !== stateProduct.entityId) {
					setProduct(location.state.product as Product);
				}
			} else {
				if (!getProductResult.isLoading) {
					getProductTrigger(`/${params.product}` || "");
				}
			}
		}
	}, [loading]);

	useEffect(() => {
		if (!getProductResult.isUninitialized && !getProductResult.isLoading) {
			if (getProductResult.data) {
				setProduct(getProductResult.data);
			} else {
				setShowNoProductError(true);
			}
		}
	}, [getProductResult]);

	useEffect(() => {
		if (product) {
			const breadcrumbs =
				location.state?.breadcrumbs || product?.categories?.breadcrumbs || [];

			datalayer.viewItem(
				product,
				breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].entityId : "",
				breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].name : "",
			);
			setLoading(false);
		}
	}, [product]);

	useEffect(() => {
		if (getProductResult.data) {
			setProduct(getProductResult.data);
			setLoading(false);
		}
	}, [getProductResult]);

	useEffect(() => {
		if (
			product &&
			breadcrumbs &&
			productOutOfStockBehavior === "HIDE_PRODUCT_AND_REDIRECT"
		) {
			redirect(
				`${t(PathTranslationKey.CATEGORY)}${
					breadcrumbs[breadcrumbs?.length - 1].path
				}`,
			);
		}
	}, [product, breadcrumbs]);

	useEffect(() => {
		if (product) {
			dispatch(addSku(product.sku));
		}
	}, [dispatch, product]);

	const hideProduct = useMemo(() => {
		if (!product || showNoProductError) {
			return true;
		}

		if (productOutOfStockBehavior === "HIDE_PRODUCT") {
			if (product.variants?.length === 0 && product.inventory.isInStock) {
				return false;
			}

			if (product.availability === "Unavailable") {
				return true;
			}

			if (
				product.variants?.length &&
				product.variants?.every(
					(variant) => !variant.inventory.isInStock || !variant.isPurchasable,
				)
			) {
				return true;
			}
		}

		return false;
	}, [product]);

	const dataFromLStorage: string | any = useMemo(
		() => localStorage.getItem(`recently_viewed_products_${storeHash}`),
		[localStorage.getItem(`recently_viewed_products_${storeHash}`)],
	);

	if (product && hideProduct && !loading) {
		return (
			<Page pageType={PageType.PRODUCT}>
				<NoProduct />
			</Page>
		);
	}

	let title = "";

	if (getProductResult.data) {
		title = getProductResult.data?.seo.pageTitle
			? getProductResult.data?.seo.pageTitle
			: getProductResult.data?.name;
	}

	return (
		<Page pageType={PageType.PRODUCT} title={title}>
			{!loading && product && !hideProduct && (
				<Fragment>
					<SEO
						title={title}
						description={getProductResult.data?.seo.metaDescription}
						keywords={getProductResult.data?.seo.metaKeywords}
					/>
					<StructuredData type="Product" data={product} />
				</Fragment>
			)}

			<BreadcrumbWrapper>
				<Breadcrumb loading={loading} items={breadcrumbs} />
			</BreadcrumbWrapper>

			<ContentWrapper className="ContentWrapper">
				<PdpDetailsSection loading={loading} product={product} />

				<PdpFeatureImages loading={loading} images={product?.images || []} />
			</ContentWrapper>

			{completeTheLook && (
				<PdpCompleteTheLook
					productPageSku={product?.sku || ""}
					field={completeTheLook}
				/>
			)}
		</Page>
	);
};

const NoProduct = () => {
	const { t } = useTranslation();

	return (
		<Page pageType={PageType.PRODUCT}>
			<ErrorContent heading={t("productNotFoundHeading")}>
				{t("productNotFound")}
			</ErrorContent>
		</Page>
	);
};

export default ProductDetailPage;
