import React from "react";

import SpTitle from "components/supportingPages/SpTitle/SpTitle";

import { Page } from "ts/types";

import { StyledSpGrid } from "./SupportingPageLayout.Styled";

type Props = {
  children: React.ReactNode;
  data: Page | undefined;
  loading: boolean;
};

const SupportingPageLayout = ({ children, data, loading }: Props) => {
  return (
    <StyledSpGrid>
      <SpTitle className="title" isLoading={loading} title={data?.title} />
      {children}
    </StyledSpGrid>
  );
};

export default SupportingPageLayout;
