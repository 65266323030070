import { FunctionComponent, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { URLSearchParams } from "url";
import ButtonWithIcon from "../Button/ButtonWithIcon/ButtonWithIcon";
import PaginationArrow from "../Icons/PaginationArrow";
import Skeleton from "../Skeleton/Skeleton";

import { StyledPaginationWrapper } from "./Pagination.Styled";

interface PaginationProps {
	loading?: boolean;
	totalPages: number;
	onChange?: (page: number) => void;
}

const getPageNumberFromParams = (searchParams: URLSearchParams) => {
	const pageParam = Number(searchParams.get("page"));

	const page = Number.isNaN(pageParam) ? 0 : pageParam;

	return page === 0 ? 1 : page;
};
const getCurrentFromParams = (searchParams: URLSearchParams) => {
	const page = searchParams.get("page");
	searchParams.delete("page");
	const extraParams = searchParams.toString();
	searchParams.append("page", String(page));

	return extraParams ? "&" + extraParams : "";
};

const Pagination: FunctionComponent<PaginationProps> = ({
	loading = false,
	totalPages,
	onChange = () => {},
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [activePage, setActivePage] = useState(
		getPageNumberFromParams(searchParams),
	);

	useEffect(() => {
		onChange(activePage);
	}, []);

	const extraParamString = getCurrentFromParams(searchParams);
	useEffect(() => {
		const pageParam = getPageNumberFromParams(searchParams);

		// don't do anything if the page is the same
		if (pageParam === activePage) {
			return;
		}

		if (totalPages !== 0) {
			if (pageParam > totalPages) {
				handlePageChange(totalPages);
			} else if (pageParam < 1) {
				handlePageChange(1);
			} else {
				handlePageChange(pageParam);
			}
		}
	}, [totalPages, searchParams]);

	useEffect(() => {
		if (onChange) {
			onChange(activePage);
		}
	}, [activePage]);

	const handlePageChange = (page: number) => {
		setActivePage(page);

		const pageParam = getPageNumberFromParams(searchParams);
		if (pageParam !== page) {
			setSearchParams(searchParams);
		}
	};

	if (loading) {
		return <PaginiationSkeleton />;
	}

	if (totalPages === 0) {
		return null;
	}

	const pageItems = [];

	for (let i = 0; i < totalPages; i++) {
		const pageNumber = i + 1;

		pageItems.push(
			<li
				key={pageNumber}
				className={activePage === pageNumber ? "active" : ""}
			>
				<Link to={`?page=${pageNumber}${extraParamString}`}>{pageNumber}</Link>
			</li>,
		);
	}

	const pagesBefore = 1;
	const pagesAfter = 1;

	let pagesToShow = pagesBefore + pagesAfter + 1;
	// make sure pages to show doesn't exceed the total number of pages
	pagesToShow = pagesToShow > totalPages ? totalPages : pagesToShow;

	const beforeLastPage = totalPages - pagesToShow;

	const leftBound = activePage;
	const rightBound = activePage + pagesAfter;

	let startPage = 0;

	if (leftBound === 1) {
		startPage = leftBound; //at the very start, page 1
	} else if (leftBound - pagesBefore > beforeLastPage) {
		startPage = beforeLastPage + 1; //near the end
	} else {
		startPage = leftBound - pagesBefore; //in the middle of pages
	}

	let endPage = 0;

	if (leftBound === 1) {
		endPage = rightBound + pagesBefore;
	} else if (rightBound > totalPages) {
		endPage = totalPages;
	} else {
		endPage = rightBound;
	}

	return (
		<StyledPaginationWrapper className="Pagination">
			<div className="Pagination__button-wrapper">
				{activePage !== 1 && (
					<Link to={`?page=${activePage - 1}${extraParamString}`} rel="prev">
						<ButtonWithIcon
							icon={<PaginationArrow variant="prev" />}
						></ButtonWithIcon>
					</Link>
				)}
			</div>
			<ul>{pageItems.slice(startPage - 1, endPage)}</ul>

			<div className="Pagination__button-wrapper">
				{activePage !== totalPages && (
					<Link to={`?page=${activePage + 1}${extraParamString}`} rel="next">
						<ButtonWithIcon
							icon={<PaginationArrow variant="next" />}
						></ButtonWithIcon>
					</Link>
				)}
			</div>
		</StyledPaginationWrapper>
	);
};

const PaginiationSkeleton = () => {
	return (
		<StyledPaginationWrapper className="Pagination">
			<div className="Pagination__button-wrapper">
				<Skeleton />
			</div>
			<ul>
				<Skeleton width={50} />
			</ul>
			<div className="Pagination__button-wrapper">
				<Skeleton />
			</div>
		</StyledPaginationWrapper>
	);
};

export default Pagination;
