import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router";

import wishlistApi from "app/api/customer/wishlistApi";

import { InputText } from "components/common/form/Input/Input";
import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import Button from "components/common/ui/Button/Button";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";

import { useSelector } from "redux/hooks";

import { Wishlist } from "../../AccountWishlistOverview/AccountWishlistOverview";

import {
	wishlistFormDefaultValues,
	WishlistFormSchema,
	wishlistFormSchema,
} from "./WishlistForm.Schema";

import { WishlistFormContainer } from "./WishlistForm.Styled";
import useDataLayer from "datalayer/useDataLayer";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

type WishlistKey = "name" | "is_public";

interface Props {
	wishlist?: WishlistFormSchema;
	isLoading?: boolean;
}

const WishlistForm = ({ wishlist, isLoading }: Props) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const datalayer = useDataLayer();

	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
		setValue,
	} = useForm<WishlistFormSchema>({
		resolver: yupResolver(wishlistFormSchema),
		defaultValues: wishlistFormDefaultValues,
	});

	React.useEffect(() => {
		if (wishlist) {
			Object.entries(wishlist).forEach(([key, value]) => {
				setValue(key as WishlistKey, value);
			});
		}
	}, [setValue, wishlist]);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [createWishlist, createWishlistResult] =
		wishlistApi.useCreateWishlistMutation();
	const [editWishlist, editWishlistResult] =
		wishlistApi.useEditWishlistMutation();
	const [getWishlistsTrigger] = wishlistApi.useLazyGetWishlistsQuery();

	const customer: any = useSelector((state) => state.auth.customer);

	const navigate = useLocaleNavigate();
	const location = useLocation();

	const items = React.useMemo(() => {
		return location.state?.items || [];
	}, [location.state?.items]);

	// Get product for datalayer
	const product = React.useMemo(() => {
		return location.state?.product || undefined;
	}, [location.state?.product]);

	const onSubmit: SubmitHandler<WishlistFormSchema> = (data) => {
		if (createWishlistResult.isLoading || editWishlistResult.isLoading) return;

		setIsSubmitting(true);

		let result;

		if (wishlist) {
			result = editWishlist(data as Wishlist);
		} else {
			result = createWishlist({ ...data, customer_id: customer.id, items });
		}

		result
			.unwrap()
			.then(({ data }: { data: Wishlist }) => {
				// datalayer
				if (product) {
					const breadcrumbs = product?.categories?.breadcrumbs || [];

					datalayer.addToWishList(
						product,
						breadcrumbs.length
							? breadcrumbs[breadcrumbs.length - 1].entityId
							: "",
						breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].name : "",
					);
				}

				getWishlistsTrigger().then(() =>
					navigate(`${t(PathTranslationKey.ACCOUNT_WISHLIST)}/${data.id}`),
				);
			})
			.catch(() => setIsSubmitting(false));
	};

	if (isLoading) {
		return (
			<WishlistFormContainer
				onSubmit={handleSubmit(onSubmit)}
				showLegend={false}
			>
				<p>
					Wishlist Name <span>*</span>
				</p>
				<Skeleton />
				<Skeleton />
				<Button type="submit" variant="primary_01" disabled>
					{id ? `${t('wishlist.saveWishlist')}` : `${t('wishlist.createWishlist')}`}
				</Button>
			</WishlistFormContainer>
		);
	}

	return (
		<WishlistFormContainer onSubmit={handleSubmit(onSubmit)} showLegend={false}>
			<InputText
				label={t("wishlists.listName") as string}
				required
				{...register("name")}
				errorMsg={errors.name?.message && (t(errors.name.message) as string)}
			/>
			<Controller
				control={control}
				name="is_public"
				render={({ field }) => (
					<InputCheckbox {...field} label={`${t('wishlist.shareWishlist')}`} />
				)}
			/>
			<Button type="submit" variant="primary_01" loading={isSubmitting}>
				{id ? `${t('wishlist.saveWishlist')}` : `${t('wishlist.createWishlist')}`}
			</Button>
		</WishlistFormContainer>
	);
};

export default WishlistForm;
