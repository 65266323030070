import Button from "../Button/Button";

import { Navigation, Pagination, Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
	StyledCarouselCtaWrapper,
	StyledCarouselVideoWrapper,
	StyledCarouselWrapper,
} from "./Styled";

import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import uuid from "react-uuid";
import "swiper/css";
import Link from "../Link/Link";

import CarouselControls from "../carouselControls/CarouselControls";
import Skeleton from "../Skeleton/Skeleton";

type Props = {
	carouselData: {
		carouselSetting: {
			showPagination: boolean;
			showNavigation: boolean;
			slidesPerView: number;
			slidesPerGroup: number;
			spaceBetween?: number;
		};
		carouselSlides: {
			type: string;
			imageAlt: string;
			slideImage: {
				desktop: {
					imageSrc: string;
				};
				mobile: {
					imageSrc: string;
				};
			};
			slideVideo: {
				desktopUrl?: string;
				mobileUrl?: string;
			};
			slideLink: {
				title: string;
				url: string;
			};
			ctaButtons: {
				buttonText: string;
				buttonLinkLocation: string;
			}[];
		}[];
	};
};

const HeroCarousel = ({ carouselData }: Props) => {
	const swiperRef = useRef<SwiperType>();
	const location = useLocation();

	const [carouselSettings, setCarouselSettings] = useState({
		showNavigation: true,
		showPagination: true,
		slidesPerView: 1,
		slidesPerGroup: 1,
	});
	const [previousSlideTitle, setPreviousSlideTitle] = useState("");
	const [nextSlideTitle, setNextSlideTitle] = useState("");
	const [isLoop, setIsLoop] = useState(false);

	const populateCarouselButtons = useCallback(
		(carouselData: any) => {
			if (!carouselData.slides.length) return;

			/* Only show alternate text for left / right arrows if there's only 2 slides */
			if (carouselData.slides.length === 2) {
				setNextSlideTitle(
					carouselData.activeIndex === 0
						? carouselData.slides[1]?.dataset.slideTitle
						: "",
				);
				setPreviousSlideTitle(
					carouselData.activeIndex === 1
						? carouselData.slides[0]?.dataset.slideTitle
						: "",
				);
				return;
			} else if (carouselData.slides.length > 2) {
				setIsLoop(true);

				setNextSlideTitle(
					carouselData.slides.length - 1 === carouselData.activeIndex
						? carouselData.slides[2]?.dataset.slideTitle
						: carouselData.slides[carouselData.activeIndex + 1]?.dataset
								.slideTitle,
				);

				setPreviousSlideTitle(
					carouselData.activeIndex === 0
						? carouselData.slides[carouselData.slides.length - 3].dataset
								.slideTitle
						: carouselData.slides[carouselData.activeIndex - 1].dataset
								.slideTitle,
				);
			}
		},
		[carouselData],
	);

	useEffect(() => {
		if (!carouselData) return;

		const {
			carouselSetting: {
				showNavigation,
				showPagination,
				slidesPerView,
				slidesPerGroup,
			},
		} = carouselData;

		setCarouselSettings({
			showNavigation,
			showPagination,
			slidesPerGroup,
			slidesPerView,
		});
	}, [carouselData]);

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(1);
			populateCarouselButtons(swiperRef.current);
		}
	}, [location.pathname, swiperRef.current, carouselData]);

	// TODO investigate if we need this
	const onNavigationClick = (direction: "next" | "prev") => {
		if (swiperRef.current) {
			const swiper = swiperRef.current;

			direction === "next" ? swiper.slideNext() : swiper.slidePrev();
		}
	};

	function VideoLinkTransformer<S extends string>(link: S): S {
		let videoID: S = link.split("/").slice(-1)[0] as S;
		let transformedLink: S =
			`https://player.vimeo.com/video/${videoID}?h=17ec0d5818&autoplay=1&loop=1&title=0&byline=0&portrait=0&background=1` as S;
		return transformedLink;
	}

	return (
		<StyledCarouselWrapper>
			{carouselData ? (
				<Swiper
					onBeforeInit={(swiper) => {
						swiperRef.current = swiper;
					}}
					modules={[Pagination, Navigation]}
					pagination={{
						enabled: carouselSettings.showPagination,
						el: ".StyledPaginationWrapper",
						bulletClass: "pagination-dot",
						bulletActiveClass: "pagination-dot--active",
						clickable: true,
					}}
					navigation={{
						enabled: carouselSettings.showNavigation,
						nextEl: ".carousel-navigation--next",
						prevEl: ".carousel-navigation--prev",
					}}
					slidesPerView={carouselSettings.slidesPerView}
					slidesPerGroup={carouselSettings.slidesPerGroup}
					loop={isLoop}
					onSlideChange={(e) => populateCarouselButtons(e)}
					onSwiper={(e) => populateCarouselButtons(e)}
				>
					{carouselData?.carouselSlides.map((slide, index) => (
						<SwiperSlide key={uuid()} data-slide-title={slide.slideLink.title}>
							<Link to={slide.slideLink.url} rel="noopener noreferrer">
								{slide.type === "Image" ? (
									<picture>
										<source
											srcSet={slide.slideImage.desktop.imageSrc}
											media="(min-width: 801px)"
										/>
										<img
											src={slide.slideImage.mobile.imageSrc}
											alt={slide.imageAlt}
										/>
									</picture>
								) : (
									<StyledCarouselVideoWrapper
										data-vimeo-id={
											String(slide.slideVideo.desktopUrl)
												.split("/")
												.slice(-1)[0]
										}
										data-vimeo-defer
									>
										<iframe
											src={VideoLinkTransformer<string>(
												slide.slideVideo.desktopUrl as string,
											)}
											loading="lazy"
											title="background video"
											allow="autoplay;"
											data-vimeo-id={
												String(slide.slideVideo.desktopUrl)
													.split("/")
													.slice(-1)[0]
											}
											data-vimeo-defer
										></iframe>

										<script src="https://player.vimeo.com/api/player.js"></script>
									</StyledCarouselVideoWrapper>
								)}
							</Link>
							{!!slide.ctaButtons.length && (
								<StyledCarouselCtaWrapper>
									{slide?.ctaButtons?.map((button) => (
										<Button
											variant="primary_02"
											link={{ to: button.buttonLinkLocation }}
											key={uuid()}
										>
											{button.buttonText}
										</Button>
									))}
								</StyledCarouselCtaWrapper>
							)}
						</SwiperSlide>
					))}
					<CarouselControls
						hasText
						previousText={previousSlideTitle}
						nextText={nextSlideTitle}
						onNavigationClick={onNavigationClick}
					/>
				</Swiper>
			) : (
				<Skeleton height="34.5rem" />
			)}
		</StyledCarouselWrapper>
	);
};

export default HeroCarousel;
