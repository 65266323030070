import styled from 'styled-components';
import { aboveMedium } from 'utilities/variables/media';

export const StyledImageTextGrid = styled.div`
	&.StyledImageTextGrid {
		@media ${aboveMedium} {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.text-container {
			height: 100%;
			margin: auto 0;
		}

		.image {
			width: 100%;
			height: 100%;
			position: relative;

			@media ${aboveMedium} {
				overflow: hidden;
			}

			picture {
				width: 100%;
			}

			.clip {
				position: absolute;
				height: 100%;
			}

			img {
				display: block;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}
`;
