import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledSingleImageWithCta = styled.div`
	position: relative;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 41%;
	}

	.text-container {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		pointer-events:none;
	}
	.text-container * {
		pointer-events: auto; 
	  }

	&.bottom-banner {
		height: ${remCalc(295)};

		@media ${aboveMedium} {
			height: ${remCalc(359)};
		}

		img {
			height: 100%;
			/* width: unset; */
			aspect-ratio: auto;

			@media ${aboveMedium} {
				width: 100%;
			}
		}
	}
`;

export const StyledCtaWrapper = styled.div`
	position: absolute;
	width: 100%;
	bottom: 2rem;

	a {
		margin-inline: auto;
		display: block;
		text-transform: uppercase;
		font-size: 16px;
		text-align: center;

		@media ${aboveMedium} {
			width: fit-content;
		}
	}
`;

export const StyledTextWrapper = styled.div`
	p {
		color: ${white};
		font-size: 32px;
		font-family: ${Roboto};
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 1.6px;
		text-align: center;
		text-transform: uppercase;
		line-height: ${remCalc(39)};
		margin: 0;
	}
`;
