
import React from "react";

import {
  FieldValue,
  NumberFieldValue,
  updateNumberFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
  FieldError,
  FormField,
  NumberField as NumberFieldType,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputNumber } from "../GravityForm.Styled";
import { useTranslation } from "react-i18next";

interface Props {
  field: FormField & Partial<NumberFieldType>;
  fieldErrors: FieldError[];
  defaultValue?: number;
}

const NumberField = ({ field, fieldErrors, defaultValue }: Props) => {
  const { id } = field;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fieldValues = useSelector((state) => state.gravityForm.fieldValues);

  const fieldValue = fieldValues.find(
    (fieldValue: FieldValue) => fieldValue.id === id
  ) as NumberFieldValue | undefined;

  const numberValue = fieldValue?.value || defaultValue;

  const updateValue = React.useCallback(
    (value: number) => {
      dispatch(updateNumberFieldValue({ id, value }));
    },
    [dispatch, id]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    updateValue(isNaN(newValue) ? 0 : newValue);
  };

  React.useEffect(() => {
    if (numberValue !== undefined) {
      updateValue(numberValue);
    }
  }, [numberValue, updateValue]);

  return (
    <StyledFieldContainer
      layoutGridColumnSpan={field.layoutGridColumnSpan}
      isError={Boolean(fieldErrors.length)}
      visibility={field.visibility}
    >
      <StyledInputNumber
        label={field.label}
        required={field.isRequired}
        isError={Boolean(fieldErrors.length)}
        errorMsg={
          fieldErrors[0]?.message.includes("required")
            ? t("form.validation.field.required") ?? ""
            : fieldErrors[0]?.message
        }
        onChange={handleChange}
        value={numberValue === undefined ? "" : numberValue.toString()}
        type="number"
      />
    </StyledFieldContainer>
  );
};

export default NumberField;