import React from "react";

import {
	FieldValue,
	NameFieldValue,
	updateNameFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
	FieldError,
	FormField,
	NameField as NameFieldType,
	NameInput,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputText } from "../GravityForm.Styled";
import { useTranslation } from "react-i18next";

interface Props {
	field: FormField & Partial<NameFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: NameInput;
}

const NameField = ({ field, fieldErrors, defaultValue }: Props) => {
	const DEFAULT_VALUE = defaultValue as NameInput;
	const { id } = field;
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as NameFieldValue | undefined;

	const nameValues = fieldValue?.nameValues || DEFAULT_VALUE;

	const updateValues = React.useCallback(
		(nameValues: NameInput) => {
			dispatch(updateNameFieldValue({ id, nameValues }));
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		const newNameValues = { ...nameValues, [name]: value };

		updateValues(newNameValues);
	};

	React.useEffect(() => {
		updateValues(nameValues);
	}, [nameValues, updateValues]);

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			{field.inputs?.map(
				(input) =>
					!input.isHidden && (
						<StyledInputText
							key={input.key}
							name={input.key}
							label={input.customLabel ?? input.label}
							required={field.isRequired}
							isError={Boolean(fieldErrors.length)}
							errorMsg={
								fieldErrors[0]?.message.includes("required")
									? t('form.validation.field.required') ?? ''
									: fieldErrors[0]?.message
							}
							onChange={handleChange}
							// @ts-ignore
							value={nameValues[input.key] || ""}
						/>
					),
			)}
		</StyledFieldContainer>
	);
};

export default NameField;
