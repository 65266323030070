import Markdown from 'components/common/ui/Markdown/Markdown';
import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledAccountLandingSection = styled.section`
    color: ${dark};

    h2 {
        font-size: 1em;
        font-weight: 700;
    }

    p {
        font-size: 0.875em;
        color: ${accountDarkGrey};
    }
`;

export const StyledLinkCards = styled.div`
    display: grid;
    gap: 0.5rem;
    margin-top: 1.5rem;

    @media ${aboveMedium} {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
`;

export const CMSContent = styled(Markdown)`
  color: ${accountDarkGrey};
  font-size: ${remCalc(14)};

  p {
    margin-top: ${remCalc(8)};
  }

`