import Aside from "components/common/ui/Aside/Aside";
import { HeadingMediumStyles } from "components/common/ui/Typography/Typography.Styled";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey, white } from "utilities/variables/colors";

export const Spacer = styled.div`
	height: ${remCalc(64)};
	width: 100%;
	background-color: ${white};
`;

export const SideMenuItemButton = styled.button<{
	header?: boolean;
}>`
	${HeadingMediumStyles}
	width: 100%;
	text-align: ${(props) => (props.header ? "center" : "left")};
	background-color: transparent;
	position: relative;
	color: ${dark};

	i {
		position: absolute;
		&.back {
			left: 4px;
		}
    &.forward {
      right: 4px;
    }
	}

	&:hover,
	&:active,
	.active {
		color: ${darkGrey};
	}
`;

export const StyledAside = styled(Aside)`
	.AsideBody {
		overflow-y: hidden;
	}
`;
