import styled from 'styled-components/macro';
import { remCalc } from 'utilities/styles';
import { aboveLarge, aboveMedium } from 'utilities/variables/media';

export const StyledResponsibilityDetailPageWrapper = styled.div`
	.main-image-banner {
		margin-bottom: ${remCalc(64)};
	}

	.image-and-text-layout {
		@media ${aboveMedium} {
			margin-bottom: ${remCalc(64)};

			&:last-of-type {
				margin-bottom: ${remCalc(96)};
			}
		}
	}

	.skeleton-container {
		height: 100%;
		display: block;
	}

	.skeleton-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		flex-wrap: wrap;
		margin-top: ${remCalc(64)};
	}

	.skeleton-text {
		padding: 0 ${remCalc(30)};
		width: 100%;
		margin-top: ${remCalc(48)};

		@media ${aboveMedium} {
			padding: 0 ${remCalc(64)};
			width: 50%;
		}
	}
`;

export const SkeletonImageSplitWrapper = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	@media ${aboveMedium} {
		width: 50%;
	}

	.skeleton-container {
		width: 100%;
	}

	// Style image
	.react-loading-skeleton {
		width: 100%;
		height: ${remCalc(230)};

		@media ${aboveMedium} {
			height: ${remCalc(590)};
		}

		@media ${aboveLarge} {
			height: ${remCalc(640)};
		}
	}
`;
