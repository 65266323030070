import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { accountLightGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const AccountWishlistContainer = styled.div`
	h1 {
		font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
		color: ${dark};
		margin: 0;
		text-align: center;
	}

	.buttons__container {
		margin-top: ${remCalc(32)};
		background-color: ${accountLightGrey};
		padding: ${remCalc(24)} ${remCalc(16)};
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		a,
		button {
			width: 100%;
		}

		@media ${aboveMedium} {
			width: 100%;
			flex-direction: row;
			justify-content: center;

			a,
			button {
				width: auto;
			}
		}
	}

	.items__container {
		margin: ${remCalc(48)} auto 0;
		display: grid;
		grid-template-columns: 1fr;
		column-gap: ${remCalc(4)};
		row-gap: ${remCalc(32)};

		@media ${aboveMedium} {
			padding: 0 ${remCalc(20)};
			grid-template-columns: repeat(auto-fill, minmax(${remCalc(307)}, 1fr));
			row-gap: ${remCalc(24)};
			/* Max Width = 4 x Card Width (307px) + 3 x Column Gap (4px) + 2 x Padding (20px) */
			max-width: calc(
				(${remCalc(307)} * 4) + (${remCalc(4)} * 3) + (${remCalc(20)} * 2) +
					${remCalc(1)}
			);
		}

		.wishlist-item {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			gap: ${remCalc(16)};

			@media ${aboveMedium} {
				gap: ${remCalc(12)};
			}
		}
	}
`;
