import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, grey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const SearchLocationContainer = styled.div<{ showButton: boolean }>`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(24)};

	.input__container {
		display: flex;
		align-items: center;
		gap: ${remCalc(8)};
		border-bottom: 1px solid ${grey};
		position: relative;
		width: 100%;

		input,
		input::placeholder {
			font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
			color: ${dark};
			letter-spacing: ${remCalc(1)};
			background-color: ${white};
			border: none;
			outline: none;
			padding: ${remCalc(4)} 0;
			width: 100%;
		}

		button {
			display: ${({ showButton }) => !showButton && "none"};
			font: bold ${remCalc(16)} / ${remCalc(24)} ${Roboto};
			color: ${dark};
			letter-spacing: ${remCalc(0.8)};
			text-transform: uppercase;
			background-color: ${white};
			padding: ${remCalc(2)} 0 ${remCalc(6)};
		}
	}
`;

export const StyledTypography = styled(Typography)`
	font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	color: ${accountDarkGrey};
`;
