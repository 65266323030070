import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

export type SigninFormScheme = {
  email: string;
  password: string;
};

export const SigninFormValidation = object().shape({
  email: string().label("Email").email("form.validation.email.email").required("form.validation.email.required"),
  password: string().label("Password").required("form.validation.password.required"),
});

export const SigninFormResolver = yupResolver(SigninFormValidation);
