import { gql } from "graphql-request";

const wordPressGetPageContentByUri = (uri: string, jobApplication = false) => {
	let query;

	if (jobApplication) {
		query = gql`
			query($uri: String!) {
				pageBy(uri: $uri) {
					content
					jobApplication {
						fieldGroupName
						introduction {
							fieldGroupName
							introductionHeaderImage {
								fieldGroupName
								title
								image {
									altText
									sourceUrl
									uri
								}
								text {
									title
									url
								}
							}
						}
					}
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
					title
				}
			}
		`;
	} else {
		query = gql`
			query($uri: String!) {
				pageBy(uri: $uri) {
					content
					faqs {
						faqs {
							faq {
								faq
								answer
							}
						}
					}
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
					title
				}
			}
		`;
	}

	return {
		variables: {
			uri,
		},
		query,
	};
};

export default wordPressGetPageContentByUri;
