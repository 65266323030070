import React from "react";

import parse, {
	DOMNode,
	Element,
	HTMLReactParserOptions,
	Text,
} from "html-react-parser";

import { StyledWrapper } from "./Styled";

export const domNodeIsElement = (domNode: DOMNode): domNode is Element => {
	return domNode.type === "tag";
};

export const domNodeIsText = (domNode: DOMNode): domNode is Text => {
	return domNode.type === "text";
};

export interface MarkdownProps {
	html?: string;
	options?: HTMLReactParserOptions;
	// Set this to true if you get a React error "Warning: Cannot update a component while rendering a different component"
	safeRender?: boolean;
}

const Markdown = ({
	html = "",
	options,
	safeRender,
	...props
}: MarkdownProps) => {
	const [parsedHtml, setParsedHtml] = React.useState<
		string | JSX.Element | JSX.Element[]
	>();

	React.useEffect(() => {
		const nextParsedHtml = parse(html, options);
		setParsedHtml(nextParsedHtml);
	}, [html, options]);

	if (safeRender) {
		return <StyledWrapper {...props}>{parsedHtml}</StyledWrapper>;
	}

	return <StyledWrapper {...props}>{parse(html, options)}</StyledWrapper>;
};

export default Markdown;
