import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { accountDarkGrey } from "utilities/variables/colors";

export const StyledTypographySku = styled(Typography)`
  margin-top: 4px;
  color: ${accountDarkGrey};
  text-transform: uppercase;
`;

export const StyledTypographyPrice = styled(Typography)`
  margin-top: 12px;
  font-size: 16px;
`;
