import Link from "components/common/ui/Link/Link";
import styled from "styled-components";
import { darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export default "";

export const ItemWrapper = styled.div`
	width: 106px;
	white-space: normal;

	@media ${aboveMedium} {
		width: 96px;
	}

	img.thumbnail {
		height: 130px;

		@media ${aboveMedium} {
			height: 146px;
		}
	}

	.skeleton-container {
		.thumbnail {
			height: 130px;

			@media ${aboveMedium} {
				height: 146px;
			}
		}

		.text {
			margin-top: 5px;
		}
	}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;

	&:hover,
	&:active {
		p {
			color: ${darkGrey};
		}
	}
`;

export const VariantsList = styled.ul`
	/* width: 100%; */
	display: inline-flex;
	justify-content: space-between;
	padding: 0;
	margin: 0 auto;
	list-style: none;

	li {
		display: inline-block;

		margin: 0px 4px;
		margin-top: 2px;
		margin-bottom: 10px; //prevent scroll overlay on content

		&:first-of-type {
			margin-left: 2px;
		}

		&:last-of-type {
			margin-right: 2px;
		}
	}
`;

export const VariantsWrapper = styled.div`
	white-space: nowrap;
	display: flex;
	justify-content: space-between;
`;
