import Page from "components/common/ui/Page/Page";

import { PageType } from "ts/enums";

import { StyledAccountWishlist } from "./WishlistSharePage.Styled";

const WishlistSharePage = () => {
  return (
    <Page pageType={PageType.PAGE}>
      <StyledAccountWishlist />
    </Page>
  );
};

export default WishlistSharePage;
