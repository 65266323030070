import Skeleton from "components/common/ui/Skeleton/Skeleton";

const OrderItemSkeleton = () => {
	return (
		<div className="item-card-loading">
			<div className="image-placeholder">
				<Skeleton width={25} height={25} style={{ marginRight: ".25rem" }} />
				<Skeleton width={"8rem"} height={"12rem"} />
			</div>
			<div className="content-placeholder">
				<Skeleton height={"1.3rem"} style={{ marginBottom: "1.5rem" }} />
				<Skeleton
					width={"50%"}
					height={"1rem"}
					count={5}
					style={{ marginBottom: ".5rem" }}
				/>
				<Skeleton width={"10ch"} className="test" />
			</div>
		</div>
	);
};

export default OrderItemSkeleton;
