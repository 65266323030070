import Typography from "components/common/ui/Typography/Typography";
import ProductBadge from "components/ProductBadge/ProductBadge";
import AddToCart from "components/ProductCard/AddToCart/AddToCart";

import {
	getCustomField,
	getSisterColourSKUs,
} from "app/utils/bigcommerceProductUtil";
import Carousel from "components/common/ui/Carousel/Carousel";
import Price from "components/common/ui/Price/Price";
import MoreColors from "components/MoreColours/MoreColours";
import {
	createRef,
	FunctionComponent,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useSelector } from "redux/hooks";
import { BreadcrumbType, Product } from "ts/types";
import {
	BelowImage,
	ContentLeft,
	ContentRight,
	ProductCardWrapper,
	ProductImages,
	ProductTitleLink,
} from "./ProductCard.Styled";
import ProductCardSkeleton from "./ProductCardSkeleton";
import Variants from "./Variants/Variants";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";
import { ModalOptions } from "components/common/ui/Modal/Modal";

interface Props {
	breadcrumbs?: BreadcrumbType[];
	triggeredFrom: string;
	idPrefix?: string;
	product?: Product;
	layout?: number;
	onClick?: (event: any) => void;
	loading?: boolean;
	className?: string;
	highResImages?: boolean;
	hide?: {
		sisterSkus?: boolean;
	};
	onAddToCart?: Function;
	variantModalOptions?: ModalOptions;
}

const ProductCard: FunctionComponent<Props> = ({
	breadcrumbs,
	triggeredFrom = "PAGE",
	idPrefix = "",
	product,
	layout = 1,
	onClick = () => {},
	loading = false,
	className = "",
	highResImages = false,
	hide = {
		sisterSkus: false,
	},
	onAddToCart,
	variantModalOptions,
}) => {
	const { t, ready } = useTranslation();
	const [addToCartOpen, setAddToCartOpen] = useState(false);
	const [moreColousOpen, setMoreColoursOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (loading || !product) {
			return;
		}

		let timeout: NodeJS.Timeout;

		if (
			JSON.parse(sessionStorage.getItem("last_viewed_product") || "{}") ===
			product?.id
		) {
			timeout = setTimeout(() => {
				const id = idPrefix ? `${idPrefix}_${product.id}` : product.id;
				sessionStorage.setItem("last_viewed_product", "{}");
				document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
			}, 500);
		}

		return () => {
			timeout && clearTimeout(timeout);
		};
	}, [loading, idPrefix, product]);

	useEffect(() => {
		setAddToCartOpen(false);
		setMoreColoursOpen(false);
	}, [layout]);

	const sisterSkusCustomField = useMemo(() => {
		if (!product) {
			return;
		}

		return getSisterColourSKUs(product);
	}, [product]);

	const productBadgeData = useMemo(() => {
		if (!product) {
			return;
		}

		const textBadge = getCustomField(product, "badge-text");
		const imageBadge = getCustomField(product, "badge-image");

		return {
			text:
				product.availability === "Preorder"
					? t("text.preorder")
					: textBadge?.value || "",
			image: imageBadge?.value || "",
		};
	}, [product, ready, t]);

	// handlers
	const handleAddToCartClick = () => {
		setAddToCartOpen(!addToCartOpen);
	};

	const handleMoreColoursClick = () => {
		setMoreColoursOpen(!moreColousOpen);
	};

	if (loading) {
		return <ProductCardSkeleton layout={layout} />;
	}

	if (!product) {
		return null;
	}

	const linkData = {
		to: `${t(PathTranslationKey.PRODUCT)}${product.path}`,
		state: {
			...(breadcrumbs && {
				breadcrumbs,
			}),
		},
	};

	const variantsModalId = `${product.entityId}_variants`;
	const sisterSkusModalId = `${product.entityId}_sister-skus`;

	const id = idPrefix ? `${idPrefix}_${product.id}` : product.id;
	const hasSalePrice =
		(product.salePrice?.value &&
			(product.salePrice?.value !== product?.price?.value ||
				product.salePrice?.value !== product?.basePrice?.value)) ||
		false;

	return (
		<ProductCardWrapper
			id={product.id}
			className={`ProductCard ${className}`}
			ref={ref}
		>
			<ProductImages id={id}>
				{layout < 2 && <ProductBadge {...productBadgeData} />}
				<Carousel
					link={linkData}
					images={product.images}
					fixed={layout === 2}
					onSlideClick={onClick}
					highRes={highResImages}
				/>
				<Variants
					triggeredFrom={triggeredFrom}
					id={variantsModalId}
					isOpen={addToCartOpen}
					close={() => {
						setAddToCartOpen(false);
					}}
					onAddToCart={onAddToCart}
					product={product}
					options={variantModalOptions}
				/>
			</ProductImages>

			{layout < 2 && (
				<BelowImage className="swiper-no-swiping">
					<ContentLeft>
						<ProductTitleLink
							{...linkData}
							onClick={onClick}
							className="product__title"
						>
							{product.name}
						</ProductTitleLink>

						<div className="price-container">
							{hasSalePrice ? (
								<Typography
									className="price price--sale"
									variant="body"
									size="small"
								>
									<Price price={product?.salePrice?.value || 0} />
								</Typography>
							) : null}
							<Typography
								className={`price${hasSalePrice ? " price--hasSale" : ""}`}
								variant="body"
								size="small"
							>
								<Price
									price={
										hasSalePrice
											? product?.basePrice?.value || 0
											: product.price.value
									}
								/>
							</Typography>
						</div>
						{!hide.sisterSkus && sisterSkusCustomField && (
							<MoreColors
								id={sisterSkusModalId}
								field={sisterSkusCustomField}
								isOpen={moreColousOpen}
								close={handleMoreColoursClick}
								onClick={handleMoreColoursClick}
								portalId={id}
							/>
						)}
					</ContentLeft>
					{product && (
						<ContentRight className={addToCartOpen ? "active" : ""}>
							<AddToCart
								onClick={handleAddToCartClick}
								data-target={variantsModalId}
							/>
						</ContentRight>
					)}
				</BelowImage>
			)}
		</ProductCardWrapper>
	);
};

export default ProductCard;
