import { ReactComponent as DropdownOpen } from "assets/images/svg/DropdownOpen.svg";
import { ReactComponent as DropdownClose } from "assets/images/svg/DropdownClose.svg";
import Icon from "./Icon";
import styled from "styled-components";
import { FunctionComponent } from "react";

interface DropdownArrowProps {
  open?: boolean;
}

const StyledIcon = styled(Icon)`
  margin-right: 0;
`;

const DropdownArrow: FunctionComponent<DropdownArrowProps> = ({ open = false }) => {
  return <StyledIcon icon={open ? <DropdownClose /> : <DropdownOpen />} />;
};

export default DropdownArrow;
