import { integrationLayer } from "app/services/integrationLayerService";
import { ForgotPasswordFormScheme } from "components/customer/PasswordReset/ForgotPassword/ForgotPasswordForm.Schema";
import { ResetPasswordFormSchema } from "components/customer/PasswordReset/ResetPassword/ResetPasswordForm.Schema";

const BASE_URL = "/bc/auth";

export const authApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: `${BASE_URL}/login`,
				method: "POST",
				body: { ...credentials },
			}),
		}),
		logout: builder.mutation({
			query: (storeHash) => ({
				url: storeHash
					? `${BASE_URL}/logout?hash=${storeHash}`
					: `${BASE_URL}/logout`,
				method: "DELETE",
			}),
		}),
		forgotPassword: builder.mutation({
			query: (data: ForgotPasswordFormScheme) => ({
				url: `${BASE_URL}/password/forgot`,
				method: "POST",
				body: { ...data },
			}),
		}),
		resetPassword: builder.mutation({
			query: (args: {
				params: URLSearchParams
				data: ResetPasswordFormSchema;
			}) => ({
				url: `${BASE_URL}/password/reset?${args.params.toString()}`,
				method: "POST",
				body: { ...args.data },
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useLogoutMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation
} = authApi;
