import styled from "styled-components"
import { Roboto } from "utilities/variables/fonts"
import { red } from "utilities/variables/colors"

export interface Position {
  bottom?: string
}

export const MessageContainer = styled.div<Position>`
  position: absolute;
  width: 100%;
  bottom: ${(props) => props.bottom || "0px"}
`

export const Message = styled.span`
  position: absolute;
  color: ${red};
  font-family: ${Roboto};
  font-size: 12px;
  line-height: normal;
`
