import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { white } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledNav = styled.nav`
	width: 100%;
	height: ${remCalc(56)};
	background-color: ${white};
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 10px;

	.menu-btn {
		> .icon {
			font-size: 32px;
		}
	}

  img {
			height: 20px;
			display: block;
		}

	.logo-wrapper {
		display: flex;
		justify-content: center;
    align-items: center;
		margin-left: 16px;
		min-height: 40px;
	}

	@media ${aboveMedium} {
		justify-content: flex-start;
		padding: 10px 20px;
		height: ${remCalc(88)};
		img {
			height: 40px;
		}
	}
`;