import styled from "styled-components";
import { Breakpoint } from "ts/enums";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

type Props = {
	compact: boolean;
};

export const StyledCarouselNavigationWrapper = styled.div<Props>`
	width: 100%;
	display: flex;
	justify-content: ${({ compact }) => (compact ? "center" : "space-between")};
	position: relative;
	padding: 1rem ${remCalc(20)} ${remCalc(34.5)};

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		padding: 1rem 0 ${remCalc(34.5)};
	}

	button {
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-transform: uppercase;
		font-weight: 600;

		i {
			height: unset;
		}

		p {
			display: none;

			@media ${aboveMedium} {
				display: block;
			}
		}

		span {
			display: none;

			@media ${aboveMedium} {
				display: block;
			}
		}

		@media ${aboveMedium} {
			min-width: 150px;
		}
	}

	button:last-child {
		display: flex;
		justify-content: flex-end;
	}

	.swiper-button-disabled {
		opacity: 0;
	}
`;

export const StyledPaginationWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content !important; // fights swiper styles

	.pagination-dot {
		width: 0.5rem;
		height: 0.5rem;
		position: relative;
		display: block;
		margin-inline: 0.5rem;
		background-color: white;
		border: 1px solid black;
		border-radius: 100px;
		z-index: 10;
		cursor: pointer;

		&--active {
			background-color: black;
		}
	}
`;
