import { AnimatePresence, motion } from "framer-motion";

import Accordion from "components/common/ui/Accordion/Accordion";

import { Store } from "ts/types";

import AccordionBody from "./AccordionBody/AccordionBody";

import {
	NoLocation,
	StyledDivider,
	StyledTypography,
} from "./ClosestStores.Styled";
import { useTranslation } from "react-i18next";

type Props = {
	stores: Store[];
	hasLocation: boolean;
	onSelectStore: (store: Store) => void;
};

const ClosestStores = ({
	stores,
	hasLocation,
	onSelectStore,
	...props
}: Props) => {
	const { t } = useTranslation();

	return (
		<div {...props}>
			{/* <StyledTypography variant="heading" size="small">
				{t("yourClosestStore")}
			</StyledTypography> */}
			<StyledDivider />
			{/* 
			{!hasLocation && (
				<NoLocation variant="body">{t("enableLocationService")}</NoLocation>
			)}

			{hasLocation && !stores.length && (
				<NoLocation variant="body">{t("noStoresMatch")}</NoLocation>
			)} */}

			{stores && (
				<Accordion>
					<AnimatePresence>
						{stores.map((store) => (
							<motion.div
								key={store.id}
								initial={{ opacity: 0, height: 0 }}
								animate={{ opacity: 1, height: "auto" }}
								exit={{ opacity: 0, height: 0 }}
								transition={{ bounce: 0 }}
							>
								<Accordion.Item
									onOpen={() => onSelectStore(store)}
									titleComponent={
										<StyledTypography>{store.title}</StyledTypography>
									}
									bodyComponent={
										<AccordionBody
											store={store}
											storeAddressClick={() => onSelectStore(store)}
										/>
									}
								/>
							</motion.div>
						))}
					</AnimatePresence>
				</Accordion>
			)}
		</div>
	);
};

export default ClosestStores;
