import orderApi from "app/api/customer/orderApi";
import { useEffect, useState } from "react";
import { Order } from "types/Order";

const useOrdersPagination = () => {
	const [activePageNumber, setActivePageNumber] = useState<number>(1); // 1
	const [currentPageData, setCurrentPageData] = useState<Order[][]>([]);
	const [loading, setLoading] = useState(false);
	const [uninitialized, setUninitialized] = useState(true);

	let ordersPerPage = Number(process.env.REACT_APP_ORDERS_PER_PAGE || 10);

	const [orders] = orderApi.useLazyOrdersQuery();

	const preloadPages = async () => {
		setUninitialized(false);
		setLoading(true);

		const result = await orders();
		const slicedOrdersArray = [];

		//  If there's more than 1 page of results, slice data into an array of orders per page
		if (result?.data.data.length > ordersPerPage) {
			for (let i = 0; i < result?.data.data.length; i += ordersPerPage) {
				slicedOrdersArray.push(result?.data.data.slice(i, i + ordersPerPage));
			}
		} else {
			slicedOrdersArray.push(result?.data.data);
		}

		setCurrentPageData(slicedOrdersArray);
		setLoading(false);
	};

	useEffect(() => {
		preloadPages();
	}, []);

	// Set current page data
	const getPage = (pageNumber?: number) => {
		if (pageNumber) {
			if (activePageNumber !== pageNumber) {
				setActivePageNumber(pageNumber);
			}
			return currentPageData[pageNumber - 1] || [];
		}
		pageNumber = activePageNumber ? activePageNumber - 1 : 0;
		return currentPageData[pageNumber] || [];
	};

	const handlePageChange = (pageNumber: number) => {
		if (!uninitialized && !loading) {
			getPage(pageNumber);
		} else {
			setActivePageNumber(pageNumber);
		}
	};

	// handle errors
	const showNoOrdersError = !uninitialized && !loading && currentPageData.length === 0;

	const showNoPageError =
		(currentPageData.length !== 0 && !currentPageData) || activePageNumber > currentPageData.length;

	return {
		uninitialized,
		loading,
		totalPages: currentPageData.length,
		getPage,
		handlePageChange,
		showNoOrdersError,
		showNoPageError,
	};
};

export default useOrdersPagination;
