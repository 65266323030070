import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Icon } from "./Wishlist.svg";


const IconWishlist: FunctionComponent = () => {
  return <SvgIcon svg={<Icon />} />;
};

export default IconWishlist;
