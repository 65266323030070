import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountLightGrey, lightGrey } from "utilities/variables/colors";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledSpGrid = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-template-rows: repeat(2, auto);
	width: 100%;

	@media ${aboveMedium} {
		grid-template-columns: minmax(340px, min-content) 1fr;
		grid-template-rows: repeat(3, auto);
		align-self: center;
	}

	.title {
		grid-column: 1;

		@media ${aboveMedium} {
			grid-column: 2 / 3;

			&::after {
				position: absolute;
				content: "";
				background-color: ${accountLightGrey};
				top: 0;
				left: 0;
				width: 100vw;
				height: ${remCalc(72)};
				z-index: 0;
			}

			h3 {
				position: relative;
				z-index: 1;
			}
		}
	}

	.nav {
		padding: ${remCalc(16)};
		border-bottom: 1px solid ${lightGrey};

		@media ${aboveMedium} {
			grid-column: 1 / 2;
			padding: ${remCalc(40)} ${remCalc(16)} 0 ${remCalc(20)};
			border-bottom: none;
		}
	}

	.content {
		@media ${aboveMedium} {
			grid-column: 2 / 3;
			gap: ${remCalc(24)};
			width: calc(100% - 116px);
		}

		@media ${aboveLarge} {
			width: calc(100% - 232px);
      max-width: ${remCalc(1280)};
		}
	}
`;
