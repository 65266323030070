import styled from 'styled-components';
import { dark, darkGrey, lightGrey, white } from 'utilities/variables/colors';

export const StyledCustomerAccountCard = styled.div`
    a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.5rem 1rem;
        color: ${dark};
        background-color: ${white};
        border: 1px solid ${lightGrey};
        font-weight: 700;
        text-decoration: none;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover,
        &:active {
            background-color: ${dark};
            color: ${white};

            svg {
                fill: ${white};
            }
        }

        &:active {
            background-color: ${darkGrey};
        }

        svg {
            fill: ${dark};
            transition: fill 0.3s ease;
        }

        .icon {
            margin-right: 1rem;
        }
    }
`;