import styled from "styled-components";

import GravityForm from "components/common/GravityForm/GravityForm";
import Map from "components/common/ui/Map/Map";

import { remCalc } from "utilities/styles";

export const StyledGravityForm = styled(GravityForm)`
	margin: ${remCalc(32)} 0 ${remCalc(40)};
`;

export const StyledMap = styled(Map)`
	margin-top: ${remCalc(16)};
	margin-bottom: ${remCalc(40)};
`;
