import { addLeadingSlash } from "app/utils/urlUtils";
import { useNavigate as rrUseNagivate } from "react-router";
import { NavigateOptions } from "react-router";
import { useSelector } from "redux/hooks";

interface NavigateFunction {
  (to: string, options?: NavigateOptions): void;
}

const useLocaleNavigate = (): NavigateFunction => {
  const { store } = useSelector((state) => state.bcStore);

  const navigate = rrUseNagivate();

  return (to: string, options?: NavigateOptions) => {
    let validatedTo = addLeadingSlash(to);

    const storeCode = `/${store?.code}`;

    const path = validatedTo.startsWith(storeCode) ? validatedTo : `${storeCode}${validatedTo}`;

    navigate(path, options);
  };
};

export default useLocaleNavigate;
