import { Helmet } from "react-helmet-async";
import Page from "../../components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import SEO from "components/common/ui/SEO/SEO";
import { useTranslation } from "react-i18next";

const ProductLandingPageSearch = () => {
	const { t } = useTranslation();

	return (
		<Page pageType={PageType.SEARCH} key={"search"}>
			<SEO
				title={t("search.seo_title") || "MONA"}
				description={t("search.seo_meta_description") || "MONA"}
			/>
			<Helmet>
				<link rel="stylesheet" href={`${process.env.REACT_APP_SEGMENTIFY_CDN_PATH}/${process.env.REACT_APP_SEGMENTIFY_API_KEY}/search.css`}></link>
				<script src={`${process.env.REACT_APP_SEGMENTIFY_CDN_PATH}/${process.env.REACT_APP_SEGMENTIFY_API_KEY}/search.js`}></script>
			</Helmet>
			<div id="segmentify-search" className="segmentify-search-main"></div>
		</Page>
	);
};

export default ProductLandingPageSearch;
