import React, { useEffect } from "react";

import GoogleMapReact from "google-map-react";
import { useOnClickOutside } from "usehooks-ts";

import { ReactComponent as MonaLogo } from "assets/images/logos/Mona Logo.svg";

import { MapMarker, Store } from "ts/types";

import { MarkerContainer, MonaMarker, StyledMapContainer } from "./Map.Styled";
import { GoogleMapsSdk } from "components/common/Google/GoogleAutocompleteTypes";

interface MarkerProps {
	zoom: number;
	store?: Store;
	lat: string | number;
	lng: string | number;
	marker: MapMarker;
}

const Marker = ({ zoom, marker }: MarkerProps) => {
	const baseSize = zoom * 4;
	const [isOpen, setIsOpen] = React.useState(marker.defaultOpen ?? false);
	const tooltipRef = React.useRef(null);
	useOnClickOutside(tooltipRef, () => setIsOpen(false));

	return (
		<MarkerContainer isTooltipOpen={isOpen} onClick={() => setIsOpen(true)}>
			<MonaMarker baseSize={baseSize}>
				<div className="circle circle--outer" />
				<div className="circle circle--middle" />
				<div className="circle circle--inner" />
				<MonaLogo className="logo" />
			</MonaMarker>
			<div className="tooltip__container" ref={tooltipRef}>
				{marker?.tooltipComponent}
			</div>
		</MarkerContainer>
	);
};

type Center = {
	lat: number;
	lng: number;
};

interface Props {
	center?: Center;
	zoom?: number;
	markers?: MapMarker[];
	options?: GoogleMapReact.MapOptions;
	onApiLoad?: (map: any, api: GoogleMapsSdk) => void;
}

const Map = ({
	center,
	zoom,
	markers = [],
	options = { gestureHandling: "cooperative" },
	onApiLoad,
	...props
}: Props) => {
	const DEFAULT_CENTER = { lat: 0, lng: 0 };
	const DEFAULT_ZOOM = 15;

	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

	const [currentZoom, setCurrentZoom] = React.useState<number>(
		zoom || DEFAULT_ZOOM,
	);

	useEffect(() => {
		setCurrentZoom(DEFAULT_ZOOM);
	}, [center]);

	// don't show anything else on the map
	const mapOptions = {
		styles: [
			{
				featureType: "transit",
				stylers: [{ visibility: "off" }],
			},
			{
				featureType: "poi",
				stylers: [{ visibility: "off" }],
			},
			{
				featureType: "poi.business",
				stylers: [{ visibility: "off" }],
			},
			{
				featureType: "poi",
				elementType: "labels",
				stylers: [{ visibility: "off" }],
			},
			{
				featureType: "poi.business",
				elementType: "labels",
				stylers: [{ visibility: "off" }],
			},
		],
		...options,
	} as GoogleMapReact.MapOptions;

	const handleApiLoaded = (map: any, api: GoogleMapsSdk) => {
		onApiLoad?.(map, api)
	}

	if (!center) {
		return null;
	}

	return (
		<StyledMapContainer {...props}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: apiKey, libraries: ["places"] }}
				defaultCenter={DEFAULT_CENTER}
				defaultZoom={DEFAULT_ZOOM}
				center={center}
				zoom={zoom || currentZoom}
				onChange={(e) => setCurrentZoom(e.zoom)}
				options={mapOptions}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
			>
				{markers.map((marker) => (
					<Marker
						key={`${marker.id}`}
						lat={marker.lat}
						lng={marker.lng}
						zoom={currentZoom}
						marker={marker}
					/>
				))}
			</GoogleMapReact>
		</StyledMapContainer>
	);
};

export default Map;
