import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { hasQueryParams } from "app/utils/urlUtils";
import Cookies from "js-cookie";

export const IL_URL = process.env.REACT_APP_IL_URL || 'https://api.testmona.com/api/'

export const integrationLayerService = fetchBaseQuery({
	baseUrl: IL_URL,
	prepareHeaders: (headers, { getState }) => {
		headers.set("Content-Type", "application/json");
		headers.set("X-Request-With", "XMLHttpRequest");
		headers.set("Accept", "application/json");

		const identifier = getState().auth.identifier;

		if (identifier.token) {
			headers.set("X-BC-Customer", identifier.token);
		}
	},
});

export const baseQueryWithAccessToken = async (api, extraOptions) => {
	let token = Cookies.get("il_token");

	if (token) {
		return token;
	}

	const { data } = await integrationLayerService(
		{
			url: `${IL_URL}token`,
			method: "POST",
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_IL_REFRESH_TOKEN}`,
			},
		},
		api,
		extraOptions,
	);

	if (data?.success) {
		const expiresDate = new Date(data.data.expires_at * 1000); //covert from s to ms

		//SHOP_TOKEN
		const cookieAttributes = {
			expires: expiresDate,
			secure: true,
			sameSite: "strict",
		};

		Cookies.set("il_token", data.data.access_token, cookieAttributes);

		return data.data.access_token;
	}

	return undefined;
};

export const bigCommerceStoreBaseQuery = async (args, api, extraOptions) => {
	const bcStore = api.getState().bcStore.store;

	if (!bcStore) {
		return {
			error: {
				status: 400,
				statusText: "Store unknown",
			},
		};
	}

	const token = await baseQueryWithAccessToken(api, extraOptions);

	if (!token) {
		return {
			error: {
				status: 404,
				statusText: "Error getting access token",
			},
		};
	}

	args = {
		...args,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	let endpointUrl = typeof args === "string" ? args : args.url;

	const paramsFromUrl = hasQueryParams(endpointUrl);

	if (!endpointUrl.includes("?hash=")) {
		let paramsWithHash = `?hash=${bcStore.hash}`;

		if (paramsFromUrl) {
			endpointUrl = endpointUrl.split("?")[0];
			paramsWithHash = `${paramsWithHash}&${paramsFromUrl}`;
		}

		endpointUrl = `${endpointUrl}?${new URLSearchParams(
			paramsWithHash,
		).toString()}`;
	}

	const adjustedArgs =
		typeof args === "string" ? endpointUrl : { ...args, url: endpointUrl };

	// provide the amended url and other params to the raw base query
	return integrationLayerService(adjustedArgs, api, extraOptions);
};

export const integrationLayer = createApi({
	reducerPath: "integrationLayer",
	baseQuery: bigCommerceStoreBaseQuery,
	tagTypes: ["Customer"],
	endpoints: (builder) => ({}),
});

export const integrationLayerCart = createApi({
	reducerPath: "integrationLayerCart",
	baseQuery: bigCommerceStoreBaseQuery,
	tagTypes: ["Cart"],
	endpoints: (builder) => ({}),
});