import Skeleton from "react-loading-skeleton";
import uuid from "react-uuid";
import { PageType } from "ts/enums";

import ImageTextSplit from "components/common/layouts/ImageTextSplit/ImageTextSplit";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";

import {
	useLazyGetCompanyPageQuery
} from "app/api/company/CompanyPageApi";
import SEO from "components/common/ui/SEO/SEO";
import getRobotsContent from "utilities/getRobotsContent";

import { useGetColleaguesPageQuery } from "app/api/company/ColleaguesApi";
import CompanyValuesCarousel from "components/common/layouts/companyValues/CompanyValuesCarousel";
import { useEffect, useState } from "react";
import { useMatch } from "react-router";
import { useSelector } from "redux/hooks";
import {
	SkeletonImageSplitWrapper,
	StyledCareersPageWrapper,
	StyledMainImageBannerCareers,
} from "./Careers.Styled";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "./CompanyPages.Styled";

const Careers = () => {
	const path = useMatch("/:locale/:path")?.params.path;
	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
		if (path) {
			const template = wordpressTemplates.find((template) =>
				template.uri.includes(path),
			);
			if (template) {
				trigger({
					pageId: template.id,
					pageType: "careers",
				});
			}
		}
	}, [path, wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const { data: colleagueData, isLoading: colleaguesIsLoading } =
		useGetColleaguesPageQuery({});

	const imageData = [
		{
			image: {
				desktopImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				mobileImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				alt: pageData?.pageData?.featuredImage.node.altText,
			},
			ctaLink: "",
			ctaText: pageData?.pageData?.featuredImage.node.title,
		},
	];

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledCareersPageWrapper className="StyledCareersPageWrapper">
				<StyledMainImageBanner className="main-image-banner">
						<StyledMainImageBannerCareers className="main-image-banner--careers">
							{loading ? (
								<Skeleton width={"100%"} />
							) : (
								<ImageBanner
									orientation="landscape"
									images={imageData}
									showButton={false}
									showText={true}
									imageText={pageData?.pageData.title}
									isLoading={loading}
								/>
							)}
						</StyledMainImageBannerCareers>
				</StyledMainImageBanner>

				{loading ? (
					<div className="skeleton-wrapper">
						<SkeletonImageSplitWrapper>
							<Skeleton containerClassName="skeleton-container" />
						</SkeletonImageSplitWrapper>
						<div className="skeleton-text">
							<Skeleton height="40px" />
							<Skeleton count={13} />
						</div>
					</div>
				) : (
					pageData?.pageData.careers.contentBlock.map((content: any) => (
						<ImageTextSplit
							isFlipped={
								content.fieldGroupName.includes("ImageRight") ? true : false
							}
							image={content.image}
							text={content.text}
							title={content.heading}
							key={uuid()}
							isLoading={loading}
						/>
					))
				)}
				{colleaguesIsLoading ? (
					<div className="skeleton-carousel-wrapper">
						<div className="skeleton-carousel">
							<Skeleton
								width="180px"
								height="180px"
								style={{ borderRadius: "50%" }}
							/>
							<Skeleton height="24px" style={{ marginTop: "24px" }} />
							<Skeleton height="17px" style={{ marginTop: "8px" }} />
							<Skeleton height="100px" style={{ marginTop: "16px" }} />
							<Skeleton
								height="17px"
								width="74px"
								style={{ marginTop: "16px" }}
							/>
						</div>
						<div className="skeleton-carousel tablet">
							<Skeleton
								width="180px"
								height="180px"
								style={{ borderRadius: "50%" }}
							/>
							<Skeleton height="24px" style={{ marginTop: "24px" }} />
							<Skeleton height="17px" style={{ marginTop: "8px" }} />
							<Skeleton height="100px" style={{ marginTop: "16px" }} />
							<Skeleton
								height="17px"
								width="74px"
								style={{ marginTop: "16px" }}
							/>
						</div>
						<div className="skeleton-carousel tablet">
							<Skeleton
								width="180px"
								height="180px"
								style={{ borderRadius: "50%" }}
							/>
							<Skeleton height="24px" style={{ marginTop: "24px" }} />
							<Skeleton height="17px" style={{ marginTop: "8px" }} />
							<Skeleton height="100px" style={{ marginTop: "16px" }} />
							<Skeleton
								height="17px"
								width="74px"
								style={{ marginTop: "16px" }}
							/>
						</div>
						<div className="skeleton-carousel desktop">
							<Skeleton
								width="180px"
								height="180px"
								style={{ borderRadius: "50%" }}
							/>
							<Skeleton height="24px" style={{ marginTop: "24px" }} />
							<Skeleton height="17px" style={{ marginTop: "8px" }} />
							<Skeleton height="100px" style={{ marginTop: "16px" }} />
							<Skeleton
								height="17px"
								width="74px"
								style={{ marginTop: "16px" }}
							/>
						</div>
					</div>
				) : (
					<CompanyValuesCarousel
						companyValues={colleagueData || []}
					/>
				)}

				{pageData?.pageData?.careers?.additionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage: link?.images.desktopImage.sourceUrl,
									mobileImage:
										link?.images.mobileImage.sourceUrl ||
										link?.images.desktopImage.sourceUrl,
									alt: link?.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url || "",
								ctaText: link?.link?.title || "",
							},
						]}
						showButton={link?.link.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledCareersPageWrapper>
		</Page>
	);
};

export default Careers;
