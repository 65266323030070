import styled from "styled-components";
import { remCalc } from "../../utilities/styles";

import Link from "components/common/ui/Link/Link";
import { darkGrey } from "utilities/variables/colors";

const RelatedArticle = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(8)};
	align-items: flex-start;
  width: 192px;

	.category {
		font-size: ${remCalc(14)};
		letter-spacing: ${remCalc(0.7)};
	}

	.title {
		font-size: ${remCalc(16)};
		letter-spacing: ${remCalc(0.8)};
	}
`

const RelatedArticleImage = styled.img`
  width: 100%;
	height: 192px;
	object-fit: cover;
`

const StyledLink = styled(Link)`
	text-decoration: none;
  width: 192px;

	&:hover {

		h3 {
			color: ${darkGrey};

		}
	}
`

export {
	RelatedArticle,
	RelatedArticleImage,
	StyledLink
};
