import { gql } from "graphql-request";

const wordPressGetLoyaltyCardApplicationData = () => ({
	query: gql`
		query getLoyaltyCardApplicationData {
			storeGeneralSettings {
				globalStoreSettings {
					caLoyaltySection {
						textArea1
						textArea2
						title1
						title2
						buttonText
					}
				}
			}
		}
	`,
});

export default wordPressGetLoyaltyCardApplicationData;
