import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const LandingPageWrapper = styled.div`
  margin-bottom: ${remCalc(64)};

  @media ${aboveMedium} {
    margin-bottom: ${remCalc(32)};
  }
  

`;