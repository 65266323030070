import { gravityFormApi } from "app/api/wordpress/gravityFormApi";
import React from "react";
import { FormFieldTypeEnum } from "types/GravityForm";

type SubmitJoinLoyaltyForm = {
	email: string;
	name: {
		first: string;
		last: string;
	};
};

// Form ID of Loyalty Sign Up gravity form
const FORM_ID = 4;

const useJoinLoyaltyForm = () => {
	const { data: gravityForm } =
		gravityFormApi.useGetGravityFormByIdQuery(FORM_ID);

	const formFields = React.useMemo(() => {
		if (!gravityForm) {
			return;
		}

		return gravityForm.formFields.nodes;
	}, [gravityForm]);

	const emailFieldId = React.useMemo(() => {
		if (!formFields) {
			return;
		}

		return formFields.find((field) => field.type === FormFieldTypeEnum.EMAIL)
			?.id;
	}, [formFields]);

	const nameFieldId = React.useMemo(() => {
		if (!formFields) {
			return;
		}

		return formFields.find((field) => field.type === FormFieldTypeEnum.NAME)
			?.id;
	}, [formFields]);

	const [submitGravityForm] = gravityFormApi.useSubmitGravityFormMutation();

	const submitJoinLoyaltyForm = React.useCallback(
		async ({ email, name }: SubmitJoinLoyaltyForm) => {
			if (!emailFieldId || !nameFieldId) {
				return;
			}

			const payload = {
				id: FORM_ID,
				fieldValues: [
					{ id: emailFieldId, emailValues: { value: email } },
					{
						id: nameFieldId,
						nameValues: { first: name.first, last: name.last },
					},
				],
			};

			const response =  await submitGravityForm(payload).unwrap().then(res => {
                return res
            }).catch(err => {
                return err
            })

            return response
		},
		[emailFieldId, nameFieldId, submitGravityForm],
	);

	return submitJoinLoyaltyForm;
};

export default useJoinLoyaltyForm;
