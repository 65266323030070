import { ReactComponent as Chat } from "assets/images/svg/IconSupport.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconSupport({className}: Props): ReactElement {
    return <Icon className={className} icon={<Chat />} />;
}

export default IconSupport;