import { ReactComponent as Menu } from "assets/images/svg/Burger Menu.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconMenu({className}: Props): ReactElement {
    return <Icon className={className} icon={<Menu />} />;
}

export default IconMenu;