import Button from "components/common/ui/Button/Button";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";
import useOutsideClick from "hooks/useOutsideClick";
import { useMemo, useState } from "react";
import { Gender } from "../../PdpSizeGuide/PdpSizeGuide";
import {
	StyledContainer,
	StyledContent,
	StyledHeader,
	StyledImageContainer,
	StyledOverlay,
	StyledWrapper,
} from "../PdpSizeCalculator.Styled";

import { getGroupId } from "app/utils/bigcommerceProductUtil";
import { Product, ProductVariant } from "ts/types";
import { getDataForSizeCalculator } from "../../PdpSizeGuide/SizeGuideDataa";
import InputStep from "./Steps/InputStep";
import ResultStep from "./Steps/ResultStep";

enum Step {
	INPUT,
	RESULT,
}

interface InputValueType {
	[key: string]: number;
}

/**
 * Try to get a size recommendation based on entered data
 *
 * @param data size guide information
 * @param measurement the body part measurement
 * @param value the input value
 */
const getSizeRecommendation = (
	data: any,
	measurement: string,
	value: number,
	unitOfMeasurement: string,
) => {
	const sizeFromGuide = Object.keys(data);

	const possibleRecommendations = [] as {
		size: string;
		range: number[];
	}[];

	if (unitOfMeasurement === "in") {
		value = Math.floor(value * 2.54);
	}

	Object.values(data).forEach((guide: any, index) => {
		if (guide[measurement]) {
			possibleRecommendations.push({
				size: sizeFromGuide[index],
				range: guide[measurement].range,
			});
		}
	});

	let recommendedSize = undefined;

	possibleRecommendations.forEach((recommendation) => {
		const rangeMin = recommendation.range[0];
		const rangeMax = recommendation.range[1];

		if (value >= rangeMin && value <= rangeMax) {
			recommendedSize = recommendation.size;
		}
	});

	return recommendedSize;
};

const Modal = ({
	isOpen,
	handleClose,
	product,
	gender,
}: {
	isOpen: boolean;
	handleClose: () => void;
	product: Product;
	gender: Gender;
}) => {
	const [step, setStep] = useState<Step>(Step.INPUT);
	const [variant, setVariant] = useState<ProductVariant | undefined>();

	const [inputValues, setInputValues] = useState<any>({});
	const [hasMultipleRecommendations, setHasMultipleRecommendations] =
		useState<boolean>(false);

	const units = [
		{ label: "cm", value: "cm" },
		{ label: "in", value: "in" },
	];
	const [selectedUnit, setSelectedUnit] = useState(units[0].value);

	const sizeGuideOptions = useMemo(() => {
		const groupId = getGroupId(product);

		if (!groupId) {
			return {
				isShirt: false,
				isBottomApparel: false,
			};
		}

		return {
			isShirt: ["420", "410"].includes(groupId.value),
			isBottomApparel: [
				"400",
				"431",
				"401",
				"500",
				"570",
				"862",
				"863",
			].includes(groupId.value),
		};
	}, [product]);

	const data = useMemo(() => {
		return getDataForSizeCalculator(gender, sizeGuideOptions);
	}, [gender, sizeGuideOptions]);

	const handleChangeStep = (backToInput = false) => {
		if (step === Step.INPUT && !backToInput) {
			let recommendations: any = [];

			Object.entries(inputValues as InputValueType).forEach(
				([key, value], _: number) => {
					const tempValue = getSizeRecommendation(
						data,
						key,
						value,
						selectedUnit,
					);

					if (recommendations.includes(tempValue)) return;
					recommendations.push(tempValue);
				},
			);

			recommendations = recommendations.filter(Boolean);

			setHasMultipleRecommendations(recommendations.length > 1);

			recommendations = Math.max(...recommendations).toString();

			if (!recommendations) {
				setVariant(undefined);
				return null;
			}

			setVariant(
				product.variants?.find(
					(variant) => variant.option.label === recommendations,
				),
			);
			setStep(Step.RESULT);
		} else {
			setStep(Step.INPUT);
		}
	};

	const close = () => {
		handleClose();
		handleChangeStep(true);
	};

	const ref = useOutsideClick(close);

	const thumbnail = product.images
		? product.images.find((image) => image.isDefault)
		: product.images[0];

	return (
		<StyledWrapper isOpen={isOpen}>
			<StyledOverlay>
				<StyledContainer ref={ref}>
					<StyledHeader>
						<Button icon={<IconClose />} onClick={close} />
					</StyledHeader>
					{thumbnail && (
						<StyledImageContainer>
							<img src={thumbnail?.urlOriginal} alt={thumbnail?.altText} />
						</StyledImageContainer>
					)}
					<StyledContent>
						{step === Step.INPUT && (
							<InputStep
								data={data}
								onValueChange={setInputValues}
								changeStep={handleChangeStep}
								units={units}
								selectedUnit={selectedUnit}
								setSelectedUnit={setSelectedUnit}
								inputValues={inputValues}
							/>
						)}
						{step === Step.RESULT && (
							<ResultStep
								product={product}
								variant={variant}
								close={close}
								changeStep={handleChangeStep}
								hasMultipleRecommendations={hasMultipleRecommendations}
							/>
						)}
					</StyledContent>
				</StyledContainer>
			</StyledOverlay>
		</StyledWrapper>
	);
};

export default Modal;
