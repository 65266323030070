import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import Modal from "components/common/ui/Modal/Modal";
import styled from "styled-components";
import { darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledButton = styled(ButtonWithIcon)`
	font-family: ${Roboto};
	font-size: 12px;
	line-height: 15px;
	background-color: unset;
	font-weight: normal;
	text-transform: none;
	white-space: nowrap;

	svg {
		vertical-align: top;
	}

	&:hover,
	&:active,
	&.active {
		color: ${darkGrey};
	}

	&.active svg {
		transform: rotate(180deg);
	}
`;

export const MoreColoursModal = styled(Modal)``;
