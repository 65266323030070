import Icon from "components/common/ui/Icons/Icon";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { ReactElement, SVGProps } from "react";

import Link from "components/common/ui/Link/Link";
import { StyledCustomerAccountCard } from "./Styled";

type Props = {
	loading: boolean;
	linkData: {
		address: string;
		text: string;
		icon: ReactElement<SVGProps<SVGAElement>> | any;
	};
};

const CustomerAccountCard = ({ linkData, loading }: Props) => {
	return (
		<StyledCustomerAccountCard className="CustomerAccountCard">
			<Link to={linkData.address}>
				{loading ? (
					<>
						<Skeleton width={24} height={24} className="icon" />
						<Skeleton width={100} />
					</>
				) : (
					<>
						<Icon icon={linkData.icon} className="icon" />
						{linkData.text}
					</>
				)}
			</Link>
		</StyledCustomerAccountCard>
	);
};

export default CustomerAccountCard;
