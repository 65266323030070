import { useEffect, useState } from "react";
import uuid from "react-uuid";

import SingleImageWithCta from "../SingleImageWithCta/SinglemageWithCta";

import { StyledImageBanner } from "./Styled";
import Link from "../Link/Link";
import Button from "../Button/Button";
import {
	StyledCtaWrapper,
	StyledSingleImageWithCta,
	StyledTextWrapper,
} from "../SingleImageWithCta/Styled";

type Props = {
	orientation: "landscape" | "portrait";
	images: {
		image: {
			desktopImage: string;
			mobileImage?: string;
			alt?: string;
		};
		ctaLink: string;
		ctaText: string;
	}[];
	showButton: boolean;
	showText: boolean;
	imageText?: string;
	className?: string;
	isLoading: boolean;
	isBottomBanner?: boolean;
};

const ImageBanner = ({
	orientation,
	images,
	showButton = true,
	showText,
	imageText,
	className = "",
	isLoading,
	isBottomBanner,
}: Props) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [gridGap, setGridGap] = useState<string>("2rem");
	const [gridColumns, setGridColumns] = useState<string>("");

	useEffect(() => {
		switch (images.length) {
			case 1:
				setGridColumns("1fr");

				break;
			case 2:
				setGridGap(orientation === "landscape" ? ".25rem" : "2rem");
				setGridColumns("repeat(2, 1fr)");

				break;
			case 3:
				setGridColumns("repeat(3, 1fr)");

				break;

			default:
				break;
		}
	}, [images]);

	useEffect(() => {
		const handleResize = () => {
		  setWindowWidth(window.innerWidth);
		};
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);

	  const handleCtaLink = (link: string) => link?.endsWith("#button") ? link.slice(0, -7) : link;
	  
	  const getImageSrc = (image: { desktopImage: string; mobileImage?: string }) => {
        return windowWidth <= 551 && image.mobileImage ? image.mobileImage : image.desktopImage;
    };

	return (
		<StyledImageBanner
			gridGap={gridGap}
			gridColumns={gridColumns}
			className={`${className} image-banner`}
		>
			<StyledSingleImageWithCta className="styled-image-cta-container">
				{images.map((image) => (
					<div key={uuid()} className="image-cta-wrapper">
						<Link to={handleCtaLink(image.ctaLink)} rel="noopener noreferrer">
							<img
                				src={getImageSrc(image.image)}
								alt={image.image.alt || ''}
              />
						</Link>
						{image.ctaLink?.includes("#button") ? (
							<div className="text-container">
								{showText && imageText && (
									<StyledTextWrapper className="StyledTextWrapper">
										<p>{imageText}</p>
									</StyledTextWrapper>
								)}
								{showButton && (
									<StyledCtaWrapper className="StyledCtaWrapper">
										<Button variant="primary_02" link={{ to: handleCtaLink(image.ctaLink) }}>
											{image.ctaText}
										</Button>
									</StyledCtaWrapper>
								)}
							</div>
						) : (
							<div className="text-container">
								{showText && imageText && (
									<StyledTextWrapper className="StyledTextWrapper">
										<p>{imageText}</p>
									</StyledTextWrapper>
								)}								
							</div>
						)}
					</div>
				))}
			</StyledSingleImageWithCta>
		</StyledImageBanner>
	);
};
export default ImageBanner;
