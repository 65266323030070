import { FunctionComponent, useState } from "react";
import IconLanguage from "components/common/ui/Icons/IconLanguage";
import Modal from "components/common/ui/Modal/Modal";
import LanguageSelect from "./LanguageSelect";
import { useDispatch } from "redux/hooks";
import { setPreferredLocation } from "redux/location/locationSlice";
import Button from "components/common/ui/Button/Button";
import { ModalTitle } from "./Styled";
import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";
import { useTranslation } from "react-i18next";
import { logout } from "redux/auth/authSlice";

interface LanguageSwitcherModalProps {
	storeCode: string | undefined;
	isOpen: boolean;
	onClose: Function;
}

const StyledModal = styled(Modal)`
	width: calc(100%);
	max-width: 328px;
	left: 50%;
	transform: translateX(-50%);
	/* bottom: env(safe-area-inset-bottom, 2rem); */
	padding-top: 46px;
	padding-bottom: 32px !important;

	.btn-close {
		z-index: 1;
	}

	@media ${aboveMedium} {
		transform: unset;
		left: 20px;
	}
`;

const LanguageSwitcherModal: FunctionComponent<LanguageSwitcherModalProps> = ({
	storeCode,
	onClose,
	...props
}) => {

	const [selectedValue, setSelectedValue] = useState(storeCode);
	const dispatch = useDispatch();

	const changeLanguage = async () => {
		if (selectedValue !== storeCode) {
			Promise.resolve(
				await dispatch(setPreferredLocation(selectedValue))
			).then(
				async () => {
					// Trigger logout of the current store
					await dispatch(logout());

					// Simulated redirect
					window.location.href = `/${selectedValue}`;
				},
			);
		}

		onClose();
	};

	const { t } = useTranslation();

	return (
		<StyledModal
			onClose={() => onClose()}
			title={
				<ModalTitle>
					<IconLanguage variant="gray" />
					{t("languageSwitcher.location")}
				</ModalTitle>
			}
			options={{
				portalId: "portal__modal",
				showCloseButton: true,
			}}
		>
			<LanguageSelect
				value={selectedValue}
				onChange={(value: string) => setSelectedValue(value)}
			/>
			<Button
				margin={{
					mt: "16px",
				}}
				variant="primary_01"
				fullWidth
				onClick={changeLanguage}
			>
				{t("languageSwitcher.submit")}
			</Button>
		</StyledModal>
	);
};

export default LanguageSwitcherModal;
