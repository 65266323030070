import {
    CheckboxField as CheckboxFieldType,
    FieldError,
    FormField
} from "types/GravityForm";
import { CheckboxFieldWrapper, StyledFieldContainer } from "../GravityForm.Styled";
import { InputCheckbox } from "components/common/form/Input/Input";
import Label from "components/common/form/Label/Label";
import ErrorMessage from "components/common/form/Input/InputError/InputError";
import { useTranslation } from "react-i18next";
import { useDispatch } from "redux/hooks";
import { useCallback, useEffect, useState } from "react";
import { updateCheckboxFieldValue } from "redux/gravityForm/gravityFormSlice";

interface Props {
	field: FormField & Partial<CheckboxFieldType>;
	fieldErrors: FieldError[];
}

const CheckboxField = ({ field, fieldErrors }: Props) => {
	const dispatch = useDispatch();
    const { t } = useTranslation();

    const [checkboxValues, setCheckboxValue] = useState<{
        value: string,
        inputId: any,
    }[]>([])

	const updateValues = useCallback(
		(
            values: {
                value: string,
                inputId: number,
            }[]
        ) => {
			dispatch(updateCheckboxFieldValue({ id: field.id, checkboxValues: values }));
		},
		[dispatch, field.id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked

        const value = String(event.target.getAttribute('data-checkbox-value'))
        const inputId = event.target.getAttribute('data-checkbox-id') as unknown

        if (isChecked) {
            setCheckboxValue(
                [
                    ...checkboxValues ?? [],
                    {
                        value,
                        inputId: Number(parseFloat(inputId as string).toFixed(1))
                    }
                ]
            )
        }

        if (!isChecked) {
            setCheckboxValue(
                checkboxValues.filter(
                    (v) => v.value !== value
                )
            )
        }
	};

    useEffect(() => {
        updateValues(checkboxValues);
    }, [checkboxValues])

    return <StyledFieldContainer
        layoutGridColumnSpan={field.layoutGridColumnSpan}
        isError={Boolean(fieldErrors.length)}
        visibility={field.visibility}
    >
        <CheckboxFieldWrapper>
            <Label required={field.isRequired}>
                {field.label}
            </Label>
            {field.inputs?.map(
                (input) => {
                    return <InputCheckbox
                        key={input.id}
                        className="gravityFormCheckbox"
                        value={input.label}
                        label={input.label}
                        onChange={handleChange}
                        data-checkbox-id={input.id}
                        data-checkbox-value={input.label}
                    />
                }
            )}
			<ErrorMessage message={
                fieldErrors[0]?.message.includes("required")
                    ? t('form.validation.field.required') ?? ''
                    : fieldErrors[0]?.message
                }
            />
        </CheckboxFieldWrapper>
    </StyledFieldContainer>
};

export default CheckboxField;