
import SpContent from "components/supportingPages/SpContent/SpContent";

import SEO from "components/common/ui/SEO/SEO";
import getRobotsContent from "utilities/getRobotsContent";
import { useLocation, useMatch } from "react-router";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import usePageContent from "hooks/usePageContent";
import { useEffect, useMemo } from "react";
import { MenuItemData } from "ts/types";
import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import SpNav from "components/supportingPages/SpNav/SpNav";
import SupportingPageLayout from "components/common/layouts/SupportingPageLayout/SupportingPageLayout";


const DeliveryPage = () => {
		const match = useMatch("/:locale/*");
	const uri = match?.params["*"] || "";

	const { data, isLoading } = wordPressApi.useGetPageContentByUriQuery(uri);
	const menuItems = wordPressApi.useGetMenuItemsBySlugQuery("footer");
	const location = useLocation();
	const pageContent = usePageContent();

	useEffect(() => {
		pageContent.scrollToTop();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const parentNavOption = useMemo(() => {
		const parentItem = menuItems.data?.find((menuItem: MenuItemData) => {
			if (menuItem.children.find((child) => child.path.includes(uri))) {
				return menuItem;
			}
		}) as MenuItemData;

		return parentItem;
	}, [menuItems.data]);

	const navOptions = useMemo(() => {
		return (
			parentNavOption?.children.map((child) => {
				return {
					label: child.label,
					value: child.path,
				};
			}) || []
		);
	}, [parentNavOption, menuItems.data]);

	if (!document) {
		return <Page pageType={PageType.PAGE} />;
	}

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={data?.seo.title}
				description={data?.seo.metaDesc}
				keywords={data?.seo.metaKeywords}
				robots={getRobotsContent([
					data?.seo.metaRobotsNofollow,
					'index'
				])}
			/>
			<SupportingPageLayout data={data} loading={isLoading}>
				<SpNav
					className="nav"
					isLoading={menuItems.isLoading}
					title={parentNavOption?.label || ""}
					options={navOptions}
				/>
					<SpContent
						className="content"
						isLoading={isLoading}
						content={data?.content}
					/>
			</SupportingPageLayout>
		</Page>
	);

};

export default DeliveryPage;
