import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";

import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";

import { useGetJobDescriptionQuery } from "app/api/jobs/jobDescriptionApi";
import { PageType } from "ts/enums";

import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import ApplicationForm from "components/GravityForm/ApplicationForm/ApplicationForm";
import Link from "components/common/ui/Link/Link";
import SEO from "components/common/ui/SEO/SEO";
import SharePageViaSocialMedia from "components/common/ui/ShareBlogPostViaSocialMedia/ShareBlogPostViaSocialMedia";
import Typography from "components/common/ui/Typography/Typography";
import * as DOMPurify from "dompurify";
import { LineBreak } from "pages/blog/BlogPost.Styled";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";
import getRobotsContent from "utilities/getRobotsContent";
import { StyledMainImageBanner } from "./CompanyPages.Styled";
import {
	PreFooterWrapper,
	SkeletonWrapper,
	StyledJobDescriptionWrapper,
} from "./JobDescription.Styled";

const JobDescription = () => {
	const locale =
		useSelector((state) => state.location.selectedLocale) || "default";

	let { jobId } = useParams();
	const { t } = useTranslation();

	const { data, isLoading } = useGetJobDescriptionQuery(jobId);

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const currentOpportunities = useMemo(() => {
		const template = wordpressTemplates.find(
			(template) =>
				template.template === WordpressTemplateEnum.CurrentOpportunities,
		);

		return template;
	}, [wordpressTemplates, useSelector]);

	const deadlineDate = data?.jobInfo?.deadline;
		

	const imageData = [
		{
			image: {
				desktopImage: data?.featuredImage?.node.sourceUrl,
				mobileImage: data?.featuredImage?.node.sourceUrl,
				alt: data?.featuredImage?.node.altText,
			},
			ctaLink: "",
			ctaText: "",
		},
	];

	const { sanitize }: any = DOMPurify;

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={data?.seo?.title}
				description={data?.seo?.metaDesc}
				keywords={data?.seo?.metaKeywords}
				robots={getRobotsContent([
					data?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledJobDescriptionWrapper className="StyledJobDescriptionWrapper">
				<StyledMainImageBanner className="main-image-banner">
					{isLoading ? (
						<Skeleton width={"100%"} />
					) : imageData[0]?.image?.desktopImage ? (
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={false}
							isLoading={isLoading}
						/>
					) : (
						""
					)}
				</StyledMainImageBanner>

				<div className="container">
					{isLoading ? (
						<SkeletonWrapper>
							<Skeleton className="title" style={{ marginBottom: "32px" }} />
							<Skeleton
								className="custom-fields"
								style={{ marginBottom: "32px" }}
							/>
						</SkeletonWrapper>
					) : (
						<>
							<h2>{data?.title}</h2>
							<div className="container--text">
								<div>
									<h6>{t("opportunities.location")}</h6>
									<h3>{data?.jobInfo.location}</h3>
								</div>
								<div className="custom-fields">
									<h6>{t("opportunities.deadline")}</h6>
									<h3>{`${deadlineDate}`}</h3>
								</div>
							</div>
						</>
					)}
					{isLoading ? (
						<Skeleton min-height="300px" width="100" />
					) : (
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: sanitize(data?.content) }}
						/>
					)}
				</div>
			</StyledJobDescriptionWrapper>
			<ApplicationForm />

			<PreFooterWrapper>
				<div className="shareContainer">
					<LineBreak />
					<SharePageViaSocialMedia url={window.location.href} />
					<LineBreak />
				</div>
				{currentOpportunities?.uri && (
					<Link to={currentOpportunities.uri} className={"link"}>
						<Typography variant="body" size="medium">
							{t("opportunities.backToOpportunities")}
						</Typography>
					</Link>
				)}
			</PreFooterWrapper>
		</Page>
	);
};

export default JobDescription;
