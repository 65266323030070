import wordPressGetColleaguesPage from 'app/gql/wordPressGetColleagues';
import { wordPressGQL } from 'app/services/wordPressGqlService';

const colleaguesApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getColleaguesPage: builder.query({
			query: () => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetColleaguesPage(),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				const colleagueData = res.data.colleagues.edges;

				return { colleagueData };
			},
		}),
	}),
});

export const { useGetColleaguesPageQuery } = colleaguesApi;
