import React from "react";

import { Portal } from "react-portal";
import { useLocation } from "react-router";

import Button, { ButtonProps } from "components/common/ui/Button/Button";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";

import useOutsideClick from "hooks/useOutsideClick";

import {
	StyledContainer,
	StyledContent,
	StyledHeader,
	StyledHeading,
	StyledModalContentContainer,
	StyledOverlay,
} from "./DeleteAddressModal.Styled";

interface Props extends ButtonProps {
	children: React.ReactNode;
	heading: string;
	description: string;
	onDelete: () => void;
}

const DeleteAddressModal = ({
	children,
	heading,
	description,
	onDelete,
	...props
}: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<Button onClick={() => setIsOpen(true)} {...props}>
				{children}
			</Button>
			<Portal node={document && document.getElementById("portal__modal")}>
				<Modal
					isOpen={isOpen}
					handleClose={handleClose}
					onDelete={onDelete}
					heading={heading}
					description={description}
				>
					{children}
				</Modal>
			</Portal>
		</>
	);
};

const Modal = ({
	isOpen,
	handleClose,
	onDelete,
	heading,
	description,
	children,
}: {
	isOpen: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	heading: string;
	description: string;
	onDelete: () => void;
}) => {
	const ref = useOutsideClick(handleClose);

	return (
		<StyledContainer isOpen={isOpen}>
			<StyledOverlay>
				<StyledModalContentContainer ref={ref}>
					<StyledHeader>
						<StyledHeading>{heading}</StyledHeading>
						<Button icon={<IconClose />} onClick={handleClose} />
					</StyledHeader>
					<StyledContent>
						<p>{description}</p>
						<Button
							variant="primary_01"
							onClick={() => {
								onDelete();
								handleClose();
							}}
						>
							{children}
						</Button>
					</StyledContent>
				</StyledModalContentContainer>
			</StyledOverlay>
		</StyledContainer>
	);
};

export default DeleteAddressModal;
