import React, { Fragment, useEffect, useState } from "react";

import customerApi from "app/api/customer/customerApi";

import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import { Address } from "types/Customer";

import AddAddressLink from "./AddAddressLink/AddAddressLink";
import AddressCard from "./AddressCard/AddressCard";

import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import {
	AccountAddressesContainer
} from "./AccountAddresses.Styled";

const AccountAddresses = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Addresses"));
	}, [dispatch]);

	const [getAddressesTrigger, getAddressesResult] =
		customerApi.useLazyGetAddressesQuery();
	const [deleteAddress, deleteAddressResult] =
		customerApi.useDeleteAddressMutation();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getAddressesTrigger({});
	}, []);

	useEffect(() => {
		if (getAddressesResult.data) {
			if (loading) {
				setLoading(false);
			}
		}
	}, [getAddressesResult]);

	const onDelete = (id: Address[keyof Address]) => {
		!deleteAddressResult.isLoading &&
			deleteAddress(id)
				.unwrap()
				.then(() => {
					getAddressesTrigger({});
				});
	};

	return (
		<Fragment>
			{loading && (
				<LoadingSpinner />
			)}

			{!loading && (
				<AccountAddressesContainer>
					{getAddressesResult.data?.map((address) => (
						<AddressCard
							key={address.id}
							address={address}
							onDelete={() => onDelete(address.id)}
						/>
					))}
					<AddAddressLink />
				</AccountAddressesContainer>
			)}
		</Fragment>
	);
};

export default AccountAddresses;
