import {
	Fragment,
	FunctionComponent,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	ResultHeadingWrapper,
	ResultWrapper,
	ScrollContainer,
	StyledDivider,
	Title,
} from "../../PdpFindInStore.Styled";

import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import Scrollbars from "react-custom-scrollbars-2";
import { Store } from "ts/types";
import StoreDetails from "../StoreDetails/StoreDetails";
import TextButton from "components/common/ui/Button/TextButton";
import Typography from "components/common/ui/Typography/Typography";
import useBreakpoints from "hooks/useBreakpoints";
import { useLazyCheckStockLevelsQuery } from "app/api/asw/aswApi";
import useStores from "hooks/useStores";
import { useTranslation } from "react-i18next";

type StoreStock = {
	store_id: string;
	quantity: number;
};

type StoreAndStock = {
	store: Store;
	quantity: number;
};

interface Props {
	productSku: string | undefined;
	showStartAgain: boolean;
	startAgain?: () => void;
}

const ResultStep: FunctionComponent<Props> = ({
	productSku,
	showStartAgain,
	startAgain = () => { },
}) => {
	const { large } = useBreakpoints();
	const { t, ready } = useTranslation();
	const { isLoading, orderByTitle } = useStores();

	const [checkStock, checkStockResult] = useLazyCheckStockLevelsQuery();
	const [stores, setStores] = useState<Store[]>([] as Store[]);
	const [storeStock, setStoreStock] = useState<StoreAndStock[] | undefined>();
	const [checkingStock, setCheckingStock] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			const stores = orderByTitle() as Store[];
			setStores(stores);
		}
	}, [isLoading]);

	useEffect(() => {
		if (stores.length && productSku && !checkingStock) {
			setCheckingStock(true);

			const storeIds = stores
				.filter((store) => store?.storeInformation.aswId)
				.map((store) => store?.storeInformation.aswId);

			if (!checkStockResult.isFetching) {
				checkStock({
					skus: [productSku],
					stores: storeIds as string[],
				})
					.unwrap()
					.then((res) => {
						const storeStocks = (res.data[productSku] as StoreStock[]) || [];

						const storesWithStock = [] as StoreAndStock[];

						stores.forEach((store) => {
							const quantity =
								storeStocks.find(
									(value) => value.store_id === store.storeInformation.aswId,
								)?.quantity || 0;
							if (quantity > 0)
								storesWithStock.push({
									store,
									quantity,
								});
						});

						setStoreStock(storesWithStock);
						setTimeout(() => {
							setCheckingStock(false);
						}, 300);
					});
			}
		} else if (!checkingStock) {
			setStoreStock(undefined);
		}
	}, [stores, productSku]);

	const startAgainButton = useMemo(() => {
		if (!showStartAgain) {
			return false;
		}

		return !large;
	}, [showStartAgain, large]);

	if (!ready) {
		return null;
	}

	return (
		<ResultWrapper>
			{!large && (
				<Fragment>
					<Title variant="heading" size="small" className="input_title">
						{t("findInStore.title")}
					</Title>
					<StyledDivider margin={16} />
				</Fragment>
			)}
			<ResultHeadingWrapper>
				<Title variant="heading" size="small">
					{t("findInStore.availability")}
				</Title>
				{startAgainButton && (
					<TextButton onClick={startAgain} className="btn_start-again">
						{t("findInStore.startAgain")}
					</TextButton>
				)}
			</ResultHeadingWrapper>
			{/* TODO this has been commented out 
			to reduce the number of Google Cloud API calls
			the previous value of `apiKey` was `process.env.REACT_APP_GOOGLE_API_KEY || ""`

			see https://app.activecollab.com/226056/projects/762/tasks/99829
			*/}
			{/* <Location
				apiKey="AIzaSyCZaN8wOyFQ35i3L1v7WqmQopL54ijOpJA"
				onSearch={setLocation}
			/> */}
			<ScrollContainer>
				{!productSku && (
					<Typography
						variant="body"
						size="small"
						className="select-variant-prompt"
					>
						{t("sizeGuide.selectSize")}
					</Typography>
				)}
				{productSku && (
					<Scrollbars autoHide>
						<div>
							{checkingStock ? (
								<LoadingSpinner />
							) : storeStock?.length ? (
								storeStock.map((data) => (
									<StoreDetails
										key={`${data.store.storeInformation.aswId}`}
										{...data}
									/>
								))
							) : (
								<Typography variant="body" className="infotip-out-of-stock">
									{t("findInStore.noStock")}
								</Typography>
							)}
						</div>
					</Scrollbars>
				)}
			</ScrollContainer>
		</ResultWrapper>
	);
};

export default ResultStep;
