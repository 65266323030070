import { useEffect, useState } from "react";

import uuid from "react-uuid";
import { Swiper, SwiperSlide } from "swiper/react";

import Link from "components/common/ui/Link/Link";
import TimelineNavigation from "./TimelineNavigation";

import { StyledTimelineWrapper } from "./timeline.Styled";

type timelineImage = {
	altText: string;
	sourceUrl: string;
	title: string;
};

type timelineLink = {
	target: string;
	url: string;
	title: string;
};

type timelineData = {
	image: timelineImage;
	year: string;
	link: timelineLink | null;
};

type Props = {
	timelineData: timelineData[];
	isSuccess: boolean;
};

const Timeline = ({ timelineData, isSuccess }: Props) => {
	const [timelineYears, setTimelineYears] = useState<string[]>([]);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	useEffect(() => {
		if (!timelineData?.length) return;

		let tempYears: string[] = [];
		timelineData.forEach((timelineObject) => {
			tempYears.push(timelineObject.year);
		});

		setTimelineYears(tempYears);
	}, [timelineData]);

	return (
		<StyledTimelineWrapper className="Timeline">
			<Swiper
				spaceBetween={16}
				slidesPerView={1.2}
				onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
				breakpoints={{
					801: {
						slidesPerView: 4,
					},
				}}
				touchEventsTarget={'container'}
			>
				{timelineData?.map((slide: timelineData) => (
					<SwiperSlide key={uuid()}>
						{slide.link ? (
							<Link to={slide.link.url} className="slide-wrapper">
								<img src={slide.image.sourceUrl} alt="" />
							</Link>
						) : (
							<div className="slide-wrapper">
								<img src={slide.image.sourceUrl} alt="" />
							</div>
						)}
					</SwiperSlide>
				))}

				<TimelineNavigation
					timelineYears={timelineYears}
					swiperIndex={activeIndex}
				/>
			</Swiper>
		</StyledTimelineWrapper>
	);
};

export default Timeline;
