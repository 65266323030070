import AddToCartAlert from "components/AddToCartAlert/AddToCartAlert";
import Button, { ButtonProps } from "components/common/ui/Button/Button";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import useCart from "hooks/cart/useCart";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { setShowAddToCartConformation } from "redux/UI/uiSlice";
import { useDispatch } from "redux/hooks";
import styled from "styled-components";
import { Product, ProductAvailability } from "ts/types";
import { remCalc } from "utilities/styles";

interface Props {
	triggeredFrom: string;
	product?: Product;
	variantId?: number;
	availability?: ProductAvailability;
	quantity?: number;
	loading?: boolean;
	onSuccess?: () => void;
	onError?: () => void;
	children?: any;
	disableAlert?: boolean;
}

const SkeletonWrapper = styled.div`
	width: 100%;
	height: ${remCalc(40)};

	.skeleton-container {
		display: flex;
		height: 100%;
	}
`;

const AddToCartButton: FunctionComponent<Props & ButtonProps> = ({
	triggeredFrom = "PAGE",
	product,
	variantId,
	availability = "Available",
	quantity = 1,
	loading = false,
	onSuccess,
	onError,
	children,
	disableAlert = false,
	...props
}) => {
	const { t, ready } = useTranslation();

	const [showAlert, setShowAlert] = useState(false);
	const dispatch = useDispatch();

	const cart = useCart();

	const handleAddToCart = () => {
		if (product) {
			if (product.variants?.length && !variantId) {
				setShowAlert(true);
				return;
			}

			cart.addCartItem(product, variantId, 1, {
				onError,
				onSuccess: () => {
					dispatch(setShowAddToCartConformation(false));
					onSuccess?.();
				},
			});
		}
	};

	const buttonContent = useMemo(() => {
		if (children) {
			return children;
		}

		let translationKey;

		switch (availability.toLowerCase()) {
			case "available":
				translationKey = "addToCart.available";
				break;
			case "preorder":
				translationKey = "addToCart.preorder";
				break;
			case "unavailable":
				translationKey = "addToCart.unavailable";
				break;
		}

		if (!translationKey) {
			return;
		}

		return t(translationKey).toString();
	}, [availability, children, ready, t]);

	if (loading) {
		return (
			<SkeletonWrapper>
				<Skeleton />
			</SkeletonWrapper>
		);
	}

	return (
		<>
			{!disableAlert && (
				<AddToCartAlert
					product={product}
					isOpen={showAlert}
					handleClose={() => setShowAlert(false)}
				/>
			)}
			<Button
				{...props}
				loading={cart.meta.isLoading}
				onClick={handleAddToCart}
				disabled={availability === "Unavailable"}
			>
				{buttonContent}
			</Button>
		</>
	);
};

export default AddToCartButton;
