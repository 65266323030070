import Button from "components/common/ui/Button/Button";
import FormattedDate from "../../../../../common/ui/Date/FormattedDate";
import Link from "components/common/ui/Link/Link";
import Price from "components/common/ui/Price/Price";

import { showTrackOrderLink } from "../OrderList";

import { useTranslation } from "react-i18next";
import { Order } from "types/Order";
import TableDataControl from "./TableDataControl";
import PathTranslationKey from "utilities/paths";

type Props = {
	order: Order;
};

const OrderListTableRow = ({ order }: Props) => {
	const { t } = useTranslation();
	return (
		<tr className="order-list-table-row">
			<TableDataControl>
				<Link className="value" to={`${t(PathTranslationKey.ACCOUNT_ORDERS)}/${order.id}`}>
					{order.id}
				</Link>
			</TableDataControl>
			<td>
				<p>
					<FormattedDate date={new Date(order.date_created)} />
				</p>
			</td>
			<td>
				<p>
					<Price price={Number(order.total_inc_tax)} />
				</p>
			</td>
			<td>
				<p>{order.custom_status}</p>
			</td>
			<td>
				{showTrackOrderLink(order) && (
					<Button
						className="button text-capitalize"
						variant="primary_03"
						textLight
						link={{ to: `${t(PathTranslationKey.ACCOUNT_ORDERS)}/${order.id}` }}
					>
						{t("track_order")}
					</Button>
				)}
			</td>
		</tr>
	);
};

export default OrderListTableRow;
