import uuid from "react-uuid";
import { StyledFiguresSection } from "./figures.Styled";

type figureContent = {
	number: number;
	text: string;
};

type figureBackgroundImage = {
	altText: string;
	sourceUrl: string;
	title: string;
};

type Props = {
	figures: {
		backgroundImage: figureBackgroundImage;
		content: figureContent[];
	};
};

const FiguresSection = ({ figures }: Props) => {
	return (
		<StyledFiguresSection
			className="StyledFiguresSection"
			style={{
				backgroundImage: `url(${figures?.backgroundImage.sourceUrl})`,
			}}
		>
			<ul>
				{figures?.content?.map((item: any) => (
					<li key={uuid()}>
						{item.number}
						<span>{item.text}</span>
					</li>
				))}
			</ul>
		</StyledFiguresSection>
	);
};

export default FiguresSection;
