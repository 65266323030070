import { integrationLayer } from "app/services/integrationLayerService";
import { updateCustomer } from "redux/auth/authSlice";

const loyaltyApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		getPointsBalance: builder.query({
			query: ({ cardNumber }) => ({
				url: `asw/loyalty/points`,
				method: "POST",
				body: {
					card_number: cardNumber,
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				return res.data.loyalty;
			},
		}),
		attachCardNumberToCustomer: builder.mutation({
			query: (cardNumber) => ({
				url: "asw/loyalty/link",
				method: "POST",
				body: {
					card_number: cardNumber,
				},
			}),
            invalidatesTags: ['Customer'],
			transformResponse: (res: any) => {
				return { message: "Card Attached"};
			},
			transformErrorResponse: (error: any) => {
				return {
					statusText: error.data.error.message,
					status: error.data.error.status,
				};
			},

			async onQueryStarted({ cardNumber }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;

					dispatch(
						updateCustomer({
							hasLoyaltyCard: true,
							loyaltyCardNumber: cardNumber,
						}),
					);
				} catch (error) {
					console.error(error);
				}
			},
		}),
	}),
});

export const {
	useGetPointsBalanceQuery,
	useAttachCardNumberToCustomerMutation,
} = loyaltyApi;
