import { integrationLayer } from "app/services/integrationLayerService";

const orderApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		order: builder.mutation({
			query: (id) => ({
				url: `/bc/order/${id}?with=items,shipments`,
				method: "GET",
			}),
		}),
		orders: builder.query<any, void>({
			query: () => ({
				url: "/bc/orders",
				method: "GET",
			}),
		}),
	}),
});

export default orderApi;
