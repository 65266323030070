import Button from "components/common/ui/Button/Button";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const VaribleSizeButton = styled(Button)`
	width: 100%;
	@media ${aboveMedium} {
		width: fit-content;
	}
`;

export const BannerContainer = styled.div`
	width: 100%;
	@media ${aboveMedium} {
		width: ${remCalc(500)};
	}
	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	h2 {
		font-family: ${Roboto};
		font-weight: bold;
		font-size: ${remCalc(24)};
		color: ${dark};

    margin:0;
    margin-bottom:${remCalc(12)};
	}
	h4 {
    font-family: ${Roboto};
		font-size: ${remCalc(16)};
		margin-bottom: 2rem;
		color: ${dark};
    margin:0;
    margin-bottom:${remCalc(32)};
	}
`;
