import { OddContainer } from "../BlogContainer.styles";
import { BannerContainer, VaribleSizeButton } from "./BannerDivider.styles";

interface link {
	title: string;
	url: string;
}
interface info {
	title: string;
	text: string;
	link: link;
}
interface Props {
	data: info;
}

const BannerDivider = ({ data }: Props) => {
	const { title, text, link } = data;
	return (
		<OddContainer>
			<BannerContainer>
				<h2>{title}</h2>
				<h4>{text}</h4>
				<VaribleSizeButton variant="primary_01" link={{ to: link.url }}>
					{link.title}
				</VaribleSizeButton>
			</BannerContainer>
		</OddContainer>
	);
};

export default BannerDivider;
