import wordPressGetPostBySlug from "../../gql/quries/wordPressGetPostBySlug";
import { wordPressGQL } from "app/services/wordPressGqlService";
import { wordPressGetBlogContentBreaks } from "app/gql/quries/wordPressGetBlogContentBreaks";
import { BlogContentBreak } from "types/WordPress";

export const wordPressBlogArticleApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getPageContentBySlug: builder.query({
			query: (uri) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetPostBySlug(uri),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				return {
					...res.data.post,
				};
			},
		}),

		getBlogContentBreaks: builder.query<BlogContentBreak[], string>({
			query: (blogPageTitle: string) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetBlogContentBreaks(blogPageTitle),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return [];
				}

				const blogTopPage = res.data.pages.edges?.[0].node.blogTopPage || undefined;

				if(!blogTopPage) {
					return [];
				}

				return  [blogTopPage.contentBreak1, blogTopPage.contentBreak2];
			},
		}),
	}),
});
