import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark, white } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

import { FormFieldVisibilityEnum } from "types/GravityForm";

import { InputCheckbox, InputEmail, InputNumber, InputRating, InputText } from "../form/Input/Input";
import TextArea from "../form/TextArea/TextArea";
import Markdown from "../ui/Markdown/Markdown";

export const StyledForm = styled.form`
	position: relative;

	.gravity-forms__required-legend {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: ${remCalc(4)};
		margin-bottom: 1.5rem;
	}

	.gravity-forms__grid {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: ${remCalc(16)};
		align-items: start;
		margin-bottom: 1.5rem;
	}
`;

export const StyledInputText = styled(InputText)`
	flex: 1;
	margin-bottom: ${({ isError }) => isError && remCalc(24)};
`;

export const StyledInputRating = styled(InputRating)`
	flex: 1
	margin-bottom: ${({ isError })=> isError && remCalc(24)};
`;

export const StyledInputNumber = styled(InputNumber)`
	flex: 1;
	margin-bottom: ${({ isError }) => isError && remCalc(24)};

	/* Hide arrows */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}
`;

export const StyledRadioButton = styled(InputCheckbox)`
	flex: 1;
`;

export const StyledInputEmail = styled(InputEmail)`
	flex: 1;
	margin-bottom: ${({ isError }) => isError && remCalc(24)};
`;

export const StyledTextArea = styled(TextArea)`
	flex: 1;
	margin-bottom: ${remCalc(6)};
	margin-bottom: ${({ isError }) => isError && remCalc(24)};
`;

interface StyledFieldContainerInterface {
	layoutGridColumnSpan?: number;
	isError?: boolean;
	visibility?: FormFieldVisibilityEnum;
}

export const StyledFieldContainer = styled.div<StyledFieldContainerInterface>`
	/* General */
	grid-column-start: auto;
	grid-column-end: span 12;
	/* NameField */
	display: ${({ visibility }) =>
		visibility === FormFieldVisibilityEnum.VISIBLE ? "flex" : "none"};
	flex-direction: column;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		grid-column-end: ${({ layoutGridColumnSpan }) =>
			`span ${layoutGridColumnSpan || 12}`};
		flex-direction: row;
	}
`;

export const StyledSkeletonContainer = styled.div`
	padding: ${remCalc(70)} 0 ${remCalc(40)};
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	.row {
		width: 100%;
		display: flex;
		gap: ${remCalc(16)};
	}

	.field {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${remCalc(4)};
	}
`;

export const SuccessMessageContainer = styled.div`
	position: absolute;
	background-color: ${white};
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: grid;

	div {
		text-align: center;
		font-weight: 700 !important;
		padding: ${remCalc(50)} ${remCalc(20)};
	}
`;

export const StyledMarkdown = styled(Markdown)`
	color: ${dark};
`;

export const CheckboxFieldWrapper = styled.div`
	display: block;
	position: relative;

	label {
		margin-bottom: ${remCalc(8)};
		display: block;
	}

	input[type='checkbox'] {
		accent-color: #212529;
	}

	.gravityFormCheckbox {
		flex-direction: row-reverse;
		margin-bottom: ${remCalc(8)};

		label {
			margin: auto 0;
		}
	}
`;
export const RadioFieldWrapper = styled.div`
	display: block;
	position: relative;

	label {
		margin-bottom: ${remCalc(8)};
		display: block;
	}
	input[type='radio'] {
		accent-color: #212529;
	}
	.gravityFormRadio {
		flex-direction: row-reverse;
		margin-bottom: ${remCalc(8)};

		label {
			margin: auto 0;
		}
	}
`;