import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend";

i18n.use(HttpBackend).use(initReactI18next);

export const init = (lng: string, fallbackLng = "en") => {
	const projectToken = process.env.REACT_APP_SIMPLELOCALIZE_PROJECT_TOKEN || "";

	const loadPath = `https://cdn.simplelocalize.io/${projectToken}/_latest/{{lng}}`;

	i18n.use(ChainedBackend).init({
		react: {
			useSuspense: false,
		},
		debug: false,
		lng: lng,
		fallbackLng: fallbackLng,
		backend: {
			backends: [LocalStorageBackend, HttpBackend],
			backendOptions: [
				{
					expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
				},
				{
					loadPath: loadPath,
				},
			],
		},
	});
};

export default i18n;
