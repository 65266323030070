import React from "react";

import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import WishlistForm from "./WishlistForm/WishlistForm";

import { Container } from "./WishlistCreateEdit";

const NewWishlist = () => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Wishlists"));
	}, [dispatch]);

	return (
		<Container>
			<WishlistForm />
		</Container>
	);
};

export default NewWishlist;
