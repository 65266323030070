export const PORTAL_MODAL = "portal__modal";

export const createPortals = (appendBelow) => {

  if(!appendBelow) {
    return;
  }

  if(!document.getElementById(PORTAL_MODAL)) {
    const modalPortalEl = document.createElement('div');
    modalPortalEl.setAttribute('id', PORTAL_MODAL);
    
    appendBelow.parentNode.insertBefore(modalPortalEl, appendBelow.nextSibling);
  }
};