import wordPressGetCompanyPage, {
	PageType,
} from 'app/gql/wordPressGetCompanyPageByUri';
import { wordPressGQL } from 'app/services/wordPressGqlService';

const basicPagesApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getCompanyPage: builder.query({
			query: ({
				pageId,
				pageType,
			}: {
				pageId: number;
				pageType: PageType;
			}) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetCompanyPage(pageId, pageType),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;

				const pageData = res?.data?.page;

				const goals = res?.data?.page?.socialResponsibility?.goals;

				const careersAdditionalLinks =
					res?.data?.page?.careers?.additionalLinks;

				const loyaltyBanner = res?.data?.page?.template?.loyaltyProgram?.additionalContentBlock;

				const socialAdditionalLinks =
					res?.data?.pageBy?.socialResponsibility?.additionalLinks;

				return {
					pageData,
					careersAdditionalLinks,
					goals,
                    loyaltyBanner,
					socialAdditionalLinks,
				};
			},
		}),
	}),
});

export const { useLazyGetCompanyPageQuery } = basicPagesApi;
