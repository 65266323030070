import { Order } from "types/Order";

import OrderListCard from "./OrderListCard/OrderListCard";
import OrderListTableRow from "./OrderListTableRow/OrderListTableRow";

import { OrderListContainer } from "./OrderList.Styled";
import { useTranslation } from "react-i18next";

type Props = {
	orders?: Order[];
};

export const showTrackOrderLink = (order: Order) => {
	return ["Shipped", "Partially Shipped"].includes(order.custom_status);
};

const OrderList = ({ orders }: Props) => {

	const { t } = useTranslation();

	const tableColumns = [t("ordersPageColumn.order"), t("ordersPageColumn.date"), t("ordersPageColumn.total"), t("ordersPageColumn.status"), ""];

	return (
		<OrderListContainer className="order-list">
			{/* Cards for Mobile */}
			<div className="order-list__cards">
				{orders?.map((order) => (
					<OrderListCard key={order.id} order={order} />
				))}
			</div>
			{/* Table for Desktop */}
			<table className="order-list__table">
				<thead>
					<tr>
						{tableColumns.map((column) => (
							<th key={column}>{column}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{orders?.map((order) => (
						<OrderListTableRow key={order.id} order={order} />
					))}
				</tbody>
			</table>
		</OrderListContainer>
	);
};

export default OrderList;
