import ProductCardSkeleton from "components/ProductCard/ProductCardSkeleton";
import { ProductRow } from "./PlpProductGrid.Styled";
import { FunctionComponent } from "react";

interface PlpProductGridProps {
  productCount: number;
  layout: number;
  large: boolean;
}

const PlpProductGridLoading: FunctionComponent<PlpProductGridProps> = ({
  productCount,
  layout,
  large,
}) => {
  const skeletons = [...Array(productCount)].map((_, index) => {
    return <ProductCardSkeleton key={index} layout={layout} />;
  });

  const itemsPerRow = layout !== 2 ? (large ? 4 : 2) : large ? 8 : 4;

  const rows = [] as any[];

  for (let i = 0; i < productCount; i += itemsPerRow) {
    const rowItems = [];

    for (let j = 0; j < itemsPerRow; j++) {
      rowItems.push(skeletons[i + j]);
    }

    rows.push(rowItems);
  }

  return (
    <div className="product-grid__loading">
      {rows.map((row, index) => (
        <ProductRow key={index} layout={layout}>{row}</ProductRow>
      ))}
    </div>
  );
};

export default PlpProductGridLoading;
