import styled from "styled-components/macro";
import { accountDarkGrey, dark, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const VariantButton = styled.button`
  min-width: 46px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  border: none;
  outline: 1px solid ${accountDarkGrey};
  font: normal 16px/20px ${Roboto};
  color: ${accountDarkGrey};
  padding: 0 4px;

  &:disabled {
    background-color: #f0efef;
    color: #aaa;
    outline-color: #aaa;
  }

  &.active:enabled,
  &:hover:enabled {
    outline: 2px solid ${dark};
    color: ${dark};
  }
`;
