export const saveToSessionStorage = (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };
  
  export const getFromSessionStorage = (key: string, defaultValue = []) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };
  
  export const removeFromSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
  };

export const getSessionStorageOriginalProducts = () => {
    return JSON.parse(sessionStorage.getItem("original_products_cache") || "[]");
};

export const getSessionStorageCache = () => {
    return JSON.parse(sessionStorage.getItem("category_cache") || "{}");
};

export const setSessionStorageCache = (cache: any) => {
    sessionStorage.setItem("category_cache", JSON.stringify(cache));
};

export const setSessionStorageOriginalProducts = (products: any) => {
    sessionStorage.setItem("original_products_cache", JSON.stringify(products));
};