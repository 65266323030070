import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { dark, grey } from 'utilities/variables/colors';
import { aboveSmall } from 'utilities/variables/media';

type Props = {
	transformWrapper: number;
};

export const StyledTimelineWrapper = styled.section`
	.slide-wrapper {
		img {
			width: 100%;
			object-fit: cover;
		}
	}
`;

export const StyledTimelineNavigationWrapper = styled.div<Props>`
	margin-top: 2rem;
	width: 64.25rem;
	margin-inline: auto;
	margin-bottom: 3rem;

	@media (min-width: 1082px) {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: ${remCalc(50)};
	}

	button {
		display: none;

		@media ${aboveSmall} {
			display: block;
			position: absolute;
			bottom: 0;

			&:first-of-type {
				left: ${remCalc(16)};
			}

			&:last-of-type {
				right: ${remCalc(16)};
			}
		}

		@media (min-width: 1082px) {
			position: relative;
			display: flex;

			&:first-of-type {
				justify-self: flex-end;
			}

			&:last-of-type {
				justify-self: flex-start;
			}
		}
	}

	ul {
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		width: 96%;
		margin-left: 2%;
		transform: ${({ transformWrapper }) =>
			`translateX(-${transformWrapper}px)`};
		transition: transform 0.3s ease;

		@media (min-width: 1082px) {
			margin-left: 0;
			margin-inline: auto;
			transform: translateX(0);
			width: 64.25rem;
		}

		&::after {
			content: '';
			width: 98%;
			height: 1px;
			background-color: ${grey};
			position: absolute;
			top: 50%;
			left: 5px;
			z-index: -1;
		}
	}

	li {
		font-size: ${remCalc(14)};
		color: ${dark};
		font-weight: 700;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 17px;

		&:nth-child(even) {
			display: flex;
			flex-direction: column-reverse;
			padding-bottom: 0;
			padding-top: 17px;
		}

		span {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 5600px;
			border: solid 1px ${dark};
			background-color: white;
			margin: 0.5rem 0;
			cursor: pointer;

			&.current {
				background-color: ${dark};
			}
		}
	}

	.disable-button {
		pointer-events: none;
		opacity: 0.3;
	}
`;
