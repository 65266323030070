import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { darkGrey } from "utilities/variables/colors";
import { accountLightGrey, dark, lightGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledErrorContent = styled(ErrorContent)`
	margin: auto;
	h1 {
		color: #212529;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0;
		margin: 0;
		font-size: 1.25rem;
	}

	p,
	a {
		padding: 0;
		margin: 0;
		margin-top: ${remCalc(16)};
		color: #212529;
		font-size: 0.8rem;
		text-decoration: underline;

		&:hover {
			color: ${darkGrey};
		}
	}

	@media ${aboveMedium} {
		margin: unset;
		margin-top: ${remCalc(64)};
	}
`;

export const StyledBasketWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex: 1;
	color: black;
	padding-inline: 1rem;
	min-height: 100%;

	.checkout-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		svg {
			margin-right: 0.5rem;
		}
	}

	.basket-top-button {
		margin-bottom: 2rem;
		@media ${aboveMedium} {
			display: none;
		}
	}

	& > h2 {
		margin: 0;
		font-size: 0.8rem;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 1rem;
		letter-spacing: ${remCalc(1)};

		@media ${aboveMedium} {
			font-size: 1.25rem;
		}
	}

	.ProductCard {
		&:first-of-type {
			padding-top: 0;
		}

		h2 {
			line-height: 1;
		}
	}

	.empty-cart {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-family: ${Roboto};

		h4 {
			color: #212529;
			font-weight: 700;
			text-transform: uppercase;
			padding: 0;
			margin: 0;
			font-size: 1.25rem;
		}

		a {
			padding: 0;
			margin: 0;
			color: #212529;
			font-size: 0.8rem;
			text-decoration: underline;

			&:hover {
				color: ${darkGrey};
			}
		}
	}
`;

export const StyledBasketContent = styled.div`
	margin-bottom: ${remCalc(64)};
	@media ${aboveMedium} {
		display: grid;
		grid-template-columns: 6fr 4fr;
		gap: 1rem;

		> :first-child {
			padding-right: 8px;
		}
	}
`;

export const StyledProductsSection = styled.div`
	@media ${aboveMedium} {
		max-height: 38.5rem;
		overflow-y: auto;
	}

	.StyledProductCard {
		margin-bottom: 1.5rem;
	}
`;

export const StyledCartInfoSection = styled.div`
	h3 {
		font-weight: 700;
		text-transform: uppercase;
		font-size: 0.8rem;
	}

	.button-as-link {
		text-decoration: underline;
		color: #495057;
		margin-bottom: 1rem;
	}

	hr {
		border-bottom: solid 1px ${lightGrey};
	}

	.cart-totals {
		background-color: ${accountLightGrey};
		padding: 1.5rem 1.5rem;
		margin-bottom: 2rem;

		width: 100vw;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		@media ${aboveMedium} {
			width: auto;
			left: unset;
			transform: translateX(0);
		}

		p {
			display: flex;
			justify-content: space-between;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1;
			margin-bottom: 1rem;

			&:first-of-type {
				margin: 0;
			}
		}

		& + a {
			margin-bottom: 2rem;
			text-transform: uppercase;
			font-weight: 700;
			color: ${dark};
		}

		hr {
			margin-block: 1.5rem;
		}
	}

	p.promo-code {
		margin-bottom: 0.5rem;

		& + p {
			font-weight: 400;
			font-size: 0.8rem;
		}
	}

	p.total {
		margin-block: 1.5rem;
	}

	.payment-brands {
		padding: 1.5rem 0;
		padding-bottom: 0.5rem;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
		}

		li {
			margin-right: 0.75rem;
		}
	}

	.need-help {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			margin-bottom: 0.75rem;
		}

		a {
			color: #495057;

			&:hover {
				text-decoration: underline;
				color: ${dark};
			}
		}
	}

	.continue-link {
		transition: color 0.3 ease;

		&:hover {
			text-decoration: underline;
			color: #525a62;
		}
	}
`;

export const StyledLoadingOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	position: fixed;
	inset: 0;
	display: grid;
	place-items: center;

	ion-spinner {
		color: white;
		width: 3rem;
		height: 3rem;
	}
`;
