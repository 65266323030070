
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const AddressWrapper = styled.div`
	position: relative;
    gap: 4px;
    display: flex;
    flex-wrap: wrap;
	
	input {
		background-color: #fff;
		border: ${remCalc(1)} solid #e3e3e3;
		border-radius: ${remCalc(2)} 0px 0px ${remCalc(2)};
		padding: 0px ${remCalc(11)};
		height: ${remCalc(40)};
		color: ${accountDarkGrey};
		font: normal ${remCalc(16)}/${remCalc(20)} ${Roboto};
		outline: none;
		width: 100%;

		&:hover,
		&:focus {
			border: ${remCalc(1)} solid ${grey};
		}
	}
`
