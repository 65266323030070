import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import customerApi from "app/api/customer/customerApi";
import { InputEmail } from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";
import Typography from "components/common/ui/Typography/Typography";
import useLogout from "hooks/auth/useLogout";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { useSelector } from "redux/hooks";
import { ModalDeleteButton, StyledButton, StyledContainer, StyledContent, StyledHeader, StyledHeading, StyledOverlay, StyledWrapper } from "./DeleteAccount.Styled";


const DeleteAccount: FC = () => {

  const logout = useLogout();

  const { t } = useTranslation();
  const { auth: { customer }, bcStore: { store } } = useSelector((state) => state);

  const [modalOpen, setModalOpen] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState('');

  const [deleteApi, deleteApiResult] = customerApi.useLazyDeleteMeQuery();

  const canDelete = useMemo(() => {
    return store && customer && customer.email === enteredEmail;
  }, [store, customer, enteredEmail]);

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleDelete = () => {
    if (!canDelete) {
      return;
    }

    deleteApi()
      .then((res) => {
        if (res.status !== QueryStatus.fulfilled) {
          return;
        }

        logout(store?.hash, true);
      })



  }

  return (
    <>
      <StyledButton type="button" variant="primary_03" onClick={() => setModalOpen(true)}>
        {t('profile.deleteAccount')}
      </StyledButton>

      {modalOpen &&
        <Portal node={document && document.getElementById('portal__modal')}>
          <StyledWrapper className="modal__wrapper">
            <StyledOverlay className="modal__overlay">
              <StyledContainer className="modal__container">
                <StyledHeader className="modal__header">
                  <StyledHeading>{t("profile.deleteAccount")}</StyledHeading>
                  <Button icon={<IconClose />} onClick={handleModalClose} />
                </StyledHeader>
                <StyledContent className="modal__content">
                  <Typography className="text_warning">{t('deleteAccount.areYouSure')}</Typography>
                  <InputEmail label={t('deleteAccount.enterEmail').toString()} value={enteredEmail} onChange={(e) => setEnteredEmail(e.target.value)} />
                  <ModalDeleteButton
                    variant="primary_01"
                    disabled={!canDelete}
                    onClick={handleDelete}
                    loading={deleteApiResult.isLoading}
                  >{t('delete')}</ModalDeleteButton>
                </StyledContent>
              </StyledContainer>
            </StyledOverlay>
          </StyledWrapper>
        </Portal>
      }
    </>

  )
}

export default DeleteAccount;