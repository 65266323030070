import { EvenContainer } from "../BlogContainer.styles";
import ImageItemCommon from "../ImageItemCommon/ImageItemCommonBase";
import ImageItemFirst from "../ImageItemCommon/ImageItemFirstBase";
import { TopGridWrapper } from "./PostGroupTop.styles";

interface DataArray {
	data: any[];
}

export const PostGroupTop = ({ data }: DataArray) => {
	if (data.length < 1) {
		return <></>;
	}
	return (
		<EvenContainer>
			<TopGridWrapper count={data.length} className="PostGroupTop">
				<div className="grid-container">
					{data.map((item, index) => {
						return (
							<div key={"top-" + index} className={`item${index} ${item.uri?'':'hiddenItem'} `}>
								{index === 0 ? (
									<ImageItemFirst data={item} />
								) : (
									<ImageItemCommon data={item} />
								)}
							</div>
						);
					})}
				</div>
			</TopGridWrapper>
		</EvenContainer>
	);
};
