import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Icon } from "./Search.svg";


const IconSearch: FunctionComponent = () => {
  return <SvgIcon svg={<Icon />} />;
};

export default IconSearch;
