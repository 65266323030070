import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledContainer = styled.div<{ isOpen: boolean }>`
	display: ${(props) => !props.isOpen && "none"};
	position: absolute;
	width: 100%;
	height: 100%;
`;

export const StyledOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media ${aboveMedium} {
		align-items: center;
	}
`;

export const StyledModalContentContainer = styled.div`
	position: relative;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: ${remCalc(24)} ${remCalc(16)};
	padding-bottom: calc(2rem + env(safe-area-inset-bottom, 0));
	width: 100%;

	@media ${aboveMedium} {
		padding: ${remCalc(32)};
		max-width: ${remCalc(500)};
	}
`;

export const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media ${aboveMedium} {
		button {
			position: absolute;
			margin: ${remCalc(16)};
			top: 0;
			right: 0;
		}
	}
`;

export const StyledHeading = styled.h4`
	margin: 0;
	font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
	color: ${dark};
	text-align: center;

	@media ${aboveMedium} {
		font: bold ${remCalc(24)} / ${remCalc(29)} ${Roboto};
		flex: 1;
	}
`;

export const StyledContent = styled.div`
	margin-top: ${remCalc(24)};
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	p {
		margin: 0;
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
	}

	button {
		width: 100%;
	}
`;
