import styled from "styled-components";
import { IonText } from "@ionic/react";

import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { accountDarkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveLarge } from "utilities/variables/media";

export const StyledWrapper = styled.div`
  text-align: center;
`;

export const StyledText = styled(IonText)`
  font: normal 12px/15px ${Roboto};
  letter-spacing: 0px;
  color: ${accountDarkGrey};
`;

export const SkeletonDisclaimer = styled(Skeleton)`
  .react-loading-skeleton {
    margin-bottom: 8px;
    height: 12px;
    width: 98%;

    &:nth-last-of-type(2) {
      width: 80%;
    }

    &:last-of-type {
      width: 58%;
    }

    @media ${aboveLarge} {
      &:last-of-type {
        width: 80%;
      }
    }
  }
`;
