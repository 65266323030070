import { gql } from 'graphql-request';

const wordPressGetListOfCurrentOpportunities = (
	pageSize: number,
	cursor?: string,
) => {
	return {
		operationName: "GetCurrentOpportunitiesData",
		variables: {
			pageSize,
			cursor,
		},
		query: gql`
			query GetCurrentOpportunitiesData($pageSize: Int!, $cursor: String) {
				jobs(first: $pageSize, after: $cursor) {
					nodes {
						title
						id
						jobs {
							deadline
							location
						}
					}
					pageInfo {
						endCursor
						hasNextPage
					}
				}
			}
		`,
	};
};

export default wordPressGetListOfCurrentOpportunities;