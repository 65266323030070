import uuid from 'react-uuid';
import 'swiper/css';
import 'swiper/css/grid';
import { StyledCompanyValuesGridWrapper } from './companyValues.Styled';

import CompanyValuesItem from './CompanyValuesItem';

export type companyValues = {
	icon: {
		altText: string;
		sourceUrl: string;
		title: string;
	};
	text: string;
	title: string;
	linkText?: string;
	link?: string;
};

type Props = {
	companyValues: companyValues[];
};

const CompanyValuesGrid = ({ companyValues }: Props) => {
	return (
		<StyledCompanyValuesGridWrapper className='CompanyValuesGridWrapper'>
			{companyValues?.map((value: any, index) => (
				<CompanyValuesItem key={uuid()} valueData={value} />
			))}
		</StyledCompanyValuesGridWrapper>
	);
};

export default CompanyValuesGrid;
