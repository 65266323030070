import {
	BulkAddCartItems,
	CartErrorType,
	CartItem,
	LineItem,
	PhysicalItem,
} from "types/CartTypes";
import {
	clearCart,
	setCartError,
	setCartIsLoading,
	setLastAddedItem,
	updateCart,
} from "redux/cart/cartSlice";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
	useAddCartItemMutation,
	useCreateCartMutation,
	useDeleteCartItemMutation,
	useDeleteCartMutation,
	useLazyGetCartQuery,
	useUpdateCartCustomerMutation,
	useUpdateCartItemMutation,
} from "app/api/cart/cartApi";
import { useDispatch, useSelector } from "redux/hooks";

import { COOKIE_CART_ID, COOKIE_PRODUCT_GROUP } from "utilities/consts";
import Cookies from "js-cookie";
import { PageType } from "ts/enums";
import { Product } from "ts/types";
import { setShowAddToCartConformation } from "redux/UI/uiSlice";
import useDataLayer from "datalayer/useDataLayer";
import { useLazyGetProductBySkuQuery } from "app/api/product/productApi";

declare global {
	interface Window {
		/**
		 * Segmentify Add Cart Item
		 * @param params Segmentify Add Cart Item Parameters
		 */
		segmentifyAddCartItem(params: SegmentifyAddCartItemOperation): Promise<any>;
	}

	export type SegmentifyAddCartItemOperation = {
		sku: string;
		variant: number | undefined;
		quantity: number;
	};
}

export type CartActionOptions = {
	triggeredFrom?: string;
	onSuccess?: () => void;
	onError?: (error: any) => void;
};

type CartCookieData = {
	storeHash: string;
	id: string;
	customer: number;
};

type CartContextType = {
	customerId: number;
	storeHash: undefined | string;
	isLoading: boolean;
	fetchCart: (shouldDeleteCart?: boolean) => void;
	internalDeleteCart: (skipApi?: boolean, storeHash?: string) => Promise<void>;
	setLoading: (loading: boolean) => void;
};

const CartContext = createContext({} as CartContextType);

export const CartProvider = ({ children }: { children: any }) => {
	const [getCartQueryTrigger] = useLazyGetCartQuery();
	const [updateCartCustomer] = useUpdateCartCustomerMutation();
	const [deleteCartMutation] = useDeleteCartMutation();

	const dispatch = useDispatch();

	const { auth, cart, bcStore } = useSelector((state) => state);

	const [isLoading, setIsLoading] = useState(cart.isLoading);

	const customerId = useMemo(() => {
		const id = auth?.customer?.id;
		return id;
	}, [auth?.customer?.id]);

	useEffect(() => {
		if (
			cart.cart?.customer_id !== undefined &&
			cart.cart?.customer_id !== customerId
		) {
			updateCartCustomer({ cartId: cart.cart.id })
				.unwrap()
				.then((cart) => {
					const cookieData = {
						storeHash: bcStore.store?.hash,
						id: cart.id,
						customer: cart.customer_id,
					} as CartCookieData;

					const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;
					const isSecure = window.location.protocol.startsWith("https");

					Cookies.set(
						COOKIE_CART_ID,
						JSON.stringify(cookieData),
						{
							expires: new Date().setHours(new Date().getHours() + 24),
							secure: isSecure,
							sameSite: "Lax",
							domain: host
						}
					);
					dispatch(updateCart(cart));
				})
				.catch((err) => {
					const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;
					dispatch(clearCart());
					Cookies.remove(COOKIE_CART_ID, { domain: host });
				});
		}
	}, [customerId]);

	useEffect(() => {
		if (!cart.cart) {
			fetchCart();
		}
	}, [bcStore.store, customerId]);

	const fetchCart = async (shouldDeleteCart = false) => {

		if (shouldDeleteCart) {
			await internalDeleteCart();

			// Redirect customer back to homepage
			window.location.href = '/';
		}

		if (bcStore.store) {
			const cookieData = JSON.parse(
				Cookies.get(COOKIE_CART_ID) || "{}",
			) as CartCookieData;

			if (!cookieData.id) {
				return;
			}

			const isGuestCart = cookieData.customer === 0;

			if (!isGuestCart && customerId === undefined) {
				return;
			}

			if (
				cookieData?.storeHash &&
				bcStore.store.hash === cookieData.storeHash
			) {
				const id = cookieData?.id;

				if (id) {
					getCartQueryTrigger(id)
						.unwrap()
						.then((cart) => {
							dispatch(updateCart(cart));
						})
						.catch(() => {
							//TODO - This probably needs a better solution that just deleting the cart
							internalDeleteCart();
						});
				}
			}
		}
	}

	const internalDeleteCart = async (
		skipApi: boolean = false,
		storeHash?: string,
	) => {
		const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;
		Cookies.remove(COOKIE_CART_ID, { domain: host });

		if (!cart.cart?.id) {
			return;
		}

		if (!skipApi) {
			await deleteCartMutation({ cartId: cart.cart?.id });
		}

		dispatch(updateCart(undefined));

		return;
	};

	const setLoading = (loading: boolean) => {
		// if (loading !== isLoading) {
		setIsLoading(loading);
		dispatch(setCartIsLoading(loading));
		// }
	};

	return (
		<CartContext.Provider
			value={{
				customerId: customerId || 0,
				storeHash: bcStore?.store?.hash,
				fetchCart,
				internalDeleteCart,
				setLoading,
				isLoading,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

const useCart = () => {
	const { customerId, storeHash, isLoading, internalDeleteCart, fetchCart, setLoading } =
		useContext<CartContextType>(CartContext);

	const [createCartMutation] = useCreateCartMutation();
	const [addCartItemMutation] = useAddCartItemMutation();
	const [updateCartItemMutation] = useUpdateCartItemMutation();
	const [deleteCartItemMutation] = useDeleteCartItemMutation();

	const datalayer = useDataLayer();
	const dispatch = useDispatch();

	const { cart } = useSelector((state) => state);
	const bcStore = useSelector((state) => state.bcStore.store);

	const [getProduct] = useLazyGetProductBySkuQuery();

	const fireDataLayerEvents = async (
		triggeredFrom: string,
		type: "add" | "remove",
		currencyCode: string,
		item: PhysicalItem & {
			parent_sku: string;
		},
		quantityChange: number,
		product?: Product,
	) => {
		if (!item || !cart) {
			return;
		}

		const breadcrumbs = product?.categories?.breadcrumbs || [];

		const id = breadcrumbs.length
			? breadcrumbs[breadcrumbs.length - 1].entityId.toString()
			: "";

		const name = breadcrumbs.length
			? breadcrumbs[breadcrumbs.length - 1].name
			: "";

		if (type === "add") {
			datalayer.addToCart(triggeredFrom, item, quantityChange, currencyCode, {
				id,
				name,
			});
		} else {
			datalayer.removeFromCart(
				triggeredFrom,
				item,
				quantityChange,
				currencyCode,
				{
					id,
					name,
				},
			);
		}
	};

	const handleCartError = (error: any) => {
		const cartError = {
			code: error.status,
			type: "generic",
			translationKey: error?.data?.error?.message || "cart.error.unknown",
			message: error?.data?.error?.message
				? undefined
				: "A unknown error occurred",
		} as CartErrorType;

		switch (cartError.code) {
			case 422:
				cartError.type = "insufficient_stock";
				cartError.translationKey = "cart.error.insufficientStock";
				cartError.message = error.data.error.message;
				break;
		}
		dispatch(setCartError(cartError));
	};

	const createCart = async (
		lineItems?: CartItem[],
		options?: CartActionOptions,
	) => {
		try {
			const cart = await createCartMutation({
				...(bcStore?.embeddedCheckoutChannelId && {
					channelId: bcStore.embeddedCheckoutChannelId,
				}),
				...(customerId && {
					customerId,
				}),
				lineItems: lineItems ?? [],
			})
				.unwrap()
				.then((res) => res)
				.catch((error) => {
					options?.onError?.(error);
					handleCartError(error);
					return;
				});

			if (!cart) {
				return;
			}

			const cookieData = {
				storeHash,
				id: cart.id,
				customer: cart.customer_id,
			} as CartCookieData;

			const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;
			const isSecure = window.location.protocol.startsWith("https");


			Cookies.set(
				COOKIE_CART_ID,
				JSON.stringify(cookieData),
				{
					expires: new Date().setHours(new Date().getHours() + 24),
					secure: isSecure,
					sameSite: "Lax",
					domain: host
				}
			);

			dispatch(updateCart(cart));
			return cart;
		} catch {
			return undefined;
		}
	};

	const deleteCart = async (storeHash?: string) => {
		setLoading(true);

		await internalDeleteCart(false, storeHash);

		setLoading(false);
	};

	const cartExists = () => {
		const cookieData = JSON.parse(
			Cookies.get(COOKIE_CART_ID) || "{}",
		) as CartCookieData;
		if (cookieData.id) {
			return true;
		}
		return false;
	}

	const addCartItem = async (
		product: Product,
		variantId: number | undefined,
		quantity: number,
		options?: CartActionOptions,
	) => {
		setLoading(true);

		const groupIDField = product?.customFields?.find(
			(field) => field.name === "GroupID",
		);

		const groupID = groupIDField ? groupIDField.value : null;

		type CookieData = {
			sku: string;
			groupId: string;
		};

		const cookieData: CookieData = {
			sku: product.sku,
			groupId: groupID || '',
		};

		const addItemToCookie = (cookieData: { sku: string; groupId: string }) => {
			const existingCookie = Cookies.get(COOKIE_PRODUCT_GROUP);

			let itemsArray = [];

			if (existingCookie) {
				try {
					itemsArray = JSON.parse(existingCookie);
				} catch (e) {
					console.error("Error parsing cookie data:", e);
				}
			}

			const itemExists = itemsArray.some((item:CookieData) => item.sku === cookieData.sku);

			if (!itemExists) itemsArray.push(cookieData);

			Cookies.set(COOKIE_PRODUCT_GROUP, JSON.stringify(itemsArray), {
				expires: new Date().setHours(new Date().getHours() + 24),
				secure: true,
				sameSite: "None",
			});
		};

		addItemToCookie(cookieData);

		const lineItems = [convertToLineItem(product, variantId, quantity)];

		let action;

		if (!cart.cart?.id) {
			action = createCart(lineItems);
		} else {
			action = addCartItemMutation({
				id: cart.cart.id,
				lineItems,
			}).unwrap();
		}

		const updatedCart = await action
			.then((cart) => cart)
			.catch((error) => {
				options?.onError?.(error);

				if (error.status === 422) {
					handleCartError(error);
					return;
				}

				internalDeleteCart(true);
				return;
				// this can give e 403 error
			});

		setLoading(false);

		if (!updatedCart) {
			return;
		}

		dispatch(updateCart(updatedCart));

		const matchingItem = getMatchingLineItem(
			product.entityId,
			variantId,
			updatedCart?.line_items.physical_items,
		);

		if (matchingItem) {
			fireDataLayerEvents(
				options?.triggeredFrom || PageType.CART,
				"add",
				updatedCart.currency.code,
				{
					...matchingItem,
					parent_sku: product.sku,
				},
				quantity,
				product,
			);
			options?.onSuccess?.();
			dispatch(setLastAddedItem(matchingItem));
			dispatch(setShowAddToCartConformation(true));
		}
	};

	const bulkAddCartItems = async ({ lineItems, options }: BulkAddCartItems) => {
		setLoading(true);

		let action;

		if (!cart.cart?.id) {
			action = createCart(lineItems);
		} else {
			action = addCartItemMutation({
				id: cart.cart.id,
				lineItems,
			}).unwrap();
		}

		const updatedCart = await action
			.then((cart) => cart)
			.catch((error) => {
				options?.onError?.(error);

				if (error.status === 422) {
					handleCartError(error);
					return;
				}

				internalDeleteCart(true);
				return;
				// this can give e 403 error
			});

		setLoading(false);

		if (!updatedCart) {
			return;
		}

		dispatch(updateCart(updatedCart));

		lineItems.forEach((item) => {
			const matchingItem = getMatchingLineItem(
				item.product_id,
				item.variant_id,
				updatedCart?.line_items.physical_items,
			);

			if (matchingItem) {
				fireDataLayerEvents(
					options?.triggeredFrom || PageType.CART,
					"add",
					updatedCart.currency.code,
					{
						...matchingItem,
						parent_sku: /[0-9](.+)/.exec(matchingItem.name)?.[1] ?? "",
					},
					item.quantity,
					undefined,
				);
			}
		});

		options?.onSuccess?.();
	};

	/**
	 * Update cart item quantity, will remove if quantity is 0.
	 * @param itemId
	 * @param product
	 * @param quantity
	 * @param changeType whether the update was an add or remove, used to determine the correct
	 * datalayer event to fires
	 * @param options
	 * @returns
	 */
	const updateCartItem = async (
		itemId: string,
		product: any,
		quantity: number,
		changeType: "add" | "remove",
		options?: CartActionOptions,
	) => {
		if (!cart.cart?.id) {
			return;
		}
		setLoading(true);

		const cartItem =
			cart.cart.line_items.physical_items.find((item) => item.id === itemId) ||
			undefined;

		// if there isn't a matching item on the cart, don't perform action
		if (!cartItem) {
			setLoading(true);
			return;
		}

		// if quantity is less than 1, do a remove instead
		// this will handle callbacks and datalayer events
		if (quantity < 1) {
			removeCartItem(itemId, options);
			return;
		}

		const updatedCart = await updateCartItemMutation({
			cartId: cart.cart.id,
			itemId,
			newItem: {
				product_id: product?.entityId || product,
				quantity,
			},
		})
			.unwrap()
			.catch((error) => {
				options?.onError?.(error);
				handleCartError(error);
				return;
			});

		if (!updatedCart) {
			setLoading(false);
			return;
		}

		const quantityChange = Math.abs(cartItem.quantity - quantity);

		dispatch(updateCart(updatedCart));
		setLoading(false);

		if (cartItem) {
			fireDataLayerEvents(
				options?.triggeredFrom || PageType.CART,
				changeType,
				updatedCart.currency.code,
				{
					...cartItem,
					parent_sku: /[0-9](.+)/.exec(cartItem.name)?.[1] ?? "",
				},
				quantityChange,
			);
		}

		options?.onSuccess?.();
	};

	const removeCartItem = async (
		itemId: string,
		options?: CartActionOptions,
	) => {
		if (!itemId.length || !cart.cart?.id) {
			return;
		}
		setLoading(true);

		const cartItem =
			cart.cart.line_items.physical_items.find((item) => item.id === itemId) ||
			undefined;

		if (!cartItem) {
			setLoading(false);
			return;
		}

		// get the currency code from the cart now, as this might get deleted
		// need for the datalayer
		const currencyCode = cart.cart.currency.code;

		let action;

		if (cart.totalItems === 1 || cartItem.quantity === cart.totalItems) {
			action = internalDeleteCart();
		} else {
			action = deleteCartItemMutation({
				cartId: cart.cart.id,
				itemId,
			}).unwrap();
		}
		//NOTE - This might need some work on the error side, as this will
		// currently delete the cart?

		const updatedCart = await action
			.then((cart: any) => cart)
			.catch((error: any) => {
				options?.onError?.(error);
			});

		dispatch(updateCart(updatedCart));

		setLoading(false);

		if (cartItem) {
			fireDataLayerEvents(
				options?.triggeredFrom || PageType.CART,
				"remove",
				currencyCode,
				{
					...cartItem,
					parent_sku: /[0-9](.+)/.exec(cartItem.name)?.[1] ?? "",
				},
				cartItem.quantity,
			);
		}

		options?.onSuccess?.();
	};

	const segmentifyAddCartItem = async ({
		sku,
		variant,
		quantity,
	}: SegmentifyAddCartItemOperation
	) => {

		console.debug("Segmentify attempting to add product to cart", { sku, variant, quantity });

		const product = await getProduct(variant ? `${sku}/${variant}` : sku).unwrap();

		console.debug("Product found. Attempting to add product to cart", { sku, product, variant, quantity });

		if (!product) {
			console.error("Product not found", { sku });

			return;
		}

		if (variant && !((product.productOptions?.length ?? 0) > 0)) {
			console.error("Segmentify specified a variant while no such variant exists on BC", { sku, variant });

			return;
		}

		const bcVariant = product.variants?.find(v => v.option.label === String(variant));

		if (variant && !bcVariant) {
			console.error("Segmentify specified a variant that does not exist on BC", { sku, variant });

			return;
		}

		console.debug("Segmentify adding product to cart", { sku, product, variant, quantity });

		addCartItem(product, bcVariant ? bcVariant.entityId : undefined, quantity);
	};

	// Expose segmentifyAddCartItem to window
	window.segmentifyAddCartItem = segmentifyAddCartItem;

	return {
		cart: cart.cart,
		meta: {
			isLoading,
			totalItems: cart.totalItems,
		},

		createCart,
		deleteCart,
		addCartItem,
		fetchCart,
		bulkAddCartItems,
		updateCartItem,
		removeCartItem,
		cartExists
	};
};

const convertToLineItem = (
	product: Product,
	variantId: number | undefined,
	quantity: number,
) => {
	return {
		product_id: product.entityId,
		variant_id: variantId,
		quantity: quantity || 1,
	} as LineItem;
};

const getMatchingLineItem = (
	productId: number,
	variantId: number | undefined,
	physicalItems: PhysicalItem[],
) => {
	return physicalItems.find((item) => {
		if (!variantId) {
			return item.product_id === productId;
		} else {
			return item.product_id === productId && item.variant_id === variantId;
		}
	});
};

export default useCart;
