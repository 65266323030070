import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	carousels: [],
};

export const productRecSlice = createSlice({
	name: "productRec",
	initialState,
	reducers: {
		setCarousels: (state, action) => {
			state.carousels = action.payload;
		},
		resetCarousels: (state) => {
			state = initialState;
		},
	},
});

export const { setCarousels, resetCarousels } = productRecSlice.actions;

export default productRecSlice.reducer;
