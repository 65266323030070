import styled from "styled-components";

import { aboveMedium } from "utilities/variables/media";
import { remCalc } from "utilities/styles";
import { Roboto } from "utilities/variables/fonts";
import { accountDarkGrey, dark, grey } from "utilities/variables/colors";

export const AddressFormSectionContainer = styled.section`
	margin-top: ${remCalc(32)};
	display: flex;
	flex-direction: column;
	gap: ${remCalc(24)};

	.form__heading {
		margin: 0;
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
	}

	.form__fields {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};
	}

	.form__row {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		@media ${aboveMedium} {
			flex-direction: row;
			gap: ${remCalc(16)};
		}

		& > * {
			flex: 1;

			@media ${aboveMedium} {
				max-width: calc(50% - ${remCalc(16)});
			}
		}
	}

	.form__field-label {
		margin: 0;
		display: flex;
		align-items: center;
		gap: ${remCalc(4)};

		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};

		.hint {
			color: ${accountDarkGrey};
		}
	}
`;