import { gql } from "graphql-request";

export const Variant = gql`
  fragment Variant on VariantEdge {
    node {
      id
      entityId
      sku
      isPurchasable
      inventory {
        isInStock
      }
      prices(includeTax: true) {
        price {
          currencyCode
          formatted
          value
        }
      }
      productOptions {
        edges {
          node {
            displayName
            isRequired
            ... on CheckboxOption {
              checkedByDefault
            }
            ... on MultipleChoiceOption {
              values {
                edges {
                  node {
                    entityId
                    label
                    isDefault
                    ... on SwatchOptionValue {
                      hexColors
                      imageUrl(width: 200)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
