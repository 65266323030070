import styled from "styled-components";

import InputSelect from "components/common/form/InputSelect/InputSelect";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
  @media ${aboveMedium} {
    display: flex;
    flex-direction: column;
    gap: ${remCalc(16)};

    h3 {
      text-transform: none;
      letter-spacing: normal;
    }
  }
`;

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${remCalc(12)};
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledLi = styled.li`
  a {
    text-decoration: none;
    font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
    color: ${dark};
  }

  &.active {
    a {
      font-weight: bold;
    }
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  .skeleton-container {
    gap: ${remCalc(12)};
  }
`;

export const StyledInputSelect = styled(InputSelect)`
  .input-select__single-value {
    color: ${accountDarkGrey};
    font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
  }
`;
