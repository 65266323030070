import Button from "components/common/ui/Button/Button";
import Markdown from "components/common/ui/Markdown/Markdown";
import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: ${remCalc(32)} 0 ${remCalc(64)};

  @media ${aboveMedium} {
    margin: ${remCalc(64)} auto;
    max-width: ${remCalc(866)};
    width: 100%;;
  }
`;

export const InnerWrapper = styled.div`
  margin: 0 ${remCalc(24)};
  @media ${aboveMedium} {
    
  }
`;

export const Name = styled(Typography)`
  margin-bottom: ${remCalc(16)};
`;

export const Description = styled(Markdown)`
  font-size: ${remCalc(16)};

`;

export const SeeMoreButton = styled(Button)`
  margin-top: ${remCalc(8)};

  font-weight: 500;
  text-transform: none;
  color: ${darkGrey};

  &:hover {
    text-decoration: underline;
    color: ${dark};
  }
`
