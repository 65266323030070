import Skeleton from 'react-loading-skeleton';
import uuid from 'react-uuid';

import Button from 'components/common/ui/Button/Button';
import { StyledContainer } from './ImageTextSplit.Styled';
import * as DOMPurify from "dompurify";

type Props = {
	isFlipped: boolean;
	image: {
		sourceUrl: string;
		altText?: string;
	};
	text: string;
	title: string;
	showButton?: boolean;
	buttonLink?: string;
	buttonText?: string;
	isLoading: boolean;
};

const ImageTextSplit = ({
	isFlipped,
	image,
	text,
	title,
	showButton = false,
	buttonLink,
	buttonText,
	isLoading,
}: Props) => {
	// Ensure text maintains line breaks and remove empty paragraphs
	const paragraphs = text?.split(/\r?\n/).filter((element) => element !== '');

	const { sanitize }: any = DOMPurify;

	return (
		<StyledContainer className='image-and-text-layout' $isFlipped={isFlipped}>
			<div className='container'>
				<div className='image-container'>
					{isLoading ? (
						<Skeleton width='100%' />
					) : (
						<div className='image'>
							<picture className='clip'>
								<source srcSet={image?.sourceUrl} />
								<img src={image?.sourceUrl} alt={image?.altText} />
							</picture>
						</div>
					)}
				</div>
				<div className='text-split-container'>
					<div className='text'>
						<h3>{title || <Skeleton height='20px' width='100%' />}</h3>
						{paragraphs.map((element) => (
							<p key={uuid()} dangerouslySetInnerHTML={{ __html: sanitize(element) }}></p>
						))}

						{showButton && buttonText && (
							<Button variant='primary_01' link={{ to: `${buttonLink}` }}>
								{buttonText}
							</Button>
						)}
					</div>
				</div>
			</div>
		</StyledContainer>
	);
};

export default ImageTextSplit;
