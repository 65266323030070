import { FunctionComponent } from "react";
import Typography from "../../components/common/ui/Typography/Typography";
import usePageContent from "../../hooks/usePageContent";
import {
	RelatedArticle,
	RelatedArticleImage,
	StyledLink,
} from "./RelatedArticle.Styled";
import { wordPressBlogArticleApi } from "app/api/blog/wordPressBlogArticleApi";

interface Prop {
	blogLandingUrl?: string,
	articles: any;
}

const RelatedArticles: FunctionComponent<Prop> = ({ blogLandingUrl, articles }) => {
	const prefetchBlogPost = wordPressBlogArticleApi.usePrefetch(
		"getPageContentBySlug",
	);

	const handleOnMouseOver = (slug: string) => {
		prefetchBlogPost(slug, { force: true });
	};

	const resetPageScroll = usePageContent();

	return articles
		.filter((article: any) => article && article.featuredImage && article.title)
		.map((article: any, key: number) => (
			<StyledLink
				to={`${blogLandingUrl}/${article.slug}`}
				key={key}
				onClick={() => resetPageScroll.scrollToTop()}
				onMouseEnter={() => handleOnMouseOver(article.slug)}
			>
				<RelatedArticle>
					<RelatedArticleImage
						src={article.featuredImage.node.sourceUrl}
						alt={article.author}
					/>
					<Typography variant="heading" size="extra-small" className="category">
						{article.categories.edges[0].node.name}
					</Typography>
					<Typography variant="heading" size="small" className="title">
						{article.title}
					</Typography>
				</RelatedArticle>
			</StyledLink>
		));
};

export default RelatedArticles;
