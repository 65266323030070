import styled from "styled-components/macro";
import { CustomiseComponent } from "ts/interfaces";

import { white } from "utilities/variables/colors";
import { zindexModal } from "utilities/variables/zIndex";

export const Container = styled.div`

`;

export const ModalContainer = styled.div<CustomiseComponent>`
	position: absolute;
	width: 328px;
	background-color: ${white};
	padding: 32px 24px;
	z-index: ${zindexModal};
	bottom: 20px;
	left: 20px;
	box-shadow: 0px 10px 18px #21252940;
	padding-bottom: env(safe-area-inset-bottom, 0) !important;

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0px 24px;
	}

	.btn_modal-close {
		position: absolute;
		font-size: 24px;
		top: 17px;
		right: 17px;
		z-index: 10;
	}

	${(props) => props.styles}
`;

export const ModalHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 6px;

	.btn_modal-close {
		position: absolute;
		font-size: 24px;
		margin-left: auto;
		justify-content: flex-end;
	}
`;

export const ModalBody = styled.div``;
