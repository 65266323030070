import styled from "styled-components";

export const VariantsList = styled.ul`
	display: inline-flex;
	justify-content: space-between;
	padding: 0;
	margin: 0 auto;
	list-style: none;

	li {
		display: inline-block;

		margin: 0px 4px;
		margin-top: 2px;
		margin-bottom: 10px; //prevent scroll overlay on content

		&:first-of-type {
			margin-left: 2px;
		}

		&:last-of-type {
			margin-right: 2px;
		}
	}
`;

export const VariantsWrapper = styled.div`
	white-space: nowrap;
`;
