import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const FinalGridWrapper = styled.section`
	.item0 {
		/* grid-area: left; */
		img {
			min-height: ${remCalc(518)};
		}
		h4 {
			padding-right: ${remCalc(16)};
			padding-left: ${remCalc(16)};
		}
		h3 {
			padding-right: ${remCalc(16)};
			padding-left: ${remCalc(16)};
		}
		padding-bottom:${remCalc(32)};
		@media ${aboveMedium} {
			padding-bottom:${remCalc(38)};
			img {
				height: ${remCalc(868)};
			}
			h4 {
				padding-left: 0;
			}
			h3 {
				padding-left: 0;
			}
		}
	}
	.item1 {
		/* grid-area: right; */
		img {
			min-height: ${remCalc(518)};
		}
		h4 {
			padding-right: ${remCalc(16)};;
			padding-left: ${remCalc(16)};;
		}
		h3 {
			padding-right: ${remCalc(16)};;
			padding-left: ${remCalc(16)};;
		}
		padding-bottom: 2rem;

		@media ${aboveMedium} {
			padding-bottom:${remCalc(38)};
			img {
				height: ${remCalc(868)};
			}
			h4 {
				padding-left: 0;
			}
			h3 {
				padding-left: 0;
			}
		}
	}

	.grid-container {
		@media ${aboveMedium} {
			display: grid;
		}
		grid-template-columns: 50% 50%;

		/* grid-template-areas:
			"left right"; */
		gap: 2rem;

		min-height: 60rem;
		height: 100%;
		overflow: hidden;
		position: relative;
	}
`;
