import { ReactComponent as Plus } from "assets/images/svg/Plus.svg";
import { ReactComponent as NewAddress } from "assets/images/svg/New Address.svg";
import Icon from "./Icon";
import { FunctionComponent } from "react";

interface IconAddProps {
	className?: string;
	newAddress?: boolean;
}

const IconAdd: FunctionComponent<IconAddProps> = ({ className, newAddress = false }) => {
	const svg = newAddress ? <NewAddress /> : <Plus />;

	return <Icon className={className} icon={svg} />;
};

export default IconAdd;
