import styled from 'styled-components';
import { pageContentContainer } from 'theme/StyledMixins';
import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledResponsibilitiesBanner = styled.div`
	.image-banner {
		.react-loading-skeleton {
			height: ${remCalc(231)};

			@media ${aboveMedium} {
				height: ${remCalc(435)};
			}
		}
	}
`;

export const StyledResponsibilitiesSection = styled.div`
	&.flex-carousel-fix {
		${pageContentContainer};
		max-height: 100%;
		display: grid;
		grid-template-columns: 1fr;
		padding: ${remCalc(32)} ${remCalc(25)} 0 ${remCalc(25)};
		margin-bottom: ${remCalc(32)};

		@media ${aboveMedium} {
			grid-template-columns: 1fr 1fr 1fr;
			gap: ${remCalc(85)};
			padding: ${remCalc(64)} 0 ${remCalc(20)} 0;
			margin-bottom: auto;
		}

		.ItemCard {
			padding-bottom: ${remCalc(64)};
			height: 100%;
			position: relative;
			display: flex;
			justify-content: flex-start;

			@media ${aboveMedium} {
				margin-bottom: auto;
			}

			h3 {
				color: ${dark};
				letter-spacing: 1px;
				margin-bottom: ${remCalc(16)};
			}

			p {
				color: ${accountDarkGrey};
				margin-bottom: ${remCalc(24)};
			}

			a {
				color: ${dark};
				font-size: ${remCalc(14)};
				position: absolute;
				bottom: ${remCalc(48)};
			}
		}
	}
`;
