import React, { useState } from "react";
import { InputProps } from "../Input/Input";
import ErrorMessage from "../Input/InputError/InputError";
import Label from "../Label/Label";
import {
  Checkbox,
  CheckboxContainer,
  HiddenCheckbox,
} from "./InputColourCheckbox.Styled";

interface InputCheckboxProps extends InputProps {
  value?: boolean | undefined;
  colour:string;
}

const InputColourCheckbox = React.forwardRef<
  HTMLInputElement,
  InputProps & InputCheckboxProps
>(({ className, label,colour, required = false, ...props }, ref) => {

  const [checked, setChecked] = useState<boolean>(props.value || false);

  React.useEffect(() => {
    setChecked(Boolean(props.value))
  }, [props.value])

  const handleOnClick = (e: any) => {
    if (props.disabled){return}
    const value = !checked;

    setChecked(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
  <CheckboxContainer className={className}>
      <HiddenCheckbox
        checked={checked}
        onChange={(e) => {
          props.onChange && props.onChange(e);
        }}
        readOnly
      />
      <Checkbox
        ref={ref}
        colour={colour}
        checked={checked}
        disabled={props.disabled}
        onClick={handleOnClick}
        onBlur={props.onBlur}
        role="checkbox"
        aria-checked={checked}
      >
        <div/>
      </Checkbox>
      <Label>{label}</Label>
      <ErrorMessage bottom="7px" message={props.errorMsg} />
    </CheckboxContainer>
  );
});

export default InputColourCheckbox;
