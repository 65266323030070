import styled from "styled-components";
import { remCalc } from "utilities/styles";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: ${remCalc(12)};
`;

export const StyledSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
