
import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";

export const ContentWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media ${aboveMedium} {
    padding: 0;
  }
`;

export const StyledOtherProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 32px 16px;

  @media ${aboveMedium} {
    gap: 32px;
    padding: 32px 20px;
  }
`;
