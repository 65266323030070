import React, { useEffect } from "react";

import wishlistApi from "app/api/customer/wishlistApi";

import Icon from "components/common/ui/Icons/Icon";
import Link from "components/common/ui/Link/Link";

import { useSelector } from "redux/hooks";

import { ReactComponent as ChevronDown } from "../../assets/images/svg/Chevron Dropdown Large.svg";

import { StyledAccountNavigation, StyledNavigationDropdown } from "./Styled";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

type LinkType = {
	key: string;
	address: string;
	text: string;
};

const DropdownNavigation = () => {
	const location = useLocation();

	const currentLink = useSelector(
		({ ui: { accountNavigationCurrentLink } }) => accountNavigationCurrentLink,
	);

	const [isOpen, setIsOpen] = React.useState(false);

	const [getWishlists, getWishlistsResult] =
		wishlistApi.useLazyGetWishlistsQuery();

	React.useEffect(() => {
		getWishlists();
	}, [getWishlists]);

	useEffect(() => {
		setIsOpen(false);
	}, [location]);

	const { t } = useTranslation();

	const links: LinkType[] = React.useMemo(() => {
		return [
			{
				key: "Overview",
				address: t(PathTranslationKey.ACCOUNT_OVERVIEW),
				text: t("account.overview"),
			},
			{
				key: "Orders",
				address: t(PathTranslationKey.ACCOUNT_ORDERS),
				text: t("account.orders"),
			},
			{
				key: "Loyalty Program",
				address: t(PathTranslationKey.ACCOUNT_LOYALTY),
				text: t("account.loyaltyProgram"),
			},
			{
				key: "Wishlists",
				address: t(PathTranslationKey.ACCOUNT_WISHLIST),
				text: `${t("account.wishlists")}${
					Boolean(getWishlistsResult.data?.data.length)
						? ` (${getWishlistsResult.data?.data.length})`
						: ""
				}`,
			},
			{
				key: "Profile",
				address: t(PathTranslationKey.ACCOUNT_PROFILE),
				text: t("account.profile"),
			},
			{
				key: "Addresses",
				address: t(PathTranslationKey.ACCOUNT_ADDRESSES),
				text: t("account.addresses"),
			},
		];
	}, [getWishlistsResult.data?.data.length, t]);

	return (
		<StyledAccountNavigation className="AccountNavigation">
			<StyledNavigationDropdown
				className="StyledNavigationDropdown"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>
					{links.find((link) => link.key === currentLink)?.text || ""}
				</span>{" "}
				<Icon icon={<ChevronDown />} className={isOpen ? "isOpen" : ""} />
			</StyledNavigationDropdown>
			<ul className={isOpen ? "isOpen" : ""}>
				{links.map((link, index) => (
					<li
						key={index}
						className={location.pathname.endsWith(link.address) ? "active" : ""}
					>
						<Link to={link.address}>{link.text}</Link>
					</li>
				))}
			</ul>
		</StyledAccountNavigation>
	);
};

export default DropdownNavigation;
