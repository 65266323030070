import { getGender } from "app/utils/bigcommerceProductUtil";
import TextButton from "components/common/ui/Button/TextButton";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { Product } from "ts/types";
import { Gender } from "../PdpSizeGuide/PdpSizeGuide";
import Modal from "./Modal/Modal";

interface Props {
	product: Product;
}

const PdpSizeCalculator: FunctionComponent<Props> = ({ product }) => {
	const { t, ready } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [gender, setGender] = useState<Gender | undefined>();

	const handleClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		setGender(getGender(product));
	}, [product]);

	if (!gender || !ready) {
		return null;
	}

	return (
		<>
			<TextButton onClick={() => setIsOpen(true)}>
				{t("sizeCalculator.title")}
			</TextButton>
			<Portal node={document && document.getElementById("portal__modal")}>
				<Modal
					isOpen={isOpen}
					handleClose={handleClose}
					product={product}
					gender={gender}
				/>
			</Portal>
		</>
	);
};

export default PdpSizeCalculator;
