import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${remCalc(710)};

	@media ${aboveMedium} {
		max-width: calc(100% - 116px);
	}

	@media ${aboveLarge} {
		width: calc(100% - 232px);
		max-width: ${remCalc(1280)};
	}

	.content {
		padding: 0;
		margin: 0;
		margin-bottom: ${remCalc(42)};
	}

	margin: ${remCalc(48)} ${remCalc(16)} ${remCalc(64)};
`;
