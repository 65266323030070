import { yupResolver } from "@hookform/resolvers/yup";
import { passwordRegex } from "app/helpers/validation";
import { object, ref, string } from "yup";

export type ResetPasswordFormSchema = {
	password: string;
	password_confirmation: string;
};

export const ResetPasswordFormValidation = object().shape({
	password: string()
		.label("Password")
		.required()
		.test("isValidPassword", "form.validation.password.alpha_num", (value) => {
			if (!value) {
				return false;
			}
			return passwordRegex.test(value);
		})
		.test("isPasswordShort", "form.validation.password.min", (value) => {
			if (!value) {
				return false;
			}
			return value.length >= 7;
		}),

	password_confirmation: string()
		.label("Confirm Password")
		.oneOf([ref("password")], "form.validation.password_confirmation.invalid")
		.required("form.validation.password_confirmation.required"),
});

export const ResetPasswordFormResolver = yupResolver(
	ResetPasswordFormValidation,
);
