import { IonSpinner } from "@ionic/react";
import { FunctionComponent, ReactElement } from "react";
import { ButtonHTMLAttributes } from "react";
import Icon from "../Icons/Icon";
import SvgIcon from "../Icons/SvgIcon";
import { LinkData } from "../Link/Link";
import {
	BasicButton,
	BasicButtonLink,
	IconOnlyButton,
	StyledButton,
	StyledButtonLink,
	StyledExternalLink,
} from "./Button.Styled";

export interface ButtonProps extends ButtonHTMLAttributes<{}> {
	variant?: "primary_01" | "primary_02" | "primary_03" | "basic";
	textLight?: boolean;
	fullWidth?: boolean;
	spaced?: boolean;
	margin?: {
		mt?: string;
		mb?: string;
		ml?: string;
		mr?: string;
	};
	onClick?: () => void;
	icon?: ReactElement<typeof SvgIcon | typeof Icon>;
	children?: any;
	className?: string;
	link?: LinkData;
	externalLink?: string;
	loading?: boolean;
	active?: boolean;
}

const Button: FunctionComponent<ButtonProps & ButtonHTMLAttributes<ButtonProps>> = ({
	variant = "basic",
	textLight = false,
	fullWidth = false,
	spaced = false,
	margin = {},
	onClick,
	icon,
	children,
	className = "",
	type = "button",
	link,
	externalLink,
	loading,
	active,
	...props
}) => {
	const iconOnly = icon && !children;

	const styledProps = {
		basic: variant === "basic",
		alternative: variant !== "primary_01",
		outlined: variant === "primary_03",
		spaced,
		margin,
		fullWidth,
		textLight,
		active: active || false,
	};

	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};

	if (iconOnly) {
		return (
			<IconOnlyButton
				{...props}
				type={type}
				className={`btn btn-icon-only ${className}`}
				{...styledProps}
				onClick={handleOnClick}
			>
				{icon}
			</IconOnlyButton>
		);
	}

	if (externalLink) {
		return (
			<StyledExternalLink
				{...props}
				href={externalLink}
				className={`btn btn-link ${className}`}
			>
				{children}
			</StyledExternalLink>
		);
	}

	if (link) {
		if (variant !== "basic") {
			return (
				<StyledButtonLink
					{...props}
					className={`btn btn-link ${className}`}
					{...link}
					{...styledProps}
					onClick={handleOnClick}
				>
					{icon} {children}
				</StyledButtonLink>
			);
		}

		return (
			<BasicButtonLink
				{...props}
				className={`btn btn-link ${className}`}
				{...link}
				{...styledProps}
			>
				{icon} {children}
			</BasicButtonLink>
		);
	}

	if (variant === "basic") {
		return (
			<BasicButton
				{...props}
				type={type}
				className={`btn ${className}`}
				{...styledProps}
				onClick={handleOnClick}
			>
				{icon} {children}
			</BasicButton>
		);
	}

	return (
		<StyledButton
			{...props}
			type={type}
			className={`btn ${className} ${loading ? "loading" : ""} ${
				active ? "active" : ""
			}`}
			{...styledProps}
			onClick={handleOnClick}
		>
			<IonSpinner name="dots" />
			<>
				{icon} {children}
			</>
		</StyledButton>
	);
};

export default Button;
