import { Link } from "react-router-dom";
import styled from "styled-components";
import { dark, darkGrey } from "utilities/variables/colors";

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${dark};

	&:hover,
	&:active {
		color: ${darkGrey};
	}
`;

export const StyledA = styled.a`
	text-decoration: none;
	color: ${dark};

	&:hover,
	&:active {
		color: ${darkGrey};
	}
`;
