/**
 * Translate the given key if it exists in the translation files, otherwise return the key as is.
 * @param translationKey The key to translate
 * @param t The translation function
 * @param i18n The i18n object
 * @returns The translated key if it exists, otherwise the key as is
 */
const translateIfPossible = (translationKey: string, t: any, i18n: any) => {
	const lowercasedTranslationKey = translationKey.toLowerCase();
	const uppercasedTranslationKey = translationKey.toUpperCase();

	const toReturn = i18n.exists(translationKey)
		? t(translationKey)
		: i18n.exists(lowercasedTranslationKey)
		? t(lowercasedTranslationKey)
		: i18n.exists(uppercasedTranslationKey)
		? t(uppercasedTranslationKey)
		: translationKey;

	return toReturn;
};

export default translateIfPossible;
