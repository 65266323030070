import React, {useEffect} from "react";
import { useOnClickOutside } from "usehooks-ts";

import Button from "components/common/ui/Button/Button";
import { NotificationDropdownContainer } from "./NotificationModal.Styled";
import IconBell from "components/common/ui/Icons/IconBell/IconBell";
import { useMatch } from "react-router";




const NotificationModal = () => {
  const pathMatch = useMatch("/:locale/*");
  const [isOpen, setIsOpen] = React.useState(false);
  const [formId, setFormId] = React.useState('');
  const [apiKey, setApiKey] = React.useState('');

  useEffect(() => {
    if (!pathMatch?.params.locale) {
      return;
    }
    const { REACT_APP_KLAVYIO_FORM_ID: IDS = "{}" } = process.env;
    setFormId(
      JSON.parse(IDS)[pathMatch?.params.locale].formIds.priceChange,
    );
    setApiKey( JSON.parse(IDS)[pathMatch?.params.locale].apiKey);
  }, [pathMatch]);


  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`;
    document.body.appendChild(script);
  },
  []);

    const handleFormTrigger = () => {
      setIsOpen((prev) => !prev);
    }

    const menuRef = React.useRef(null);
	  useOnClickOutside(menuRef, () => setIsOpen(false));

  return (
    <NotificationDropdownContainer className="notification">
        <Button icon={<IconBell />} onClick={handleFormTrigger} />
        <div className="notification-menu" style={{ display: isOpen ? 'block' : 'none' }} ref={menuRef}>
          <div className={`klaviyo-form-${formId}`}></div>
        </div>
    </NotificationDropdownContainer>
  );
};

export default NotificationModal;
