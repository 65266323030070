import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Eye } from "./visible.svg";

interface Props {
	className?: string;
}

const IconEye: FunctionComponent<Props> = ({ className = "" }) => {
	return <SvgIcon className={className} svg={<Eye />} />;
};

export default IconEye;
