import React, { Fragment, useEffect, useState } from "react";

import { useLocation, useParams } from "react-router";

import wishlistApi from "app/api/customer/wishlistApi";

import { setAccountActiveLink } from "redux/UI/uiSlice";
import { useDispatch } from "redux/hooks";

import WishlistForm from "./WishlistForm/WishlistForm";

import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Wishlist } from "../AccountWishlistOverview/AccountWishlistOverview";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import { Container } from "./WishlistCreateEdit";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

const EditWishlist = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { state } = useLocation();
	const navigate = useLocaleNavigate();
	const { id } = useParams();

	const [getWishlist, getWishlistResult] =
		wishlistApi.useLazyGetWishlistQuery();

	const [wishlist, setWishlist] = useState<Wishlist>(
		state?.wishlist || undefined,
	);
	const [loading, setLoading] = useState(wishlist === undefined);

	useEffect(() => {
		if (!wishlist) {
			setLoading(true);
			getWishlist(id);
		}
	}, []);

	useEffect(() => {
		dispatch(setAccountActiveLink("Wishlists"));
	}, [dispatch]);

	useEffect(() => {
		const { isUninitialized, isLoading, data } = getWishlistResult;

		if (!wishlist && !isUninitialized) {
			if (!isLoading) {
				if (data) {
					setWishlist(data.data);
				} else {
					navigate(t(PathTranslationKey.ACCOUNT_WISHLIST).toString());
				}
			}
		}
	}, [getWishlistResult]);

	useEffect(() => {
		if(wishlist && loading) {
			setLoading(false);
		}
	}, [wishlist])

	return (
		<Fragment>
			{loading && <LoadingSpinner />}

			{!loading && (
				<Container>
					<WishlistForm wishlist={wishlist} />
				</Container>
			)}
		</Fragment>
	);
};

export default EditWishlist;
