import React, { Fragment, useMemo } from "react";
import { useSelector } from "redux/hooks";
import { Currency } from "types/BigCommerce";

export const FormatPriceToCurrency = (price: number, currency: Currency) => {
	if (!currency) return "";

	const {
		symbol,
		symbolPlacement,
		decimalToken,
		thousandsToken,
		decimalPlaces,
	} = currency.display;

	const formattedPrice = price.toFixed(decimalPlaces);
	const [integerPart, decimalPart] = formattedPrice.split(".");
	const integerPartWithThousandsToken = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		thousandsToken,
	);
	const formattedDecimalPart = decimalPart
		? `${decimalToken}${decimalPart}`
		: "";

	const nextFormattedPrice =
		symbolPlacement === "LEFT"
			? `${symbol} ${integerPartWithThousandsToken}${formattedDecimalPart}`
			: `${integerPartWithThousandsToken}${formattedDecimalPart} ${symbol}`;

	return nextFormattedPrice;
};

const Price = ({ price }: { price: number }) => {
	const { currencies } = useSelector((state) => state.bcStore);

	const activeCurrency = useMemo(() => {
		return currencies.find((currency) => currency.isActive) as Currency;
	}, [currencies]);

	const formattedPrice = React.useMemo(() => {
		return FormatPriceToCurrency(price, activeCurrency);
	}, [activeCurrency, price]);

	return <>{formattedPrice}</>;
};

export default Price;
