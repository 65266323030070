import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { setIdentifier } from "redux/auth/authSlice";
import { useDispatch, useSelector } from "redux/hooks";
import { COOKIE_CUSTOMER_IDENTIFIER } from "utilities/consts";
import PathTranslationKey from "utilities/paths";

export type CustomerIdentifierType = {
	storeHash: string;
	token: string;
};

const useLogin = () => {
	const { t } = useTranslation();
	const { store } = useSelector((state) => state.bcStore);

	const dispatch = useDispatch();
	const navigate = useLocaleNavigate();

	const doLogin = async (data: any) => {
		const host = window.location.hostname.startsWith(".") ? window.location.hostname : `.${window.location.hostname}`;
		const isSecure = window.location.protocol.startsWith("https");

		const cookie = data.cookie;

		const expiresDate = new Date(cookie.expires * 1000); //covert from s to ms

		//SHOP_TOKEN
		Cookies.set(cookie.name, cookie.value, {
			expires: expiresDate,
			secure: isSecure,
			sameSite: "Lax",
			domain: host
		});

		const customerIdentifier = {
			storeHash: store?.hash,
			token: data.customer.identifier,
		} as CustomerIdentifierType;

		Cookies.set(
			COOKIE_CUSTOMER_IDENTIFIER,
			JSON.stringify(customerIdentifier),
			{
				expires: expiresDate,
				secure: isSecure,
				sameSite: "Lax",
				domain: host
			},
		);

		dispatch(setIdentifier(customerIdentifier));

		navigate(t(PathTranslationKey.ACCOUNT_OVERVIEW).toString());
	};

	return (data: any) => {
		doLogin(data);
	};
};

export default useLogin;
