import styled from "styled-components";

import BaseForm from "components/common/form/Form/Form";

import { remCalc } from "utilities/styles";
import { Roboto } from "utilities/variables/fonts";
import { accountDarkGrey, dark, red } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const AddressFormContainer = styled(BaseForm)`
	margin: ${remCalc(32)} 0 0;
	display: flex;
	flex-direction: column;

	.error-message {
		margin: ${remCalc(24)} 0 0;
		color: ${red};
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	}

	.form__section {
		margin-top: ${remCalc(32)};
		display: flex;
		flex-direction: column;
		gap: ${remCalc(24)};
	}

	.form__heading {
		margin: 0;
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
	}

	.form__fields {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(25)};
	}

	.form__row {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		& > * {
			flex: 1;

			@media ${aboveMedium} {
				max-width: calc(50% - ${remCalc(16)});
			}
		}

		@media ${aboveMedium} {
			flex-direction: row;
			gap: ${remCalc(32)};
		}
	}

	.form__field-label {
		margin: 0;
		display: flex;
		align-items: center;
		gap: ${remCalc(4)};

		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};

		.hint {
			color: ${accountDarkGrey};
		}
	}

	.form__buttons {
		margin-top: ${remCalc(25)};
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};

		@media ${aboveMedium} {
			flex-direction: row;
		}

		& > * {
			width: 100%;

			@media ${aboveMedium} {
				width: auto;
			}
		}
	}
`;
