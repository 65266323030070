import { gql } from "graphql-request";

const wordPressGetLoyaltyCardThresholds = () => ({
	query: gql`
		query GetAboutUsPage {
			storeGeneralSettings {
				globalStoreSettings {
					loyalty {
						tier {
							aswId
							breakAmount
							displayName
                            discountPercentage
						}
					}
				}
			}
		}
	`,
});

export default wordPressGetLoyaltyCardThresholds;
