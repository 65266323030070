import useOutsideClick from "hooks/useOutsideClick";
import { Fragment, FunctionComponent, ReactNode } from "react";
import { Portal } from "react-portal";
import CloseButton from "../Button/CloseButton";
import Overlay from "../Overlay/Overlay";

import { ModalBody, ModalContainer, ModalHeader } from "./Modal.Styled";

export type ModalOptions = {
	portalId?: string;
	overlay?: boolean;
	showCloseButton?: boolean;
	closeOnOverlayClick?: boolean;
	closeOnOutsideClick?: boolean;
};
interface ModalProps {
	id?: string;
	title?: ReactNode;
	children: ReactNode;
	onClose?: () => void;
	options?: ModalOptions;
	additionalStyles?: {
		container?: any;
		overlay?: any;
	};
	ref?: HTMLElement;
	className?: string;
}

const Modal: FunctionComponent<ModalProps> = ({
	id,
	title,
	children,
	onClose = () => {},
	options = {
		portalId: "",
		overlay: false,
		showCloseButton: false,
		closeOnOverlayClick: false,
		closeOnOutsideClick: false,
	},
	additionalStyles = {
		container: "",
		overlay: "",
	},
	className = "",
}) => {
	const onOverlayClick = () => {
		if (options.closeOnOverlayClick) {
			onClose();
		}
	};

	const onOutsideClick = () => {
		if (options.closeOnOutsideClick) {
			onClose();
		}
	};

	const ref = useOutsideClick(onOutsideClick);

	const modal = (
		<Fragment>
			{options.overlay && (
				<Overlay
					onClick={onOverlayClick}
					show
					styles={additionalStyles.overlay}
				/>
			)}
			<ModalContainer
				id={id}
				className={`Modal ${className}`}
				ref={ref}
				styles={additionalStyles.container}
			>
				{options.showCloseButton && (
					<CloseButton onClick={onClose} className="btn_modal-close" />
				)}
				{title && <ModalHeader>{title}</ModalHeader>}
				<ModalBody className="ModalBody">{children}</ModalBody>
			</ModalContainer>
		</Fragment>
	);

	if (options.portalId?.toString()) {
		return (
			<Portal node={document && document.getElementById(options.portalId)}>
				{modal}
			</Portal>
		);
	} else {
		return modal;
	}
};

export default Modal;
