import React from "react";

import Typography from "components/common/ui/Typography/Typography";

import ErrorMessage from "../Input/InputError/InputError";

import {
	StyledColumn,
	StyledInput,
	StyledRequiredIndicator,
} from "./TextArea.Styled";

type TextAreaProps = {
	className?: string;
	label?: string;
	required?: boolean;
	rows?: number;
	cols?: number;
	errorMsg?: string;
	onChange?: (v: any) => void;
	isError?: boolean;
	defaultValue?: string;
	value?: string;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			className,
			label,
			required,
			rows,
			cols,
			errorMsg,
			...props
		}: TextAreaProps,
		ref,
	) => {
		return (
			<StyledColumn className={className}>
				<Typography>
					{label}
					{required && <StyledRequiredIndicator> *</StyledRequiredIndicator>}
				</Typography>
				<StyledInput ref={ref} rows={rows} cols={cols} {...props} />
				<ErrorMessage message={errorMsg} />
			</StyledColumn>
		);
	},
);

export default TextArea;
