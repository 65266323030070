import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";

import {
	RenderedContent,
	StyledCApplicationFormPageWrapper,
} from "./ApplicationForm.Styled";

import { useLazyGetCompanyPageQuery } from "app/api/company/CompanyPageApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import ApplicationForm from "components/GravityForm/ApplicationForm/ApplicationForm";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import SEO from "components/common/ui/SEO/SEO";
import { sanitize } from "dompurify";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import getRobotsContent from "utilities/getRobotsContent";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "./CompanyPages.Styled";

const ApplicationFormPage = () => {
	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);
	const currentOpportunities = wordpressTemplates.find(
		(template) =>
			template.template === WordpressTemplateEnum.CurrentOpportunities,
	);

	const [isLoading, setIsLoading] = useState(true);

	const [getPageContentTrigger, getPageContentResult] =
		wordPressApi.useLazyGetPageContentByUriQuery();

	const [
		getCompanyPage,
		{ data: careersPageData, isLoading: careersDataLoading },
	] = useLazyGetCompanyPageQuery();

	useEffect(() => {
		const applicationPage = wordpressTemplates.find(
			(template) =>
				template.template === WordpressTemplateEnum.JobApplicationPage,
		);

		if (applicationPage) {
			getPageContentTrigger(applicationPage.uri, true);
		}

		const careersPage = wordpressTemplates.find(
			(template) => template.template === WordpressTemplateEnum.Careers,
		);

		if (careersPage) {
			getCompanyPage({
				pageId: careersPage.id,
				pageType: "careers",
			});
		}
	}, []);

	useEffect(() => {
		const loading = getPageContentResult.isLoading && careersDataLoading;

		if (isLoading !== loading) {
			setIsLoading(loading);
		}
	}, [getPageContentResult.isLoading, careersDataLoading]);

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={getPageContentResult.data?.seo?.title}
				description={getPageContentResult.data?.seo?.metaDesc}
				keywords={getPageContentResult.data?.seo?.metaKeywords}
				robots={getRobotsContent([
					getPageContentResult.data?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledCApplicationFormPageWrapper className-="StyledCApplicationFormPageWrapper">
				<StyledMainImageBanner>
					<ImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage:
										getPageContentResult?.data?.application?.image?.sourceUrl ||
										careersPageData?.pageData?.featuredImage.node.sourceUrl,
									mobileImage:
										getPageContentResult?.data?.application?.image?.sourceUrl ||
										careersPageData?.pageData?.featuredImage.node.sourceUrl,
									alt: "appBanImg",
								},
								ctaLink: "",
								ctaText: "",
							},
						]}
						showButton={false}
						showText={true}
						imageText={getPageContentResult?.data?.application?.title || ""}
						isLoading={isLoading}
					/>
				</StyledMainImageBanner>

				<RenderedContent>
					<div
						className="htmlContent"
						dangerouslySetInnerHTML={{
							__html: sanitize(getPageContentResult.data?.content),
						}}
					/>
				</RenderedContent>

				<ApplicationForm />
				<StyledImageBanner
					orientation="landscape"
					images={[
						{
							image: {
								desktopImage:
									careersPageData?.pageData?.featuredImage.node.sourceUrl,
								mobileImage:
									careersPageData?.pageData?.featuredImage.node.sourceUrl,
								alt: careersPageData?.pageData?.featuredImage.node.altText,
							},
							ctaLink: currentOpportunities?.uri || "",
							ctaText: currentOpportunities?.title || "",
						},
					]}
					showButton={true}
					showText={true}
					imageText={currentOpportunities?.title || ""}
					isLoading={isLoading}
				/>
			</StyledCApplicationFormPageWrapper>
		</Page>
	);
};

export default ApplicationFormPage;
