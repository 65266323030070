import { gql } from "graphql-request";

export const wordPressGetPhoneNumber = {
	query: gql`
		query {
			storeGeneralSettings {
				globalStoreSettings {
					phoneNumber
				}
			}
		}
	`,
};

export const wordPressGetFooterContent = {
	query: gql`
		query {
			storeGeneralSettings {
				footer {
					disclaimer
					badges {
						altText
						id
						title
						sourceUrl
						description
					}
				}
			}
		}
	`,
};

export const wordPressGetStores = {
	query: gql`
		query {
			stores(first: 100, where: { status: PUBLISH }) {
				nodes {
					id
					storeInformation {
						aswId
						emailAddress
						address {
							addressLine1
							addressLine2
							city
							companyName
							country
							postCode
							state
						}
						phoneNumber
						storeLocation {
							googleMapsLink
							latitude
							longitude
						}
						storeOpeningTimes {
							monday {
								close
								closed
								open
							}
							tuesday {
								close
								closed
								open
							}
							wednesday {
								close
								closed
								open
							}
							thursday {
								close
								closed
								open
							}
							friday {
								close
								closed
								open
							}
							saturday {
								close
								closed
								open
							}
							sunday {
								close
								closed
								open
							}
						}
					}
					title
				}
			}
		}
	`,
};

export const wordPressGetStoreSettings = {
	query: gql`
		query {
			storeGeneralSettings {
				globalStoreSettings {
					accountcreationEmaillistId
					paymentMethodsIcons {
						id
						altText
						title
						sourceUrl
						description
					}
					signUpFormId
				}
			}
		}
	`,
};

export const wordPressGetKlaviyoData = {
	query: gql`
		query {
			storeGeneralSettings {
				globalStoreSettings {
					accountcreationEmaillistId
					signUpFormId
				}
			}
		}
	`,
};
