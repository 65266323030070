import React, { useEffect } from "react";

import InputRadioButton from "../InputRadioButton/InputRadioButton";

import {
	RadioButtonGroupStyles,
	RadioButtonGroupWrapper,
} from "./RadioButtonGroup.Styled";
import { useTranslation } from "react-i18next";

interface RadioButtonGroupProps {
	className?: string;
	values: string[];
	value?: string;
	onChange?: (value: string) => void;
	styles?: RadioButtonGroupStyles;
}

const RadioButtonGroup = React.forwardRef<
	HTMLDivElement,
	RadioButtonGroupProps
>(
	(
		{ className, values, value = undefined, onChange = () => {}, styles },
		ref,
	) => {
		const [checked, setChecked] = React.useState(value);

		useEffect(() => {
			setChecked(value);
		}, [value]);

		const handleOnChange = (value: any) => {
			if (checked !== value) {
				setChecked(value);

				if (onChange) {
					onChange(value);
				}
			}
		};

		const { t } = useTranslation();

		return (
			<RadioButtonGroupWrapper {...styles} className={className} ref={ref}>
				{values.map((value) => {
					return (
						<InputRadioButton
							key={value}
							label={t(value.toLowerCase()).toString()}
							value={value}
							checked={value === checked}
							onChange={handleOnChange}
						/>
					); 
				})}
			</RadioButtonGroupWrapper>
		);
	},
);

export default RadioButtonGroup;
