import { GoogleAutocompleteWindow, GoogleMapsSdk } from 'components/common/Google/GoogleAutocompleteTypes';

export default class GoogleApiScriptLoader {
    private _googleAutoComplete?: Promise<GoogleMapsSdk>;

    loadMapsSdk(apiKey: string): Promise<GoogleMapsSdk> {
        if (this._googleAutoComplete) {
            return this._googleAutoComplete;
        }

        this._googleAutoComplete = new Promise((resolve, reject) => {
            const callbackName = 'initAutoComplete';
            const params = [
                'language=en',
                `key=${apiKey}`,
                'libraries=places',
                `callback=${callbackName}`,
            ].join('&');

            (window as GoogleCallbackWindow)[callbackName] = () => {
                if (isAutocompleteWindow(window)) {
                    resolve(window.google.maps);
                }

                reject();
            };

            try {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?${params}`

                document.body.appendChild(script);
            } catch (e) {
                this._googleAutoComplete = undefined;
                throw e;
            }
        })

        return this._googleAutoComplete;
    }
}

function isAutocompleteWindow(window: Window): window is GoogleAutocompleteWindow {
    const autocompleteWindow = window as GoogleAutocompleteWindow;

    return Boolean(
        autocompleteWindow.google &&
            autocompleteWindow.google.maps &&
            autocompleteWindow.google.maps.places,
    );
}

export interface GoogleCallbackWindow extends Window {
    initAutoComplete?(): void;
}
