import InputCheckbox from 'components/common/form/InputCheckbox/InputCheckbox';
import React from 'react';
import { useDispatch, useSelector } from 'redux/hooks';

import {
	FieldValue,
	ConsentFieldValue,
	updateConsentFieldValue,
} from 'redux/gravityForm/gravityFormSlice';

import {
	FieldError,
	FormField,
	ConsentField as ConsentFieldType,
	ConsentInput,
} from 'types/GravityForm';

import { StyledFieldContainer } from '../GravityForm.Styled';

interface Props {
	field: FormField & Partial<ConsentFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: ConsentInput;
}

const ConsentField = ({ field, fieldErrors, defaultValue }: Props) => {
	const DEFAULT_VALUE = defaultValue?.value === "1" ? "1" : "0";

	const { id } = field;
	const dispatch = useDispatch();
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as ConsentFieldValue | undefined;

	const consentValues = fieldValue?.value || DEFAULT_VALUE;

	const updateValue = React.useCallback(
		(consentValues: string) => {
			dispatch(updateConsentFieldValue({ id, value: consentValues }));
		},
		[dispatch, id],
	);

	const handleChange = (value: boolean) => {
		updateValue(value ? "1" : "0");
	};

	React.useEffect(() => {
		updateValue(consentValues);
	}, [consentValues, updateValue]);

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			<InputCheckbox
				inputLabel={field.label}
				label={field.checkboxLabel}
				required={field.isRequired}
				isError={Boolean(fieldErrors.length)}
				errorMsg={fieldErrors[0]?.message}
				onChange={handleChange}
				value={consentValues === "1" ? true : false}
			/>
		</StyledFieldContainer>
	);
};

export default ConsentField;
