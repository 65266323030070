import { EvenContainer } from "../BlogContainer.styles";
import ImageItemCommon from "../ImageItemCommon/ImageItemCommonBase";
import { BotGridWrapper } from "./PostGroupBot.styles";

interface DataArray {
	data: any[];
}

export const PostGroupBot = ({ data }: DataArray) => {
	if (data.length < 1) {
		return <></>;
	}
	return (
		<EvenContainer bot={'0rem'}>
			<BotGridWrapper count={data.length}>
				<div className="grid-container">
					{data.slice(0,3).map((item, index) => {
						return (
							<div key={"botone-" + index} className={"item0"}>
								<ImageItemCommon data={item} />
							</div>
						);
					})}
				</div>
				<div className="grid-container-lower">
					{data.slice(3,6).map((item, index) => {
						return (
							<div key={"bottwo-" + index} className={"item0"}>
								<ImageItemCommon data={item} />
							</div>
						);
					})}
				</div>
			</BotGridWrapper>
		</EvenContainer>
	);
};
