import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";
import { useEffect, useState } from "react";
import { useSwiper } from "swiper/react";
import { StyledTimelineNavigationWrapper } from "./timeline.Styled";

type Props = {
	timelineYears: string[];
	swiperIndex: number;
};

const TimelineNavigation = ({ timelineYears, swiperIndex }: Props) => {
	const swiper = useSwiper();
	const [currentIndex, setCurrentndex] = useState(0);

	const transformWrapper = (1082 / (timelineYears.length + 3)) * swiperIndex;

	const dotElements = document.querySelectorAll(".timeline-dots");

	const pageWidth = window.innerWidth;

	useEffect(() => {
		/* Update index when Swiper is 'dragged'.
		Passed in swiperIndex only logs index upto 10 which overrides the dot styling when you click on slide 10 onwards,
		this prevents this */
		if (pageWidth > 881 && swiperIndex >= 10) {
			return;
		}
		setCurrentndex(swiperIndex);
	}, [swiperIndex, pageWidth]);

	const handleDotClick = (e: any, index: number) => {
		setCurrentndex(index);
		swiper.slideTo(index);
	};

	const handleArrowClick = (direction: string) => {
		if (direction === "left") {
			setCurrentndex(currentIndex - 1);
			swiper.slideTo(currentIndex - 1);
			return;
		}
		if (currentIndex === timelineYears.length - 1) return;

		setCurrentndex(currentIndex + 1);
		swiper.slideTo(currentIndex + 1);
	};

	/* Add styling to dots on change of slide index */
	useEffect(() => {
		dotElements?.forEach((dot, index) => {
			dot.classList.remove("current");

			if (index === currentIndex) {
				dot.classList.add("current");
			}
		});
	}, [currentIndex, dotElements]);

	return (
		<StyledTimelineNavigationWrapper
			className="TimelineNavigationWrapper"
			transformWrapper={transformWrapper}
		>
			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="left" />}
				onClick={() => handleArrowClick("left")}
				className={`${swiperIndex === 0 ? "disable-button" : ""}`}
			/>
			<ul>
				{timelineYears.map((year, index) => (
					<li key={`${year}-${index}`}>
						{year}
						<span
							className={`timeline-dots ${index === 0 && "current"}`}
							onClick={(e) => handleDotClick(e, index)}
						></span>
					</li>
				))}
			</ul>
			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="right" />}
				onClick={() => handleArrowClick("right")}
				className={`${
					currentIndex === timelineYears.length - 1 ? "disable-button" : ""
				}`}
			/>
		</StyledTimelineNavigationWrapper>
	);
};

export default TimelineNavigation;
