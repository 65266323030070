import styled, { css } from "styled-components";
import { dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { remCalc } from "../../../../utilities/styles";

const baseHeading = css`
  font-family: ${Roboto};
  color: ${dark};
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`;

export const HeadingMediumStyles = css`
  ${baseHeading}
  font-size: 24px;
  letter-spacing: 1.2px;
`;

export const HeadingLarge = styled.h1`
  ${baseHeading}
  font-size: 32px;
  letter-spacing: 1.6px;
`;

export const HeadingMedium = styled.h2`
  ${HeadingMediumStyles}
`;

export const HeadingSmall = styled.h3<{
  narrow?: boolean;
}>`
  ${baseHeading}
  font-size: 20px;
  letter-spacing: ${(props) => (!props.narrow ? "1.2px" : "1px")};
`;

export const ExtraSmall = styled.h3<{
  narrow?: boolean;
}>`
  ${baseHeading};
  font-size: ${remCalc(14)};
  letter-spacing: ${(props) => (!props.narrow ? "1.2px" : "1px")};
`;

export const Body = styled.p<{
  small?: boolean;
  dangerouslySetInnerHTML?: any;
}>`
  padding: 0;
  margin: 0;
  font-family: ${Roboto};
  color: ${dark};
  font-size: ${(props) => (props.small ? "14px" : "16px")};
`;

export const RichBody = styled.article<{
  small?: boolean;
}>`
  padding: 0;
  margin: 0 0 ${remCalc(16)};
  font-family: ${Roboto};
  color: ${dark};
  font-size: ${(props) => (props.small ? "14px" : "16px")};
`;
