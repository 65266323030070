import ButtonWithIcon from "../Button/ButtonWithIcon/ButtonWithIcon";

import {
	StyledCarouselNavigationWrapper,
	StyledPaginationWrapper,
} from "./controls.Styled";

import "swiper/css/navigation";
import "swiper/css/pagination";
import IconChevron from "../Icons/IconChevron/IconChevron";

type Props = {
	hasText?: boolean;
	previousText?: string;
	nextText?: string;
	compact?: boolean;
	onNavigationClick?: any;
};

const CarouselControls = ({
	hasText = false,
	previousText = "",
	nextText = "",
	compact = false,
	onNavigationClick = () => {},
}: Props) => {
	return (
		<StyledCarouselNavigationWrapper compact={compact}>
			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="left" />}
				position="before"
				className="carousel-navigation carousel-navigation--prev"
				onClick={() => onNavigationClick("prev")}
			>
				{hasText ? previousText : ""}
			</ButtonWithIcon>

			<StyledPaginationWrapper className="StyledPaginationWrapper" />

			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="right" />}
				position="after"
				className="carousel-navigation carousel-navigation--next"
				onClick={() => onNavigationClick("next")}
			>
				{hasText ? nextText : ""}
			</ButtonWithIcon>
		</StyledCarouselNavigationWrapper>
	);
};

export default CarouselControls;
