import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";
import useOutsideClick from "hooks/useOutsideClick";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { setCartError } from "redux/cart/cartSlice";
import { useDispatch, useSelector } from "redux/hooks";
import {
	ButtonContainer,
	StyledContainer,
	StyledContent,
	StyledOverlay,
	StyledWrapper,
} from "./CartErrorModal.Styled";

const CartErrorModal: FunctionComponent = () => {
	const { cartError } = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleClose = () => {
		dispatch(setCartError(undefined));
	};

	const ref = useOutsideClick(handleClose);

	const message = useMemo(() => {
		if (!cartError) {
			return undefined;
		}

		return cartError.translationKey
			? t(cartError.translationKey)
			: cartError.message;
	}, [cartError, t]);

	if (!cartError) {
		return null;
	}

	return (
		<Portal node={document && document.getElementById("portal__modal")}>
			<StyledWrapper className="modal__wrapper">
				<StyledOverlay className="modal__overlay">
					<StyledContainer className="modal__container" ref={ref}>
						<StyledContent className="modal__content">
							{message && <Typography variant="body">{message}</Typography>}
							<ButtonContainer>
								<Button variant="primary_03" onClick={handleClose}>
									{t("close")}
								</Button>
							</ButtonContainer>
						</StyledContent>
					</StyledContainer>
				</StyledOverlay>
			</StyledWrapper>
		</Portal>
	);
};

export default CartErrorModal;
