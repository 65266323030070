import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { dark, darkGrey, lightGrey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
`;

export const StyledItem = styled.div<{ $isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	padding-bottom: ${(props) => (props.$isOpen ? remCalc(16) : "0")};
	border-bottom: 1px solid ${lightGrey};
	overflow: hidden;

	i {
		font-size: ${remCalc(32)};
	}

	.accordion__title {
		padding: ${(props) =>
			props.$isOpen ? `${remCalc(16)} 0 ${remCalc(12)}` : `${remCalc(16)} 0`};

		@media ${aboveMedium} {
			padding: ${(props) =>
				props.$isOpen ? `${remCalc(18)} 0 ${remCalc(14)}` : `${remCalc(18)} 0`};
		}
	}
`;

export const StyledButton = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${remCalc(8)};
	text-align: left;
	font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	color: ${dark};
	text-transform: none;
	background-color: ${white};

	&:hover {
		* {
			color: ${darkGrey};
		}
	}
`;
