enum PathTranslationKey {
    BRAND_WOMEN = "women",
    BRAND_MEN = "men",
    REGISTRATION = 'url.registration',
    SIGN_IN = 'url.signIn',
    SIGN_OUT = 'url.signOut',
    FORGOT_PASSWORD = 'url.forgotPassword',
    ACCOUNT_OVERVIEW = 'url.account.overview',
    ACCOUNT_WISHLIST = 'url.account.wishlists',
    ACCOUNT_WISHLIST_NEW = 'url.account.wishlistsNew',
    ACCOUNT_WISHLIST_EDIT = 'url.account.wishlistsEdit',
    ACCOUNT_ORDERS = 'url.account.orders',
    ACCOUNT_ADDRESSES = 'url.account.addresses',
    ACCOUNT_ADDRESSES_NEW = 'url.account.addressesNew',
    ACCOUNT_ADDRESSES_EDIT = 'url.account.addressesEdit',
    ACCOUNT_LOYALTY = 'url.account.loyalty',
    ACCOUNT_PROFILE = 'url.account.profile',
    CART = 'url.cart',
    CHECKOUT = 'url.checkout',
    ORDER_CONFIRMATION = 'url.orderConfirmation',
    SHARED_WISHLIST = "url.sharedWishlist",
    SEARCH = "url.search",
    CATEGORY = "url.category",
    PRODUCT = "url.product",
    PRIVACY_POLICY = 'url.page.privacyPolicy',
    LOYALTY_INFORMATION_PAGE = "url.page.loyaltyProgram",
    SALE_CATEGORY = "url.category.sale",

    APPLICATION_FORM = 'application-form',
    AWARDS = 'awards',
    CAREERS = 'careers',
    HRADVICE = 'hr-advice',
    HRADVICEDETAIL = 'hr-advice/:hrDetailName',
    LOYALTY = 'loyalty-program',
    ABOUT_US = 'about',
    JOB_DESCRIPTION = 'current-opportunities/:jobId',
    COLLEAGUES = 'colleagues/:colleagueName',
    SOCIALRESPONSIBILITY = 'social-responsibility',
    SOCIALRESPONSIBILITYDETAIL = 'social-responsibility/:responsibilityName',
}

export default PathTranslationKey;
