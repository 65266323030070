import GoogleApiScriptLoader from "./GoogleApiScriptLoader";

let instance: GoogleApiScriptLoader;

export default function getGoogleApiScriptLoader(): GoogleApiScriptLoader {
    if (!instance) {
        instance = new GoogleApiScriptLoader();
    }

    return instance;
}
