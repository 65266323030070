import { ReactComponent as Addresses } from "assets/images/svg/Overview - Addresses.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconAddresses({className}: Props): ReactElement {
    return <Icon className={className} icon={<Addresses />} />;
}

export default IconAddresses;