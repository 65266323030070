import { IonAccordion, IonLabel } from "@ionic/react";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledAccordion = styled(IonAccordion)`
	margin-top: 32px;
	background-color: #fff;
	--ion-item-background: #fff;
	--ion-item-color: ${dark};
	--ion-font-family: ${Roboto};

	ion-item {
		--padding-end: 0px;
		--inner-padding-end: 0px;
		--padding-start: 0px;
		--inner-padding-start: 0px;
		--min-height: 0px;
    --ripple-color: transparent;

		&:hover {
			--background-hover: #fff;
			--ion-item-color: ${darkGrey};

			ion-icon {
				color: ${darkGrey};
				margin: 0;
			}
		}
	}

	ion-label {
		margin: 8px 0;
	}

	ion-icon {
		color: ${dark};
		margin: 0;
	}

	div[slot="content"] {
		padding-top: 8px;
    margin-bottom: 16px;
	}
`;

export const StyledLabel = styled(IonLabel)`
	font: bold 16px/20px ${Roboto};
	color: ${dark};
	text-transform: uppercase;
`;

export const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const StyledUl = styled.ul`
	margin: 0;
	padding: 0;
	padding-left: ${remCalc(18)};
	display: flex;
	flex-direction: column;
	gap: 9px;
`;

export const StyledLi = styled.li`
	p {
		font: normal 14px/17px ${Roboto};
		color: ${dark};
	}

	span.label {
		font-weight: bold;
	}
`;
