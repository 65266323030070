import styled from "styled-components";
import { green, red } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { AlertType } from "./Alert";

const bgRed = "#f2c9c8";
const bgGreen = "#e8faeb";

export const AlertWrapper = styled.div<{
  alertType: AlertType;
}>`
  padding: 10px;
  background-color: ${(props) => props.alertType === AlertType.ERROR ? bgRed : bgGreen};
  border: 1px solid ${(props) => props.alertType === AlertType.ERROR ? red : green};
  font-family: ${Roboto};
  margin-top: 24px;

  color: ${(props) => props.alertType === AlertType.ERROR ? red : green};

  h3 {
    font-size: 14px;
  }

  h3, p {
    color: ${(props) => props.alertType === AlertType.ERROR ? red : green};
  }
`;