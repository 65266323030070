import { useState } from "react";
import LanguageSwitcherModal from "./LanguageSwitcherModal";
import IconLanguage from "components/common/ui/Icons/IconLanguage";
import { LocationButton } from "./Styled";
import { WrapperUl } from "../Header.Styled";
import Divider from "components/common/ui/List/Divider/Divider";

import { useSelector } from "redux/hooks";
import useBreakpoints from "hooks/useBreakpoints";

const LanguageSwitcher = () => {
	const { medium } = useBreakpoints();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const bcStore = useSelector((state) => state.bcStore.store);
	const { ui } = useSelector((state) => state);

	const phone = <a href={`tel:${ui.phoneNumber}`}>{ui.phoneNumber}</a>;

	return (
		<>
			<WrapperUl>
				{medium && (
					<>
						<li>{phone}</li>
						<Divider />
					</>
				)}
				<li>
					<LocationButton onClick={() => setIsModalOpen(!isModalOpen)}>
						<IconLanguage variant={"gray"} />
						<span>{bcStore?.name}</span>
					</LocationButton>
				</li>
			</WrapperUl>

			{isModalOpen && (
				<LanguageSwitcherModal
					storeCode={bcStore?.code}
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
				/>
			)}
		</>
	);
};

export default LanguageSwitcher;
