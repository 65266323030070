import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";
import { menuZIndex } from "utilities/variables/zIndex";

export const StyledMapContainer = styled.div`
	height: 360px;

	@media ${aboveMedium} {
		height: 380px;
	}
`;

export const MarkerContainer = styled.div<{ isTooltipOpen?: boolean }>`
	position: relative;

	.tooltip__container {
		cursor: default;
		display: ${({ isTooltipOpen }) => (isTooltipOpen ? "block" : "none")};
		z-index: ${menuZIndex};
		position: absolute;
		box-shadow: 0px 10px 18px #21252940;
	}

	&:hover {
		.tooltip__container {
			display: block;
		}
	}
`;

export const MonaMarker = styled.div<{ baseSize: number }>`
	position: relative;

	.logo {
		position: absolute;
		width: ${({ baseSize }) => `${remCalc(baseSize)}`};
		height: ${({ baseSize }) => `${remCalc(baseSize)}`};
		transform: ${({ baseSize }) =>
			`translate(-${remCalc(baseSize / 2)}, -${remCalc(baseSize / 2)})`};
		transition: all 0.2s;
	}

	.circle {
		position: absolute;
		border-radius: 50%;
		transition: all 0.2s;

		&--inner {
			background-color: white;
			width: ${({ baseSize }) => `${remCalc(baseSize * 0.76)}`};
			height: ${({ baseSize }) => `${remCalc(baseSize * 0.76)}`};
			transform: ${({ baseSize }) =>
				`translate(-${remCalc((baseSize * 0.76) / 2)}, -${remCalc(
					(baseSize * 0.76) / 2,
				)})`};
		}

		&--middle {
			border: 1px solid white;
			background-color: rgba(189, 195, 199, 0.1);
			width: ${({ baseSize }) => `${remCalc(baseSize * 1.24)}`};
			height: ${({ baseSize }) => `${remCalc(baseSize * 1.24)}`};
			transform: ${({ baseSize }) =>
				`translate(-${remCalc((baseSize * 1.24) / 2)}, -${remCalc(
					(baseSize * 1.24) / 2,
				)})`};
		}

		&--outer {
			border: 1px solid white;
			background-color: rgba(189, 195, 199, 0.3);
			width: ${({ baseSize }) => `${remCalc(baseSize * 1.6)}`};
			height: ${({ baseSize }) => `${remCalc(baseSize * 1.6)}`};
			transform: ${({ baseSize }) =>
				`translate(-${remCalc((baseSize * 1.6) / 2)}, -${remCalc(
					(baseSize * 1.6) / 2,
				)})`};
		}
	}
`;
