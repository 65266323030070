import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const AccountWishlistsContainer = styled.div`
	max-width: ${remCalc(1028)};
	padding: 0 ${remCalc(20)};
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: ${remCalc(32)};

	.account-wishlists__buttons {
		display: flex;
		justify-content: center;
		gap: ${remCalc(16)};

		& > * {
			flex: 1;

			@media ${aboveMedium} {
				flex: none;
			}
		}
	}
`;
