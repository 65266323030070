import styled, { css } from "styled-components";
import { white } from "utilities/variables/colors";
import { aboveSmall } from "utilities/variables/media";
import { menuZIndex } from "utilities/variables/zIndex";
import { motion } from "framer-motion";

export const ViewWrapper = styled.div<{ isOpen: boolean }>`
	display: ${(props) => !props.isOpen && "none"};
	position: absolute;
	height: 100vh;
	width: 100vw;
`;

export interface StyledAsideProps {
	position: "left" | "right";
}

export const StyledAside = styled(motion.aside)<StyledAsideProps>`
  position: absolute;
  background-color: ${white};
  height: 100%;
  width: 95%;
  max-width: 95%;
  z-index: ${menuZIndex};
  padding-top: env(safe-area-inset-top, 0);

  ${({ position }) =>
		position === "left" ? contentWrapperLeft : contentWrapperRight}

  display: flex;
  flex-direction: column;


  @media ${aboveSmall} {
    width: 518px;
    max-width: 518px;


  }
`;

const contentWrapperLeft = css`
	left: 0;
	.btn-close {
		position: absolute;
		top: calc(25px + env(safe-area-inset-top, 0));
		left: 20px;
	}
`;

const contentWrapperRight = css`
	right: 0;
	.btn-close {
		position: absolute;
		top: calc(25px + env(safe-area-inset-top, 0));
		right: 25px;
	}
`;

export const AsideBody = styled.div`
	flex: 1;
	overflow-y: auto;
`;
