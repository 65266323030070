import Link from 'components/common/ui/Link/Link';
import Skeleton from 'react-loading-skeleton';
import { StyledImageTextGrid } from './ImageTextGrid.Styled';

type Props = {
	image: {
		desktopImage: {
			altText?: string;
			sourceUrl: string;
		};
		mobileImage?: {
			altText?: string;
			sourceUrl: string;
		};
	};
	urlLink?: string;
	text: string;
	title: string;
	isLoading: boolean;
};

const ImageTextGrid = ({
	image,
	urlLink = '',
	text,
	title,
	isLoading,
}: Props) => {
	return (
		<StyledImageTextGrid className='StyledImageTextGrid'>
			{isLoading ? (
				<Skeleton width='100%' />
			) : (
				<div className="image">
				<Link to={urlLink !== '' ? urlLink : ''} >
					<picture className='clip'>
						<source
							media='(max-width: 551px)'
							srcSet={image?.mobileImage?.sourceUrl}
						/>
						<img
							src={image?.desktopImage?.sourceUrl}
							alt={image?.desktopImage?.altText}
						/>
					</picture>
				</Link>
				</div>
			)}
			<div className='text-container'>
				<h3>{title || <Skeleton height='20px' width='100%' />}</h3>
				<p>{text || <Skeleton height='60px' width='100%' />}</p>
			</div>
		</StyledImageTextGrid>
	);
};

export default ImageTextGrid;
