import AccountAddress from "components/customer/AccountAddress/AccountAddress/AccountAddress";
import AccountAddresses from "components/customer/AccountAddress/AccountAddresses/AccountAddresses";
import AccountLanding from "components/customer/AccountLanding/AccountLanding";
import AccountLoyalty from "components/customer/AccountLoyalty/AccountLoyalty";
import AccountOrder from "components/customer/AccountOrders/AccountOrder/AccountOrder";
import AccountOrders from "components/customer/AccountOrders/AccountOrders/AccountOrders";
import AccountProfile from "components/customer/AccountProfile/AccountProfile";
import AccountWishlist from "components/customer/AccountWishlist/AccountWishlist/AccountWishlist";
import AccountWishlistOverview from "components/customer/AccountWishlist/AccountWishlistOverview/AccountWishlistOverview";
import NewWishlist from "components/customer/AccountWishlist/WishlistCreateEdit/CreateWishlist";
import EditWishlist from "components/customer/AccountWishlist/WishlistCreateEdit/EditWishlist";
import Basket from "pages/basket/Basket";
import Checkout from "pages/checkout/Checkout";
import PasswordResetPage from "pages/customer/PasswordReset/PasswordResetPage";
import RegistrationPage from "pages/customer/RegistrationPage";
import SignOutPage from "pages/customer/SignOutPage";
import SigninPage from "pages/customer/SigninPage";
import WishlistSharePage from "pages/customer/WishlistSharePage/WishlistSharePage";
import LandingPage from "pages/landingPage/LandingPage";
import ProductDetailPage from "pages/pdp/ProductDetailPage";
import ProductLandingPage from "pages/plp/ProductLandingPage";
import ProductLandingPageSearch from "pages/plp/ProductLandingPageSearch";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router";
import PathTranslationKey from "utilities/paths";
import OrderConfirmationPage from "../../pages/checkout/OrderConfirmationPage";

export type RouteInformation = {
	path: string;
	pathPrefix?: string;
	pathSuffix?: string;
	index?: boolean;
	exact?: boolean;
	element: ReactElement;
};

const routes = [
	{
		path: "",
		index: true,
		element: <LandingPage />,
	},
	{
		path: PathTranslationKey.BRAND_WOMEN,
		pathPrefix: "/",
		element: <LandingPage />,
	},
	{
		path: PathTranslationKey.BRAND_MEN,
		pathPrefix: "/",
		element: <LandingPage />,
	},
	{
		path: PathTranslationKey.SHARED_WISHLIST,
		pathSuffix: "/:id_hash",
		element: <WishlistSharePage />,
	},
	{
		path: PathTranslationKey.CATEGORY,
		pathSuffix: "/*",
		element: <ProductLandingPage />,
	},
	{
		path: PathTranslationKey.PRODUCT,
		pathSuffix: "/:product",
		element: <ProductDetailPage />,
	},
	{
		path: PathTranslationKey.SIGN_IN,
		element: <SigninPage />,
	},
	{
		path: PathTranslationKey.SIGN_OUT,
		element: <SignOutPage />,
	},
	{
		path: PathTranslationKey.REGISTRATION,
		element: <RegistrationPage />,
	},
	{
		path: PathTranslationKey.CART,
		element: <Basket />,
	},
	{
		path: PathTranslationKey.FORGOT_PASSWORD,
		element: <PasswordResetPage />,
	},
	{
		path: PathTranslationKey.CHECKOUT,
		element: <Checkout />,
	},
	{
		path: PathTranslationKey.SEARCH,
		element: <ProductLandingPageSearch />,
	},
	{
		path: PathTranslationKey.ORDER_CONFIRMATION,
		element: <OrderConfirmationPage />,
	},
] as RouteInformation[];

const AccountRoutes = () => {
	const { t } = useTranslation();
	const definedRoutes = [
		{
			path: "",
			element: <AccountLanding />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ORDERS,
			element: <AccountOrders />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ORDERS,
			pathSuffix: "/:id",
			element: <AccountOrder />,
		},
		{
			path: PathTranslationKey.ACCOUNT_WISHLIST,
			element: <AccountWishlistOverview />,
		},
		{
			path: PathTranslationKey.ACCOUNT_WISHLIST,
			pathSuffix: "/:id",
			element: <AccountWishlist />,
		},
		{
			path: PathTranslationKey.ACCOUNT_WISHLIST_NEW,
			element: <NewWishlist />,
		},
		{
			path: PathTranslationKey.ACCOUNT_WISHLIST_EDIT,
			pathSuffix: "/:id",
			element: <EditWishlist />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ADDRESSES,
			element: <AccountAddresses />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ADDRESSES,
			pathSuffix: "/:id",
			element: <AccountAddress />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ADDRESSES_NEW,
			element: <AccountAddress />,
		},
		{
			path: PathTranslationKey.ACCOUNT_ADDRESSES_EDIT,
			pathSuffix: "/:id",
			element: <AccountAddress />,
		},
		{
			path: PathTranslationKey.ACCOUNT_LOYALTY,
			element: <AccountLoyalty />,
		},
		{
			path: PathTranslationKey.ACCOUNT_PROFILE,
			element: <AccountProfile />,
		},
	] as RouteInformation[];

	return definedRoutes.map((route: RouteInformation) => {
		const path = formatPath(t(route.path), route.pathPrefix, route.pathSuffix);
		return (
			<Route
				key={path}
				index={route.index}
				path={path}
				element={route.element}
			/>
		);
	});
};

const formatPath = (path: string, prefix = "", suffix = "") => {
	return `${prefix}${path}${suffix}`;
};

export { AccountRoutes, routes, formatPath };
