import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { setBrand } from "redux/UI/uiSlice";
import { useDispatch, useSelector } from "redux/hooks";

import { remove as removeAccents } from "remove-accents";

export enum Brand {
	Global = "global",
	Women = "women",
	Men = "men",
}

type FunctionReturnType<S, A> = {
	active: S;
	param: A;
};

const useBrand = (): FunctionReturnType<string, any> => {
	const dispatch = useDispatch();
	const brand = useSelector((state) => state.ui.brand);
	const { pathname, search } = useLocation();
	const mkStoreMenTranslation = "masko";

	const normalizeUrl = decodeURI(pathname + search).toLowerCase();
	// Check to correct un-matched translations for gender in MK store
	const editedUrl = normalizeUrl.replace(mkStoreMenTranslation, "машки");

	const { t } = useTranslation();

	const translations = useMemo(() => {
		return {
			women: t("women").toLowerCase(),
			men: t("men").toLowerCase(),
			womenNoAccents: removeAccents(t("women")).toLowerCase(),
			menNoAccents: removeAccents(t("men")).toLowerCase(),
		};
	}, [t]);

	useEffect(() => {
		let active: string = Brand.Global as string;
		if (String(editedUrl) && editedUrl !== undefined && editedUrl !== null) {
			if (
				editedUrl.includes(translations.women) ||
				editedUrl.includes(translations.womenNoAccents)
			) {
				active = Brand.Women as string;
			} else if (
				editedUrl.includes(translations.men) ||
				editedUrl.includes(translations.menNoAccents)
			) {
				active = Brand.Men as string;
			}
		}

		if (brand !== active) {
			dispatch(setBrand(active));
		}
	}, [normalizeUrl, t]);

	return {
		active: brand as string,
		param: brand === Brand.Global ? "/" : translations[brand],
	};
};

export default useBrand;
