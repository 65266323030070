import Button from "components/common/ui/Button/Button";
import Link from "components/common/ui/Link/Link";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import WishlistShareModal from "components/customer/AccountWishlist/WishlistShareModal/WishlistShareModal";
import { useState } from "react";

import { Wishlist } from "../../AccountWishlistOverview";
import WishlistDeleteModal from "../../WishlistDeleteModal/WishlistDeleteModal";

import { WishlistListItemContainer } from "./WishlistListItem.Styled";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

interface Props {
	wishlist?: Wishlist;
	onDelete?: () => void;
	prefetch: (id: any) => void;
	isLoading?: boolean;
}

const WishlistListItem = ({
	wishlist,
	onDelete,
	prefetch,
	isLoading,
	...props
}: Props) => {
	const [deleteClicked, setDeleteClicked] = useState(false);
	const { t } = useTranslation();

	const handleDetele = async () => {
		if (onDelete && wishlist) {
			setDeleteClicked(true);
			await onDelete();
			setDeleteClicked(false);
		}
	};

	if (isLoading || !wishlist) {
		return (
			<WishlistListItemContainer className="wishlist-list-item" {...props}>
				<div className="details__container">
					<div className="details__row">
						<p className="details__title">Wish List Name</p>
						<Skeleton width={120} height={20} />
					</div>
					<div className="details__row">
						<p className="details__title">Items</p>
						<Skeleton width={24} height={20} />
					</div>
					<div className="details__row">
						<p className="details__title">Shared</p>
						<Skeleton width={36} height={20} />
					</div>
				</div>
				<div className="buttons__container">
					<Skeleton height={40} />
					<Skeleton height={40} />
					<Skeleton height={40} />
				</div>
			</WishlistListItemContainer>
		);
	}

	return (
		<WishlistListItemContainer className="wishlist-list-item" {...props}>
			<div className="details__container">
				<div className="details__row">
					<p className="details__title">Wish List Name</p>
					<Link
						to={`${t(PathTranslationKey.ACCOUNT_WISHLIST)}/${wishlist.id}`}
						className="details__detail"
						onMouseEnter={() => prefetch(wishlist.id)}
					>
						{wishlist.name}
					</Link>
				</div>
				<div className="details__row">
					<p className="details__title">Items</p>
					<p className="details__detail">{wishlist.items.length}</p>
				</div>
				<div className="details__row">
					<p className="details__title">Shared</p>
					<p className="details__detail">{wishlist.is_public ? "Yes" : "No"}</p>
				</div>
			</div>
			<div className="buttons__container">
				{wishlist.is_public && wishlist.share_url && (
					<WishlistShareModal
						shareURL={wishlist.share_url}
						variant="primary_02"
					/>
				)}
				<Button
					variant="primary_01"
					link={{
						to: `${t(PathTranslationKey.ACCOUNT_WISHLIST_EDIT).toString()}/${
							wishlist.id
						}`,
					}}
				>
					{t("wishlist.edit")}
				</Button>
				<WishlistDeleteModal
					heading={t('wishlist.deleteWishlist')}
					description={t('wishlist.deleteWishlistConfirmation')}
					onDelete={handleDetele}
					isLoading={deleteClicked}
					variant="primary_03"
					textLight
				>
					{t("wishlist.delete")}
				</WishlistDeleteModal>
			</div>
		</WishlistListItemContainer>
	);
};

export default WishlistListItem;
