import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Email } from "./Email.svg";
import { ReactComponent as Facebook } from "./Facebook.svg";
import { ReactComponent as Instagram } from "./Instagram.svg";
import { ReactComponent as LinkedIn } from "./LinkedIn.svg";
import { ReactComponent as Telephone } from "./Telephone.svg";
import { ReactComponent as Twitter } from "./Twitter.svg";
import { ReactComponent as Whatsapp } from "./Whatsapp.svg";

interface Props {
	type:
		| "email"
		| "facebook"
		| "instagram"
		| "linkedin"
		| "telephone"
		| "twitter"
		| "whatsapp";
}

const IconSocial: FunctionComponent<Props> = ({ type }) => {
	let icon;

	switch (type) {
		case "email":
			icon = <Email />;
			break;
		case "facebook":
			icon = <Facebook />;
			break;
		case "instagram":
			icon = <Instagram />;
			break;
		case "linkedin":
			icon = <LinkedIn />;
			break;
		case "telephone":
			icon = <Telephone />;
			break;
		case "twitter":
			icon = <Twitter />;
			break;
		case "whatsapp":
			icon = <Whatsapp />;
			break;
	}

	return <SvgIcon svg={icon} />;
};

export default IconSocial;
