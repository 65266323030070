import React from "react";

import { useLocation, useNavigate, useParams } from "react-router";

import customerApi from "app/api/customer/customerApi";

import { useDispatch, useSelector } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import AddressForm from "./AddressForm/AddressForm";

import { StyledContainer } from "./AccountAddress.Styled";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

const AccountAddress = () => {
	const { id } = useParams();
	const isEditPage = Boolean(id);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state } = useLocation();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Addresses"));
	}, [dispatch]);

	const [getAddress, getAddressResult] = customerApi.useGetAddressMutation();

	React.useEffect(() => {
		if (id && !state?.address) getAddress(id);
	}, [getAddress, id, state?.address]);

	const storeCode = useSelector((state) => state.bcStore.store?.code);

	// Navigate to Account Address page if address doesn't exist
	React.useEffect(() => {
		if (isEditPage && !state?.address && getAddressResult.isError) {
			navigate(`/${storeCode}${t(PathTranslationKey.ACCOUNT_ADDRESSES)}`);
		}
	}, [
		getAddressResult.isError,
		isEditPage,
		navigate,
		state?.address,
		storeCode,
	]);

	const { t } = useTranslation();

	return (
		<StyledContainer role="button">
			<p className="heading">{isEditPage ? t("edit_address") : t("new_address")}</p>
			<AddressForm
				address={
					(!getAddressResult.isLoading && getAddressResult.data) ??
					state?.address
				}
			/>
		</StyledContainer>
	);
};

export default AccountAddress;
