import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(4)};
	position: relative;
`;

export const StyledRequiredIndicator = styled.span`
	color: #c92a2a;
`;

interface Props {
	rows?: number;
	cols?: number;
}

export const StyledInput = styled.textarea<Props>`
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 2px 0px 0px 2px;
	padding: ${remCalc(10)} ${remCalc(12)};
	color: ${accountDarkGrey};
	font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	outline: none;
	resize: none;

	&:hover,
	&:focus {
		border: 1px solid ${grey};
	}
`;
