import { createSlice } from "@reduxjs/toolkit";
import { Brand } from "hooks/useBrand";

export const uiSlice = createSlice({
	name: "ui",
	initialState: {
		brand: Brand.Global,
		phoneNumber: "",
		accountNavigationCurrentLink: "overview",
		accountTitle: "Overview",
		isLoading: false,
		activeCategoryId: null,
		showAddToCartConformation: false,
		passwordResetMessage: undefined,
		sizeGuideModalIsOpen: false,
		canShowFooter: false,
	},
	reducers: {
		setBrand: (state, action) => {
			state.brand = action.payload
		},
		setPhoneNumber: (state, action) => {
			state.phoneNumber = action.payload;
		},
		setAccountActiveLink: (state, action) => {
			state.accountNavigationCurrentLink = action.payload;
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setActiveCategoryId: (state, action) => {
			state.activeCategoryId = action.payload;
		},
		setShowAddToCartConformation: (state, action) => {
			state.showAddToCartConformation = action.payload;
		},
		setPasswordResetMessage: (state, action) => {
			state.passwordResetMessage = action.payload;
		},
		setSizeGuideModalIsOpen: (state, action) => {
			state.sizeGuideModalIsOpen = action.payload;
		},
		setCanShowFooter: (state, action) => {
			if (state.canShowFooter) {
				return;
			}

			state.canShowFooter = action.payload
		}
	},
});

export const {
	setBrand,
	setPhoneNumber,
	setAccountActiveLink,
	setActiveCategoryId,
	setIsLoading,
	setShowAddToCartConformation,
	setPasswordResetMessage,
	setSizeGuideModalIsOpen,
	setCanShowFooter,
} = uiSlice.actions;

export default uiSlice.reducer;
