import { Product, ProductAvailability, ProductVariant } from "ts/types";

import PdpAskModal from "./PdpAskModal/PdpAskModal";
import PdpShareModal from "./PdpShareModal/PdpShareModal";
import PdpWishlistDropdown from "./PdpWishlistDropdown/PdpWishlistDropdown";

import AddToCartButton from "components/AddToCartButton/AddToCartButton";
import Skeleton from "react-loading-skeleton";
import { StyledSpaceBetween, StyledWrapper } from "./PdpActions.Styled";
import PdpFindInStore from "./PdpFindInStore/PdpFindInStore";
import { useMemo } from "react";
import NotificationModal from "components/NotificationModal/NotificationModal";

type Props = {
	product?: Product;
	selectedVariant?: ProductVariant;
	loading?: boolean;
	onAddToCartSuccess?: () => void;
};

const PdpActions = ({
	product,
	selectedVariant,
	loading,
	onAddToCartSuccess,
}: Props) => {
	const availability = useMemo((): ProductAvailability => {
		let availability: ProductAvailability = "Available";

		if (product) {
			if (product.availability !== availability) {
				return product.availability;
			}

			if (product.variants?.length === 0 && product.inventory.isInStock) {
				return "Available";
			}

			if (!product.variants) {
				return "Unavailable";
			}

			const hasVariantInStock = product.variants.some(
				(variant) => variant.inventory.isInStock,
			);

			if (!hasVariantInStock) {
				return "Unavailable";
			}
		}

		return availability;
	}, [product]);

	if (loading) {
		return <LoadingSkeleton />;
	}

	if (!product) {
		return null;
	}

	return (
		<StyledWrapper className="PdpActions">
			<StyledSpaceBetween>
				<AddToCartButton
					triggeredFrom="PRODUCT_VIEW"
					product={product}
					variantId={selectedVariant?.entityId}
					availability={availability}
					onSuccess={onAddToCartSuccess}
					fullWidth
					variant="primary_01"
				/>
				<NotificationModal />
				<PdpWishlistDropdown productId={product.entityId} product={product} />
			</StyledSpaceBetween>

			<StyledSpaceBetween>
				<PdpFindInStore product={product} />
				<PdpAskModal product={product} />
				<PdpShareModal />
			</StyledSpaceBetween>
		</StyledWrapper>
	);
};

const LoadingSkeleton = () => {
	return (
		<StyledWrapper className="PdpActions loading">
			<StyledSpaceBetween>
				<AddToCartButton
					triggeredFrom="PRODUCT_VIEW"
					loading={true}
					product={undefined}
					fullWidth
				/>
				<Skeleton height={40} width={40} />
			</StyledSpaceBetween>
			<StyledSpaceBetween>
				<Skeleton width={100} />
				<Skeleton width={100} />
				<Skeleton width={80} />
			</StyledSpaceBetween>
		</StyledWrapper>
	);
};

export default PdpActions;
