import styled from "styled-components";
import { white } from "utilities/variables/colors";

export const PageContainer = styled.div`
  background-color: ${white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`