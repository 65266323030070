import { createSlice } from "@reduxjs/toolkit";
import { KlaviyoSettings } from "app/types/klaviyo";
import { WordpressPageTemplate } from "app/types/wordpress";

export const settingsSlice = createSlice({
	name: "settings",
	initialState: {
		klaviyo: {} as KlaviyoSettings,
		wordpressTemplates: [] as WordpressPageTemplate[],
		wordpressBlogTitle: "",
	},
	reducers: {
		setKlaviyoSettings: (state, { payload }) => {
			state.klaviyo = payload;
		},
		setWordpressTemplates: (state, { payload }) => {
			state.wordpressTemplates = payload;
		},
		setWordpressBlogTitle: (state, { payload }) => {
			state.wordpressBlogTitle = payload;
		},
	},
});

export const {
	setKlaviyoSettings,
	setWordpressTemplates,
	setWordpressBlogTitle,
} = settingsSlice.actions;

export default settingsSlice.reducer;
