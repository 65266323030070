import { useMemo } from "react";

import { UseFormReturn } from "react-hook-form";
import countryList from "react-select-country-list";

import { SelectOption } from "ts/types";

import { AddressFormSectionContainer } from "./AddressSection.Styled";
import { useSelector } from "redux/hooks";
import RSAddressForm from "./RSAddressForm/RSAddressForm";
import AddressForm from "./AddressForm/AddressForm";

type AddressSchema = {
	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	state_or_province: string;
	country_code: string;
};

interface Props {
	form: UseFormReturn<AddressSchema & any>;
}

const AddressSection = ({ form }: Props) => {
	const storeCode = useSelector((state) => state.bcStore.store?.code) || "rs";

	const countryOptions = useMemo(() => {
		let countries = countryList().getData();

		if (storeCode !== "en")
			countries = countries.filter(
				(option) => option.value.toLowerCase() === storeCode.toLowerCase(),
			);
		return countries.map((op) => {
			return {
				label: op.label,
				value: op.value,
			} as SelectOption;
		});
	}, [storeCode]);
	const RenderAddress = () => {
		switch (storeCode) {
			case "rs":
				return <RSAddressForm form={form} countryOptions={countryOptions} />;
			default:
				return <AddressForm form={form} countryOptions={countryOptions} />;
		}
	};

	return (
		<AddressFormSectionContainer className="address-section form__section">
			{RenderAddress()}
		</AddressFormSectionContainer>
	);
};

export default AddressSection;
