import { isValidPhoneNumber } from "libphonenumber-js";
import * as yup from "yup";

export type CustomerAddressSchema = {
	first_name: string;
	last_name: string;
	address1: string;
	address2: string;
	city: string;
	state_or_province: string;
	postal_code: string;
	country_code: string;
	phone: string;
	address_type: "residential" | "commercial";
	customer_id: number;
};

export const addressFormDefaultValues = {
	first_name: "",
	last_name: "",
	address1: "",
	address2: "",
	city: "",
	state_or_province: "",
	postal_code: "",
	country_code: "",
	phone: "",
} as CustomerAddressSchema;

export const addressSchema = yup.object({
	first_name: yup.string().label("First Name").required("form.validation.first_name.required").min(2),
	last_name: yup.string().label("Last Name").required("form.validation.last_name.required").min(2),
	address1: yup.string().label("Address Line 1").required("form.validation.address1.required"),
	address2: yup.string().required("form.validation.address2.required"), 
	city: yup.string().label("Town/City").required("form.validation.city.required"),
	postal_code: yup.string().label("ZIP Code").required("form.validation.postal_code.required"),
	country_code: yup.string().label("Country").required("form.validation.country.required"),
	phone: yup.string().test({
		message: "form.validation.phone.invaild_country",
		test: (value = "", ctx) => {
			if (value.length > 4) {
				return isValidPhoneNumber(value, ctx.parent.country_code);
			}

			return true;
		},
	}),
});
