import styled from "styled-components";

export const StyledPopover = styled.div`
    background: white;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    box-shadow: 0 2px 0 rgba(#ebebeb, 0.6);
    left: 0;
    position: absolute;
    right: 0;
    z-index: 5;
`