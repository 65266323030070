import { gql } from "graphql-request";
import { ProductOnProduct } from "../fragments/product";

const bigcommerceGetProductBySku = (sku: string) => {
	return {
		operationName: "GetProductBySku",
		variables: {
			sku,
		},
		query: gql`
			query GetProductBySku($sku: String!) {
				site {
					product(sku: $sku) {
						...Product
					}
				}
			}
			${ProductOnProduct}
		`,
	};
};

export default bigcommerceGetProductBySku;
