import Accordion from "components/common/ui/Accordion/Accordion";
import Typography from "components/common/ui/Typography/Typography";
import AccordionBody from "components/supportingPages/customerService/stores/ClosestStores/AccordionBody/AccordionBody";
import { Store } from "ts/types";
import { StyledAccordionItem } from "./StoreDetails.Styled";
import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";

interface Props {
	store: Store;
	quantity: number;
}

const StoreDetails: FunctionComponent<Props> = ({ store, quantity }) => {
	const { t } = useTranslation();
	let title;

	if (quantity) {
		title = (
			<Typography>
				{`${quantity} ${t("inStock")}`} - {store.title}
			</Typography>
		);
	} else {
		title = (
			<Typography>
				{t("outOfStock")} - {store.title}
			</Typography>
		);
	}

	return (
		<Accordion>
			<StyledAccordionItem
				hasStock={quantity !== 0}
				className={quantity ? "stock-available" : "stock-unavailable"}
				titleComponent={title}
				bodyComponent={<AccordionBody store={store} />}
			/>
		</Accordion>
	);
};

export default StoreDetails;
