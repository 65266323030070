import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { Roboto } from 'utilities/variables/fonts';

export const StyledColleaguesItemCard = styled.div`

    * {
        font-family: ${Roboto}
    }

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
    height: 100%;
    min-height: ${remCalc(340)};
    min-width: ${remCalc(262)};
    padding: ${remCalc(20)};

    img {
        border-radius: 50%;
        height: ${remCalc(180)};
        width: ${remCalc(180)};
        object-fit: cover;
    }

    h1, h3, a {
        color: ${dark};
        font-weight: 700;
    }

    h3, p, a {
        font-size ${remCalc(14)};
    }

    h1 {
        font-size ${remCalc(20)};
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: ${remCalc(8)};
    }

    .job-title {
        margin: 0 0 ${remCalc(16)};
        padding: 0;
    }

    p {
        color: ${accountDarkGrey};
        margin: 0;
        padding-bottom: ${remCalc(16)};
    }

    a {
        letter-spacing: 0.7px;
        padding-bottom: ${remCalc(10)};
        position: absolute;
        bottom: 0;
        width: calc(100% - 40px);
        text-decoration: underline;
    }
`