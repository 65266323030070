import Markdown from "components/common/ui/Markdown/Markdown";
import styled from "styled-components"
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";
import { accountDarkGrey } from "utilities/variables/colors";

export const RegistrationContainer = styled.div`
  max-width: 816px;
  width: 100%;
  margin: ${remCalc(32)} auto ${remCalc(64)};
  padding: 0px ${remCalc(16)};
  
  @media ${aboveMedium} {
    margin: ${remCalc(64)} auto;
    padding: 0;
  }

  form {
    .btn {
      width: 100%;
      padding: ${remCalc(10)} ${remCalc(16)};

      @media ${aboveMedium} {
        width: ${remCalc(150)}
      }
    }
  }
`

export const CMSContent = styled(Markdown)`
  color: ${accountDarkGrey};
  font-size: 14px;

  p {
    margin-top: 8px;
  }

`