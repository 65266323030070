export const fallbackImage = (dataArray: any[], imageIndex: number) => {
	return dataArray[imageIndex]
		? dataArray[imageIndex]?.featuredImage
		: dataArray[0]?.featuredImage;
};

export const ImagePlaceholder = () => {
	const canvas = document.createElement("canvas");
	canvas.width = 1031;
	canvas.height = 346;

	const ctx = canvas.getContext("2d");
	if (ctx) {
		ctx.fillStyle = "black";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
	}

	const img = new Image(1031, 346);
	img.src = canvas.toDataURL();
	return img;
};
