import uuid from 'react-uuid';

import { useLazyGetCompanyPageQuery } from 'app/api/company/CompanyPageApi';
import CompanyValuesItem from 'components/common/layouts/companyValues/CompanyValuesItem';
import ImageBanner from 'components/common/ui/ImageBanner/ImageBanner';
import Page from 'components/common/ui/Page/Page';
import SEO from 'components/common/ui/SEO/SEO';
import Skeleton from 'react-loading-skeleton';
import { PageType } from 'ts/enums';
import getRobotsContent from 'utilities/getRobotsContent';

import { WordpressTemplateEnum } from 'app/wordpress/components/WordpressTemplates';
import { useEffect, useState } from 'react';
import { useSelector } from 'redux/hooks';
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from '../CompanyPages.Styled';
import { StyledResponsibilitiesSection } from './SocialResponsibility.Styled';

const SocialResponsibility = () => {

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
			const template = wordpressTemplates.find((template) =>
				template.template === WordpressTemplateEnum.SocialResponsibility,
			);
			if (template) {
				trigger({
					pageId: template.id,
					pageType: "responsibility",
				});
			}
	}, [ wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const imageData = [
		{
			image: {
				desktopImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				mobileImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				alt: pageData?.pageData?.featuredImage.altText,
			},
			ctaLink: '',
			ctaText: pageData?.pageData?.featuredImage.title,
		},
	];

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledMainImageBanner className='main-image-banner'>
				{loading ? (
					<Skeleton width={'100%'} />
				) : (
					<ImageBanner
						orientation='landscape'
						images={imageData}
						showButton={false}
						showText={true}
						imageText={pageData?.pageData?.title}
						isLoading={loading}
					/>
				)}
			</StyledMainImageBanner>

			{/* Responsibilities */}
			<StyledResponsibilitiesSection className='flex-carousel-fix'>
				{loading ? (
					<>
						<Skeleton height={'318px'} />
						<Skeleton height={'318px'} />
						<Skeleton height={'318px'} style={{ marginBottom: '64px' }} />
					</>
				) : (
					pageData?.goals?.map((goal: any) => (
						<CompanyValuesItem
							valueData={{
								icon: {
									altText: goal?.image?.altText,
									sourceUrl: goal?.image?.sourceUrl,
									title: goal?.image?.heading,
								},
								text: goal?.text,
								title: goal?.heading,
								linkText: goal?.link?.title,
								link: goal?.link?.url,
							}}
							key={uuid()}
							includeContainerLink
						/>
					))
				)}
			</StyledResponsibilitiesSection>

			{pageData?.pageData?.socialResponsibility?.additionalLinks?.map(
				(link: any) => (
					<StyledImageBanner
						orientation='landscape'
						images={[
							{
								image: {
									desktopImage: link?.images.desktopImage.sourceUrl,
									mobileImage:
										link?.images.mobileImage.sourceUrl ||
										link?.images.desktopImage.sourceUrl,
									alt: link?.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.link.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				),
			)}
		</Page>
	);
};

export default SocialResponsibility;
