import { useGetBlogPageQuery } from "app/api/blog/blogApi";
import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import Pagination from "components/common/ui/Pagination/Pagination";
import SEO from "components/common/ui/SEO/SEO";
import useBlogPostPagination from "hooks/useBlogPostPagination";
import React, { FunctionComponent, useEffect, useState } from "react";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import Page from "../../components/common/ui/Page/Page";
import usePageContent from "../../hooks/usePageContent";
import BlogPageSkeleton from "./BlogPageSkeleton";
import BannerDivider from "./components/BannerDivider/BannerDivider";
import {
	ContentWrapper
} from "./components/BlogContainer.styles";
import { BlogPostStack } from "./components/BlogPostStack/BlogPostStack";
import { BlogTagSelector } from "./components/BlogTagToggleButton/BlogTagSelection";
import HeadingBanner from "./components/HeadingBanner";
import { PostGroupBot } from "./components/ImageGrids/PostGroupBot";
import { PostGroupFinal } from "./components/ImageGrids/PostGroupFinal";
import { PostGroupMid } from "./components/ImageGrids/PostGroupMid";
import { PostGroupMidLower } from "./components/ImageGrids/PostGroupMidLower";
import { PostGroupTop } from "./components/ImageGrids/PostGroupTop";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";

const BlogPage: FunctionComponent = () => {
	const PAGE_SIZE = process.env.REACT_APP_BLOGS_LANDING_PAGE_SIZE || 19;

	const blogPageTitle = useSelector((state) => state.settings.wordpressBlogTitle);

	const { data, isLoading } = useGetBlogPageQuery(blogPageTitle);
	const pagination = useBlogPostPagination(blogPageTitle, Number(PAGE_SIZE));
	const [page, setPage] = useState<any[]>([]);
	const handleFilterSet = (value: string) => {
		pagination.setActivePage(0);
		pagination.setTagFilter(value);
	};
	useEffect(() => {
		if (!pagination.uninitialized && !pagination.loading) {
			setPage(pagination.getPage());
		}
	}, [pagination.loading]);

	const pageContent = usePageContent();
	const { t } = useTranslation();

	const handlePageChange = (page: number) => {
		pageContent.scrollToTop();

		if (!pagination.uninitialized && !pagination.loading) {
			setPage(pagination.getPage(page));
		} else {
			pagination.setActivePage(page);
		}
	};

	const showNoPostsError =
		!pagination.uninitialized &&
		!pagination.loading &&
		pagination.totalPages === 0;

	if (showNoPostsError) {
		return (
			<Page pageType={PageType.DEFAULT}>
				<ErrorContent heading="No Posts Found">
					{t("blogPosts.noPostError")}
				</ErrorContent>
			</Page>
		);
	}

	if (isLoading) {
		return (
			<Page pageType={PageType.PAGE} title={"Loading"}>
				<BlogPageSkeleton />
			</Page>
		);
	}
	return (
		<Page pageType={PageType.PAGE} title={data?.pages.title}>
			<SEO
				title={data?.seo?.title}
				description={data?.seo?.metaDesc}
				keywords={data?.seo?.metaKeywords}
				robots={getRobotsContent([
					data?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<ContentWrapper>
				<HeadingBanner
					imageUrl={data?.pages.imageUrl}
					title={data?.pages.title}
				/>
				<BlogTagSelector setFilter={handleFilterSet} />
				{pagination.loading ? (
					<BlogPageSkeleton />
				) : (
					<React.Fragment>
						<PostGroupTop data={page.slice(0, 4)} />
						<BannerDivider data={data?.pages?.contentBreak1} />
						<PostGroupMid data={page.slice(4, 6)} />
						<PostGroupMidLower data={page.slice(6, 7)} />
						<BannerDivider data={data?.pages?.contentBreak2} />
						<PostGroupBot data={page.slice(7, 13)} />
						<PostGroupFinal data={page.slice(13, 15)} />
						<BlogPostStack data={page.slice(15, 19)} />
					</React.Fragment>
				)}
				<Pagination
					loading={pagination.loading}
					totalPages={pagination.totalPages}
					onChange={handlePageChange}
				/>
			</ContentWrapper>
		</Page>
	);
};

export default BlogPage;
