import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const ArticleCardWrapper = styled.div`
	&.ArticleCardWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		height: calc(100% + ${remCalc(40)});

		@media ${aboveMedium} {
			max-width: ${remCalc(261)};
		}

		.heading {
			margin-top: ${remCalc(8)};
			font-size: ${remCalc(16)};
		}

		 {
			/*TODO - see how banner has been fixed, could  be the same fix here - currently square images added but need to be rectangle */
		}
		picture {
			height: ${remCalc(174)};
			overflow: hidden;
		}

		img {
			width: 100%;
			object-fit: contain;
		}

		a {
			width: 100%;
			@media ${aboveMedium} {
				width: unset;
				position: absolute;
				bottom: 0;
			}
		}
	}
`;
