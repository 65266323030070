import styled from 'styled-components';
import { lightGrey } from 'utilities/variables/colors';

export const StyledQuantityChanger = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button {
        padding: 0;
        margin: 0;
        width: 24px;
        height: 24px;
        min-height: 100%;
        padding-right: 5px;
        border: solid 1px ${lightGrey};

        &:hover {
            background-color: ${lightGrey};;
            border-color: ${lightGrey};;
        }
    }

    span {
        font-size: 1rem;
        display: inline-block;
        width: 1.8rem;
        text-align: center;
    }
`;
