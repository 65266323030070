import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { aboveMedium } from 'utilities/variables/media';

export const StyledOpportunitiesPageWrapper = styled.div`
	color: ${dark};

	.opportunities-row {
		@media ${aboveMedium} {
			display: grid;
			grid-template-columns: 3fr 2fr 1fr 1fr;
			gap: ${remCalc(32)};
			width: 100%;
			max-width: 64.3rem;
		}

		h3 {
			font-size: 1rem;
			margin: 0;
			margin-bottom: 0.5rem;
			color: ${accountDarkGrey};
		}

		p {
			font-size: 1.25rem;
			font-weight: 700;
			margin: 0;
			margin-bottom: ${remCalc(16)};
			max-width: ${remCalc(369)};
			letter-spacing: 1px;

			@media ${aboveMedium} {
				margin-bottom: 0;
			}
		}
	}

	.opportunity-button {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: ${remCalc(40)};
			padding: 0;
			width: 100%;

			@media ${aboveMedium} {
				width: ${remCalc(136)};
			}
		}
	}

	li {
		list-style: none;
	}

	.opportunities-container {
		padding: ${remCalc(24)};
		background-color: #f8f9fa;
		min-height: ${remCalc(300)};
		width: 100%;
		margin-bottom: ${remCalc(16)};

		@media ${aboveMedium} {
			display: flex;
			align-items: center;
			min-height: ${remCalc(100)};
			margin: ${remCalc(5)} auto ${remCalc(16)};
			padding: ${remCalc(32)};
			width: calc(100% - 0.5rem);
			max-width: 64.3rem;
		}

		&:first-of-type {
			margin-top: ${remCalc(48)};

			@media ${aboveMedium} {
				margin-top: ${remCalc(64)};
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.opportunities-contact {
		background-color: #f8f9fa;
		text-align: center;
		padding: ${remCalc(46)} ${remCalc(32)} ${remCalc(64)} ${remCalc(32)};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: ${remCalc(64)};

		@media ${aboveMedium} {
			padding: ${remCalc(46)} ${remCalc(64)} ${remCalc(64)} ${remCalc(64)};
		}

		h2 {
			font-size: 1.25rem;
			font-weight: 700;
			letter-spacing: 1px;
		}

		p {
			margin-bottom: ${remCalc(24)};
			max-width: ${remCalc(472)};
		}
	}

	.Pagination {
		height: ${remCalc(64)};
		margin: 0;

		.Pagination__button-wrapper {
			display: flex;
			align-items: center;
		}
	}

    .no-opportunities-title {
        text-align: center;
        margin-bottom: 1rem;
    }
`;

export const StyledErrorMessage = styled.div`
	p {
		text-align: center;
		padding: 0 ${remCalc(20)};
	}
`;

export const SkeletonJobsWrapper = styled.div`
	.react-loading-skeleton {
		display: block;
		min-height: ${remCalc(300)};
		margin-top: ${remCalc(100)};
		margin-bottom: ${remCalc(16)};

		@media ${aboveMedium} {
			min-height: ${remCalc(140)};
			margin: ${remCalc(-20)} auto ${remCalc(16)};
			width: calc(100% - 2rem);
			max-width: 64.3rem;
		}

		&:first-of-type {
			margin-top: ${remCalc(48)};
		}
	}
`;
