import { gql } from "graphql-request";

import { FieldValueUnion } from "redux/gravityForm/gravityFormSlice";

const wordPressSubmitGravityForm = ({
	id,
	fieldValues,
}: {
	id: string | number;
	fieldValues: FieldValueUnion[];
}) => {
	return {
		query: gql`
			mutation submitForm($id: ID!, $fieldValues: [FormFieldValuesInput]!) {
				submitGfForm(input: { id: $id, fieldValues: $fieldValues }) {
					entry {
						id
					}
					errors {
						id
						message
					}
				}
			}
		`,
		variables: {
			id,
			fieldValues,
		},
	};
};

export default wordPressSubmitGravityForm;
