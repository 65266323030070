import { gql } from "graphql-request";

const wordPressGetPostBySlug = (uri: string) => {
	return {
		operationName: "GetMenuBySlug",
		variables: {
			id: uri,
		},
		query: gql`
			query GetMenuBySlug($id: ID!) {
				post(id: $id, idType: SLUG) {
					id
					author {
						node {
							id
						}
					}
					tags {
						nodes {
							slug
						}
					}
					date
					modified
					content(format: RENDERED)
					title(format: RENDERED)
					featuredImage {
						node {
							sourceUrl
						}
					}
					categories {
						nodes {
							name
							posts {
								nodes {
									title
									author {
										node {
											name
										}
									}
									featuredImage {
										node {
											sourceUrl
										}
									}
									slug
									categories {
										edges {
											node {
												name
											}
										}
									}
								}
							}
						}
					}
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
				}
			}
		`,
	};
};

export default wordPressGetPostBySlug;
