import { gql } from "graphql-request";

const wordPressGetStaffStoryPage = (colleagueName: String) => {
	return {
		operationName: "GetStaffStoryPage",
		variables: {
			colleagueName,
		},
		query: gql`
			query GetStaffStoryPage($colleagueName: ID!) {
				colleague(id: $colleagueName, idType: URI) {
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
					colleague {
						image1 {
							altText
							sourceUrl
						}
						image2 {
							altText
							sourceUrl
						}
						image3 {
							altText
							sourceUrl
						}
						textSection1
						textSection2
						textSection3
						jobTitle
					}
					title
				}
			}
		`,
	};
};

export default wordPressGetStaffStoryPage;
