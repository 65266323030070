import {
	ContentWrapper,
	MenuScroll,
	MenuWrapper,
} from "./SideMenuContent.Styled";
import Menu from "components/common/ui/Menu/Menu";
import SideMenuItem from "./SideMenuItem";
import { FunctionComponent, useEffect, useState } from "react";
import SideMenuHeader from "./SideMenuHeader";
import { MenuItemData } from "ts/types";
import { bigCommerceMenuDataApi } from "app/api/sidemenuDataApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { DataSource } from "ts/enums";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";
import { useSelector } from "redux/hooks";

const SideMenuContent: FunctionComponent = () => {
	const { t } = useTranslation();

	const store = useSelector((state) => state.bcStore.store);

	/* Hooks */
	const [getBigcommerceItems, bigcommerceItems] =
		bigCommerceMenuDataApi.useLazyGetMenuItemsQuery();
	const [getWordpressItems, wordpressItems] =
		wordPressApi.useLazyGetMenuItemsBySlugQuery();

	useEffect(() => {
		getBigcommerceItems({});
		getWordpressItems("header");
	}, [store]);

	useEffect(() => {
		const data = [];

		if (bigcommerceItems.data) {
			data.push(...bigcommerceItems.data);
		}

		if (wordpressItems.data) {
			data.push(...wordpressItems.data);
		}

		setData(data);
	}, [bigcommerceItems, wordpressItems]);

	/* States */
	const [data, setData] = useState<MenuItemData[]>([]);
	const [activeTabs, setActiveTabs] = useState([] as MenuItemData[]);

	/* Functions */
	const onItemClick = (item: MenuItemData) => {
		if (item.children && item.children.length > 0) {
			setActiveTabs([...activeTabs, item]);
		}
	};

	const onHeaderClick = (item: MenuItemData) => {
		setActiveTabs(activeTabs.filter((_item) => _item.id !== item.id));
	};

	const createSidenavMenuItem = (item: MenuItemData, subMenu = false) => {
		const hasChildren = item.children && item.children.length > 0;


		const path =
			item.dataSource === DataSource.WP
				? item.path
				: `${t(PathTranslationKey.CATEGORY)}${item.path}`;

	     const isSaleCategory = item.label.toLowerCase() === "sale" ? { color: "red" } : {};
		if (!hasChildren) {
			return (
				<SideMenuItem
					style={isSaleCategory}
					key={item.id}
					id={item.dataSource === DataSource.BC ? item.id : null}
					label={item.label}
					path={path}
					onClick={() => onItemClick(item)}
					subMenu={subMenu}
				/>
			);
		} else {
			return (
				<SideMenuItem
					style={isSaleCategory}
					key={item.id}
					id={item.dataSource === DataSource.BC ? item.id : null}
					label={item.label}
					path={path}
					hasChildren
					onClick={() => onItemClick(item)}
					subMenu={subMenu}
				/>
			);
		}
	};

	let menu = null;

	if (activeTabs.length === 0) {
		const menuItems = data.map((item) => createSidenavMenuItem(item));
		menu = (
			<MenuScroll>
				<MenuWrapper>
					<Menu>{menuItems}</Menu>
				</MenuWrapper>
			</MenuScroll>
		);
	} else {
		const lastTabData = activeTabs[activeTabs.length - 1];
		const itemsHaveChildren = lastTabData.children.some(
			(child) => child.children && child.children.length > 0,
		);
		const menuItems = lastTabData.children.map((item) =>
			createSidenavMenuItem(item, activeTabs.length > 1 || !itemsHaveChildren),
		);

		menu = (
			<>
				<SideMenuHeader onClick={() => onHeaderClick(lastTabData)}>
					{lastTabData.label}
				</SideMenuHeader>
				<MenuScroll>
					<MenuWrapper>
						<Menu>{menuItems}</Menu>
					</MenuWrapper>
				</MenuScroll>
			</>
		);
	}

	return <ContentWrapper>{menu}</ContentWrapper>;
};

export default SideMenuContent;
