import wordPressGetGravityFormById from "app/gql/wordPressGetGravityFormById";
import wordPressGetGravityForms from "app/gql/wordPressGetGravityForms";
import wordPressSubmitGravityForm from "app/gql/wordPressSubmitGravityForm";
import { wordPressGQL } from "app/services/wordPressGqlService";
import { FieldValueUnion } from "redux/gravityForm/gravityFormSlice";
import { GravityForm } from "types/GravityForm";

export const gravityFormApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getGravityFormById: builder.query({
			query: (id: string | number) => ({
				url: "",
				method: "POST",
				body: { ...wordPressGetGravityFormById(id) },
			}),
			transformResponse: (res: any) => res.data.gfForm as GravityForm,
		}),
		submitGravityForm: builder.mutation({
			query: (payload: {
				id: string | number;
				fieldValues: FieldValueUnion[];
			}) => ({
				url: "",
				method: "POST",
				body: { ...wordPressSubmitGravityForm(payload) },
			}),
		}),
		getGravityForms: builder.query({
			query: (_: void) => ({
				url: "",
				method: "POST",
				body: wordPressGetGravityForms(),
			}),
			transformResponse: (res: any) => {
				return res.data.gfForms.nodes as GravityForm[];
			},
		}),

		submitFormWithFile: builder.mutation({
			query: (body: any) => ({
				url: "",
				method: "POST",
				headers: {
					"content-type": undefined,
				},
				body,
			}),
		}),
	}),
});
