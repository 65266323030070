import ImageBanner from 'components/common/ui/ImageBanner/ImageBanner';
import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { aboveLarge, aboveMedium, aboveSmall } from 'utilities/variables/media';

export const StyledMainImageBanner = styled.div`
	.react-loading-skeleton {
		height: ${remCalc(231)};

		@media ${aboveSmall} {
			height: ${remCalc(282)};
		}

		@media ${aboveMedium} {
			height: ${remCalc(432)};
		}
	}

	.SingleImageWithCta {
		picture img {
			min-height: ${remCalc(231)};
			max-height: ${remCalc(435)};
			vertical-align: bottom;
		}
	}

	p {
		margin: 0;
		padding: 0 ${remCalc(20)};
	}
`;

export const StyledImageBanner = styled(ImageBanner)`
	.SingleImageWithCta {
		position: relative;
		min-height: ${remCalc(360)};
	}

	.StyledCtaWrapper {
		position: relative;
		bottom: auto;
	}

	&.image-banner {
		margin-bottom: ${remCalc(32)};

		&:last-of-type {
			margin-bottom: ${remCalc(64)};
		}

		@media ${aboveMedium} {
			max-height: ${remCalc(359)};
		}

		@media ${aboveLarge} {
			max-height: unset;
		}

		.SingleImageWithCta {
			picture {
				position: absolute;
				width: 100%;
				height: 100%;

				img {
					vertical-align: bottom;
					width: inherit;
					height: inherit;
				}
			}

			@media ${aboveMedium} {
				max-height: ${remCalc(359)};
			}
		}
	}

	a {
		text-transform: none;
		height: auto;
		font-size: ${remCalc(16)};
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: ${remCalc(25)};
	}

	.bottom-banner {
		.react-loading-skeleton {
			height: 100%;

			@media ${aboveMedium} {
				height: ${remCalc(435)};
			}
		}
	}

	.bottom-banner, .bottom-banner picture, .bottom-banner img {
		@media ${aboveLarge} {
			min-height: ${remCalc(550)};
		}
	}
`;
