import { useGetProductBySkuQuery } from "app/api/product/productApi";
import Price from "components/common/ui/Price/Price";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Order, OrderItem as OrderItemType } from "types/Order";

import { OrderItemContainer, StyledInputCheckbox } from "./OrderItem.Styled";

interface Props {
	order: Order;
	item: OrderItemType;
	onChange: (isSelected: boolean, item: OrderItemType) => void;
}

const OrderItem = ({ order, item, onChange: onChangeProp }: Props) => {
	const productResult = useGetProductBySkuQuery(item.sku);
	const product = productResult.data;
	const { t } = useTranslation();

	const onChange = (isSelected: boolean) => {
		onChangeProp(isSelected, item);
	};

	return (
		<OrderItemContainer className="order-item">
			<div className="order-item__checkbox-container">
				<StyledInputCheckbox onChange={onChange} />
			</div>
			<div className="order-item__image-container">
				{product && <LazyLoadImage src={product?.images?.[0]?.urlOriginal} />}
			</div>
			<div className="order-item__details-container">
				<p className="order-item__name">{item.name}</p>
				<p>{item.sku}</p>
				{item.product_options.map((option) => (
					<p
						key={option.id}
					>{`${option.display_name}: ${option.display_value}`}</p>
				))}
				<p>
					{t("price")}: <Price price={Number(item.base_total)} />
				</p>
				<p>{`Quantity: ${item.quantity}`}</p>
				<div className="order-item__total-container--mobile">
					<p className="order-item__total">
						<Price price={Number(item.base_total)} />
					</p>
				</div>
			</div>
			<div className="order-item__total-container--desktop">
				<p className="order-item__total">
					<Price price={Number(item.base_total)} />
				</p>
			</div>
		</OrderItemContainer>
	);
};

export default OrderItem;
