import Button from "components/common/ui/Button/Button";
import styled from "styled-components";
import { accountDarkGrey, lightGrey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${aboveMedium} {
    flex-direction: row;
    gap: 0;
  }
`;

export const StyledTextInput = styled.input`
  height: 40px;
  flex: 1;
  background-color: #f8f9fa;
  border: 1px solid ${lightGrey};
  padding: 10px 12px;
  font: normal 16px/20px ${Roboto};
  color: ${accountDarkGrey};
`;

export const StyledButton = styled(Button)`

  width: 100%;
  padding: 10px 16px;
  height: 40px;
  position: relative;
  text-transform: capitalize;
  
  @media ${aboveMedium} {
    width: fit-content;
  }

  span {
    display: none;
  }

  &.copied {
    height: 40px;

    color: transparent;

    span {
      position: absolute;
      display: inline;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: ${white}
    }
  }

`;
