import Button from "components/common/ui/Button/Button";

import { Address } from "types/Customer";

import DeleteAddressModal from "./DeleteAddressModal/DeleteAddressModal";

import { StyledContainer } from "./AddressCard.Styled";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

type Props = {
	address: Address;
	onDelete: () => void;
};

const AddressCard = ({ address, onDelete }: Props) => {

	const { t } = useTranslation();

	return (
		<StyledContainer>
			<div className="details__container">
				<p className="detail detail--heading">{`${address.first_name} ${address.last_name}`}</p>
				<p className="detail">{address.address1}</p>
				{address.address2 && <p className="detail">{address.address2}</p>}
				<p className="detail">{`${address.city}, ${address.postal_code}`}</p>
				<p className="detail">{address.country}</p>
				{address.phone && (
					<p className="detail detail--phone">{address.phone}</p>
				)}
			</div>
			<div className="buttons__container">
				<Button
					variant="primary_01"
					link={{ to: `${t(PathTranslationKey.ACCOUNT_ADDRESSES)}/${address.id}`, state: { address } }}
				>
					{t("profile.addressEdit")}
				</Button>
				<DeleteAddressModal
					heading={t("delete_address")}
					description={t("delete_address_notice")}
					onDelete={onDelete}
					variant="primary_03"
					textLight
				>
					{t("profile.addressDelete")}
				</DeleteAddressModal>
			</div>
		</StyledContainer>
	);
};

export default AddressCard;
