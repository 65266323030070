import { ReactComponent as Tick } from "assets/images/svg/Tick.svg";
import React, { useState } from "react";
import { InputProps } from "../Input/Input";
import ErrorMessage from "../Input/InputError/InputError";
import Label from "../Label/Label";
import {
	Checkbox,
	CheckboxContainer,
	CheckboxWrapper,
	HiddenCheckbox,
} from "./InputCheckbox.Styled";

interface InputCheckboxProps extends InputProps {
	value?: boolean | undefined;
	disabled?: boolean;
	inputLabel?: string;
	id?: string;
}

const InputCheckbox = React.forwardRef<
	HTMLInputElement,
	InputProps & InputCheckboxProps
>(({ className, label, required = false, disabled = false, ...props }, ref) => {
	const [checked, setChecked] = useState<boolean>(props.value || false);

	React.useEffect(() => {
		setChecked(Boolean(props.value));
	}, [props.value]);

	const handleOnClick = (e: any) => {
		if (!disabled) {
			const value = !checked;

			setChecked(value);
			if (props.onChange) {
				props.onChange(value);
			}
		}
	};

	return (
		<CheckboxWrapper>
			{props.inputLabel && <Label required={true}>{props.inputLabel}</Label>}

			<CheckboxContainer className={className}>
				<HiddenCheckbox
					checked={checked}
					onChange={(e) => {
						props.onChange && props.onChange(e);
					}}
					readOnly
                    disabled={disabled}
				/>
				<Checkbox
					ref={ref}
					checked={checked}
					onClick={handleOnClick}
					onBlur={props.onBlur}
					role="checkbox"
					aria-checked={checked}
                    disabled={disabled}
				>
					<Tick />
				</Checkbox>
				<Label>{label}</Label>
				<ErrorMessage bottom="7px" message={props.errorMsg} />
			</CheckboxContainer>
		</CheckboxWrapper>
	);
});

export default InputCheckbox;
