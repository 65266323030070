import React from "react";
import { StyledDividerContainer, StyledDivider } from "./Divider.Styled";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const Divider = ({ children, ...props }: Props) => {
  return (
    <StyledDividerContainer className="Divider" {...props}>
      {children ? (
        <>
          <StyledDivider />
          {children}
          <StyledDivider />
        </>
      ) : (
        <StyledDivider />
      )}
    </StyledDividerContainer>
  );
};

export default Divider;
