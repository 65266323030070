import LoadingSpinner from 'components/common/ui/LoadingSpinner/LoadingSpinner';
import Page from 'components/common/ui/Page/Page';
import useLogout from 'hooks/auth/useLogout';
import { FunctionComponent, useEffect } from 'react'
import { PageType } from 'ts/enums';

const SignOutPage: FunctionComponent = () => {
    const logout = useLogout();

    useEffect(() => {
        logout();
    }, [])

    return <Page pageType={PageType.PAGE} title='Sign Out'>
        <LoadingSpinner/>
    </Page>
};

export default SignOutPage;
