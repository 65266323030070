import Link from "components/common/ui/Link/Link";
import { ArticleItem } from "./BlogPostStack.styles";

interface Props {
	category: string;
	title: string;
	uri: string;
}

export const BlogPostItem = ({ category, title, uri }: Props) => {
	return (
		<ArticleItem vaild={title.length>0}>
			<Link to={uri}>
				<h4>{category}</h4>
				<h3>{title}</h3>
			</Link>
		</ArticleItem>
	);
};
