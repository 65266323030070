import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import customerApi from "app/api/customer/customerApi";

import {
	InputPhoneNumber,
	InputText,
} from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import AddressSection from "components/common/form/AddressSection/AddressSection";

import { useSelector } from "redux/hooks";

import { Address as AddressType } from "types/Customer";

import {
	addressFormDefaultValues,
	addressSchema,
	CustomerAddressSchema,
} from "./AddressForm.Schema";

import { AddressFormContainer } from "./AddressForm.Styled";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

type AddressKey =
	| "first_name"
	| "last_name"
	| "address1"
	| "address2"
	| "city"
	| "state_or_province"
	| "postal_code"
	| "country_code"
	| "phone"
	| "address_type"
	| "customer_id";

interface AddressFormInterface {
	address?: AddressType;
}

const AddressForm = ({ address }: AddressFormInterface) => {
	const { t } = useTranslation();

	const form = useForm({
		defaultValues: address || addressFormDefaultValues,
		resolver: yupResolver(addressSchema),
	});

	const {
		formState: { errors, isDirty },
		handleSubmit,
		register,
		setValue,
		watch,
	} = form;

	const countryCode = watch("country_code");

	const { store } = useSelector((state) => state.bcStore);
	useEffect(() => {
		if (store) {
			setValue("phone", store.phonePrefix);
		}
	}, []);

	// Set the default values of the form if editing an address
	React.useEffect(() => {
		if (address) {
			Object.entries(address).forEach(([key, value]) => {
				setValue(key as AddressKey, value);
			});
		}
	}, [address, setValue]);

	const [updateAddress, updateAddressResult] =
		customerApi.useUpdateAddressMutation();
	const [createAddress, createAddressResult] =
		customerApi.useCreateAddressMutation();

	const [getAddressesTrigger] = customerApi.useLazyGetAddressesQuery();

	const navigate = useLocaleNavigate();
	const storeCode = useSelector((state) => state.bcStore.store?.code);

	const onSubmit = (data: CustomerAddressSchema) => {
		if (!isDirty) {
			return navigate(
				`/${storeCode}${t(PathTranslationKey.ACCOUNT_ADDRESSES)}`,
			);
		}

		if (store && data.phone === store?.phonePrefix) {
			data.phone = "";
		}

		let result;

		if (address) {
			// Update address
			const payload = { ...address, ...data };
			result = updateAddress(payload).unwrap();
		} else {
			// Create address
			result = createAddress(data as AddressType).unwrap();
		}

		result.then(() => {
			getAddressesTrigger({}).then(() => {
				navigate(`${t(PathTranslationKey.ACCOUNT_ADDRESSES)}`);
			});
		});
	};

	return (
		<AddressFormContainer className="form" onSubmit={handleSubmit(onSubmit)}>
			<h4 className="form__heading">{t("your_details")}</h4>
			<section className="form__section">
				<div className="form__fields">
					<div className="form__row">
						<InputText
							{...register("first_name")}
							errorMsg={
								errors.first_name?.message &&
								(t(errors.first_name.message) as string)
							}
							label={t("form.registerFirstName") as string}
							required
						/>
						<InputText
							{...register("last_name")}
							errorMsg={
								errors.last_name?.message &&
								(t(errors.last_name.message) as string)
							}
							label={t("form.registerLastName") as string}
							required
						/>
					</div>
					<div className="form__row">
						<InputPhoneNumber
							{...register("phone")}
							errorMsg={
								errors.phone?.message && (t(errors.phone.message) as string)
							}
							countryCode={countryCode}
							label={
								<p className="form__field-label">
									{t("form.registerPhone") as string}
									<span className="hint">{`(${t(
										"common.optional_text",
									)})`}</span>
								</p>
							}
						/>
					</div>
				</div>
			</section>
			<AddressSection form={form} />
			{createAddressResult.isError && (
				<p className="error-message">
					{/* @ts-ignore */}
					{createAddressResult.error?.data?.error?.message}
				</p>
			)}
			<div className="form__buttons">
				<Button
					variant="primary_01"
					type="submit"
					loading={
						address
							? updateAddressResult.isLoading
							: createAddressResult.isLoading
					}
				>
					{t("save")}
				</Button>
				<Button
					variant="primary_03"
					link={{ to: t(PathTranslationKey.ACCOUNT_ADDRESSES).toString() }}
					textLight
				>
					{t("cancel")}
				</Button>
			</div>
		</AddressFormContainer>
	);
};

export default AddressForm;
