import styled from "styled-components";

import BaseForm from "components/common/form/Form/Form";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";
import { Roboto } from "utilities/variables/fonts";
import { dark, red } from "utilities/variables/colors";

export const WishlistFormContainer = styled(BaseForm)`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(24)};
	margin: 0;

	p {
		margin: 0;
		font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};

		span {
			color: ${red};
		}
	}

	button {
		margin-top: ${remCalc(8)};
		width: 100%;

		@media ${aboveMedium} {
			width: fit-content;
		}
	}
`;
