import { FunctionComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  justify-content: flex-end;
  width: 100%;
`;

const InputWrapper: FunctionComponent<{
  className?: string;
  children: any;
}> = ({ className, children }) => {
  return <Wrapper className={`input ${className}`}>{children}</Wrapper>;
};

export default InputWrapper;
