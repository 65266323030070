import { WordpressImage } from "ts/types";

import {
  SkeletonBadges,
  SkeletonCards,
  StyledCardRow,
  StyledRow,
  StyledWrapper,
} from "./Cards.Styled";
import { FunctionComponent } from "react";

/**
 * Cleans HTML tags from the description by removing <p> and </p> tags.
 * The function also trims any extra whitespace from the beginning and end of the string.
 *
 * @param {string} description - The HTML string that may contain <p> and </p> tags.
 * @returns {string} - The cleaned string without <p> tags, with leading and trailing spaces removed.
 */
const cleanDescription = (description?: string) => {
  return description?.replace(/<\/?p>/g, "").trim();
};

interface CardsProps {
  loading?: boolean;
  badges: WordpressImage[];
  paymentMethodIcons: WordpressImage[];
}

const Cards: FunctionComponent<CardsProps> = ({
  loading = false,
  badges,
  paymentMethodIcons,
}) => {
  if (loading) {
    return (
      <StyledWrapper>
        <StyledRow>
          <SkeletonBadges />
        </StyledRow>
        <StyledCardRow>
          <SkeletonCards />
        </StyledCardRow>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledRow>
        {badges.map((badge) => {
          return (
            <a 
              key={badge.id}
              href={cleanDescription(badge.description)}
              target="_blank"
              rel="noopener noreferrer">
            <img
              key={badge.id}
              title={badge.title}
              alt={badge.altText}
              src={badge.sourceUrl}
            />
            </a>
          );
        })}
      </StyledRow>
      <StyledCardRow>
        {paymentMethodIcons.map((icon) => {
          return (
            <a 
              key={icon.id}
              href={cleanDescription(icon.description)}
              target="_blank"
              rel="noopener noreferrer">
            <img
              key={icon.id}
              title={icon.title}
              alt={icon.altText}
              src={icon.sourceUrl}
            />
            </a>
          );
        })}
      </StyledCardRow>
    </StyledWrapper>
  );
};

export default Cards;
