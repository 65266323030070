import styled from "styled-components";

import Divider from "components/common/ui/Divider/Divider";
import Typography from "components/common/ui/Typography/Typography";

import { remCalc } from "utilities/styles";
import { grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledTypography = styled(Typography)`
	font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	letter-spacing: ${remCalc(0.8)};
	text-transform: uppercase;
`;

export const NoLocation = styled(Typography)`
	margin-top: ${remCalc(16)};
`

export const StyledDivider = styled(Divider)`
	margin-top: ${remCalc(8)};

	div {
		background-color: ${grey};
	}
`;
