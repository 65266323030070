import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${remCalc(72)};
  padding: ${remCalc(24)};
  background-color: #f8f9fa;
  text-align: center;

  @media ${aboveMedium} {
    gap: ${remCalc(32)};
    padding: ${remCalc(24)} ${remCalc(16)};
    text-align: left;
  }
`;
