import { EvenContainer } from "../BlogContainer.styles";
import ImageItemCommon from "../ImageItemCommon/ImageItemCommonBase";
import { LowerGridWapper } from "./PostGroupMid.styles";

interface DataArray {
	data: any[];

}

export const PostGroupMidLower = ({ data}: DataArray) => {
	if (data.length < 1) {
		return <></>;
	}
	return (
		<EvenContainer top="0">
			<LowerGridWapper>
				<div className="item">
					<ImageItemCommon data={data[0]} />
				</div>
			</LowerGridWapper>
		</EvenContainer>
	);
};
