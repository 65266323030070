import { configureStore } from "@reduxjs/toolkit";

import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import { integrationLayerASW } from "app/services/integrationLayerAswService";
import {
	integrationLayer,
	integrationLayerCart,
} from "app/services/integrationLayerService";
import { locationService } from "app/services/locationService";
import { wordPressGQL } from "app/services/wordPressGqlService";
import uiReducer from "./UI/uiSlice";
import authReducer from "./auth/authSlice";
import bcStoreReducer from "./bigcommerce/bcStoreSlice";
import cartReducer from "./cart/cartSlice";
import locationReducer from "./location/locationSlice";
import settingReducer from "./slices/settingsSlice";

import { klaviyoService } from "app/services/klaviyoService";
import gravityFormSlice from "./gravityForm/gravityFormSlice";
import productRecReducer from "./productRecs/productRecSlice";
import recentlyViewedSlice from "./recentlyViewed/recentlyViewedSlice";

const store = configureStore({
	reducer: {
		cart: cartReducer,
		location: locationReducer,
		bcStore: bcStoreReducer,
		auth: authReducer,
		ui: uiReducer,
		settings: settingReducer,
		productRec: productRecReducer,
		integrationLayer: integrationLayer.reducer,
		integrationLayerASW: integrationLayerASW.reducer,
		locationService: locationService.reducer,
		wordPressGQL: wordPressGQL.reducer,
		bigCommerceGQL: bigCommerceGQL.reducer,
		integrationLayerCart: integrationLayerCart.reducer,
		gravityForm: gravityFormSlice,
		recentlyViewed: recentlyViewedSlice,
		klaviyoService: klaviyoService.reducer,
	},
	middleware: (gDM) =>
		gDM({
			serializableCheck: false,
		}).concat(
			integrationLayer.middleware,
			integrationLayerCart.middleware,
			integrationLayerASW.middleware,
			locationService.middleware,
			wordPressGQL.middleware,
			bigCommerceGQL.middleware,
			klaviyoService.middleware,
		),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
