import { useLazyGetMeQuery } from "app/api/customer/customerApi";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { logout } from "redux/auth/authSlice";
import { useSelector } from "redux/hooks";

const useLoginState = () => {
	const [haveChecked, setHaveChecked] = useState(false);

	const location = useLocation();

	const { store } = useSelector((state) => state.bcStore);

	const { loginRedirect, customer, identifier, state } = useSelector(
		(state) => state.auth,
	);

	const [me] = useLazyGetMeQuery();

	useEffect(() => {
		if (!haveChecked && identifier && customer && store) {
			const getCustomerDetails = async () => {
				await me()
					.unwrap()
					.then((res) => {
						if (!res.success || !res.data) {
							logout();
						}
					})
					.catch((error: any) => {
						logout();
					});

				setHaveChecked(true);
			};

			if (!identifier.storeHash && customer.id) {
				getCustomerDetails();
				return;
			}

			if (identifier.storeHash && !customer.id) {
				getCustomerDetails();
				return;
			}

			if ((identifier.storeHash || customer.id) && location.pathname.includes("account")) {
				getCustomerDetails();
				return;
			}

			setHaveChecked(true);
		}
	}, [identifier, customer, store]);

	const isLoggedIn = useMemo(() => {
		return haveChecked && customer.id;
	}, [haveChecked, customer]);

	return {
		isLoggedIn,
		haveChecked,
		loginRedirect,
		customer,
		state,
	};
};

export default useLoginState;
