import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const LowerGridWapper = styled.section`
	.item {
		padding-bottom: ${remCalc(32)};
		h4 {
			padding-right: ${remCalc(16)};
			padding-left: ${remCalc(16)};
		}
		h3 {
			padding-right: ${remCalc(16)};
			padding-left: ${remCalc(16)};
		}
		img {
			height: ${remCalc(360)};
		}

		@media ${aboveMedium} {
			padding-bottom: ${remCalc(12)};

			h4 {
				padding-left: 0;
			}
			h3 {
				padding-left: 0;
			}
			img {
				height: ${remCalc(419)};
			}
		}
	}
`;
interface ItemCount {
	count: number;
}

export const MidGridWrapper = styled.section<ItemCount>`
	.hiddenItem {
		visibility: hidden;
		padding-bottom: 0 !important;
		@media ${aboveMedium} {
			padding-bottom: ${remCalc(32)};
		}
	}
	.item0 {
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-top: 2rem;
		padding-bottom: ${remCalc(32)};
		@media ${aboveMedium} {
			padding: 0;
		}
		img {
			height: ${remCalc(322)};
		}
	}
	.item1 {
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-bottom: ${remCalc(32)};
		@media ${aboveMedium} {
			padding: 0;
		}
		img {
			height: ${remCalc(322)};
		}
	}

	.grid-container {
		@media ${aboveMedium} {
			display: grid;
			padding-top: ${remCalc(32)};
			padding-bottom: ${remCalc(12)};
			grid-template-columns: ${({ count }) => "auto ".repeat(Number(count))};
			gap: 2rem;
		}
		height: 100%;
		overflow: hidden;
		position: relative;
	}
`;
