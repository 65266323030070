import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";

import IconLanguage from "components/common/ui/Icons/IconLanguage";
import LanguageSwitcherModal from "components/header/LanguageSwitcher/LanguageSwitcherModal";
import { LocationButton } from "components/header/LanguageSwitcher/Styled";

import useBreakpoints from "hooks/useBreakpoints";

import {
  StyledColumn,
  StyledLanguageSwitcherWrapper,
  StyledText,
  StyledWrapper,
} from "./Copyrigh.Styled";

import { useTranslation } from "react-i18next";

const Copyright = () => {
  const [copyright, setCopyright] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const bcStore = useSelector((state) => state.bcStore.store);
  const { medium } = useBreakpoints();

  const { t } = useTranslation();

  useEffect(() => {
    const year = new Date().getFullYear();
    const copyright = `©${year} www.monaonline.com.`;

    setCopyright(copyright);
  }, []);

  return (
    <StyledWrapper>
      <StyledLanguageSwitcherWrapper>
        <LocationButton onClick={() => setIsModalOpen(true)}>
          <IconLanguage />
          <span>{bcStore?.name}</span>
        </LocationButton>
        {isModalOpen && (
          <LanguageSwitcherModal
            storeCode={bcStore?.code}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </StyledLanguageSwitcherWrapper>
      <StyledColumn>
        <StyledText>{copyright}</StyledText>
        <StyledText>{t("allRightsReserved")}</StyledText>
      </StyledColumn>
      {medium && <div></div>}
    </StyledWrapper>
  );
};

export default Copyright;
