import styled, { css } from "styled-components";
import { aboveMedium } from "utilities/variables/media";

export const ProductGridWrapper = styled.div<{
  isLoading: boolean
}>`
  .product-grid {
    display: ${(props) => props.isLoading ? "none" : ""};
  }

  .product-grid__loading {
    display: ${(props) => props.isLoading ? "" : "none"};
  }
`;

export const ProductRow = styled.div<{
  layout: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:first-of-type {
    padding-top: 0px;
  }

  &:last-of-type {
    padding-bottom: 0px;
  }

  margin: 0px -2px;

  .ProductCard {
    padding: 0 2px;
  }


  ${(props) =>
    props.layout === 2 &&
    css`
      justify-content: flex-start;

      .ProductCard {
        padding: 2px;
        width: 25%;

        @media ${aboveMedium} {
          width: 12.5%;
        }
      }
    `}
`;
