import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { ReactComponent as MinusIcon } from "../../../../assets/images/svg/Minus.svg";
import { ReactComponent as AddIcon } from "../../../../assets/images/svg/Plus.svg";

import { StyledQuantityChanger } from "./QuantityChanger.Styled";

type Props = {
	value: number;
	onChange?: (quantity: number, changeType: "add" | "remove") => void;
	showSkeleton: boolean | undefined;
	className?: string;
};

const QuantityChanger = ({
	value,
	onChange = () => {},
	showSkeleton,
	className = "",
}: Props) => {
	const handleQuantityChange = (changeType: "add" | "remove") => {
		let newValue = value;

		if (changeType === "add") {
			newValue += 1;
		} else {
			newValue -= 1;
		}

		onChange(newValue, changeType);
	};

	return (
		<StyledQuantityChanger className={`quantityChanger ${className}`}>
			<ButtonWithIcon
				icon={<MinusIcon />}
				variant={"primary_03"}
				onClick={() => handleQuantityChange("remove")}
			/>
			<span>{showSkeleton ? <Skeleton width={16} height={16} /> : value}</span>
			<ButtonWithIcon
				icon={<AddIcon />}
				variant={"primary_03"}
				onClick={() => handleQuantityChange("add")}
			/>
		</StyledQuantityChanger>
	);
};

export default QuantityChanger;
