import Typography from "components/common/ui/Typography/Typography";

import {
	FieldError,
	FormField,
	FormFieldTypeEnum,
	FormFieldVisibilityEnum,
} from "types/GravityForm";

import { GravityFormDefaultValue } from "./GravityForm";
import AddressField from "./GravityFormFields/AddressField";
import ConsentField from "./GravityFormFields/ConsentField";
import EmailField from "./GravityFormFields/EmailField";
import FileUploadField from "./GravityFormFields/FileUploadField";
import NameField from "./GravityFormFields/NameField";
import PhoneField from "./GravityFormFields/PhoneField";
import NumberField from "./GravityFormFields/NumberField";
import TextAreaField from "./GravityFormFields/TextAreaField";
import TextField from "./GravityFormFields/TextField";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "redux/hooks";
import { updateRecaptchaFieldValue } from "redux/gravityForm/gravityFormSlice";
import ErrorMessage from "../form/Input/InputError/InputError";
import { StyledFieldContainer } from "./GravityForm.Styled";
import InputWrapper from "../form/Input/InputWrapper";
import SelectField from "./GravityFormFields/SelectField";
import { useTranslation } from "react-i18next";
import CheckboxField from "./GravityFormFields/CheckboxField";
import RadioField from "./GravityFormFields/RadioField";
import SurveyField from "./GravityFormFields/SurveyField";

interface Props {
	field: FormField;
	fieldErrors: FieldError[];
	defaultValue?: GravityFormDefaultValue;
}

const GravityFormField = ({ field, fieldErrors, defaultValue }: Props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		location: { selectedLocale },
	} = useSelector((state) => state);

	switch (field.type) {
		case FormFieldTypeEnum.NAME:
			return (
				<NameField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.NAME]}
				/>
			);
		case FormFieldTypeEnum.EMAIL:
			return (
				<EmailField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.EMAIL]}
				/>
			);
		case FormFieldTypeEnum.PHONE:
			return (
				<PhoneField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.PHONE]}
				/>
			);
		case FormFieldTypeEnum.ADDRESS:
			return (
				<AddressField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.ADDRESS]}
				/>
			);
		case FormFieldTypeEnum.TEXTAREA:
			return (
				<TextAreaField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.TEXTAREA]}
				/>
			);
		case FormFieldTypeEnum.TEXT:
			return (
				<TextField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.TEXT]}
				/>
			);
			case FormFieldTypeEnum.NUMBER:
			return (
				<NumberField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.NUMBER]}
				/>
			);
		case FormFieldTypeEnum.CONSENT:
			return (
				<ConsentField
					field={field}
					fieldErrors={fieldErrors}
					defaultValue={defaultValue?.[FormFieldTypeEnum.CONSENT]}
				/>
			);
		case FormFieldTypeEnum.CHECKBOX:
			return <CheckboxField field={field} fieldErrors={fieldErrors} />;
		case FormFieldTypeEnum.FILEUPLOAD:
			return <FileUploadField field={field} fieldErrors={fieldErrors} />;
		case FormFieldTypeEnum.CAPTCHA:
			const sitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

			if (!sitekey) {
				return null;
			}

			return (
				<StyledFieldContainer
					layoutGridColumnSpan={12}
					isError={Boolean(fieldErrors.length)}
					visibility={FormFieldVisibilityEnum.VISIBLE}
				>
					<InputWrapper>
						<ReCAPTCHA
							hl={selectedLocale ?? "rs"}
							sitekey={sitekey}
							onChange={(t: any) =>
								dispatch(updateRecaptchaFieldValue({ id: field.id, value: t }))
							}
						/>

						<ErrorMessage
							message={
								fieldErrors[0]?.message.includes("invalid")
									? t("form.validation.recaptcha.invalid") ?? ""
									: fieldErrors[0]?.message
							}
						/>
					</InputWrapper>
				</StyledFieldContainer>
			);
		case FormFieldTypeEnum.SURVEY:
			return (
				<SurveyField
					field={field}
					fieldErrors={fieldErrors}				
				/>
			);
		case FormFieldTypeEnum.SELECT:
			return <SelectField field={field} fieldErrors={fieldErrors} />;
		case FormFieldTypeEnum.RADIO:
			return <RadioField field={field} fieldErrors={fieldErrors}/>;
		default:
			return (
				<Typography>{`Field type not supported: ${field.type}.`}</Typography>
			);
	}
};

export default GravityFormField;
