import { gql } from "graphql-request";
import { ProductOnProduct } from "../fragments/product";

const bigcommerceGetProductsBySkus = (skus: string[] = [], nextProductSetCursor?: string) => {
	return {
		operationName: "GetProductBySku",
		variables: {
			skus: skus,
			nextProductSetCursor: nextProductSetCursor,
		},

		query: gql`
			query productsBySku($skus: [Int!]!, $nextProductSetCursor: String) {
				site {
					products(entityIds: $skus, first: 48, after: $nextProductSetCursor) {
						pageInfo {
							startCursor
							endCursor
							hasNextPage
						}
						edges {
							cursor
							node {
								...Product
							}
						}
					}
				}
			}
			${ProductOnProduct}
		`,
	};
};

export default bigcommerceGetProductsBySkus;
