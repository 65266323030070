import styled from "styled-components";
import {
  dark,
  darkGrey,
  grey,
  lightGrey,
  white,
} from "utilities/variables/colors";
import {
  Roboto,
} from "utilities/variables/fonts";

import Select from "react-select";

export const StyledSelect = styled(Select)`
  .input-select {
    &__single-value {
      font-family: ${Roboto};
      color: ${darkGrey};
    }

    &__control {
      border-radius: 0;
      position: relative;
      width: 100%;
      height: 40px;

      border: solid 1px ${lightGrey};

      font-family: ${Roboto};
      color: ${darkGrey};

      &:hover,
      &--is-focused,
      &--menu-is-open {
        box-shadow: none;
        border-color: ${grey};
        color: ${dark};

        .input-select__dropdown-indicator {
          color: ${dark};
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      background-color: ${white};
      box-shadow: 0px 3px 6px #21252940;
      width: 100%;
      /* z-index: 10; */
      margin: 0;
    }

    &__menu-list {
      /* overflow: hidden; */
      padding: 0;
    }

    &__option {
      background-color: ${white};
      padding: 12px 16px;
      border-bottom: 1px solid ${lightGrey};
      color: ${darkGrey};
      font-weight: 400;
      font-family: ${Roboto};

      &--is-selected {
        color: ${dark};
        font-weight: 600;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: ${dark};
      }
    }
  }
`;
