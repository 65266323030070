import Button, { ButtonProps } from "components/common/ui/Button/Button";
import IconCart from "components/common/ui/Icons/IconCart";
import { AddToCartBtnWrapper } from "./AddToCart.Styled";
import { FunctionComponent } from "react";

interface Props {
  onClick: () => void;
}

const AddToCart: FunctionComponent<Props & ButtonProps> = ({ onClick, ...props }) => {
  return (
    <AddToCartBtnWrapper>
      <Button
        margin={{
          ml: '0'
        }}
        {...props}
        onClick={onClick}
        icon={<IconCart />}
      />
    </AddToCartBtnWrapper>
  );
};

export default AddToCart;
