import { unitOfMeasure } from "components/pdp/PdpDetails/PdpSizeActions/PdpSizeGuide/Measurement/UnitsOfMeasure";
import { FunctionComponent } from "react";
import { useSelector } from "redux/hooks";
import { VariantButton } from "./ProductVariant.Styled";

interface ProductVariantProps {
	value: string | number;
	onClick: () => void;
	active?: boolean;
	available?: boolean;
	groupId?: string | undefined;
}

const ProductVariant: FunctionComponent<ProductVariantProps> = ({
	value,
	onClick,
	active,
	available,
	groupId,
}) => {
	const outOfStockBehavior = useSelector(
		(state) => state.bcStore.settings?.inventory.optionOutOfStockBehavior,
	);

	const variantValue = unitOfMeasure(groupId, value);

	if (
		outOfStockBehavior &&
		outOfStockBehavior === "HIDE_OPTION" &&
		!available
	) {
		return null;
	}

	return (
		<li>
			<VariantButton
				disabled={!available}
				className={active ? "active" : ""}
				onClick={onClick}
			>
				{variantValue}
			</VariantButton>
		</li>
	);
};

export default ProductVariant;
