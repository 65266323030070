import { integrationLayer } from "app/services/integrationLayerService";
import { Street, Town } from "ts/types";

const addressApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		getTowns: builder.query({
			query: () => ({
				url: `/dexpress/towns`,
				method: "GET",
			}),
			transformResponse: (res: any) => {
				return res.data as Town[];
			},
		}),
		getStreets: builder.query({
			query: (townId: number) => ({
				url: `/dexpress/towns/${townId}?with=streets`,
				method: "GET",
			}),
			transformResponse: (res: any) => {
				return res.data.streets as Street[];
			},
		})
	}),
});

export const {
	useLazyGetTownsQuery,
	useLazyGetStreetsQuery
} = addressApi;

export default addressApi;
