import React, {
	InputHTMLAttributes,
	ReactElement,
	useState,
} from "react";
import Label from "../Label/Label";
import InputWrapper from "./InputWrapper";
import { EyeIconWrapper, PasswordWrapper, StarRequired, StarWrapper, StyledInput } from "./Input.Styled";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import ErrorMessage from "./InputError/InputError";
import { useSelector } from "redux/hooks";
import { stars } from "utilities/ratingStars"
import IconEye from "components/common/ui/Icons/IconEye/IconEye";
import IconClosedEye from "components/common/ui/Icons/IconEye/IconClosedEye";

export interface InputProps {
	className?: string;
	label?: string | ReactElement;
	disabled?: boolean;
	required?: boolean;
	filesAccepted?: string[];
	onChange?: (v: any) => void;
	onBlur?: (v: any) => void;
	name?: string;
	errorMsg?:
		| string
		| FieldError
		| Merge<FieldError, FieldErrorsImpl<any>>
		| Partial<{ type: string | number; message: string }>
		| undefined;
	isError?: boolean;
}

interface InputPasswordProps extends InputProps {
	eyeIconOpen : React.ReactNode;
	eyeIconClosed : React.ReactNode;

}

interface InputType {
	type: string;
}

export const Input = React.forwardRef<
	HTMLInputElement,
	InputProps & InputType & InputHTMLAttributes<{}>
>(
	(
		{
			className,
			type = "text",
			label,
			required = false,
			filesAccepted,
			...props
		},
		ref,
	) => {
		// Remove spaces & add '.' for each file type
		const acceptedFilesArray = filesAccepted?.map((fileType: string) => {
			let formatedFileType = fileType.replaceAll(" ", "");
			return `.${formatedFileType}`;
		});

		return (
			<InputWrapper className={className}>
				<Label required={required} htmlFor={props.name}>
					{label}
				</Label>
				<StyledInput
					ref={ref}
					type={type}
					accept={acceptedFilesArray?.join(",")}
					onChange={props.onChange}
					onBlur={props.onBlur}
					name={props.name}
					{...props}
				/>
				<ErrorMessage message={props.errorMsg} />
			</InputWrapper>
		);
	},
);

export const InputText = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="text" />;
});

export const InputRating = React.forwardRef<HTMLInputElement, InputProps & React.InputHTMLAttributes<{}>>(
	(props, ref) => {
		const [rating, setRating] = useState(0);
	
		const handleStarClick = (value: number) => {
		  setRating(value);
		  props.onChange?.(value);
		  
		};

		React.useEffect(() => {
			if (!props.value) {
			  setRating(0); 
			}
		  }, [props.value]);
	
		return (
			<InputWrapper>
			{props.label && <Label>{props.label}

			{props.required && props.label && <StarRequired></StarRequired>}
			</Label>
			
			}
			<StarWrapper>
				{stars.map(star => (
					<>
					<input type="radio" id={star.id} value={star.value} checked={rating === star.value} onChange={() => handleStarClick(star.value)} />
					<label htmlFor={star.id}></label>
					</>
				))}
				<ErrorMessage message={props.errorMsg} />
			</StarWrapper>

			</InputWrapper>	
		  );
  });

export const InputNumber = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="number" />;
});

export const InputEmail = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="email" inputMode="email" />;
});

export const InputPassword = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	}
	return (
	<PasswordWrapper>
	<Input {...props} ref={ref} type={showPassword ? 'text' : 'password'} />
	<EyeIconWrapper
	onClick={togglePasswordVisibility}
	>
		{showPassword ? <IconEye /> : <IconClosedEye/>}
	</EyeIconWrapper>
	</PasswordWrapper>
	);
});

export const InputCheckbox = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="checkbox" />;
});

export const InputRadio = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="radio" />;
});

export const InputFileUpload = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="file" />;
});

export const InputPhoneNumber = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}> & { countryCode: string }
>((props, ref) => {
	const store = useSelector((state) => state.bcStore.store);
	
	const removeLeadingZero = (value: string) => {
		if(store) {

			if(value.startsWith("0")){
				return value.substring(1);
			}
			if(value.startsWith("+")){
				return value.substring(1);
			}
			
			if(!value.startsWith(store.phonePrefix)){
				return store.phonePrefix
			}

			
			
		}
		return value;

	}

  const handleChange = (e: any) => {
	// This code checks if the phone number is valid
	// If phone number starts with "+" then the "+" will be deleted
	// if the fourth digit is 0 then the 0 will be deleted
    let number = removeLeadingZero(e.target.value);
	number = number.replace(/\+/g, '')
	const fourthDigit = number.charAt(3);
	const prefix = number.slice(0, 3)

	if(fourthDigit === '0'){
		number = prefix + number.slice(4)
	}

    e.target.value = number;

    props.onChange?.(e);
  };

	return (
		<Input
			{...props}
			value={props.value}
			onChange={handleChange}
			ref={ref}
			type="text"
			inputMode="tel"
		/>
	);
});
