import IconClose from "components/common/ui/Icons/IconClose/IconClose";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { red, white } from "utilities/variables/colors";

export const OuterWrapper = styled.div`
	background-color: ${red};
	z-index: 10000;

	position: fixed;
	top: env(safe-area-inset-top, 0);
	left: 50%;
	transform: translateX(-50%);
	/* min-width: 50vw; */
	padding: ${remCalc(4)} ${remCalc(8)};
	border-radius: 0 0 ${remCalc(8)} ${remCalc(8)};
`;

export const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	div.text {
		display: flex;

		p {
			color: ${white};
			margin-right: ${remCalc(16)};

			span {
				font-weight: 700;
			}
		}
	}
`;

export const StyledIconClose = styled(IconClose)`
	fill: ${white};
	margin-left: auto;
`;
