import { FunctionComponent } from "react";
import { StyledFooter } from "./AsideFooter.Styled";

const AsideFooter: FunctionComponent<{
  children?: any;
}> = ({ children }) => {
  return (
    <StyledFooter className="AsideFooter">
      {children}
    </StyledFooter>
  );
};

export default AsideFooter;
