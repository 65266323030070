import { gql } from "graphql-request";

const wordPressGetJobDescription = (id: any) => {
	return {
		operationName: "GetJobDescriptionData",
		variables: {
			id,
		},
		query: gql`
			query GetJobDescriptionData($id: ID!) {
				job(id: $id) {
					content
					jobs {
						deadline
						location
						fieldGroupName
					}
					title
					featuredImage {
						node {
							altText
							sourceUrl
						}
					}
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
				}
			}
		`,
	};
};

export default wordPressGetJobDescription;
