import { gql } from "graphql-request";

const wordPressGetGravityForms = () => {
	return {
		query: gql`
			query GetGravityForms {
				gfForms {
					nodes {
						databaseId
						title
					}
				}
			}
		`,
	};
};

export default wordPressGetGravityForms;
