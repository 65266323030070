import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { IonAccordion } from "@ionic/react";

import Button from "components/common/ui/Button/Button";
import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import DropdownArrow from "components/common/ui/Icons/DropdownArrow";

import useBreakpoints from "hooks/useBreakpoints";

import { MenuItemData } from "ts/types";

import {
	SkeletonMenuItem,
	StyledAccordionGroup,
	StyledDivider,
	StyledItem,
	StyledLabel,
	StyledLabelLink,
	StyledLink,
	StyledList,
	StyledMenu,
	StyledMenuContent,
} from "./Sitemap.Styled";

interface SitemapProps {
	loading: boolean;
	menuItems: MenuItemData[];
}

const Sitemap: FunctionComponent<SitemapProps> = ({ loading, menuItems }) => {
	const { large } = useBreakpoints();
	const location = useLocation();

	const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);

	useEffect(() => {
		// reset state on location change
		setActiveMenuIndex(null);
	}, [location]);

	const handleMenuItemClick = (index: number) => {
		if (activeMenuIndex === index) {
			setActiveMenuIndex(null);
		} else {
			setActiveMenuIndex(index);
		}
	};

	if (loading) {
		const ITEM_COUNT = 8;

		const skeleton = large
			? Array(ITEM_COUNT)
					.fill(null)
					.map((_, i) => {
						return <SkeletonMenuItem key={i} />;
					})
			: Array(ITEM_COUNT)
					.fill(null)
					.map((_, i) => {
						return (
							<IonAccordion key={i}>
								<StyledItem slot="header">
									<SkeletonMenuItem />
								</StyledItem>
							</IonAccordion>
						);
					});

		return (
			<Fragment>
				{large ? (
					<Fragment>
						<StyledMenu>{skeleton}</StyledMenu>
						<StyledDivider />
					</Fragment>
				) : (
					<StyledAccordionGroup>{skeleton}</StyledAccordionGroup>
				)}
			</Fragment>
		);
	}

	return (
		<Fragment>
			{large ? (
				<Fragment>
					<StyledMenu className="SiteMap">
						{menuItems.map((item, index) => {
							return item.children.length ? (
								<ButtonWithIcon
									key={item.id}
									variant="basic"
									icon={<DropdownArrow open={activeMenuIndex === index} />}
									position="after"
									onClick={() => handleMenuItemClick(index)}
									spaced
									className={activeMenuIndex === index ? "active" : ""}
								>
									{item.label}
								</ButtonWithIcon>
							) : (
								<Button
									key={item.id}
									link={{
										to: item.path,
									}}
									spaced
								>
									{item.label}
								</Button>
							);
						})}
					</StyledMenu>
					<StyledDivider />
					{activeMenuIndex !== null && (
						<Fragment>
							<StyledMenuContent>
								{menuItems[activeMenuIndex]?.children.map((childItem) => {
									return (
										<StyledLink key={childItem.id} to={childItem.path}>
											{childItem.label}
										</StyledLink>
									);
								})}
							</StyledMenuContent>
							<StyledDivider />
						</Fragment>
					)}
				</Fragment>
			) : (
				<StyledAccordionGroup>
					{menuItems.map((item) =>
						item.children.length ? (
							<IonAccordion key={item.id} value={item.label}>
								<StyledItem slot="header">
									<StyledLabel>{item.label}</StyledLabel>
								</StyledItem>
								<StyledList slot="content">
									{item.children.map((childItem) => (
										<li key={childItem.id}>
											<StyledLink to={childItem.path}>
												{childItem.label}
											</StyledLink>
										</li>
									))}
								</StyledList>
							</IonAccordion>
						) : (
							<StyledItem key={item.id} lines="full">
								<StyledLabelLink to={item.path}>{item.label}</StyledLabelLink>
							</StyledItem>
						),
					)}
				</StyledAccordionGroup>
			)}
		</Fragment>
	);
};

export default Sitemap;
