import { FunctionComponent, useMemo } from "react";

import IconWishlist from "components/common/ui/Icons/IconWishlist/IconWishlist";
import IconProfile from "components/common/ui/Icons/IconProfile/IconProfile";
import IconDashboard from "components/common/ui/Icons/IconDashboard";
import IconOrders from "components/common/ui/Icons/IconOrders";
import IconLoyalty from "components/common/ui/Icons/IconLoyalty";
import IconAddresses from "components/common/ui/Icons/IconAddresses";

import Typography from "components/common/ui/Typography/Typography";
import { useSelector } from "redux/hooks";

import RequireAuth from "components/routing/RequireAuth/RequireAuth";
import useLogout from "hooks/auth/useLogout";
import {
	StyledButton,
	StyledButtonLink,
	StyledClearButton,
	StyledColumn,
	StyledRow,
} from "./UserContent.Styled";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

const UserContent: FunctionComponent = () => {
	const { t } = useTranslation();
	const logout = useLogout();
	const customer = useSelector((state) => state.auth.customer) as any;

	const menuItems = useMemo(() => {
		return [
			{
				label: "account.overview",
				href: t(PathTranslationKey.ACCOUNT_OVERVIEW),
				icon: <IconDashboard />,
			},
			{
				label: "account.orders",
				href: t(PathTranslationKey.ACCOUNT_ORDERS),
				icon: <IconOrders />,
			},
			{
				label: "loyalty_program",
				href: t(PathTranslationKey.ACCOUNT_LOYALTY),
				icon: <IconLoyalty />,
			},
			{
				label: "account.wishlists",
				href: t(PathTranslationKey.ACCOUNT_WISHLIST),
				icon: <IconWishlist />,
			},
			{
				label: "account.profile",
				href: t(PathTranslationKey.ACCOUNT_PROFILE),
				icon: <IconProfile />,
			},
			{
				label: "account.addresses",
				href: t(PathTranslationKey.ACCOUNT_ADDRESSES),
				icon: <IconAddresses />,
			},
		];
	}, [t]);

	const onSignOut = () => {
		logout();
	};

	return (
		<RequireAuth>
			<>
				<StyledRow>
					<Typography variant="heading" size="small">
						{t("account.greeting")}, {customer?.first_name || ""}
					</Typography>
					<StyledClearButton onClick={onSignOut}>{t("signOut")}</StyledClearButton>
				</StyledRow>
				<StyledColumn>
					{menuItems.map((item, index) => (
						<StyledButtonLink key={index} to={item.href}>
							<StyledButton icon={item.icon} variant="primary_03" fullWidth>
								{t(item.label)}
							</StyledButton>
						</StyledButtonLink>
					))}
				</StyledColumn>
			</>
		</RequireAuth>
	);
};

export default UserContent;
