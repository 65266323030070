import styled from 'styled-components';

import { remCalc } from 'utilities/styles';
import { accountDarkGrey, dark } from 'utilities/variables/colors';
import { Roboto } from 'utilities/variables/fonts';
import { aboveMedium } from 'utilities/variables/media';

export const StyledContainer = styled.div<{ $isFlipped: boolean }>`
	* {
		font-family: ${Roboto};
	}

	.container {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;

		@media ${aboveMedium} {
			grid-template-columns: 1fr 1fr;

			min-height: ${remCalc(609)};
		}
	}

	.image-container {
		@media ${aboveMedium} {
			order: ${(props) => (props.$isFlipped ? '1' : '0')};
		}
	}

	.image {
		padding-bottom: 50%;
		min-height: 235px;
		width: 100%;
		height: 100%;
		position: relative;

		picture {
			width: 100%;
		}

		@media ${aboveMedium} {
			padding-bottom: 0%;
		}

		.clip {
			position: absolute;
			height: 100%;
		}

		img {
			display: block;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}

		@media ${aboveMedium} {
			overflow: hidden;
			width: 100%;

			picture {
				width: 100%;
			}
		}
	}

	h3 {
		font-weight: 700;
		font-size: ${remCalc(24)};
		color: ${dark};
		text-transform: uppercase;
		letter-spacing: 1.2px;
		margin: 0;
		margin-bottom: ${remCalc(16)};
	}

	.text-split-container {
		display: flex;
		padding: ${remCalc(48)} ${remCalc(24)};

		@media ${aboveMedium} {
			padding: ${remCalc(64)};
		}
	}

	.text {
		@media ${aboveMedium} {
			margin: auto 0;
		}

		p {
			display: inline-block;
			font-size: ${remCalc(16)};
			color: ${accountDarkGrey};
			line-height: ${remCalc(24)};
			margin: 0;
			margin-bottom: ${remCalc(16)};

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	a {
		display: inline-block;
	}
`;
