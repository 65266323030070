import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { lightestGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

interface isVaild {
	valid: boolean;
}

export const ImageItemCommonWrapper = styled.div<isVaild>`
	cursor: pointer;

	visibility: ${({ valid }) => (valid ? "visible" : "hidden")};
	min-width: 2rem;
	height: ${({ valid }) => (valid ? "100%" : "0")};
	@media ${aboveMedium} {
		padding-bottom: ${remCalc(85)};
		height: 100%;
	}
	overflow: hidden;
	position: relative;
	width: 100%;

	img {
		width: 100%;
		object-fit: cover;
		position: relative;
		object-position: 25%;

		@media ${aboveMedium} {
			height: 100%;
		}
	}

	div {
		position: relative;
		@media ${aboveMedium} {
			position: absolute;
			height: ${remCalc(92)};
		}
		background: white;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: 100%;
		padding-top: ${remCalc(11)};

		h4 {
			font-size: 1rem;
			margin: 0;
			padding: 0;
			font-weight: bold;
			text-transform: uppercase;
		}
		h3 {
			font-weight: bold;
			margin: 0;
			padding: 0;
			padding-top: 0.5rem;
			z-index: 5;
			text-overflow: ellipsis;
			max-lines: 2;
			overflow: hidden;
		}
	}
`;

export const ImageItemFirstWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 100%;
	cursor: pointer;
	img {
		object-fit: cover;
		position: relative;
		height: 100%;
	}
	div {
		z-index: 3;
		height: 100%;
		width: 100%;
		position: absolute;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: ${remCalc(8)};
		text-align: center;

		h4 {
			color: white;
			font-size: 1rem;
			margin: 0;
			padding: 0;
			font-weight: bold;
			text-transform: uppercase;
			padding-top: 1rem;
		}
		h3 {
			color: white;
			font-weight: bold;
			margin: 0;
			padding: 0;
			padding-top: 0.5rem;
			z-index: 5;
		}
	}

	&:hover {
		h3,
		h4 {
			color: ${lightestGrey};
		}
	}
`;
