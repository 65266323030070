import Skeleton from "react-loading-skeleton";

import { useLazyGetCompanyPageQuery } from "app/api/company/CompanyPageApi";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import Button from "components/common/ui/Button/Button";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "redux/hooks";
import { StyledMainImageBanner } from "./CompanyPages.Styled";
import { StyledLoyaltyPageWrapper } from "./Loyalty.Styled";
import * as DOMPurify from "dompurify";
import { useMatch } from "react-router";
import { validateUrlSlashes } from "app/utils/urlUtils";

const LoyaltyPage = () => {
	const path = useMatch("/:locale/*")?.params["*"];

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
		if (path) {
			const template = wordpressTemplates.find(
				(template) =>
					validateUrlSlashes(template.uri) === validateUrlSlashes(path),
			);
			if (template) {
				trigger({
					pageId: template.id,
					pageType: "loyalty",
				});
			}
		}
	}, [path, wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const createAccountBannerData =
		pageData?.pageData.template.loyaltyProgram.additionalContentBlock?.[0] ||
		undefined;

	const { sanitize }: any = DOMPurify;

	const imageData = useMemo(() => {
		if (!pageData?.pageData?.featureImage) {
			return undefined;
		}

		return [
			{
				image: {
					desktopImage: pageData?.pageData?.featuredImage?.node.sourceUrl,
					mobileImage: pageData?.pageData?.featuredImage?.node.sourceUrl,
					alt: pageData?.pageData?.featuredImage?.node.altText,
				},
				ctaLink: "",
				ctaText: pageData?.pageData?.featuredImage?.node.title,
			},
		];
	}, [pageData]);

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledLoyaltyPageWrapper className="StyledLoyaltyPageWrapper">
				{loading ||
					(imageData && (
						<StyledMainImageBanner>
							{loading ? (
								<Skeleton width={"100%"} />
							) : (
								<ImageBanner
									orientation="landscape"
									images={imageData}
									showButton={false}
									showText={true}
									imageText={pageData?.pageData?.title}
									isLoading={loading}
								/>
							)}
						</StyledMainImageBanner>
					))}

				{loading ? (
					<Skeleton className="skeleton-content" height="500px" />
				) : (
					<div
						className="loyalty-content"
						dangerouslySetInnerHTML={{
							__html: sanitize(pageData?.pageData?.content),
						}}
					></div>
				)}

				{loading ||
					(createAccountBannerData && (
						<div className="create-account-container">
							{loading ? (
								<Skeleton className="skeleton-account" />
							) : (
								<div className="create-account-text">
									<h2>{createAccountBannerData.title}</h2>
									<p>{createAccountBannerData.text}</p>
									<Button
										variant="primary_01"
										link={{ to: `${createAccountBannerData.link?.url}` }}
									>
										{createAccountBannerData.link?.title}
									</Button>
								</div>
							)}
						</div>
					))}
			</StyledLoyaltyPageWrapper>
		</Page>
	);
};

export default LoyaltyPage;
