import { Navigate, Route, Routes } from "react-router-dom";

import AccountTemplate from "pages/customer/AccountTemplate";
import { useSelector } from "redux/hooks";
import PathTranslationKey from "utilities/paths";
import RequireAuth from "./RequireAuth/RequireAuth";

import { getStoreLangCodes } from "app/bigcommerce/helpers/BcConfigHelper";
import CookiePolicy from "components/CookiePolicy/CookiePolicy";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RemoveTrailingSlash } from "./RemoveTrailingSlash";
import WordPressRedirects from "./WordPressRedirects";
import { AccountRoutes, RouteInformation, formatPath, routes } from "./routes";
import AppUrlListener from "./AppUrlListener";
import WordpressRoutes from "app/routes/WordpressRoutes";
import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import GenericErrorPage from "pages/error/GenericErrorPage";

const AvailableRoutes = () => {
	const { t } = useTranslation();

	const getPath = (key: PathTranslationKey): string => {
		return t(key);
	};

	return (
		<Routes>
			{routes.map((route: RouteInformation) => {
				const path = formatPath(
					t(route.path),
					route.pathPrefix,
					route.pathSuffix,
				);
				return (
					<Route
						key={path}
						index={route.index}
						path={path}
						element={route.element}
					/>
				);
			})}
			{/* account section */}
			<Route
				path={getPath(PathTranslationKey.ACCOUNT_OVERVIEW)}
				element={
					<RequireAuth>
						<AccountTemplate />
					</RequireAuth>
				}
			>
				{AccountRoutes()}
			</Route>
			<Route>{WordpressRoutes()}</Route>
		</Routes>
	);
};

const Routing = () => {
	const preferredLocation = useSelector(
		(state) => state.location.preferredLocation,
	);

	const { store } = useSelector((state) => state.bcStore);

	return (
		<Fragment>			
				<RemoveTrailingSlash />
				{!store && <Page pageType={PageType.DEFAULT} />}
				<Routes>
					<Route path="*" element={<GenericErrorPage />} />
					{store && preferredLocation && (
						<Route
							index
							element={<Navigate to={`/${preferredLocation}`} />}
						/>
					)}
					{store &&
						getStoreLangCodes().map((lang) => (
							<Route
								key={lang}
								path={`${lang}/*`}
								element={<AvailableRoutes />}
							/>
						))}
				</Routes>
				<AppUrlListener />			
		</Fragment>
	);
};

export default Routing;
