import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

interface ItemCount {
	count: number;
}

export const TopGridWrapper = styled.section<ItemCount>`
	.hiddenItem {
		visibility: hidden;
		padding-bottom: 0 !important;
		@media ${aboveMedium} {
			padding-bottom: ${remCalc(32)};
		}
	}
	.item0 {
		grid-area: lhalf;
		padding-top: ${remCalc(42)};
		padding-bottom: 2rem;
		@media ${aboveMedium} {
			padding: 0;
			padding-bottom: 6rem;
		}
		img {
			height: ${remCalc(472)};
			@media ${aboveMedium} {
				height: ${remCalc(868)};
			}
		}
	}
	.item1 {
		grid-area: rtopr;
		text-overflow: ellipsis;
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-bottom: ${remCalc(32)};
		@media ${aboveMedium} {
			padding: 0;
		}
		img {
			height: ${remCalc(240)};
			@media ${aboveMedium} {
				height: ${remCalc(336)};
			}
		}
	}
	.item3 {
		grid-area: rtopl;
		text-overflow: ellipsis;
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-bottom: ${remCalc(32)};

		@media ${aboveMedium} {
			padding: 0;
		}
		img {
			height: ${remCalc(240)};
			@media ${aboveMedium} {
				height: ${remCalc(336)};
			}
		}
	}
	.item2 {
		grid-area: rbot;
		text-overflow: ellipsis;
		padding-right: ${remCalc(16)};
		padding-left: ${remCalc(16)};
		padding-bottom: ${remCalc(32)};

		@media ${aboveMedium} {
			padding: 0;
			padding-bottom: ${remCalc(10)};
		}
		img {
			height: ${remCalc(240)};
			@media ${aboveMedium} {
				height: ${remCalc(408)};
			}
		}
	}
	.grid-container {
		@media ${aboveMedium} {
			display: grid;
		}
		grid-template-areas: ${({ count }) =>
			count > 3
				? `"lhalf lhalf lhalf rtopl rtopr"
			"lhalf lhalf lhalf rbot rbot "`
				: `"lhalf lhalf lhalf rtopr rtopr"
			"lhalf lhalf lhalf rbot rbot "`};

		gap: 2rem;
		height: 100%;
		overflow: hidden;
		position: relative;
	}
`;
