import { FunctionComponent } from "react";
import styled from "styled-components";
import { CustomiseComponent } from "ts/interfaces";
import { overlayZIndex } from "utilities/variables/zIndex";

export const StyledOverlay = styled.div<
	CustomiseComponent & {
		show: boolean;
	}
>`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${(props) =>
		props.show ? "rgb(33, 37, 41, 0.9)" : "transparent"};
	z-index: ${overlayZIndex};

	${(props) => props.styles}
`;

interface OverlayProps {
	show?: boolean;
	onClick?: () => void;
}

const Overlay: FunctionComponent<OverlayProps & CustomiseComponent> = ({
	show = false,
	onClick = () => {},
	...props
}) => {
	if (!show) {
		return null;
	}

	return (
		<StyledOverlay
			className="Overlay"
			onClick={() => onClick()}
			show={show}
			{...props}
		/>
	);
};

export default Overlay;
