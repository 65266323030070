import { FunctionComponent } from "react";
import { MenuList } from "./Menu.Styled";

interface MenuProps {
  children?: any;
}

const Menu: FunctionComponent<MenuProps> = ({ children }) => {
  return <MenuList>{children}</MenuList>;
};

export default Menu;
