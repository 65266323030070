import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const StyledCApplicationFormPageWrapper = styled.div`
    .ApplicationForm {
        margin-bottom: ${remCalc(34)};
    }
`;

export const RenderedContent = styled.div`
    padding: 0;
    margin: 0;
    text-align: center;
    .htmlContent{
        width: 85%;
        min-width: ${remCalc(328)};

        @media ${aboveMedium} {
            width:${remCalc(816)};
        }
    }
    margin-top: ${remCalc(2)};
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
`