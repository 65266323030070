import Button from "components/common/ui/Button/Button";
import FormattedDate from "../../../../../common/ui/Date/FormattedDate";
import Link from "components/common/ui/Link/Link";
import Price from "components/common/ui/Price/Price";

import { showTrackOrderLink } from "../OrderList";

import { useTranslation } from "react-i18next";
import { Order } from "types/Order";
import PathTranslationKey from "utilities/paths";

type Props = {
	order: Order;
};

const OrderListCard = ({ order }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="order-list-card">
			<p className="label">{t("order")}:</p>
			<Link className="value" to={`${t(PathTranslationKey.ACCOUNT_ORDERS)}/${order.id}`}>
				{order.id}
			</Link>
			<p className="label">{t("date")}:</p>
			<p className="value">
				<FormattedDate date={new Date(order.date_created)} />
			</p>
			<p className="label">{t("total")}:</p>
			<p className="value">
				<Price price={Number(order.total_inc_tax)} />
			</p>
			<p className="label">{t("status")}:</p>
			<p className="value">{order.custom_status}</p>
			{showTrackOrderLink(order) && (
				<Button
					className="button"
					variant="primary_03"
					textLight
					link={{ to: `${t(PathTranslationKey.ACCOUNT_ORDERS)}/${order.id}` }}
				>
					{t("track_order")}
				</Button>
			)}
		</div>
	);
};

export default OrderListCard;
