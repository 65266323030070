import styled from "styled-components";

import Typography from "components/common/ui/Typography/Typography";

import { remCalc } from "utilities/styles";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2000;
`;

export const StyledOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media ${aboveMedium} {
		align-items: center;
	}
`;

export const StyledContainer = styled.div`
	position: relative;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: ${remCalc(34)} ${remCalc(16)} ${remCalc(32)};
	width: 100%;
	padding-bottom: calc(2rem + env(safe-area-inset-bottom, 0));

	@media ${aboveMedium} {
		padding: ${remCalc(34)} ${remCalc(24)} ${remCalc(32)};
    min-width: ${remCalc(330)};
		max-width: fit-content;
	}
`;

export const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media ${aboveMedium} {
		button {
			position: absolute;
			margin: ${remCalc(16)};
			top: 0;
			right: 0;
		}
	}
`;

export const StyledHeading = styled(Typography)`
	font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	text-transform: uppercase;

	@media ${aboveMedium} {
		flex: 1;
		letter-spacing: 0.8px;
	}
`;

export const StyledContent = styled.div`
	margin-top: ${remCalc(26)};
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		margin-top: ${remCalc(24)};
	}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: ${remCalc(16)};
`;
