import {
    FieldError,
    FormField,
    RadioField as RadioFieldType,
} from "types/GravityForm";
import { StyledFieldContainer, RadioFieldWrapper } from "../GravityForm.Styled";
import { InputRadio } from "components/common/form/Input/Input";
import Label from "components/common/form/Label/Label";
import ErrorMessage from "components/common/form/Input/InputError/InputError";
import { useTranslation } from "react-i18next";
import { useDispatch } from "redux/hooks";
import { useCallback, useEffect, useState } from "react";
import { updateRadioFieldValue } from "redux/gravityForm/gravityFormSlice";
import uuid from "react-uuid";

interface Props {
    field: FormField & Partial<RadioFieldType>;
    fieldErrors: FieldError[];
}

const RadioField = ({ field, fieldErrors }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(null);

    const updateValue = useCallback(
        (value: string | null) => {
            dispatch(updateRadioFieldValue({ id: field.id, radioValue: value }));
        },
        [dispatch, field.id],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedRadioValue(value);
    };

    useEffect(() => {
        updateValue(selectedRadioValue);
    }, [selectedRadioValue]);

    return (
        <StyledFieldContainer
            layoutGridColumnSpan={field.layoutGridColumnSpan}
            isError={Boolean(fieldErrors.length)}
            visibility={field.visibility}
        >
            <RadioFieldWrapper>
                <Label required={field.isRequired}>{field.label}</Label>
                {field.choices?.map((choice) => (
                    <InputRadio
                        key={uuid()}
                        className="gravityFormRadio"
                        value={choice.value}
                        label={choice.text}
                        checked={selectedRadioValue === choice.text}
                        onChange={handleChange}
                    />
                ))}
                <ErrorMessage
                    message={
                        fieldErrors[0]?.message.includes("required")
                            ? t("form.validation.field.required") ?? ""
                            : fieldErrors[0]?.message
                    }
                />
            </RadioFieldWrapper>
        </StyledFieldContainer>
    );
};

export default RadioField;
