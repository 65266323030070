import { useEffect } from "react";


import customerApi from "app/api/customer/customerApi";

import { useDispatch, useSelector } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";


import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import useKlaviyo from "hooks/useKlaviyo";
import ProfileForm from "./ProfileForm/ProfileForm";

const AccountProfile = () => {
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.auth.customer);

	const { klaviyoProfile } = useKlaviyo();

	useEffect(() => {
		dispatch(setAccountActiveLink("Profile"));
	}, [dispatch]);

	const [getMeTrigger] = customerApi.useLazyGetMeQuery();

	return (
		<>
			{customer && customer.id ?
				<ProfileForm
					profile={customer as any}
					klaviyoProfile={klaviyoProfile}
					onSubmit={() => getMeTrigger()}
				/>
				: <LoadingSpinner />}
		</>
	);
};

export default AccountProfile;
