import React from "react";

import { useNavigate } from "react-router";
import { useOnClickOutside } from "usehooks-ts";

import wishlistApi, {
	AddWishlistItemPayload,
} from "app/api/customer/wishlistApi";

import Button from "components/common/ui/Button/Button";
import IconWishlist from "components/common/ui/Icons/IconWishlist/IconWishlist";
import { Wishlist } from "components/customer/AccountWishlist/AccountWishlistOverview/AccountWishlistOverview";

import { useSelector } from "redux/hooks";
import useDataLayer from "datalayer/useDataLayer";

import { PdpWishlistDropdownContainer } from "./PdpWishlistDropdown.Styled";
import { Product } from "ts/types";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

type MenuItem = {
	id: string;
	label: string;
	href?: string;
	callback?: (menuItem: MenuItem) => void;
};

interface PdpWishlistDropdownProps {
	productId?: number;
	product?: Product;
}

const PdpWishlistDropdown = ({
	productId,
	product,
}: PdpWishlistDropdownProps) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);

	const datalayer = useDataLayer();

	const toggleDropdown = () => setIsOpen((prev) => !prev);

	const customer: any = useSelector((state) => state.auth.customer);
	const [getWishlists, getWishlistsResult] =
		wishlistApi.useLazyGetWishlistsQuery();

	React.useEffect(() => {
		if (customer.id) getWishlists();
	}, [customer, getWishlists]);

	function GenerateRandomNmber<N extends number, S extends string>(
		number: N,
	): S {
		return (Math.random() * number + "") as S;
	}

	const menuItems: MenuItem[] = React.useMemo(() => {
		const signedOutMenu: MenuItem[] = [
			{
				id: GenerateRandomNmber(9999),
				label: `${t("wishlist.addToMyList")}`,
				href: t(PathTranslationKey.ACCOUNT_WISHLIST).toString(),
			},
			{
				id: GenerateRandomNmber(5555),
				label: `${t("wishlist.createNewList")}`,
				href: t(PathTranslationKey.ACCOUNT_WISHLIST_NEW).toString(),
			},
		];

		if (!customer) {
			return signedOutMenu;
		}

		const wishlists =
			getWishlistsResult.data?.data.map((wishlist: Wishlist) => ({
				id: wishlist.id,
				label: wishlist.name,
				callback: (menuItem: MenuItem) => { },
			})) || [];

		const menu: MenuItem[] = [
			...wishlists,
			{
				id: 'wishlist-create-new',
				label: `${t("wishlist.createNewList")}`,
				href: `${t(PathTranslationKey.ACCOUNT_WISHLIST_NEW)}`,
			},
		];

		return menu;
	}, [customer, getWishlistsResult.data?.data]);

	const [addWishlistItem] = wishlistApi.useAddWishlistItemMutation();

	const navigate = useNavigate();
	const storeCode = useSelector((state) => state.bcStore.store?.code);

	const handleAddToWishlist = React.useCallback(
		(wishlistId: string) => {
			if (!productId) {
				return;
			}

			const payload = {
				wishlistId,
				items: [{ product_id: productId }],
			} as AddWishlistItemPayload;

			addWishlistItem(payload)
				.unwrap()
				.then(() => {
					if (product?.categories) {
						const breadcrumbs = product?.categories?.breadcrumbs || [];

						datalayer.addToWishList(
							product,
							breadcrumbs.length
								? breadcrumbs[breadcrumbs.length - 1].entityId
								: "",
							breadcrumbs.length
								? breadcrumbs[breadcrumbs.length - 1].name
								: "",
						);
					}

					navigate(
						`/${storeCode}${t(
							PathTranslationKey.ACCOUNT_WISHLIST,
						)}/${wishlistId}`,
					);
				});
		},
		[addWishlistItem, navigate, productId, storeCode],
	);

	React.useEffect(() => {
		if (customer.id) getWishlists();
	}, [product]);

	const menuRef = React.useRef(null);
	useOnClickOutside(menuRef, () => setIsOpen(false));

	return (
		<PdpWishlistDropdownContainer className="pdp-wishlist">
			<Button
				className={`pdp-wishlist__button ${isOpen ? "active" : ""}`}
				variant="primary_03"
				onClick={toggleDropdown}
			>
				<IconWishlist />
			</Button>
			{isOpen && (
				<div className="pdp-wishlist__menu" ref={menuRef}>
					{menuItems.map((menuItem) => (
						<Button
							key={menuItem.id}
							variant="primary_02"
							link={
								menuItem.href
									? {
										to: menuItem.href,
										state: { items: [{ product_id: productId }], product },
									}
									: undefined
							}
							onClick={
								menuItem.href
									? undefined
									: () => handleAddToWishlist(menuItem.id)
							}
						>
							{menuItem.label}
						</Button>
					))}
				</div>
			)}
		</PdpWishlistDropdownContainer>
	);
};

export default PdpWishlistDropdown;
