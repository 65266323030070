import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import InputColourCheckbox from "components/common/form/InputColourCheckbox/InputColourCheckbox";
import CollapseContainer from "components/common/ui/CollapseContainer/CollapseContainer";
import styled from "styled-components";
import { remCalc } from "utilities/styles";

export const StyledInputCheckbox = styled(InputCheckbox)`
	align-items: center;
	margin-right:2rem;
	div {
		margin: ${remCalc(8)} ${remCalc(8)} ${remCalc(8)} ${remCalc(1)};
	}

	label {
		margin: 0;
	}

	span {
		margin-top: ${remCalc(8)};
	}
`;

export const StyledColourInputCheckbox = styled(InputColourCheckbox)`
	align-items: center;

	label {
		margin: 0;
	}

	span {
		margin-top: ${remCalc(8)};
	}
`;

export const FilterListing = styled.div`

    .horizontalContainer{
        display:flex;
        flex-direction: row;
    }

    .verticalContainer{
        display:flex;
        flex-direction: column;
		width: 100%;
    }
`
interface CollapseContainerProps {
	hasMarginEnabled?: boolean;
}

export const StyledCollapsibleContainer = styled(CollapseContainer)<CollapseContainerProps>`
	margin-left: ${(props) => (props.hasMarginEnabled ? '1rem' : '0px')};
	width: 100%;
	${(props) => {
		if (props.hasMarginEnabled) {
			return `
				i {
					margin-right: 1rem;
				}
			`
		}

		return '';
	}}
`