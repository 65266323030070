import { gql } from "graphql-request";

export const Image = gql`
  fragment Image on ImageEdge {
    node {
      url(width: 307, height: 460)
      urlOriginal
      altText
      isDefault
    }
  }
`;
