import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

export type ForgotPasswordFormScheme = {
  email: string;
};

export const ForgotPasswordFormValidation = object().shape({
  email: string().label("Email").email("form.validation.email.email").required("form.validation.email.required"),
});

export const ForgotPasswordFormResolve = yupResolver(ForgotPasswordFormValidation);
