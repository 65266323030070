import Aside from "components/common/ui/Aside/Aside";
import { FunctionComponent, ReactElement } from "react";
import { ModalProps } from "ts/interfaces";
import { ContentWrapper } from "./PlpAside.Styled";

interface Props {
  heading: string;
  children?: any;
  footer?: ReactElement;
}

const PlpAside: FunctionComponent<ModalProps & Props> = ({
  isOpen,
  close,
  heading,
  children,
  footer,
}) => {
  return (
    <Aside position="right" isOpen={isOpen} close={close} heading={heading} footer={footer}>
      <ContentWrapper>{children}</ContentWrapper>
    </Aside>
  );
};

export default PlpAside;
