// component imports
import BrandSwitcher from "./BrandSwitcher/BrandSwitcher";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import {
  BrandSwitcherWrapper,
  LanguageSwitcherWrapper,
  StyledHeader,
} from "./Header.Styled";

const Header = () => {
  return (
    <StyledHeader>
      <BrandSwitcherWrapper>
        <BrandSwitcher />
      </BrandSwitcherWrapper>
      <LanguageSwitcherWrapper>
        <LanguageSwitcher />
      </LanguageSwitcherWrapper>
    </StyledHeader>
  );
};

export default Header;
