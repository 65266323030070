import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Image } from "types/Product";
import { ImageWrapper, StyledWrapper } from "./PdpFeatureImages.Styled";

type Props = {
	loading?: boolean;
	images: Image[];
};

const PdpFeatureImages = ({ loading, images, ...props }: Props) => {
	const MAX_FEATURE_IMAGES =
		Number(process.env.REACT_APP_PDP_FEATURE_IMAGES) || 3;

	const imagesToRender = useMemo(() => {
		return images.slice(
			0,
			images.length > MAX_FEATURE_IMAGES ? MAX_FEATURE_IMAGES : images.length,
		);
	}, [images]);

	return (
		<StyledWrapper className="PdpProductImages" {...props}>
			{loading &&
				[...Array(MAX_FEATURE_IMAGES)].map((_, index) => (
					<ImageWrapper key={`pdp-feature-image_skeleton-${index}`}>
						<Skeleton />
					</ImageWrapper>
				))}

			{!loading &&
				imagesToRender.map((image, index) => (
					<ImageWrapper key={index}>
						<LazyLoadImage
							key={index}
							src={image.urlOriginal}
							alt={image.altText}
						/>
					</ImageWrapper>
				))}
		</StyledWrapper>
	);
};

export default PdpFeatureImages;
