import Skeleton from 'components/common/ui/Skeleton/Skeleton';
import { StyledLandingPageSkeletonWrapper } from './LandingPageSkeleton.Styled';

const LandingPageSkeleton = () => {
    return (
        <StyledLandingPageSkeletonWrapper className='StyledLandingPageSkeletonWrapper'>
            <div className='three-col-wrapper'>
                {Array.from({ length: 3 }, (ele, i) => (
                    <Skeleton
                        style={{
                            aspectRatio: '1 / 1.5',
                        }}
                        key={`three-col${i}`}
                    />
                ))}
            </div>
            <div className='two-col-portrait-wrapper'>
                {Array.from({ length: 2 }, (ele, i) => (
                    <Skeleton
                        style={{
                            aspectRatio: '1 / 1.5',
                        }}
                        key={`two-col${i}`}
                    />
                ))}
            </div>
        </StyledLandingPageSkeletonWrapper>
    );
};

export default LandingPageSkeleton;