import styled from "styled-components";

import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const AccountAddressesContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		grid-template-columns: repeat(auto-fill, minmax(${remCalc(286)}, 1fr));
		gap: ${remCalc(32)};
	}
`;
