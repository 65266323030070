export const normalizePlace = (place?: google.maps.places.PlaceResult) => {
	if (!place || !place.address_components) {
		return {
			address1: "",
			address2: "",
			city: "",
			country_code: "",
			state_or_province: "",
			postal_code: "",
		};
	}

	const components = place.address_components;

	return {
		address1: `${getStreetNumber(components)} ${getAddressOne(components)}`.trim(),
		address2: getAddressTwo(components),
		city: getCity(components),
		country_code: getCountry(components),
		state_or_province: getState(components),
		postal_code: getPostCode(components),
	};
};

const getComponent = (
	type: string,
	value: "short_name" | "long_name",
	components: google.maps.GeocoderAddressComponent[],
) => {
	const component = components.find((component) =>
		component.types.includes(type),
	);

	if (!component) {
		return "";
	}

	return component[value];
};

const getStreetNumber = (components: google.maps.GeocoderAddressComponent[]) => {
	return getComponent("street_number", "short_name", components);
};

const getState = (components: google.maps.GeocoderAddressComponent[]) => {
	return getComponent("administrative_area_level_1", "short_name", components);
};

const getAddressOne = (components: google.maps.GeocoderAddressComponent[]) => {
	return getComponent("route", "short_name", components);
};

const getAddressTwo = (components: google.maps.GeocoderAddressComponent[]) => {
	if (getCountry(components) === "NZ") {
		return getComponent("sublocality", "short_name", components);
	}

	if (getCountry(components) === "UK") {
		return getComponent("locality", "short_name", components);
	}

	return getComponent("subpremise", "short_name", components);
};

const getCity = (components: google.maps.GeocoderAddressComponent[]) => {
	const keys = [
		{
			type: "postal_town",
			value: "long_name",
		},
		{
			type: "locality",
			value: "long_name",
		},
		{
			type: "neighborhood",
			value: "short_name",
		},
	];

	const r = keys.find((key) => {
		const value = getComponent(key.type, key.value as any, components);

		if (value !== "") {
			return value;
		}
	});


	return r ? getComponent(r.type, r.value as any, components) : "";
};
const getCountry = (components: google.maps.GeocoderAddressComponent[]) => {
	return getComponent("country", "short_name", components);
};

const getPostCode = (components: google.maps.GeocoderAddressComponent[]) => {
	return getComponent("postal_code", "short_name", components);
};
