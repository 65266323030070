import styled from "styled-components";
import { accountLightGrey, dark, darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";
import Modal from "../../common/ui/Modal/Modal";
import { remCalc } from "utilities/styles";

export const AddToCartConformationWrapper = styled(Modal)`
	width: 100%;
	inset: 0;
	top: unset;
	padding: 1rem;
	padding-top: env(safe-area-inset-top, 0);
	max-height: 100%;
	overflow-y: auto;
	padding-bottom: calc(
		${remCalc(175)} + env(safe-area-inset-bottom)
	) !important;

	.btn-close {
		top: calc(17px + env(safe-area-inset-top, 0));
	}

	@media ${aboveMedium} {
		overflow-y: auto;
		max-height: ${remCalc(748)};
		width: 90%;
		max-width: 56.25rem;
		top: 50%;
		left: 50%;
		bottom: unset;
		transform: translate(-50%, -50%);
		padding-block: 2rem;
		padding-inline: clamp(3rem, 7vw, 6rem);
	}

	.ProductCard {
		border: none;
		padding-top: 0;
	}

	.product-details {
		h2 {
			color: ${dark};
			font-weight: 700;
			font-size: 1rem;
		}

		ul > * + * {
			margin-top: 0.25rem;
		}
	}

	.modal-title {
		max-width: 92.5%;
		@media ${aboveMedium} {
			max-width: 100%;
		}
		font-size: 1.25rem;
		color: ${dark};
		font-weight: 700;
		margin-bottom: 1rem;
	}
`;

export const StyledModalBody = styled.div`
	@media ${aboveMedium} {
		display: grid;
		grid-template-columns: 6fr 4fr;
	}

	.button-wrapper {
		background-color: ${accountLightGrey};
		padding: 1rem;
		padding-bottom: calc(3rem + env(safe-area-inset-bottom, 0));
		position: fixed;
		bottom: 0;
		inset-inline: 0;
		z-index: 10;

		@media ${aboveMedium} {
			padding: 1rem;
			position: relative;
			inset: unset;
		}

		& > a {
			width: 100%;
			display: block;
			margin-top: 1rem;
		}

		.checkout-button {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				margin-right: 0.5rem;
			}
		}
	}

	.continue-shopping {
		display: block;
		margin: 1rem auto 0;
		position: fixed;
		bottom: 1rem;
		padding-bottom: calc(env(safe-area-inset-bottom, 0));
		inset-inline: 0;
		font-size: 0.8rem;
		text-transform: uppercase;
		color: ${dark};
		text-align: center;
		transition: color 0.3s ease;
		font-weight: 500;
		z-index: 10;

		@media ${aboveMedium} {
			position: relative;
			width: 100%;
			inset: unset;
		}

		&:hover {
			text-decoration: underline;
			color: ${darkGrey};
		}
	}

	.total-quantity-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.price-total {
		margin-left: 0;
		margin-top: 1rem;
	}
`;

export const StyledCarouselContainer = styled.div`
	& > div {
		gap: 1rem;

		@media ${aboveMedium} {
			gap: 0.5rem;
		}
	}

	.swiper {
		.swiper-slide {
			width: 8rem !important;

			@media ${aboveMedium} {
				width: 12.5rem !important;
			}
		}
	}

	.header {
		& > h3 {
			@media ${aboveMedium} {
				font-size: 0.8rem;
			}
		}
	}
`;
