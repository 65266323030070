import React from "react";

import { AnimatePresence, motion, useAnimationControls } from "framer-motion";
import { StyledButton, StyledItem } from "./Accordion.Styled";
import DropdownArrow from "../Icons/DropdownArrow";

type Props = {
	titleComponent: React.ReactNode;
	bodyComponent: React.ReactNode;
  className?: string;
	onOpen?: () => void;
};

const AccordionItem = ({ titleComponent, bodyComponent, onOpen, ...props }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const controls = useAnimationControls();

	const toggleOpen = () => {
		setIsOpen((isOpen) => {
			if (!isOpen) onOpen?.();
			return !isOpen;
		});
	};

	React.useEffect(() => {
		controls.start({
			rotate: isOpen ? -180 : 0,
			transition: { duration: 0.2, bounce: 0 },
		});
	}, [controls, isOpen]);

	return (
		<StyledItem className={`accordion__item ${props.className}`} $isOpen={isOpen} {...props}>
			<StyledButton
				className="accordion__title"
				onClick={toggleOpen}
			>
				{titleComponent}
				<motion.div animate={controls}>
					<DropdownArrow open={false} />
				</motion.div>
			</StyledButton>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="accordion__body"
						initial={{ height: 0 }}
						animate={{ height: "auto" }}
						exit={{ height: 0 }}
						transition={{ duration: 0.2 }}
						layout
					>
						{bodyComponent}
					</motion.div>
				)}
			</AnimatePresence>
		</StyledItem>
	);
};

export default AccordionItem;
