import styled from "styled-components";

import Link from "components/common/ui/Link/Link";

import { remCalc } from "utilities/styles";
import {
	dark,
	darkGrey,
	grey,
	lightGrey,
	white,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const AddAddressLinkContainer = styled(Link)`
	padding: ${remCalc(24)};

	@media ${aboveMedium} {
		min-width: ${remCalc(286)};
		min-height: ${remCalc(285)};
	}

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: ${remCalc(16)};

	background-color: ${white};
	border: 1px solid ${grey};

	color: ${dark};
	font: normal ${remCalc(20)} / ${remCalc(24)} ${Roboto};
	text-decoration: none;

	.icon__container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ${remCalc(48)};
		height: ${remCalc(48)};
		border-radius: 50%;
		background-color: ${lightGrey};

		svg {
			fill: #45484c;
		}
	}

	&:hover {
		.icon__container {
			background-color: ${dark};

			svg {
				fill: ${white};
			}
		}
	}

	&:active {
		.icon__container {
			background-color: ${darkGrey};
		}

		svg {
			fill: #e9ebec;
		}
	}
`;
