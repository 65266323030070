import styled from "styled-components";
import { accountDarkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledWrapper = styled.div`
  font: normal 14px/17px ${Roboto};
  color: ${accountDarkGrey};

  p {
    margin: 0;
  }
`;
