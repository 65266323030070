import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const usePageContent = () => {
	const location = useLocation();

	const el = document.querySelector("#ion-content");

	const [element, setElement] = useState(el);

	useEffect(() => {
		const el = document.querySelector("#ion-content");
		setElement(el);
	}, [location]);

	const scrollToTop = () => {
		if (element?.scrollEl) {
			element.scrollEl.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	};

	return {
		element,
		scrollToTop,
	};
};

export default usePageContent;
