import Page from "../../components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import { SigninTitle } from "utilities/variables/pageTitles";
import { SigninPageWrapper } from "components/customer/Signin/Signin.Styled";
import Signin from "components/customer/Signin/Signin";
import { useDispatch, useSelector } from "redux/hooks";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { FunctionComponent, useEffect } from "react";
import Alert, { AlertType } from "components/common/ui/Alert/Alert";
import { setPasswordResetMessage } from "redux/UI/uiSlice";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

const SigninPage: FunctionComponent = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const navigate = useLocaleNavigate();

	const {
		auth: { identifier },
		ui: { passwordResetMessage },
	} = useSelector((state) => state);

	useEffect(() => {
		if (identifier && identifier.storeHash) {
			navigate(t(PathTranslationKey.ACCOUNT_OVERVIEW).toString(), {
				replace: true,
			});
		}

		return () => {
			dispatch(setPasswordResetMessage(undefined));
		};
	}, [identifier]);

	return (
		<Page pageType={PageType.PAGE} title={SigninTitle}>
			<SigninPageWrapper>
				{passwordResetMessage && (
					<Alert type={AlertType.SUCCESS} messages={[t(passwordResetMessage)]} />
				)}
				<Signin pageView />
			</SigninPageWrapper>
		</Page>
	);
};

export default SigninPage;
