/* eslint-disable no-useless-escape */
import * as yup from "yup";

export type ProfileFormSchema = {
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	current_password: string;
	password: string;
	password_confirmation: string;
	newsletter_subscribe: boolean;
	newsletter_preference: string;
	company: string;
};

export const profileFormDefaultValues = {
	first_name: "",
	last_name: "",
	email: "",
	phone: "",
	current_password: "",
	password: "",
	password_confirmation: "",
	newsletter_subscribe: false,
	newsletter_preference: "",
	company: "",
} as ProfileFormSchema;

const passwordRegex =
	/^(?=.*[a-zA-Z])(?=.*[\d])[a-zA-Z\d!@#$%^&*()_+={}|[\]\\';:\/?,.~`-]{7,}$/;

const phoneRegex =
	/^(?=.*\d{10,})(?:\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;

const passwordRequirement =
	"Password must include at least one lowercase letter, and one uppercase letter";

export const profileFormSchema = yup.object().shape(
	{
		first_name: yup
			.string()
			.label("First Name")
			.required("form.validation.first_name.required")
			.min(2),
		last_name: yup
			.string()
			.label("Last Name")
			.required("form.validation.last_name.required")
			.min(2),
		email: yup
			.string()
			.email("form.validation.email.email")
			.label("Email")
			.required("form.validation.email.required"),
		phone: yup
			.string()
			.label("Phone")
			.required("form.validation.phone.required")
			.test({
				message: "form.validation.phone.regex",
				test: (value) => {
					// Let the required check handle it if no input
					if (!value) {
						return true;
					}

					return phoneRegex.test(value);
				},
			}),
		current_password: yup
			.string()
			.label("Current Password")
			.when("password", {
				is: (val: string) => val && val.length > 0,
				then: yup.string().required("form.validation.password.incorrect"),
				otherwise: yup.string().notRequired(),
			}),
		password: yup.string().notRequired().label("New Password"),
		// .when("current_password", {
		// 	is: (val: string) => !val,
		// 	then: yup.string().required("form.validation.password.required"),
		// 	otherwise: yup.string().notRequired(),
		// })
		// .test(
		// 	"isValidPassword",
		// 	"form.validation.password.alpha_num",
		// 	(value) => {
		// 		if (!value) {
		// 			return false;
		// 		}
		// 		return passwordRegex.test(value);
		// 	},
		// )
		// .test("isPasswordShort", "form.validation.password.min", (value) => {
		// 	if (!value) {
		// 		return false;
		// 	}
		// 	return value.length > 7;
		// }),
		password_confirmation: yup
			.string()
			.label("Confirm New Password")
			.oneOf(
				[yup.ref("password")],
				"form.validation.password_confirmation.invalid",
			),
		interest: yup.string().label("Interest"),
		newsletter_subscribe: yup.boolean().label("Newsletter"),
		company: yup.string().label("Company"),
	},
	[["current_password", "password"]],
);
