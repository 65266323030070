import { bigcommerceObjectByUrl } from "app/gql/queries/bigcommerceObjectByUrl";
import { bigcommerceGetCategoryProductPage } from "app/gql/queries/bigcommerceGetCategoryProudctPage";
import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import {
	convertBigCommerceCategory,
	convertBigCommerceProduct,
} from "app/utils/bigcommerceApiUtil";
import { bigcommerceGetCategoryViaSearch } from "app/gql/queries/bigcommerceGetCategoryViaSearch";

export const categoryApi = bigCommerceGQL.injectEndpoints({
	endpoints: (builder) => ({
		getCategory: builder.query({
			query: (path: string) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceObjectByUrl(path),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				return convertBigCommerceCategory(res.data.site.route.node);
			},
		}),
		getCategoryPage: builder.query({
			query: (args: {
				entityId: number;
				pageSize: number;
				cursor?: string;
			}) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetCategoryProductPage(
						args.entityId,
						args.pageSize,
						args.cursor,
					),
				},
			}),
			transformResponse: (res: any): any => {
				if (res.data) {
					const products = res.data.site.category.products.edges.map(
						(edge: any) => convertBigCommerceProduct(edge),
					);

					const pageInfo = { ...res.data.site.category.products.pageInfo };

					return {
						pageInfo,
						products,
					};
				}

				return undefined;
			},
		}),
		getCategoryViaSearch: builder.query({
			query: (args: {
				entityId?: number;
				nextProductSetCursor?: string
				filterAttributes?: {
					attribute: string;
					values: string[];
				}[];
				filterCategoryIds?: number[],
				filterPrice?: {
					minPrice?: number
					maxPrice?: number
				},
				sort: string
			}) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetCategoryViaSearch({
						...args?.nextProductSetCursor && {
							nextProductSetCursor: args.nextProductSetCursor
						},
						searchSubCategories: true,
						...args?.filterAttributes && {
							productAttributes: args.filterAttributes
						},
						categoryEntityIds: args.entityId ? [args.entityId] : [...(args.filterCategoryIds || [])],
						...(args.filterPrice && (args.filterPrice.minPrice || args.filterPrice.maxPrice)) && {
							price: {
								...args.filterPrice.minPrice && {
									minPrice: args.filterPrice.minPrice
								},
								...args.filterPrice.maxPrice && {
									maxPrice: args.filterPrice.maxPrice
								}
							}
						},
						sort: args.sort
					}),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return null;
 				}

				const products = res.data.site.search.searchProducts.products.edges.map(
					(edge: any) => convertBigCommerceProduct(edge),
				);

				const pageInfo = { ...res.data.site.search.searchProducts.products.pageInfo };

				const filters = res.data.site.search.searchProducts.filters.edges.map(
					(filter: any) => {

						if (filter.node.__typename === 'OtherSearchFilter') {
							return null
						}

						const transformCategoryFilterResponse = () => {
							return {
								values: filter.node.categories.edges.map(
									(category: any) => {
										return {
											label: category.node.name,
											value: category.node.entityId,
											count: category.node.productCount,
											isSelected: category.node.isSelected,
											subCategories: category.node.subCategories.edges.map(
												(sub: any) => {
													return {
														label: sub.node.name,
														value: sub.node.entityId,
														count: sub.node.productCount,
														isSelected: sub.node.isSelected,
														subCategories: sub.node.subCategories.edges.map(
															(sub: any) => {
																return {
																	label: sub.node.name,
																	value: sub.node.entityId,
																	count: sub.node.productCount,
																	isSelected: sub.node.isSelected
																}
															}
														)
													}
												}
											)
										}
									}
								)
							}
						}

						const transformProductAttributeFilterResponse = () => {
							return {
								values: filter.node.attributes.edges.map(
									(attribute: {[key:string]: any}) => {
										return {
											label: attribute.node.value,
											value: attribute.node.value,
											count: attribute.node.productCount,
										}
									}
								)
							}
						}

						const transformPriceFilterResponse = () => {
							return {
								...filter.node.selected
							}
						}

						return {
							label: filter.node.name,
							isCollapsedByDefault: filter.node.isCollapsedByDefault,
							__typename: filter.node.__typename,
							...filter.node.__typename === "CategorySearchFilter" && {
								...transformCategoryFilterResponse()
							},
							...filter.node.__typename === "ProductAttributeSearchFilter" && {
								...transformProductAttributeFilterResponse()
							},
							...filter.node.__typename === "PriceSearchFilter" && {
								...transformPriceFilterResponse()
							},
						}
					}
				).filter(Boolean);

				return {
					pageInfo,
					products,
					filters,
				};
			}
		})
	}),
});

export const { useLazyGetCategoryQuery, useLazyGetCategoryPageQuery, useGetCategoryViaSearchQuery, useLazyGetCategoryViaSearchQuery } =
	categoryApi;
