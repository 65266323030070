import styled from "styled-components";
import { accountDarkGrey, darkGrey, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import Label from "../Label/Label";
import { remCalc } from "utilities/styles";

export const StyledInput = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 2px 0px 0px 2px;
  padding: 0px 11px;
  height: 40px;
  color: ${accountDarkGrey};
  font: normal 16px/20px ${Roboto};
  outline: none;
  display: none;

  &:hover,
  &:focus {
    border: 1px solid ${grey};
  }

  &[type="file"] {
    padding: unset;
    height: unset;
    background-color: unset;
    border: none;
  }
`;

export const InputLabel = styled(Label)`
  margin-top: ${remCalc(6)};
  margin-bottom: ${remCalc(16)};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${darkGrey};
  }

`;