import { ReactComponent as Dashboard } from "assets/images/svg/dashboard.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconDashboard({className}: Props): ReactElement {
    return <Icon className={className} icon={<Dashboard />} />;
}

export default IconDashboard;