import uuid from "react-uuid";

import ImageTextSplit from "components/common/layouts/ImageTextSplit/ImageTextSplit";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

import { useLazyGetCompanyPageQuery } from "app/api/company/CompanyPageApi";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "redux/hooks";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "../CompanyPages.Styled";
import {
	SkeletonImageSplitWrapper,
	StyledResponsibilityDetailPageWrapper,
} from "./SocialResponsibilityDetail.Styled";
import { useMatch } from "react-router";

const SocialResponsibilityDetailPage = () => {
	const path = useMatch("/:locale/*")?.params["*"];

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [triggerPageData, { data: pageData }] = useLazyGetCompanyPageQuery();
	const [triggerAdditionalLinks, { data: socialAdditionalLinks }] =
		useLazyGetCompanyPageQuery();

	useEffect(() => {
		if (path) {
			const parentPage = wordpressTemplates.find(
				(template) =>
					template.template === WordpressTemplateEnum.SocialResponsibility,
			);
			if (parentPage) {
				triggerAdditionalLinks({
					pageId: parentPage.id,
					pageType: "responsibility",
				});
			}

			const page = wordpressTemplates.find((template) =>
				template.uri.includes(path),
			);
			if (page) {
				triggerPageData({
					pageId: page.id,
					pageType: "responsibilityDetail",
				});
			}
		}
	}, [path, wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const imageData = useMemo(() => {
		if (!pageData?.pageData?.featuredImage) {
			return undefined;
		}

		return [
			{
				image: {
					desktopImage: pageData?.pageData?.featuredImage?.node.sourceUrl,
					mobileImage: pageData?.pageData?.featuredImage?.node.sourceUrl,
					alt: pageData?.pageData?.featuredImage?.node.altText,
				},
				ctaLink: "",
				ctaText: pageData?.pageData?.featuredImage?.node.title,
			},
		];
	}, [pageData]);

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					'index'
				])}
			/>
			<StyledResponsibilityDetailPageWrapper className="StyledResponsibilityDetailPageWrapper">
				{imageData && (
					<StyledMainImageBanner className="main-image-banner">
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={true}
							imageText={pageData?.pageData?.title}
							isLoading={loading}
							isBottomBanner={false}
						/>
					</StyledMainImageBanner>
				)}

				{loading ? (
					<div className="skeleton-wrapper">
						<SkeletonImageSplitWrapper>
							<Skeleton containerClassName="skeleton-container" />
						</SkeletonImageSplitWrapper>
						<div className="skeleton-text">
							<Skeleton height="40px" />
							<Skeleton count={13} />
						</div>
					</div>
				) : (
					pageData?.pageData?.responsibilityDetail.contentBlock.map(
						(content: any) => (
							<ImageTextSplit
								isFlipped={content.fieldGroupName.includes("ImageRight")}
								image={content.image}
								text={content.text}
								title={content.heading}
								key={uuid()}
								isLoading={loading}
							/>
						),
					)
				)}

				{socialAdditionalLinks?.socialAdditionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage: link.images.desktopImage.sourceUrl,
									mobileImage:
										link.images.mobileImage.sourceUrl ||
										link.images.desktopImage.sourceUrl,
									alt: link.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.link.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledResponsibilityDetailPageWrapper>
		</Page>
	);
};

export default SocialResponsibilityDetailPage;
