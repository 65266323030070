import { FunctionComponent } from "react";
import styled from "styled-components";

const StyledDivider = styled.span`
    cursor: default !important;
    border-right: 1px solid #adb5bd; //1px total
    margin: 0 6px 0 12px;
    height: 16px;
    align-self: center;
`;

interface Props {
  className?: string;
}

const Divider: FunctionComponent<Props> = ({ className = '' }) => {
  return <StyledDivider className={className} />;
};

export default Divider;
