import { createSlice } from "@reduxjs/toolkit";
import { getGlobalStore } from "app/bigcommerce/helpers/BcConfigHelper";

import { BcStore } from "ts/types";
import { Currencies, Settings } from "types/BigCommerce";

export const bcStoreSlice = createSlice({
	name: "bcStore",
	initialState: {
		store: null as null | BcStore,
		currencies: [] as Currencies,
		settings: null as null | Settings,
	},
	reducers: {
		setStore: (state, action) => {
			state.store = action.payload ? action.payload : getGlobalStore();
		},
		setCurrencies: (state, action: { payload: Currencies }) => {
			const currencies = action.payload;
			return { ...state, currencies };
		},
		setSettings: (state, action: { payload: Settings }) => {
			const settings = action.payload;
			return { ...state, settings };
		},
	},
});

export const { setStore, setCurrencies, setSettings } = bcStoreSlice.actions;

export default bcStoreSlice.reducer;

export const selectStore = (state: any): BcStore => state.bcStore.store;
