import { logout, setCustomer } from "redux/auth/authSlice";

import { Address } from "types/Customer";
import { integrationLayer } from "app/services/integrationLayerService";

async function SafeAPIExecution(api: any) {
	try {
		await api.queryFulfilled;
	} catch (err: any) {
		console.error("Failed to execute API call", err);

		if (err.error.status === 403 || !err.error.data?.success) {
			api.dispatch(logout());
		}
	}
}

export const customerApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		getMe: builder.query<any, void>({
			query: () => ({
				// TODO check where this was used and refactor it not to call the addresses endpoint, since we can fetch the addresses in this request
				// on the /rs/nalog page two requests are made - one for the profile and one for the addresses. prior to this commit, the line below had a badly formatted query string, which would only fetch the attributes, but not the addresses as well. this was fixed by comma-separating the values of the include query parameter
				url: `/bc/customer/me?include=addresses,attributes,formFields`,
				method: "GET",
			}),
			providesTags: ["Customer"],
			async onQueryStarted(arg, api) {
				try {
					const res = await api.queryFulfilled;
					api.dispatch(setCustomer(res.data.data));
				} catch (err: any) {
					console.log("Failed to fetch user account", err);

					if (err.error.status === 403 || !err.error.data?.success) {
						api.dispatch(logout());
					}
				}
			},
		}),
		deleteMe: builder.query<any, void>({
			query: () => ({
				url: `/bc/customer/me`,
				method: "DELETE",
			}),
		}),
		createAddress: builder.mutation({
			query: (address: Address) => ({
				url: "/bc/customer/address",
				method: "POST",
				body: address,
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
		}),
		deleteAddress: builder.mutation({
			query: (id) => ({
				url: `/bc/customer/address/${id}`,
				method: "DELETE",
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
		}),
		getAddress: builder.mutation({
			query: (id) => ({
				url: `/bc/customer/address/${id}`,
				method: "GET",
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
			transformResponse: (data: any) => data?.data as Address,
		}),
		getAddresses: builder.query({
			query: () => ({
				url: "/bc/customer/addresses",
				method: "GET",
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
			transformResponse: (data: any) => data?.data as Address[],
		}),
		updateAddress: builder.mutation({
			query: (address: Address) => ({
				url: `/bc/customer/address/${address.id}`,
				method: "PUT",
				body: address,
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
		}),
		updateProfile: builder.mutation({
			query: (body) => ({
				url: "/bc/customer/me",
				method: "PUT",
				body,
			}),
			async onQueryStarted(arg, api) {
				SafeAPIExecution(api);
			},
		}),
	}),
});

export const { useLazyGetMeQuery } = customerApi;

export default customerApi;
