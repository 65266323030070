import React, { useState } from "react";
import DropdownArrow from "../Icons/DropdownArrow";
import { Container } from "./CollapseContainer.styles";

type Props = {
	title: string;
	children: any;
	className?: any;
	isOpenByDefault?: boolean;
};

const CollapseContainer = ({ title, children, className = '', isOpenByDefault = true }: Props) => {
	const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault);
	return (
		<Container isOpen={isOpen} className={className}>
			<div onClick={() => setIsOpen(!isOpen)} className="header">
				<h1>{title}</h1>
				<DropdownArrow open={isOpen} />
			</div>
			<div className="body">{children}</div>
		</Container>
	);
};

export default CollapseContainer;
