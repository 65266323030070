import { FunctionComponent } from "react";
import { StyledMenuItem } from "./MenuItem.Styled";

export interface MenuItemProps {
  onClick?: Function
  children?: any;
}

export interface StyledaleProps {
  additionalStyles?: any;
  className?: string;
}

const MenuItem: FunctionComponent<MenuItemProps & StyledaleProps> = ({
  onClick = () => {},
  children,
  additionalStyles,
  className
}) => {
  return (<StyledMenuItem additionalStyles={additionalStyles} className={className} onClick={() => onClick()}>{children}</StyledMenuItem>);
}

export default MenuItem;