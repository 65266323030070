import { Gender } from "./PdpSizeGuide";

export const femaleSizeGuide = {
	upperApparel: {
		34: {
			chest: {
				size: 80,
				range: [78, 82],
			},
			waist: {
				size: 63,
				range: [61, 65],
			},
			hip: {
				size: 88,
				range: [86, 90],
			},
		},
		36: {
			chest: {
				size: 84,
				range: [83, 86],
			},
			waist: {
				size: 67,
				range: [66, 69],
			},
			hip: {
				size: 92,
				range: [91, 94],
			},
		},
		38: {
			chest: {
				size: 88,
				range: [87, 90],
			},
			waist: {
				size: 71,
				range: [70, 73],
			},
			hip: {
				size: 96,
				range: [95, 98],
			},
		},
		40: {
			chest: {
				size: 92,
				range: [91, 94],
			},
			waist: {
				size: 75,
				range: [74, 77],
			},
			hip: {
				size: 100,
				range: [99, 102],
			},
		},
		42: {
			chest: {
				size: 96,
				range: [95, 98],
			},
			waist: {
				size: 79,
				range: [78, 81],
			},
			hip: {
				size: 104,
				range: [103, 106],
			},
		},
		44: {
			chest: {
				size: 100,
				range: [99, 102],
			},
			waist: {
				size: 83,
				range: [82, 85],
			},
			hip: {
				size: 108,
				range: [107, 110],
			},
		},
		46: {
			chest: {
				size: 104,
				range: [103, 108],
			},
			waist: {
				size: 87,
				range: [86, 91],
			},
			hip: {
				size: 112,
				range: [111, 114],
			},
		},
		48: {
			chest: {
				size: 110,
				range: [109, 114],
			},
			waist: {
				size: 93,
				range: [92, 97],
			},
			hip: {
				size: 116,
				range: [115, 119],
			},
		},
	},
	bottomApparel: {
		34: {
			waist: {
				size: 63,
				range: [61, 65],
			},
			hip: {
				size: 88,
				range: [86, 90],
			},
		},
		36: {
			waist: {
				size: 67,
				range: [66, 69],
			},
			hip: {
				size: 92,
				range: [91, 94],
			},
		},
		38: {
			waist: {
				size: 71,
				range: [70, 73],
			},
			hip: {
				size: 96,
				range: [95, 98],
			},
		},
		40: {
			waist: {
				size: 75,
				range: [74, 77],
			},
			hip: {
				size: 100,
				range: [99, 102],
			},
		},
		42: {
			waist: {
				size: 79,
				range: [78, 81],
			},
			hip: {
				size: 104,
				range: [103, 106],
			},
		},
		44: {
			waist: {
				size: 83,
				range: [82, 85],
			},
			hip: {
				size: 108,
				range: [107, 110],
			},
		},
		46: {
			waist: {
				size: 87,
				range: [86, 91],
			},
			hip: {
				size: 112,
				range: [111, 114],
			},
		},
		48: {
			waist: {
				size: 93,
				range: [92, 97],
			},
			hip: {
				size: 116,
				range: [115, 119],
			},
		},
	},
};

export const maleSizeGuide = {
	upperApparel: {
		46: {
			chest: {
				size: 92,
				range: [90, 94],
			},
			waist: {
				size: 80,
				range: [78, 82],
			},
			neck: {
				size: 38,
				range: [37.5, 38.5],
			},
			head: {
				size: 55,
				range: [54.5, 55.5],
			},
		},
		48: {
			chest: {
				size: 96,
				range: [94, 98],
			},
			waist: {
				size: 84,
				range: [82, 86],
			},
			neck: {
				size: 39,
				range: [38.5, 39.5],
			},
			head: {
				size: 56,
				range: [55.5, 56.5],
			},
		},
		50: {
			chest: {
				size: 100,
				range: [98, 102],
			},
			waist: {
				size: 88,
				range: [86, 90],
			},
			neck: {
				size: 40,
				range: [39.5, 40.5],
			},
			head: {
				size: 57,
				range: [56.5, 56.5],
			},
		},
		52: {
			chest: {
				size: 104,
				range: [102, 106],
			},
			waist: {
				size: 92,
				range: [90, 94],
			},
			neck: {
				size: 41,
				range: [40.5, 41.5],
			},
			head: {
				size: 58,
				range: [57.5, 58.5],
			},
		},
		54: {
			chest: {
				size: 108,
				range: [106, 110],
			},
			waist: {
				size: 97,
				range: [95, 100],
			},
			neck: {
				size: 42,
				range: [41.5, 42.5],
			},
			head: {
				size: 59,
				range: [58.5, 59.5],
			},
		},
		56: {
			chest: {
				size: 112,
				range: [110, 114],
			},
			waist: {
				size: 102,
				range: [100, 105],
			},
			neck: {
				size: 43,
				range: [42.5, 43.5],
			},
			head: {
				size: 60,
				range: [59.5, 60.5],
			},
		},
		58: {
			chest: {
				size: 116,
				range: [114, 118],
			},
			waist: {
				size: 107,
				range: [105, 110],
			},
			neck: {
				size: 44,
				range: [43.5, 44.5],
			},
			head: {
				size: 61,
				range: [60.5, 61.5],
			},
		},
		60: {
			chest: {
				size: 120,
				range: [118, 122],
			},
			waist: {
				size: 112,
				range: [110, 114],
			},
			neck: {
				size: 45,
				range: [44.5, 45.5],
			},
			head: {
				size: 62,
				range: [61.5, 62.5],
			},
		},
	},
	bottomApparel: {
		46: {
			waist: {
				size: 82,
				range: [80, 84],
			},
			hip: {
				size: 95,
				range: [94, 97],
			},
		},
		48: {
			waist: {
				size: 86,
				range: [84, 88],
			},
			hip: {
				size: 98,
				range: [97, 100],
			},
		},
		50: {
			waist: {
				size: 90,
				range: [88, 93],
			},
			hip: {
				size: 101,
				range: [100, 103],
			},
		},
		52: {
			waist: {
				size: 95,
				range: [93, 98],
			},
			hip: {
				size: 104,
				range: [103, 106],
			},
		},
		54: {
			waist: {
				size: 100,
				range: [98, 103],
			},
			hip: {
				size: 107,
				range: [106, 109],
			},
		},
		56: {
			waist: {
				size: 105,
				range: [103, 108],
			},
			hip: {
				size: 110,
				range: [109, 112],
			},
		},
		58: {
			waist: {
				size: 110,
				range: [108, 113],
			},
			hip: {
				size: 113,
				range: [112, 115],
			},
		},
		60: {
			waist: {
				size: 115,
				range: [113, 118],
			},
			hip: {
				size: 116,
				range: [115, 118],
			},
		},
	},
};

const getSizeGuide = (gender: Gender) => {
	if (gender === Gender.MALE) {
		return maleSizeGuide;
	} else {
		return femaleSizeGuide;
	}
};

export type SizeGuideOptions = {
	isShirt: boolean;
	isBottomApparel: boolean;
	for?: "size guide" | "size calculator";
};

export const getDataForSizeGuide = (
	gender: Gender,
	size: number,
	options: SizeGuideOptions,
) => {
	const guideForGender = getSizeGuide(gender) as any;

	if (!options.isBottomApparel && !options.isShirt) {
		return guideForGender.upperApparel[size];
	}

	if (options.isBottomApparel && !options.isShirt) {
		return guideForGender.bottomApparel[size];
	}

	if (!options.isBottomApparel && options.isShirt) {
		const upperApparelGuide = guideForGender.upperApparel;

		const guideDataValues = Object.values(upperApparelGuide);

		let neckGuideData = {} as any;

		guideDataValues.forEach((obj: any) => {
			neckGuideData = {
				...neckGuideData,
				[obj.neck.size]: obj,
			};
		});

		return neckGuideData[size];
	}

	return undefined;
};

export const getDataForSizeCalculator = (
	gender: Gender,
	options: SizeGuideOptions,
) => {
	const guideForGender = getSizeGuide(gender) as any;

	if (!options.isBottomApparel && !options.isShirt) {
		return guideForGender.upperApparel;
	}

	if (options.isBottomApparel && !options.isShirt) {
		return guideForGender.bottomApparel;
	}

	if (!options.isBottomApparel && options.isShirt) {
		const upperApparelGuide = guideForGender.upperApparel;

		const guideDataValues = Object.values(upperApparelGuide);

		let neckGuideData = {} as any;

		guideDataValues.forEach((obj: any) => {
			neckGuideData = {
				...neckGuideData,
				[obj.neck.size]: obj,
			};
		});

		return neckGuideData;
	}

	return undefined;
};