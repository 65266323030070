import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	margin: 32px 0px 24px;

	display: flex;
	flex-direction: column;
	padding: 0;

	@media ${aboveMedium} {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 12px;
		margin-top: 16px;
	}
`;

export const ImageWrapper = styled.div`
	padding: ${remCalc(8)} 0;

	@media ${aboveMedium} {
		flex: 50%;
		max-width: 50%;

		padding: ${remCalc(8)};
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.skeleton {
			padding-bottom: 149%;
		}
`;
