import { EvenContainer } from "../BlogContainer.styles";
import ImageItemCommon from "../ImageItemCommon/ImageItemCommonBase";
import { FinalGridWrapper } from "./PostGroupFinal.styles";

interface DataArray {
	data: any[];
	pb?: string;
	pt?: string;
}

export const PostGroupFinal = ({ data, pb, pt }: DataArray) => {
	if (data.length < 1) {
		return <div />;
	}
	return (
		<EvenContainer top={'0rem'} bot={'0rem'}>
			<FinalGridWrapper>
				<div className="grid-container">
					{data.map((item, index) => {
						return (
							<div key={"bottwo-" + index} className={"item" + index}>
								<ImageItemCommon data={item} />
							</div>
						);
					})}
				</div>
			</FinalGridWrapper>
		</EvenContainer>
	);
};
