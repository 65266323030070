import { gql } from "graphql-request";

const CategoryTree3LevelsDeep = {
	query: gql`
		query CategoryTree3LevelsDeep {
			site {
				categoryTree {
					...CategoryFields
					children {
						...CategoryFields
						children {
							...CategoryFields
							children {
								...CategoryFields
							}
						}
					}
				}
			}
		}

		fragment CategoryFields on CategoryTreeItem {
			name
			path
			entityId
		}
	`,
};

export default CategoryTree3LevelsDeep;
