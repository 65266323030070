import styled, { css } from "styled-components";
import { white } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const Wrapper = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media ${aboveMedium} {
    flex-direction: row;
    padding: 20px;
  }
`;

export const FilterSort = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-top: 16px;

  @media ${aboveMedium} {
    flex-direction: row;
    margin-top: 0px;
  }
`

export const ButtonGroupStyles = css`
  gap: 12px;
  button {
    flex-grow: 0;

    &:first-of-type {
      margin-right: auto;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }
`