import React from "react";
import { StyledTextButton, StyledTextButtonContent } from "./Button.Styled";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const TextButton = ({ className, children, onClick }: Props) => {
  return (
    <StyledTextButton className={className} onClick={onClick}>
      <StyledTextButtonContent>{children}</StyledTextButtonContent>
    </StyledTextButton>
  );
};

export default TextButton;
