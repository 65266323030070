import React from "react";
import { useTranslation } from "react-i18next";
import {
	StyledButton,
	StyledTextInput,
	StyledWrapper,
} from "./UrlCopyInput.Styled";

const UrlCopyInput = ({ url }: { url: string }) => {
	const { t } = useTranslation();
	const [isCopied, setIsCopied] = React.useState(false);

	const onCopy = async () => {
		await navigator.clipboard.writeText(url);
		setIsCopied(true);
	};

	React.useEffect(() => {
		if (isCopied) {
			setTimeout(() => setIsCopied(false), 1500);
		}
	}, [isCopied]);

	return (
		<StyledWrapper>
			<StyledTextInput value={url} disabled />
			<StyledButton
				variant="primary_01"
				onClick={onCopy}
				disabled={isCopied}
				className={isCopied ? "copied" : ""}
			>
				{t("copy_link")}
				<span>{t("copied")}!</span>
			</StyledButton>
		</StyledWrapper>
	);
};

export default UrlCopyInput;
