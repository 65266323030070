import Modal from "components/common/ui/Modal/Modal";
import styled, { css } from "styled-components/macro";

export const VariantsContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 4px;
`;

export const ModalContainerStyles = css`
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #F8F9FA;
  bottom: 0;
  padding: 16px 14px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom, 0)) !important;

  display: flex;
  flex-direction: column;

  z-index: 1501;
  box-shadow: none;
`;


export const OverlayStyles = css`
  z-index: 1500;
`;