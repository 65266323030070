import { capitalize } from "lodash";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "redux/hooks";
import {
	NewsArticle,
	BreadcrumbPart,
	Product,
	ProductAvailability,
	ProductCondition,
} from "ts/types";

enum TypeEnum {
	BreadcrumbList = "BreadcrumbList",
	Product = "Product",
	NewsArticle = "NewsArticle",
}

type Type = "BreadcrumbList" | "Product" | "NewsArticle";

type Data = BreadcrumbPart[] | Product | NewsArticle;

type Props = {
	type: Type;
	data: Data;
};

const StructuredData = ({ type, data }: Props) => {
	const location = window.location;
	const storeCode = useSelector((state) => state.bcStore.store?.code);

	const getItemListElements = (breadcrumbs: BreadcrumbPart[]) => {
		return breadcrumbs.map((breadcrumb, index) => ({
			"@type": "ListItem",
			position: index + 1,
			name: capitalize(breadcrumb.name),
			...(index + 1 !== breadcrumbs.length && {
				item: `${location.origin}/${storeCode}/c${breadcrumb.path}`,
			}),
		}));
	};

	const getItemCondition = (condition: ProductCondition) => {
		switch (condition) {
			case "NEW":
				return "https://schema.org/NewCondition";
			case "REFURBISHED":
				return "https://schema.org/RefurbishedCondition";
			case "USED":
				return "https://schema.org/UsedCondition";
			default:
				break;
		}
	};

	const getAvailability = (availability: ProductAvailability) => {
		switch (availability) {
			case "Available":
				return "https://schema.org/InStock";
			case "Preorder":
				return "https://schema.org/PreOrder";
			case "Unavailable":
				return "https://schema.org/OutOfStock";
			default:
				break;
		}
	};

	const getProductProperties = (product: Product) => {
		return {
			name: product.name,
			image: product.images.map((image) => image.url ?? image.urlOriginal),
			description: product.plainTextDescription,
			sku: product.sku,
			mpn: product.mpn,
			...(product.brand && {
				brand: { "@type": "Brand", name: product.brand?.name },
			}),
			offers: {
				"@type": "Offer",
				url: location.href,
				priceCurrency: product.price.currencyCode,
				price: product.price.value,
				itemCondition: getItemCondition(product.condition),
				availability: getAvailability(product.availability),
			},
		};
	};

	const getBlogPostProperties = (blogpost: NewsArticle) => {
		return {
			headline: blogpost.title,
			image: blogpost.featuredImage?.node?.sourceUrl,
			datePublished: blogpost.date,
			dateModified: blogpost.modified,
		};
	};

	const getStructuredData = (type: Type, data: Data) => {
		switch (type) {
			case TypeEnum.BreadcrumbList:
				return {
					"@context": "https://schema.org",
					"@type": TypeEnum.BreadcrumbList,
					itemListElement: getItemListElements(data as BreadcrumbPart[]),
				};
			case TypeEnum.Product:
				return {
					"@context": "https://schema.org",
					"@type": TypeEnum.Product,
					...getProductProperties(data as Product),
				};
			case TypeEnum.NewsArticle:
				return {
					"@context": "https://schema.org",
					"@type": TypeEnum.NewsArticle,
					...getBlogPostProperties(data as NewsArticle),
				};
			default:
				break;
		}
	};

	const [jsonLd, setJsonLd] = React.useState<string>();

	React.useEffect(() => {
		const structuredData = getStructuredData(type, data);
		const nextJsonLd = JSON.stringify(structuredData);
		setJsonLd(nextJsonLd);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, type]);

	return (
		<Helmet>
			{jsonLd && <script type="application/ld+json">{jsonLd}</script>}
		</Helmet>
	);
};

export default StructuredData;
