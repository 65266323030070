import { CreateCustomerResponse } from "app/types/integrationLayerTypes";
import { FormFieldNames } from "app/bigcommerce/constants";
import { RegistrationFormScheme } from "components/customer/registration/RegistrationForm/RegistrationForm.Schema";
import { integrationLayer } from "app/services/integrationLayerService";

const transformErrorResponse = (err: any) => {
	const data = err.data;

	if (data.error.status === 422 && data.error.data.validation) {
		// this means the server-side validation has failed

		const validation = data.error.data.validation;

		return {
			type: "fail_validation",
			message: "Please review details",
			data: {
				firstName: validation.first_name,
				lastName: validation.last_name,
				email: validation.email,
				phone: validation.phone,
				address1: validation.address1,
				city: validation.city,
				postal_code: validation.postal_code,
				country_code: validation.country,
				password: validation.password,
				passwordConfirmation: validation.password_confirmation,
				loyaltyProgram: validation.join_loyalty,
			},
		};
	} else if (
		data.error.status === 422 &&
		data.error.data.errors[".customer_create"]
	) {
		let email;
		try {
			email = data.error.data.errors[".customer_create"]
				.split("email")[1]
				.split("already")[0]
				.trim();
		} catch {
			email = "";
		}

		return {
			type: "account_exists",
			email,
			message: data.error.data.errors[".customer_create"],
			accountType: data.error.data["account_type"] || "email",
		};
	} else {
		return {
			type: "error",
			message: "Something went wrong",
		};
	}
};

const api = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		register: builder.mutation({
			query: (form: RegistrationFormScheme) => ({
				url: `/bc/customer`,
				method: "POST",
				body: {
					first_name: form.firstName,
					last_name: form.lastName,
					email: form.email,
					phone: form.phone,
					address1: form.address1,
					address2: form.address2 || "",
					city: form.city,
					postal_code: form.postal_code,
					state_or_province: " ", //this is need otherwise is fails
					country_code: form.country_code,
					password: form.password,
					password_confirmation: form.passwordConfirmation,
					privacy_policy: form.privacyPolicy,
					newsletter_subscribe: form.newsletterPreference.length !== 0,
					join_loyalty: form.loyaltyProgram,
					company: form.company ? form.company : undefined, // this is only present for company accounts
					form_fields: form.legal_entity_id // this is only present for company accounts
						? [
								{
									name: FormFieldNames.LegalEntityID,
									value: form.legal_entity_id,
								},
						  ]
						: undefined,
					attributes: [
						{
							attribute_id: 2, //this is to set the account_type attribute
							attribute_value: form.accountType,
						},
					],
				},
			}),
			transformErrorResponse,
			transformResponse: (res: any, meta, args): CreateCustomerResponse => {
				return { ...res };
			},
		}),
	}),
});

export const { useRegisterMutation } = api;
