import { useMatch } from "react-router";
import { EvenContainer } from "../BlogContainer.styles";
import { BlogPostItem } from "./BlogPostItem";
import { ArticleContainer } from "./BlogPostStack.styles";

interface Props {
	data: any[];
}

export const BlogPostStack = ({ data }: Props) => {
	const path = useMatch("/:locale/*")?.params["*"] || "";

	if (data.length < 1) {
		return <></>;
	}
	return (
		<EvenContainer>
			<ArticleContainer>
				<img src={data[0]?.featuredImage} alt="feature" />
				<div className="rightlist">
					{data.map((item) => {
						return (
							<div key={item.postID}>
								<BlogPostItem
									uri={`${path}/${item.uri}`}
									title={item.title}
									category={item.categories}
								/>
							</div>
						);
					})}
				</div>
			</ArticleContainer>
		</EvenContainer>
	);
};
