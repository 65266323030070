import styled from "styled-components";

import { remCalc } from "utilities/styles";
import {
	accountDarkGrey,
	accountLightGrey,
	dark,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledContainer = styled.div`
	padding: ${remCalc(24)};

	@media ${aboveMedium} {
		min-width: ${remCalc(286)};
		min-height: ${remCalc(285)};
	}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: ${remCalc(24)};

	background-color: ${accountLightGrey};

	.details__container {
		display: flex;
		flex-direction: column;

		.detail {
			font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
			color: ${accountDarkGrey};
			margin: 0;

			&--heading {
				margin-bottom: ${remCalc(16)};
				font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
				color: ${dark};
			}

			&--phone {
				margin-top: ${remCalc(16)};
			}
		}
	}

	.buttons__container {
		display: flex;
		align-items: center;
		gap: ${remCalc(16)};
	}
`;
