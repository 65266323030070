export const supportedCountries = [
    'AT',
    'BE',
    'CA',
    'CH',
    'CZ',
    'DE',
    'DK',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'IE',
    'IT',
    'NL',
    'NO',
    'NZ',
    'PL',
    'PT',
    'SE',
];
export const supportedCountriesRequiringStates = ['AU'];
