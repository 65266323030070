import Button from "components/common/ui/Button/Button";
import Link from "components/common/ui/Link/Link";

import { Wishlist } from "../AccountWishlistOverview";
import WishlistShareModal from "../../WishlistShareModal/WishlistShareModal";
import WishlistDeleteModal from "../WishlistDeleteModal/WishlistDeleteModal";

import {
	StyledWishlistListItem,
	WishlistListContainer,
} from "./WishlistList.Styled";
import { useState } from "react";
import wishlistApi from "app/api/customer/wishlistApi";
import TableDataControl from "components/customer/AccountOrders/AccountOrders/OrderList/OrderListTableRow/TableDataControl";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

interface Props {
	wishlists?: Wishlist[];
	onDelete?: (id: Wishlist[keyof Wishlist]) => void;
	isLoading?: boolean;
}

const WishlistList = ({ wishlists, onDelete, isLoading }: Props) => {
	const prefetchWishlist = wishlistApi.usePrefetch("getWishlist");
	const { t } = useTranslation();
	const [deleteClicked, setDeleteClicked] = useState(false);

	const handleDelete = async (id: Wishlist[keyof Wishlist]) => {
		if (onDelete !== undefined) {
			setDeleteClicked(true);
			await onDelete(id);
			setDeleteClicked(false);
		}
	};

	const handlePrefetch = (id: any) => {
		prefetchWishlist(id);
	};

	return (
		<WishlistListContainer className="wishlist-list">
			{/* Cards for Mobile */}
			<div className="wishlist-list__cards">
				{wishlists?.map((wishlist, index) => (
					<StyledWishlistListItem
						key={index}
						wishlist={wishlist}
						onDelete={() => handleDelete(wishlist.id)}
						isLoading={isLoading}
						prefetch={() => handlePrefetch(wishlist.id)}
					/>
				))}
			</div>
			{/* Table for Desktop */}
			<table className="wishlist-list__table">
				<thead>
					<tr>
						<th>{t("wishlists.listName")}</th>
						<th>{t("wishlists.item")}</th>
						<th>{t("wishlists.shared")}</th>
						<th className="spacer"></th>
						<th>{t("wishlists.actions")}</th>
					</tr>
				</thead>
				<tbody>
					{wishlists?.map((wishlist, index) => (
						<tr key={index}>
							<TableDataControl>
								<Link
									className="link"
									to={`${t(PathTranslationKey.ACCOUNT_WISHLIST)}/${
										wishlist.id
									}`}
									onMouseEnter={() => prefetchWishlist(wishlist.id)}
								>
									{wishlist.name}
								</Link>
							</TableDataControl>
							<td>{wishlist.items.length}</td>
							<td>{wishlist.is_public ? t("yes") : t("no")}</td>
							<td className="spacer"></td>
							<td>
								<div className="action-buttons__container">
									{wishlist.is_public && wishlist.share_url && (
										<WishlistShareModal
											id={wishlist.id}
											shareURL={`${t(
												PathTranslationKey.SHARED_WISHLIST,
											).toString()}/${wishlist.share_url.replace(
												"/wishlists/",
												"",
											)}`}
											variant="primary_02"
										/>
									)}
									<Button
										variant="primary_01"
										link={{
											to: `${t(
												PathTranslationKey.ACCOUNT_WISHLIST_EDIT,
											).toString()}/${wishlist.id}`,
											state: { wishlist },
										}}
									>
										{t("wishlist.edit")}
									</Button>
									<WishlistDeleteModal
										heading={t('wishlist.deleteWishlist')}
										description={t('wishlist.deleteWishlistConfirmation')}
										onDelete={() => handleDelete(wishlist.id)}
										variant="primary_03"
										isLoading={deleteClicked}
										textLight
									>
										{t("wishlist.delete")}
									</WishlistDeleteModal>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</WishlistListContainer>
	);
};

export default WishlistList;
