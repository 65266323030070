import styled from "styled-components";
import { pageContentContainer } from "theme/StyledMixins";
import { accountDarkGrey, lightGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";
import { menuZIndex } from "utilities/variables/zIndex";

export const StyledAccountNavigation = styled.nav`
	border-bottom: #e3e3e3 solid 1px;
	padding-block: 1rem;
	z-index: ${menuZIndex};

	ul {
		${pageContentContainer}

		position: absolute;
		list-style: none;
		left: 1rem;
		top: 7.125rem;
		border: solid 1px ${lightGrey};
		border-top: none;
		background-color: white;
		padding: 1rem 0.75rem;
		padding-bottom: 0;
		transform: scaleY(0);
		transform-origin: top;
		transition: transform 0.3s ease;

		@media ${aboveMedium} {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0;
			line-height: calc(20 / 16);
			margin-block: 0;
			margin-inline: auto;
			position: unset;
			border: none;
			transform: unset;
		}

		&.isOpen {
			transform: scaleY(100%);
		}

		li {
			margin-bottom: 1rem;

			@media ${aboveMedium} {
				margin: 0;
			}
		}
	}

	a {
		color: ${accountDarkGrey};
		font-weight: normal;
		text-decoration: none;

		&:hover,
		&:active {
			font-weight: 700;
		}

		&.active {
			display: none;

			@media ${aboveMedium} {
				font-weight: 700;
			}
		}
	}
	.active {
		display: none;

		@media ${aboveMedium} {
			display: list-item;
		}

		a {
			font-weight: 700;
		}
	}
`;

export const StyledNavigationDropdown = styled.div`
	${pageContentContainer}

	border: solid 1px ${lightGrey};
	color: ${accountDarkGrey};
	padding: 0.75rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1em;
	cursor: pointer;

	@media ${aboveMedium} {
		display: none;
	}

	span {
		font-weight: 700;
	}

	i {
		width: fit-content;
		transform: rotate(0deg);
		transition: transform 0.3s ease;
		transform-origin: 50% 40%;

		&.isOpen {
			transform: rotate(180deg);
		}
	}
`;
