import customerApi from "app/api/customer/customerApi";
import orderApi from "app/api/customer/orderApi";
import wishlistApi from "app/api/customer/wishlistApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import Page from "components/common/ui/Page/Page";
import {
	StyledAccountOutletWrapper,
	StyledAccountPageBanner,
	StyledCustomerAccountContainer,
} from "components/customer/Customer.Styled";
import DropdownNavigation from "components/DropdownNavigation/DropdownNavigation";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { useSelector } from "redux/hooks";
import { PageType } from "ts/enums";

const AccountTemplate = () => {
	const currentPage = useSelector(
		({ ui: { accountNavigationCurrentLink } }) => accountNavigationCurrentLink,
	);

	const { customer } = useSelector((state) => state.auth);

	const prefetchOrders = orderApi.usePrefetch("orders");
	const prefetchWishlists = wishlistApi.usePrefetch("getWishlists");
	const prefetchAddresses = customerApi.usePrefetch("getAddresses");

	const prefetchLoyaltyApplicationData = wordPressApi.usePrefetch(
		"getLoyaltyCardApplicationData",
	);
	const prefetchLoyaltyCardThresholds = wordPressApi.usePrefetch(
		"getLoyaltyCardThresholds",
	);

	useEffect(() => {
		prefetchOrders(undefined);
		prefetchWishlists(undefined);
		prefetchAddresses(undefined);

		if (customer?.hasLoyaltyCard) {
			prefetchLoyaltyCardThresholds(undefined);
		} else {
			prefetchLoyaltyApplicationData(undefined);
		}
	});

	const pagesWithoutPadding = ["Wishlists"];

	const { t } = useTranslation();

	enum CURRENT_PAGE {
		ORDERS = "orders",
		LOYALTY_PROGRAM = "loyalty program",
		WISHLISTS = "wishlists",
		PROFILE = "profile",
		ADDRESSES = "addresses"
	}

	const setCurrentPage = (page: string): string => {
		if(page !== "overview") {
			switch(page.toLowerCase()) {
				case CURRENT_PAGE.ORDERS:
					return "account.orders";
				case CURRENT_PAGE.LOYALTY_PROGRAM:
					return "loyalty_program";
				case CURRENT_PAGE.WISHLISTS:
					return "account.wishlists";
				case CURRENT_PAGE.PROFILE:
					return "account.profile";
				case CURRENT_PAGE.ADDRESSES:
					return "account.addresses";
			}
		}

		return "account.overview";
	}

	return (
		<Page pageType={PageType.PAGE}>
			<StyledAccountPageBanner>
				<StyledCustomerAccountContainer>
					<h1>{t(setCurrentPage(currentPage))}</h1>
				</StyledCustomerAccountContainer>
			</StyledAccountPageBanner>

			<DropdownNavigation />

			<StyledAccountOutletWrapper>
				{pagesWithoutPadding.find((page) => currentPage.includes(page)) ? (
					<Outlet />
				) : (
					<StyledCustomerAccountContainer>
						<Outlet />
					</StyledCustomerAccountContainer>
				)}
			</StyledAccountOutletWrapper>
		</Page>
	);
};

export default AccountTemplate;
