import React, { useEffect, useState } from "react";

import { wordPressApi } from "app/api/wordpress/wordPressApi";

import { MenuItemData } from "ts/types";

import Cards from "./Cards/Cards";
import Copyright from "./Copyright/Copyright";
import Disclaimer from "./Disclaimer/Disclaimer";
import Sitemap from "./Sitemap/Sitemap";

import { StyledCardsAndDisclaimerWrapper, StyledFooter } from "./Footer.Styled";
import { useSelector } from "redux/hooks";

const Footer = () => {
	const store = useSelector((state) => state.bcStore.store);

  const [isLoading, setIsLoading] = useState(true);

	const [getSettings, settings] =
		wordPressApi.useLazyGetGlobalStoreSettingsQuery();
	const [getMenuItems, menuItems] =
		wordPressApi.useLazyGetMenuItemsBySlugQuery();
	const [getFooterContent, footerContent] =
		wordPressApi.useLazyGetFooterContentQuery();

	useEffect(() => {
    setIsLoading(true);
		getSettings();
		getMenuItems("footer");
		getFooterContent("");
	}, [store]);

  useEffect(() => {
    if(menuItems.data) {
      setIsLoading(false);
    }
  }, [menuItems.data])

	const sitemapItems = React.useMemo(() => {
		return (
			menuItems.data?.filter(
				(menuItem: MenuItemData) => menuItem.parentId === null,
			) || []
		);
	}, [menuItems.data]);

	return (
		<StyledFooter className="Footer">
			<Sitemap loading={isLoading} menuItems={sitemapItems} />
			<StyledCardsAndDisclaimerWrapper>
				<Cards
					loading={settings.isLoading || footerContent.isLoading}
					badges={footerContent.data?.badges || []}
					paymentMethodIcons={
						settings.data?.storeGeneralSettings.globalStoreSettings
							.paymentMethodsIcons || []
					}
				/>
				<Disclaimer
					loading={footerContent.isLoading}
					text={footerContent.data?.disclaimer}
				/>
			</StyledCardsAndDisclaimerWrapper>
			<Copyright/>
		</StyledFooter>
	);
};

export default Footer;
