export const supportedLocales: { [language: string]: string[] } = {
    ar: ['ar'],
    bg: ['bg'],
    cs: ['cs'],
    da: ['da'],
    de: ['de'],
    el: ['el'],
    en: ['en', 'en-GB'],
    es: ['es', 'es-419'],
    et: ['et'],
    fi: ['fi'],
    fil: ['fil'],
    fr: ['fr', 'fr-CA'],
    he: ['he'],
    hr: ['hr'],
    hu: ['hu'],
    id: ['id'],
    it: ['it'],
    ja: ['ja'],
    ko: ['ko'],
    lt: ['lt'],
    lv: ['lv'],
    ms: ['ms'],
    mt: ['mt'],
    nb: ['nb'],
    nl: ['nl'],
    pl: ['pl'],
    pt: ['pt', 'pt-BR'],
    ro: ['ro'],
    ru: ['ru'],
    sk: ['sk'],
    sl: ['sl'],
    sv: ['sv'],
    th: ['th'],
    tr: ['tr'],
    vi: ['vi'],
    zh: ['zh', 'zh-HK', 'zh-TW'],
};
