import bigcommerceGetStoreSettings from "app/gql/queries/bigcommerceGetStoreSettings";
import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import { SiteData } from "types/BigCommerce";

type GetStoreSettingsResponse = {
	data: SiteData;
};

const bigcommerceApi = bigCommerceGQL.injectEndpoints({
	endpoints: (builder) => ({
		getStoreSettings: builder.query({
			query: (_: void) => ({
				url: "",
				method: "POST",
				body: bigcommerceGetStoreSettings(),
			}),
			transformResponse: (res: GetStoreSettingsResponse) => res.data,
		}),
	}),
});

export default bigcommerceApi;
