import { gql } from "graphql-request";

export const ProductOption = gql`
	fragment ProductOption on ProductOptionEdge {
		node {
			entityId
			displayName
			... on MultipleChoiceOption {
				values {
					edges {
						node {
							...ProductOptionValue
						}
					}
				}
			}
		}
	}

	fragment ProductOptionValue on CatalogProductOptionValue {
		entityId
		label
		isDefault
		isSelected
	}
`;
