import { accountDarkGrey, darkGrey, grey, red } from "utilities/variables/colors";

import { Roboto } from "utilities/variables/fonts";
import styled from "styled-components";

export const StyledInput = styled.input`
	background-color: #fff;
	border: 1px solid #e3e3e3;
	border-radius: 2px 0px 0px 2px;
	padding: 0px 11px;
	height: 40px;
	color: ${accountDarkGrey};
	font: normal 16px/20px ${Roboto};
	outline: none;

	&:hover,
	&:focus {
		border: 1px solid ${grey};
	}

	&:disabled {
		background-color: #f8f9fa;
	}
`;
export const PasswordWrapper = styled.div`
	position: relative;
`;

export const EyeIconWrapper = styled.div`
	position: absolute;
	top: 50%;
	right: 10px;
	cursor: pointer;
`;
export const StarWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	gap: 4px;
	position: relative;
	-webkit-box-pack: end;
	justify-content: flex-end;
	width: 100%;

input {
	display: none;
  }

  label {
	float: right;
	cursor: pointer;
	font-size: 30px;
	color: #bfc1c7;
  }

  label:before {
	content: '★';
  }

  input:checked + label:hover,
  input:checked + label:hover ~ label,
  input:checked ~ label:hover,
  input:checked ~ label:hover ~ label,
  label:hover ~ input:checked ~ label {
	color: #58595c;
  }

  input:not(:checked) + label:hover,
  input:not(:checked) + label:hover ~ label {
	color: #15171a;
  }

  input:checked ~ label {
	color: #212529;
  }
`;

export const StarRequired = styled.span`
font-size: 14px;
font-family: ${Roboto};
color: ${darkGrey};
text-transform: lowercase;
position: absolute;
  &::before {
    content: "*";
    color: ${red};
    margin-left: 3px;
  }
`;