export const addLeadingSlash = (value: string) => {
	if (value?.length && !value.startsWith("/")) {
		return "/" + value;
	}

	return value;
};

/**
 * Removes trailing "/" from URL string
 *
 * @param value to remove slash from
 * @returns URL without trailing "/"
 */
export const removeTrailingSlash = (value: string) => {
	if (value?.length && value.match("/.*/$")) {
		return value.replace(/\/+$/, "");
	}

	return value;
};

/**
 * Validates URL strings, ensuring they start with a "/" and removes any
 * trailing "/"
 * @param value URL string to validate
 * @returns validate URL string
 */
export const validateUrlSlashes = (value: string) => {
	let path = value;

	if (path.length) {
		path = addLeadingSlash(path);
		path = removeTrailingSlash(path);
	}

	return path;
};

export const pathsMatch = (pathOne: string, pathTwo: string) => {
	const matchOne = validateUrlSlashes(pathOne);
	const matchTwo = validateUrlSlashes(pathTwo);

	return matchOne === matchTwo;
};

export const hasQueryParams = (url: string) => {

	const urlSplit = url.split("?");

  return urlSplit.length === 1 ? undefined : urlSplit[1];
};

export const addLocaleCode = (path: string) => {
	const pathSplit = window.location.pathname.split("/");
	let locale = "";

	path = validateUrlSlashes(path);

	if (pathSplit.length > 1) {
		locale = "/" + pathSplit[1];
	}

	if (!path.startsWith(locale)) {
		path = `${locale}${path}`;
	}

	return validateUrlSlashes(path);
};
