const CHECKOUT_DEFAULT_INCLUDES = [
    'cart.lineItems.physicalItems.options',
    'cart.lineItems.digitalItems.options',
    'customer',
    'customer.customerGroup',
    'payments',
    'promotions.banners',
];

export default CHECKOUT_DEFAULT_INCLUDES;
