import styled from "styled-components/macro";
import { dark } from "utilities/variables/colors";

export const Container = styled.div<{
	isOpen: boolean;
}>`
	margin-left: 1rem;
	.header {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		& h1 {
			font-size: 16px;
			font-weight: 700;
			color: ${dark};
		}
	}
	.body {
		display: flex;
		flex-direction: row;
		width: 100%;
		/* background: yellow; */
		max-height: ${(props) => (props.isOpen ? 100 : 0)};
    	overflow: hidden;
    	transition: max-height ease 0.9s;
	}
`;
