import React from "react";
import { useDispatch } from "redux/hooks";

import { updateFileUploadFieldValue } from "redux/gravityForm/gravityFormSlice";

import {
	FieldError,
	FileUploadField as FileUploadFieldType,
	FileUploadInput,
	FormField,
} from "types/GravityForm";

import { StyledFieldContainer } from "../GravityForm.Styled";
import FileUpload from "components/common/form/FileUpload/FileUpload";

interface Props {
	field: FormField & Partial<FileUploadFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: FileUploadInput;
}

const FileUploadField = ({ field, fieldErrors }: Props) => {
	const { id } = field;
	const dispatch = useDispatch();

	const updateValue = React.useCallback(
		(file: File, value: string) => {
			dispatch(
				updateFileUploadFieldValue({
					id,
					fileUploadValues: [file],
				}),
			);
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}
		if (field?.maxFileSize) {
			// Convert upload into MB
			const fileSize = event.target.files[0]?.size;
			const fileSizeMb = fileSize / 1024 ** 2;

			if (fileSizeMb >= field?.maxFileSize) {
				// Prevents user select files that are over the file limit
				return;
			}
		}

		updateValue(event.target.files[0], event.target.value);
	};

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			<FileUpload
				label={field.label}
				required={field.isRequired}
				isError={Boolean(fieldErrors.length)}
				errorMsg={fieldErrors[0]?.message}
				onChange={handleChange}
				filesAccepted={field.allowedExtensions}
				maxFileSize={field.maxFileSize}
			/>
		</StyledFieldContainer>
	);
};

export default FileUploadField;
