import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey, lightGrey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const SelectSearch = styled.div`
    width: 100%;
    position: relative;

    .input-select__indicators {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;

        position: absolute;
        top: ${remCalc(25)};
        right: 0;

        .input-select__indicator {
            display: flex;
            transition: color 150ms;
            color: hsl(0, 0%, 80%);
            padding: 8px;
            box-sizing: border-box;
        }

        svg {
            display: inline-block;
            fill: currentColor;
            line-height: 1;
            stroke: currentColor;
            stroke-width: 0;
        }
    }

    .form-select {
        &-search-results {
            position: absolute;
            top: 100%;
            width: 100%;
            background-color: ${white};
            box-shadow: 0px ${remCalc(3)} ${remCalc(6)} #21252940;
            width: 100%;
            z-index: 10;
            margin: 0;
            max-height: ${remCalc(300)};
            overflow: auto;

            button {
                background-color: ${white};
                padding: ${remCalc(12)} ${remCalc(16)};
                border-bottom: ${remCalc(1)} solid ${lightGrey};
                color: ${darkGrey};
                width: 100%;
                text-align: left;
                font: normal normal 400 ${remCalc(16)}/${remCalc(20)} ${Roboto};

                &--is-selected {
                    color: ${dark};
                    font-weight: 600;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    color: ${dark};
                }
            }
        }
    }
`;