import { ReactComponent as LoyaltyProgram } from "assets/images/svg/Overview - Loyalty Program.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = { className?: string; }

function IconLoyalty({className}: Props): ReactElement {
    return <Icon className={className} icon={<LoyaltyProgram />} />;
}

export default IconLoyalty;