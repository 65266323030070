import { gql } from "graphql-request";

export type PageType =
	| "hr"
	| "careers"
	| "responsibility"
	| "hrDetail"
	| "responsibilityDetail"
    | "loyalty"
	| "awards";

const hrAdvice = gql`
	hrAdvice {		
		contentBlock {
			... on Page_Hradvice_ContentBlock_ContentBlockImageLeft {
				fieldGroupName
				heading
				image {
					altText
					sourceUrl
				}
				text
				link {
					title
					url
				}
			}
			... on Page_Hradvice_ContentBlock_ContentBlockImageRight {
				fieldGroupName
				heading
				image {
					altText
					sourceUrl
				}
				text
				link {
					title
					url
				}
			}
		}
		additionalLinks {
			title
			link {
				title
				url
			}
			images {
				desktopImage {
					altText
					sourceUrl
				}
				mobileImage {
					altText
					sourceUrl
				}
			}
		}
	}
	featuredImage {
		node {
			sourceUrl
			altText
			link
			title
		}
	}
`;

const hrDetail = gql`
content
    featuredImage {
      node {
        altText
        title
        sourceUrl
      }
    }
    title
    hrAdvice {
      additionalLinks {
        title
        images {
          desktopImage {
            altText
            sourceUrl
          }
          mobileImage {
            altText
            sourceUrl
          }
        }
        link {
          title
          url
        }
      }
    }
`;

const careers = gql`
	careers {
		additionalLinks {
			images {
				desktopImage {
					altText
					sourceUrl
				}
				mobileImage {
					altText
					sourceUrl
				}
			}
			title
			link {
				target
				title
				url
			}
		}
		contentBlock {
			... on Page_Careers_ContentBlock_ContentBlockImageLeft {
				fieldGroupName
				heading
				text
				image {
					altText
					title
					sourceUrl
				}
			}
			... on Page_Careers_ContentBlock_ContentBlockImageRight {
				fieldGroupName
				heading
				text
				image {
					sourceUrl
					title
					altText
				}
			}
		}
	}
	featuredImage {
		node {
			sourceUrl
			altText
			link
			title
		}
	}
`;

const socialResponsibility = gql`
	socialResponsibility {
		additionalLinks {
			title
			link {
				target
				title
				url
			}
			images {
				desktopImage {
					altText
					sourceUrl
				}
				mobileImage {
					altText
					sourceUrl
				}
			}
		}
		goals {
			text
			heading
			image {
				altText
				sourceUrl
			}
			link {
				title
				url
			}
		}
	}
	featuredImage {
		node {
			sourceUrl
			altText
			link
			title
		}
	}
	
`;

const responsibilityDetail = gql`
	id
	featuredImage {
		node {
			altText
			sourceUrl
		}
	}
    responsibilityDetail {
		contentBlock {
			... on Page_Responsibilitydetail_ContentBlock_ContentBlockImageLeft {
				fieldGroupName
				heading
				text
				image {
					altText
					sourceUrl
				}
			}
			... on Page_Responsibilitydetail_ContentBlock_ContentBlockImageRight {
				fieldGroupName
				heading
				text
				image {
					altText
					sourceUrl
				}
			}
		}
    }
`;

const awards = gql`
title
    awards {
      additionalLinks {
        images {
          desktopImage {
            altText
            sourceUrl
          }
          mobileImage {
            altText
            sourceUrl
          }
        }
        link {
          title
          url
        }
        title
      }
      awardprize {
        images {
          desktopImage {
            altText
            sourceUrl
          }
          mobileImage {
            altText
            sourceUrl
          }
        }
        link {
          title
          url
        }
        shortDescription
        title
      }
    }
    content
	featuredImage {
      node {
        altText
        sourceUrl
      }
    }
`;

const loyaltyPage = gql`
	id
    content
    featuredImage {
      node {
        altText
        sourceUrl
      }
    }
	template {
      ... on Template_LoyaltyPage {
        loyaltyProgram {
          additionalContentBlock {
            title
            text
            link {
              title
              url
            }
          }
        }
      }
	}
`;

const wordPressGetCompanyPage = (pageId: number, pageType: PageType) => {
	return {
		operationName: "GetCompanyPage",
		variables: {
			pageId,
		},
		query: gql`
			query GetCompanyPage($pageId: ID!) {
				page(id: $pageId, idType: DATABASE_ID) {
					title
					${
						(pageType === "hr" && hrAdvice) ||
						(pageType === "careers" && careers) ||
						(pageType === "responsibility" && socialResponsibility) ||
						(pageType === "hrDetail" && hrDetail) ||
						(pageType === "awards" && awards) ||
						(pageType === "loyalty" && loyaltyPage) ||
						(pageType === "responsibilityDetail" && responsibilityDetail) ||
						(pageType === "loyalty" && loyaltyPage)
					}
					seo {
						metaDesc
						metaKeywords
						metaRobotsNofollow
						metaRobotsNoindex
						title
					}
				}
			}
		`,
	};
};

export default wordPressGetCompanyPage;
