import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import StructuredData from "components/common/ui/StructuredData/StructuredData";
import Typography from "components/common/ui/Typography/Typography";
import parse, {
	domToReact,
	Element,
	HTMLReactParserOptions,
} from "html-react-parser";
import { Fragment, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useMatch } from "react-router";
import { useSelector } from "redux/hooks";
import { Brand } from "hooks/useBrand";

import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBrand } from "redux/UI/uiSlice";

import { remove as removeAccents } from "remove-accents";

import { PageType } from "ts/enums";
import { BlogContentBreak } from "types/WordPress";
import getRobotsContent from "utilities/getRobotsContent";
import { wordPressBlogArticleApi } from "../../app/api/blog/wordPressBlogArticleApi";
import Button from "../../components/common/ui/Button/Button";
import FormattedDate from "../../components/common/ui/Date/FormattedDate";
import Link from "../../components/common/ui/Link/Link";
import ShareBlogPostViaSocialMedia from "../../components/common/ui/ShareBlogPostViaSocialMedia/ShareBlogPostViaSocialMedia";
import BlogCarousel from "./blogCarousel/BlogCarousel";
import {
	BreakImageOutSlightly,
	Container,
	ContainerMedium,
	FeaturedImage,
	FullBlogWidthImage,
	ImageSideBySideWithinContainer,
	LineBreak,
	PostContent,
	RelatedCategories,
	Section,
	SignUpContainer,
	SwipeContainerUponOverFlow,
	UtilityContainer,
} from "./BlogPost.Styled";
import RelatedArticles from "./RelatedArticles";
import BlogPostSkeleton from "./skeleton/BlogPostSkeleton";
import { Portal } from "react-portal";
import { PORTAL_MODAL } from "utilities/portals";
import { StyledErrorContent, StyledLoadingOverlay } from "pages/basket/Basket.Styled";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import PathTranslationKey from "utilities/paths";

type BlogContent = {
	title: string;
	featuredImage: { node: { sourceUrl: "" } };
	content: string;
	date: "";
	categories: {
		nodes: [
			{
				name: "";
				posts: {
					nodes: [
						{
							author: { node: { name: "" } };
							title: "";
							slug: "";
							featuredImage: { node: { sourceUrl: "" } };
						},
					];
				};
			},
		];
	};
	tags: {
		nodes: {
			slug: "";
		};
	};
	seo: any;
};

const BlogPost: FunctionComponent = () => {
	const [blogContent, setBlogContent] = useState<BlogContent | undefined>();
	const [contentBreaks, setContentBreaks] = useState<BlogContentBreak[]>([]);
	const [isBlogLoading, setIsBlogLoading] = useState(true);
	const blogPageTitle = useSelector(
		(state) => state.settings.wordpressBlogTitle,
	);

	const pathMatch = useMatch("/:locale/:blog_landing/:blog_slug");
	const brand = useSelector((state) => state.ui.brand);
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const translations = useMemo(() => {
		return {
			women: removeAccents(t("women")).toLowerCase(),
			men: removeAccents(t("men")).toLowerCase(),
		};
	}, [t]);

	const relatedCategories: string[] = ["trends", "news", "outfits"];

	const [isLoading, setIsLoading] = useState(true);
	const [trigger, apiResult] =
		wordPressBlogArticleApi.useLazyGetPageContentBySlugQuery();

	const getBlogContentBreaks =
		wordPressBlogArticleApi.useGetBlogContentBreaksQuery(blogPageTitle);

	useEffect(() => {
		setTimeout(() => setIsBlogLoading(false), 1500)
	}, []);

	useEffect(() => {
		setIsLoading(true);

		trigger(pathMatch?.params.blog_slug);
	}, [pathMatch?.params.blog_slug, trigger]);

	useEffect(() => {
		if (getBlogContentBreaks.data) {
			setContentBreaks(getBlogContentBreaks.data || []);
		}
	}, [getBlogContentBreaks]);

	useEffect(() => {
		if (!apiResult.isUninitialized && !apiResult.isLoading) {
			if (apiResult.isSuccess) {
				const content = (apiResult as any).data;

				if (Object.keys(content).length === 0) {
					setBlogContent(undefined);
					setIsLoading(false);
				} else {
					setBlogContent(content);
				}
			}
		}
	}, [apiResult]);

	useEffect(() => {
		if (!apiResult.isUninitialized) {
			setIsLoading(false);
		}
		if (
			Array.isArray(blogContent?.tags?.nodes) &&
			blogContent?.tags?.nodes?.length
		) {

			let gender = blogContent?.tags?.nodes[0].slug;

			// Check to correct un-matched translations for gender in MK store
			gender = gender === 'masko' ? 'машки' : gender;

			let active: string = Brand.Global as string;

			if (gender) {
				switch (gender) {
					case translations.men:
						active = Brand.Men;
						break;
					case translations.women:
						active = Brand.Women;
						break;
				}
			}
			brand !== active && dispatch(setBrand(active));
		}
	}, [blogContent, t]);

	const relatedArticles = useMemo(() => {
		if (!blogContent) {
			return [];
		}

		return blogContent.categories.nodes[0].posts.nodes
			.filter((node) => node.slug !== pathMatch?.params.blog_slug)
			.filter(
				(article: any) => article && article.featuredImage && article.title,
			)
			.slice(0, 4);
	}, [blogContent]);

	const options: HTMLReactParserOptions = {
		replace: (domNode) => {
			if (domNode.nodeType === 1 && (domNode as Element).attribs) {
				const element = domNode as Element;

				if (
					element.attribs.class &&
					element.attribs.class.includes("blogFullWidth")
				)
					return (
						<FullBlogWidthImage src={element.attribs.src} loading={"lazy"} />
					);

				if (
					element.attribs.class &&
					element.attribs.class.includes("blogImageBreakout")
				)
					return (
						<BreakImageOutSlightly src={element.attribs.src} loading={"lazy"} />
					);

				if (
					element.attribs.class &&
					element.children.length > 5 &&
					element.attribs.class.includes("gallery-columns")
				) {
					return <BlogCarousel arrayOfChildrenNodes={element.children} />;
				}

				if (
					element.attribs.class &&
					element.attribs.class.includes("gallery-columns") &&
					element.children.length > 2 &&
					element.children.length < 6
				) {
					return (
						<ImageSideBySideWithinContainer width={element.children.length - 1}>
							{domToReact(element.children)}
						</ImageSideBySideWithinContainer>
					);
				}
			}
		},
	};

	const renderBlogLoadingSpinner = () => {
		return <Portal node={document && document.getElementById(PORTAL_MODAL)}>
			<StyledLoadingOverlay className="loading-container">
				<LoadingSpinner />
			</StyledLoadingOverlay>
		</Portal>
	}

	const renderBlogContent = () => {
		if (!blogContent && !isLoading && !isBlogLoading) {
			return <StyledErrorContent heading={t("basket.emptyCart")}>
				<Link to={`${t(PathTranslationKey.CATEGORY)}`}>
					No blog content found
				</Link>
			</StyledErrorContent>
		}

		if (!isLoading && !isBlogLoading) {
			return <Fragment>
					<SEO
						title={blogContent?.seo?.title}
						description={blogContent?.seo?.metaDesc}
						keywords={blogContent?.seo?.metaKeywords}
						robots={getRobotsContent([
							blogContent?.seo?.metaRobotsNofollow,
							'index'
						])}
					/>
					<StructuredData type="NewsArticle" data={apiResult?.data} />

					<Section gap={64} gapMobile={32} className={"PageContainer"}>
						{blogContent?.featuredImage && (
							<FeaturedImage
								imageLink={blogContent.featuredImage.node.sourceUrl}
							/>
						)}
						<ContainerMedium className="blog-content">
							<Section gap={32}>
								<Container padding={16} gap={24} gapMobile={20}>
									<UtilityContainer
										flexDirection={"column"}
										alignItems={"flex-start"}
										justifyContent={"flex-start"}
										gap={12}
									>
										{blogContent?.categories && (
											<Typography variant="heading" size="small">
											{blogContent.categories.nodes[0].name}
										</Typography>
										)}
										{blogContent?.title && (
											<Typography variant="heading" size="medium">
												{blogContent.title}
											</Typography>
										)}
										{blogContent?.date && (
											<Typography variant="body" size="medium">
												<FormattedDate date={new Date(blogContent.date)} />
											</Typography>
										)}
									</UtilityContainer>
									<LineBreak />
									{blogContent?.content && (
										<PostContent>
											{parse(blogContent.content, options)}
										</PostContent>
									)}
									<LineBreak />
									{blogContent?.categories.nodes[0].name.toLowerCase() !==
										"uncategorized" && (
										<>
											<RelatedCategories>
												<Typography variant="body" size="medium">
													{"Related categories:"}
												</Typography>
												<RelatedCategories>
													{relatedCategories.map((relatedCategory) => (
														<Typography key={relatedCategory.toString()}>
															{relatedCategory}
														</Typography>
													))}
												</RelatedCategories>
											</RelatedCategories>
											<LineBreak />
										</>
									)}
									<ShareBlogPostViaSocialMedia url={window.location.href} />
									<LineBreak />
									<UtilityContainer
										flexDirection={"row"}
										alignItems={"start"}
										justifyContent={"center"}
									>
										<Link
											to={pathMatch?.params.blog_landing}
											className={"link"}
										>
											<Typography variant="body" size="medium">
												{"Back To News"}
											</Typography>
										</Link>
									</UtilityContainer>
								</Container>
								{relatedArticles.length !== 0 && (
									<Container padding={16} gap={12}>
										<UtilityContainer
											flexDirection={"column"}
											alignItems={"center"}
											justifyContent={"center"}
											alignItemsMobile={"center"}
											gap={23.96}
										>
											<Typography variant="heading" size="medium">
												{"Related Articles"}
											</Typography>
											<SwipeContainerUponOverFlow>
												<Scrollbars style={{ height: "268px" }}>
													<UtilityContainer flexDirection={"row"} gap={14}>
														<RelatedArticles
															blogLandingUrl={pathMatch?.params.blog_landing}
															articles={relatedArticles}
														/>
													</UtilityContainer>
												</Scrollbars>
											</SwipeContainerUponOverFlow>
										</UtilityContainer>
									</Container>
								)}
							</Section>
						</ContainerMedium>

						{contentBreaks.length !== 0 && (
							<SignUpContainer padding={40} className="SignUpContainer">
								<UtilityContainer
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
									gap={32}
								>
									<UtilityContainer
										flexDirection={"column"}
										alignItems={"center"}
										justifyContent={"center"}
										gap={10}
										padding={24}
									>
										<Typography variant="heading" size="medium">
											{contentBreaks[0].title}
										</Typography>
										<Typography
											variant="body"
											size="medium"
											className={"sign-up-content"}
										>
											{contentBreaks[0].text}
										</Typography>
									</UtilityContainer>
									{contentBreaks[0].link?.url && (
										<UtilityContainer
											flexDirection={"column"}
											alignItems={"center"}
											justifyContent={"center"}
											padding={16}
										>
											<Button
												variant="primary_01"
												className={"signup-btn"}
												link={{ to: contentBreaks[0].link.url }}
											>
												{contentBreaks[0].link.title}
											</Button>
										</UtilityContainer>
									)}
								</UtilityContainer>
							</SignUpContainer>
						)}
					</Section>
				</Fragment>
		}
	}

	return (
		<Page pageType={PageType.PAGE} title="Blog Article">
			{isBlogLoading ? renderBlogLoadingSpinner() : renderBlogContent()}
		</Page>
	);
};

export default BlogPost;
