import styled from "styled-components";
import { Roboto } from "utilities/variables/fonts";

export const HeaderWrapper = styled.section`
	h1 {
		font-weight: bold;
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		color: white;
		text-align: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: fit-content;
		margin: auto;
		font-family: ${Roboto};
	}
	overflow: hidden;
	position: relative;
	height: 38.5rem;

	div {
		position: absolute;
		z-index: 3;
		width: 100%;
		min-height: 30rem;
		height: 100%;

		justify-content: center;
		align-items: center;
		text-align: center;
	}
	img {
		position: absolute;
		object-position: center top;
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
`;
