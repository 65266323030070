//@ts-nocheck
import Button from "components/common/ui/Button/Button";
import ButtonGroup from "components/common/ui/ButtonGroup/ButtonGroup";
import IconFilter from "components/common/ui/Icons/IconFilter/IconFilter";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { ModalProps } from "ts/interfaces";
import PlpAside from "../../plp/PlpAside/PlpAside";
import { useTranslation } from "react-i18next";
import { FilterListing, StyledCollapsibleContainer, StyledInputCheckbox } from "./PlpFilter.styles";
import CollapseContainer from "components/common/ui/CollapseContainer/CollapseContainer";
import { Filter, FilterValues } from "hooks/useCategoryPagination";
import { StyledInput } from "components/common/form/Input/Input.Styled";
import { aboveMedium } from "utilities/variables/media";
import { remCalc } from "utilities/styles";
import { isArray } from "lodash";
import translateIfPossible from 'utilities/translateIfPossible'
import { getFromSessionStorage, removeFromSessionStorage, saveToSessionStorage } from "utilities/variables/localStorageUtils";
import { FILTER_OPTIONS } from "utilities/consts";

const PlpFilter: FunctionComponent<{
	categoryId?: number;
	filters: Filter[];
	handleOnClearFilters: () => void;
	handleOnFilterAttributes: (filters: {
		attribute: string;
		values: string[];
	}[]) => void;
	handleOnFilterCategory: (ids: number[]) => void;
	handleOnFilterPrices: (minPrice: number | undefined, maxPrice: number | undefined) => void;
}> = ({
	categoryId,
	filters,
	handleOnClearFilters,
	handleOnFilterAttributes,
	handleOnFilterCategory,
	handleOnFilterPrices,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	const { t } = useTranslation();

	useEffect(() => {
		
		setIsOpen(false);
	}, [location.pathname]);


	

	return (
		<Fragment>
			<Button
				variant="basic"
				icon={<IconFilter />}
				onClick={() => setIsOpen(true)}
			>
				{t("headerFilters")}
			</Button>
			<FilterAside
				{...categoryId && {
					categoryId
				}}
				isOpen={isOpen}
				close={handleClose}
				filters={filters}
				handleOnClearFilters={handleOnClearFilters}
				handleOnFilterAttributes={handleOnFilterAttributes}
				handleOnFilterCategory={handleOnFilterCategory}
				handleOnFilterPrices={handleOnFilterPrices}
			/>
		</Fragment>
	);
};

const FooterWrapper = styled.div`
	padding: ${remCalc(16)};

	@media ${aboveMedium} {
		padding: ${remCalc(32)} ${remCalc(24)};
	}

	.plp-filter__buttons {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: ${remCalc(8)};

		@media ${aboveMedium} {
			gap: ${remCalc(16)};
		}

		button {
			margin: 0;
			width: 100%;
		}
	}
`;

const FilterAside: FunctionComponent<ModalProps & {
	categoryId?: number;
	filters: Filter[];
	handleOnClearFilters: () => void;
	handleOnFilterAttributes: (filters: {
		attribute: string;
		values: string[];
	}[]) => void;
	handleOnFilterCategory: (ids: number[]) => void;
	handleOnFilterPrices: (minPrice: number | undefined, maxPrice: number | undefined) => void;
}> = ({
	categoryId,
	isOpen,
	close,
	filters,
	handleOnClearFilters,
	handleOnFilterAttributes,
	handleOnFilterCategory,
	handleOnFilterPrices
}) => {
	const { t, i18n } = useTranslation("pdp.attributes");


	const [toFilterAttributes, setToFilterAttributes] = useState<{
		attribute: string;
		values: string[];
	}[]>([])
	const [toFilterCategoryIds, setToFilterCategoryIds] = useState<number[]>([]);
	const [toFilterMinPrice, setToFilterMinPrice] = useState<number>();
	const [toFilterMaxPrice, setToFilterMaxPrice] = useState<number>();
	const location = useLocation();

	const getLocalisedLabel = (label: string) => {
		if (label.includes('Erp.')) {
			return t(`pdp.${label.toLowerCase()}`)
		}

		if (label.toLowerCase() === 'category') {
			return t('filters.category')
		}

		return t(label.toLowerCase())
	}

	useEffect(() => {
		// Clear state(s)
		setToFilterAttributes([]);
		setToFilterCategoryIds([]);
		setToFilterMaxPrice(0);
		setToFilterMinPrice(0);
	}, [location.pathname]);

	const footerContent = (
		<FooterWrapper>
			<ButtonGroup className="plp-filter__buttons">

				<Button variant="primary_01" onClick={()=> {
					handleOnFilterAttributes([...toFilterAttributes]);
					handleOnFilterCategory([...toFilterCategoryIds]);
					handleOnFilterPrices(toFilterMinPrice, toFilterMaxPrice);
					setToFilterSelection([...toFilterAttributes]);
					saveToSessionStorage(FILTER_OPTIONS, toFilterAttributes);
					// Close filters
					close();
				}}>
					{t("filterAsideViewItems")}
				</Button>
				<Button
					variant="primary_03"
					onClick={() => {
						handleOnClearFilters();

						// Clear state(s)
						setToFilterAttributes([]);
						setToFilterCategoryIds([]);
						setToFilterSelection([]);
						setToFilterMaxPrice(0);
						setToFilterMinPrice(0);

						// Close filters
						close();
					}}
					textLight
				>
					{t("filterAsideClear")}
				</Button>
			</ButtonGroup>
		</FooterWrapper>
	);


	const [filterSelection, setToFilterSelection] = useState(undefined);
	useEffect(() => {
		const handlePageReload = () => {
			removeFromSessionStorage(FILTER_OPTIONS);
			setToFilterSelection([]);
		}

		window.addEventListener("beforeunload", handlePageReload);

		const value = getFromSessionStorage(FILTER_OPTIONS, []);
		setToFilterSelection(value);
		setToFilterAttributes(value);

		return () => {
			window.removeEventListener("beforeunload", handlePageReload)
		}
	}, [])

	return (
		<PlpAside
			isOpen={isOpen}
			close={close}
			heading={t("filters.heading")}
			footer={footerContent}
		>
			<FilterListing>
				{filterSelection && filters.map(
					(filter) => {
						const label = String(filter.label);
						const isLabelCustomField = label.includes('Erp.');
						const localisedLabel = getLocalisedLabel(label);

						return <CollapseContainer
							title={localisedLabel.toUpperCase()}
							isOpenByDefault={!filter.isCollapsedByDefault}
							key={filter.label}
						>
							<div className="verticalContainer">
								{
									filter.__typename === 'PriceSearchFilter' ? (
									<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												width: '100%',
												justifyContent: 'space-between',
												alignItems: 'center',
												position: 'relative'
											}}
										>
											<div className="minBox" style={{ marginRight: '0.25rem'}}>
												<h4 style={{
													fontSize: '16px'
												}}>{t('filters.min')}</h4>
												<StyledInput
													type="number"
													onChange={(event) => {
														const value = parseFloat(Number(event.target.value).toFixed(2));
														setToFilterMinPrice(value);
													}}
													value={toFilterMinPrice}
												/>
											</div>
											<div className="maxBox" style={{ marginLeft: '0.25rem'}}>
												<h4 style={{
													fontSize: '16px'
												}}>{t('filters.max')}</h4>
												<StyledInput
													type="number"
													onChange={(event) => {
														const value = parseFloat(Number(event.target.value).toFixed(2));
														setToFilterMaxPrice(value);
													}}
													value={toFilterMaxPrice}
												/>
											</div>
										</div>
									) : (
										filter.values?.map(
										(value) => {
											if (!value.label) {
												return null
											}

											const renderInput = (render: FilterValues) => {
												const renderContent = (r) => {
													const {
														value,
														isSelected,
														subCategories
													} = r

													const doesAttributeExist = toFilterAttributes?.filter(
														x => x.attribute === (isLabelCustomField ? label.toLowerCase() : label)
													).length

													let isOptionSelected = false

													let newAttributeSet = [...toFilterAttributes];

													if (doesAttributeExist){
														const foundAttributeIndex = newAttributeSet?.findIndex(
															x => x.attribute === (isLabelCustomField ? label.toLowerCase() : label)
														)

														isOptionSelected = newAttributeSet[foundAttributeIndex].values.filter(x => value === x).length > 0
													}

													const stored = filterSelection.find(attr => attr.attribute.toLowerCase() === label.toLowerCase());
													if(stored) {
														isOptionSelected = stored.values.includes(value);
													}

													return <>
														<StyledInputCheckbox
															key={render.label}
															className={`checkbox__${filter.__typename}${categoryId && categoryId === parseInt(render.value, 10) ? ' activeCategory' : ''}`}
															onChange={() => {
																if (filter.__typename === 'CategorySearchFilter') {
																	const id = parseInt(value, 10);

																	if (
																		isSelected || toFilterCategoryIds.filter(x => id === x).length
																	) {
																		return setToFilterCategoryIds(
																			[
																				...toFilterCategoryIds.filter(x => x !== id)
																			]
																		)
																	}

																	return setToFilterCategoryIds(
																		[
																			...toFilterCategoryIds,
																			id,
																		]
																	)
																}

																if (doesAttributeExist) {
																	const foundAttributeIndex = newAttributeSet?.findIndex(
																		x => x.attribute === (isLabelCustomField ? label.toLowerCase() : label)
																	);

																	if (
																		isSelected
																		|| newAttributeSet[foundAttributeIndex].values.filter(x => value === x).length
																	) {
																		const attributeValues = newAttributeSet[foundAttributeIndex].values
																		newAttributeSet[foundAttributeIndex] = {
																			...newAttributeSet[foundAttributeIndex],
																			values: attributeValues.filter(x => x !== value)
																		}

																		return setToFilterAttributes(
																			newAttributeSet.filter(
																				x => x.values.length
																			)
																		);
																	}

																	newAttributeSet[foundAttributeIndex] = {
																		...newAttributeSet[foundAttributeIndex],
																		values: [
																			...toFilterAttributes[foundAttributeIndex].values,
																			value
																		]
																	}

																	return setToFilterAttributes(newAttributeSet);
																}

																setToFilterAttributes(
																	[
																		...toFilterAttributes ?? [],
																		{
																			attribute: isLabelCustomField ? label.toLowerCase() : label,
																			values: [value]
																		}
																	]
																)
															}}
															label={
																<>
																	{translateIfPossible(render.label, t, i18n)} {"(" + render.count + ")"}
																</>
															}
															value={render.isSelected || isOptionSelected}
														/>
														{subCategories && subCategories.length ? (
															subCategories.map(
																sub => {
																	const render = () => renderInput(sub);
																	if (sub.subCategories && sub.subCategories.length) {
																		return <StyledCollapsibleContainer
																			title={sub.label}
																			key={`plp.${sub.label}`}
																			isOpenByDefault={false}
																		>
																			<div className="vertical-container">{render()}</div>
																		</StyledCollapsibleContainer>
																	}

																	return render();
																}
															)
														) : null}
													</>
												}

												if (isArray(render)) {
													return render.map(
														(r) => renderContent(r)
													)
												}

												return renderContent(render);
											}

											if ((value?.subCategories || []).length || filter.__typename === 'CategorySearchFilter') {
												return <StyledCollapsibleContainer
													title={value.label}
													key={value.label}
													isOpenByDefault={false}
													hasMarginEnabled
												>
													<div className="verticalContainer">
														{renderInput(value)}
													</div>
												</StyledCollapsibleContainer>
											}

											return renderInput(value)
										}
									)
									.filter(Boolean)
								)}
							</div>
						</CollapseContainer>
					}
				)}
			</FilterListing>
		</PlpAside>
	);
};

export default PlpFilter;
