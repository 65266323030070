import { FunctionComponent, useState } from "react";
import { Gender } from "../PdpSizeGuide";
import { MeasurementButton, MeasurementWrapper } from "./Measurement.Styled";

interface Props {
	gender: Gender;
	target: string;
	position: "left" | "right";
	value: string;
	active?: boolean;
	onHover?: (helperText: string | undefined) => void;
}

const Measurement: FunctionComponent<Props> = ({
	gender,
	target,
	position,
	value,
	active = false,
	onHover = () => {},
}) => {
	const [clicked, setClicked] = useState(active);

	//this isn't a valid measurement
	if (gender === "female" && target === "neck") {
		return null;
	}

	return (
		<MeasurementWrapper className={`${gender} ${target}`} position={position}>
			<MeasurementButton
				className={active ? "active" : ""}
				onMouseEnter={() => onHover(target)}
				onMouseLeave={() => {
					if (!clicked) {
						onHover(undefined);
					}
				}}
				onClick={() => {
					setClicked(true);
					onHover(target);
				}}
			>
				{value}
			</MeasurementButton>
			<span className="marker-line" />
		</MeasurementWrapper>
	);
};

export default Measurement;
