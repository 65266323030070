import styled from "styled-components";

export const StyledDividerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
`;
