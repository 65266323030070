import styled from "styled-components";

import { Input } from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";

import { remCalc } from "utilities/styles";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";
import GravityForm from "components/common/GravityForm/GravityForm";

export const StyledWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`;

export const StyledOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media ${aboveMedium} {
		align-items: center;
	}
`;

export const StyledContainer = styled.div`
	position: relative;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: ${remCalc(34)} ${remCalc(16)} ${remCalc(32)};
	width: 100%;
	padding-bottom: env(safe-area-inset-bottom, 0);

	@media ${aboveMedium} {
		padding: ${remCalc(34)} ${remCalc(24)} ${remCalc(32)};
		max-width: ${remCalc(450)};
	}
`;

export const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media ${aboveMedium} {
		button {
			position: absolute;
			margin: ${remCalc(16)};
			top: 0;
			right: 0;
		}
	}
`;

export const StyledHeading = styled(Typography)`
	font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
	text-transform: uppercase;

	@media ${aboveMedium} {
		flex: 1;
		letter-spacing: 0.8px;
	}
`;

export const StyledContent = styled.div`
	margin-top: ${remCalc(26)};
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		margin-top: ${remCalc(24)};
	}
`;

export const StyledFieldsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	@media ${aboveMedium} {
		flex-direction: row;
	}
`;

export const StyledInput = styled(Input)`
	flex: 1;
`;

export const StyledButton = styled(Button)`
	margin-top: ${remCalc(8)};
`;

export const StyledGravityForm = styled(GravityForm)`
	button[type="submit"] {
		width: 100%;
	}
`;
