import { ReactComponent as Language } from "assets/images/svg/Language White.svg";
import { ReactComponent as LanguageGray } from "assets/images/svg/Language.svg";
import { ReactElement } from "react";
import Icon from "./Icon";

type Props = {
    className?: string;
    variant?: "gray";
}

function IconLanguage({className, variant}: Props): ReactElement {
    return variant === "gray" ? <Icon className={className} icon={<LanguageGray />} /> : <Icon className={className} icon={<Language />} />;
}

export default IconLanguage;