import { useLazyGetListOfCurrentOpportunitiesQuery } from 'app/api/opportunities/currentOpportunitiesApi';
import { useEffect, useState } from 'react';

const useOpportunitiesPagination = (pageSize?: number) => {
	const pagesToPreload = 3;

	// this is the only bit you need to change, and update references to this
	const [getOpportunity, getOpportunityResult] =
		useLazyGetListOfCurrentOpportunitiesQuery();

	const [pages, setPages] = useState<any[][]>([]);
	const [pageInfo, setPageInfo] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [uninitialized, setUninitialized] = useState(true);
	const [activePage, setActivePage] = useState<number>();

	const preloadPages = async () => {
		setUninitialized(false);
		setLoading(true);
		let hasNext = true;
		let cursor = '';

		const pages = [] as any[];
		const pageInfos = [] as any[];

		const pagesToGet =
			activePage !== undefined && activePage !== 0
				? activePage + (pagesToPreload - 1)
				: pagesToPreload;

		for (let i = pages.length; i < pagesToGet; i++) {
			if (!hasNext) {
				break;
			}

			const res = await getOpportunity({
				pageSize: pageSize || 2,
				cursor: cursor,
			}).unwrap();

			const pageInfo = res?.pageInfo;

			if (res?.jobData.length) {
				pages.push(res?.jobData);
				pageInfos.push(pageInfo);
			}

			hasNext = pageInfo.hasNextPage;
			cursor = pageInfo.endCursor;
		}

		if (pages.length > 0) {
			setPages(pages);
			setPageInfo(pageInfos);
		}

		setLoading(false);
	};

	useEffect(() => {
		preloadPages();
	}, []);

	const prefetchPage = async (page: number) => {
		if (!getOpportunityResult.isFetching) {
			if (pages.length >= page) {
				return;
			}

			const lastPageInfo = pageInfo[pageInfo.length - 1];

			if (lastPageInfo && lastPageInfo.hasNextPage) {
				const res = await getOpportunity({
					pageSize: pageSize || 2,
					cursor: lastPageInfo.endCursor,
				}).unwrap();

				setPages([...pages, res?.jobData]);
				setPageInfo([...pageInfo, res?.pageInfo]);
			}
		}
	};

	useEffect(() => {
		if (activePage) {
			const pageIndex = activePage - 1;
			const remainingPreloadedPages = pages.length - pageIndex;

			if (remainingPreloadedPages < pagesToPreload) {
				prefetchPage(pages.length + 1);
			}
		}
	}, [activePage]);

	const getPage = (page?: number) => {
		if (page) {
			if (activePage !== page) {
				setActivePage(page);
			}

			return pages[page - 1] || [];
		}
		page = activePage ? activePage - 1 : 0;
		return pages[page] || [];
	};

	return {
		uninitialized,
		loading,
		totalPages: pages.length,
		activePage,
		getPage,
		setActivePage,
	};
};

export default useOpportunitiesPagination;