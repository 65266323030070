import styled, { css } from "styled-components";

export interface RadioButtonGroupStyles {
  direction?: "column" | "row"
}

export const RadioButtonGroupWrapper = styled.div<RadioButtonGroupStyles>` 
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};

  .input_radio-btn {
    ${(props) => props.direction === "row" && css`
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0px;
      }
    `}
  }
`