import styled from "styled-components";

import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";

import { remCalc } from "utilities/styles";
import { dark, lightGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const OrderItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${remCalc(16)};
	padding: ${remCalc(16)} 0;
	border-bottom: 1px solid ${lightGrey};

	.order-item__image-container {
		width: ${remCalc(128)};
		max-height: ${remCalc(195)};

		img {
			width: 100%;
			min-width: ${remCalc(128)};
		}
	}

	.order-item__details-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: ${remCalc(8)};
	}

	p {
		margin: 0;
		font: normal ${remCalc(14)} / ${remCalc(17)} ${Roboto};
		color: ${dark};
		text-transform: uppercase;

		&.order-item__name {
			font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
			letter-spacing: ${remCalc(0.8)};
			margin-bottom: ${remCalc(4)};
		}
	}

	.order-item__total-container--desktop {
		position: relative;
		display: none;

		@media ${aboveMedium} {
			display: block;
		}
	}

	.order-item__total-container--mobile {
		display: block;
		margin-top: ${remCalc(4)};

		@media ${aboveMedium} {
			display: none;
		}
	}

	.order-item__total {
		font: bold ${remCalc(16)} / ${remCalc(20)} ${Roboto};
		color: ${dark};
		white-space: nowrap;

		@media ${aboveMedium} {
			position: absolute;
			top: calc(56% + ${remCalc(20)});
			right: 0;
		}
	}
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
	& > div {
		margin: 0;
	}
`;
