import { FunctionComponent, ReactElement } from "react";
import styled, { css } from "styled-components";
import Icon from "../../Icons/Icon";
import SvgIcon from "../../Icons/SvgIcon";
import Button, { ButtonProps } from "../Button";

interface ButtonWithIconProps {
  icon: ReactElement<typeof SvgIcon | typeof Icon>;
  position?: "before" | "after";
}

const StyledButton = styled(Button)<{
  position: "before" | "after";
}>`
  ${(props) => css`

    i {
      margin-right: ${props.position === "before" ? "4px" : "0px"};
      margin-left: ${props.position === "after" ? "4px" : "0px"};
    }


  `}
`;

const ButtonWithIcon: FunctionComponent<ButtonWithIconProps & ButtonProps> = ({
  icon,
  position = "before",
  ...props
}) => {

  return (
    <StyledButton {...props} position={position} className={`ButtonWithIcon ${props.className}`}>
      {position == "before" && icon}
      <span>{props.children}</span>
      {position === "after" && icon}
    </StyledButton>
  );
};

export default ButtonWithIcon;
