import wordPressGetJobDescription from "app/gql/wordPressGetJobDescription";
import { wordPressGQL } from "app/services/wordPressGqlService";

const jobDescriptionApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getJobDescription: builder.query({
			query: (id) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetJobDescription(id),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return;
				const {
					content,
					jobs: jobInfo,
					title,
					featuredImage,
					seo,
				} = res.data.job;

				return { content, jobInfo, title, featuredImage, seo };
			},
		}),
	}),
});

export const { useGetJobDescriptionQuery } = jobDescriptionApi;
