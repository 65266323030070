import React, { useState } from "react";

import { Portal } from "react-portal";
import { useLocation } from "react-router";

import Button, { ButtonProps } from "components/common/ui/Button/Button";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";

import useOutsideClick from "hooks/useOutsideClick";

import {
	StyledContainer,
	StyledContent,
	StyledHeader,
	StyledHeading,
	StyledModalContentContainer,
	StyledOverlay,
} from "./WishlistDeleteModal.Styled";
import { Wishlist } from "../AccountWishlistOverview";

interface Props extends ButtonProps {
	wishlists?: Wishlist[];
	children: React.ReactNode;
	heading: string;
	description: string;
	isLoading: boolean;
	onDelete: () => void;
}

const WishlistDeleteModal = ({
	wishlists,
	children,
	heading,
	description,
	isLoading,
	onDelete,
	...props
}: Props) => {

	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);



	return (
		<>
			<Button onClick={() => setIsOpen(true)} {...props}>
				{children}
			</Button>
			<Portal node={document && document.getElementById("portal__modal")}>
				<Modal
					isOpen={isOpen}
					handleClose={handleClose}
					onDelete={onDelete}
					heading={heading}
					description={description}
					isDeleting={isLoading}
				>
					{children}
				</Modal>
			</Portal>
		</>
	);
};

const Modal = ({
	isOpen,
	isDeleting,
	handleClose,
	onDelete,
	heading,
	description,
	children,
}: {
	isOpen: boolean;
	isDeleting: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	heading: string;
	description: string;
	onDelete: () => void;
}) => {
	const ref = useOutsideClick(handleClose);

	return (
		<StyledContainer isOpen={isOpen}>
			<StyledOverlay>
				<StyledModalContentContainer ref={ref}>
					<StyledHeader>
						<StyledHeading>{heading}</StyledHeading>
						<Button icon={<IconClose />} onClick={handleClose} />
					</StyledHeader>
					<StyledContent>
						<p>{description}</p>
						<Button
							variant="primary_01"
							onClick={async () => {
								await onDelete();
								handleClose();
							}}
							loading={isDeleting}
						>
							{children}
						</Button>
					</StyledContent>
				</StyledModalContentContainer>
			</StyledOverlay>
		</StyledContainer>
	);
};

export default WishlistDeleteModal;
