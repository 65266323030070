import Page from "../../components/common/ui/Page/Page";
import { PageType } from "../../ts/enums";
import LoadingSpinner from "../../components/common/ui/LoadingSpinner/LoadingSpinner";
import { FC, useEffect } from "react";
import useCart from "hooks/cart/useCart";

const OrderConfirmationPage: FC = () => {
	const { fetchCart } = useCart();

	useEffect(() => {
		fetchCart(true);
	}, []);

	return (
		<Page pageType={PageType.PAGE} title='Order Confirmation'>
			<LoadingSpinner/>
		</Page>
	)
}

export default OrderConfirmationPage;
