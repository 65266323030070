import styled, { css } from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

const positionLeft = css`
	left: 50%;
	transform: translateX(-100%);

  span.marker-line {
    order: 2;
  }
  
  button {
    order: 1;
  }

`;

const positionRight = css`
	right: 50%;
	transform: translateX(100%);

  span.marker-line {
    order: 1;
  }
  
  button {
    order: 2;
  }
`;

export const MeasurementWrapper = styled.div<{
	position: "left" | "right";
}>`
	position: absolute;
	display: flex;
	align-items: center;

	span.marker-line {
		display: inline-block;
		width: ${remCalc(46)};
		height: 1px;
		background-color: ${dark};
		position: relative;
	}

	${(props) => (props.position === "left" ? positionLeft : positionRight)};

  &.male {
    &.head {
			top: 2%;
      span.marker-line {
        width: ${remCalc(50)};
      }
		}
    &.neck {
			top: 15%;
      span.marker-line {
        width: ${remCalc(80)};
      }
		}
    &.chest {
			top: 23%;
      span.marker-line {
        width: ${remCalc(70)};
      }
		}
    &.waist {
			top: 36%;
      span.marker-line {
        width: ${remCalc(45)};
      }
		}
    &.hip {
			top: 40%;
      span.marker-line {
        width: ${remCalc(55)};
      }
		}
  }

  &.female {
    &.head {
			top: 7%;
      span.marker-line {
        width: ${remCalc(40)};
      }
		}
    &.chest {
			top: 26%;
      span.marker-line {
        width: ${remCalc(55)};
      }
		}
    &.waist {
			top: 39%;
      span.marker-line {
        width: ${remCalc(45)};
      }
		}
    &.hip {
			top: 44%;
      span.marker-line {
        width: ${remCalc(65)};
      }
		}
  }
`;

export const MeasurementButton = styled.button`
	z-index: 10;
	padding: ${remCalc(4)} ${remCalc(8)};
	background-color: ${white};
	border: 1px solid ${dark};

	font-family: ${Roboto};
	font-size: 14px;
	font-weight: 700;

  &:hover, &.active {
    background-color: ${dark};
    color: ${white};
  }
`;

