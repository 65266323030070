import Button from "components/common/ui/Button/Button";
import Link from "components/common/ui/Link/Link";
import styled from "styled-components";
import { accountDarkGrey, dark, lightGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledColumn = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledButtonLink = styled(Link)`
  text-decoration: none;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border: 1px solid ${lightGrey};
  padding: 10px 16px;

  svg {
    transform: scale(.75);
  }

  &:hover {
    .icon {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }
`;

export const StyledClearButton = styled.button`
  background-color: transparent;
  font: normal 14px/17px ${Roboto};
  color: ${accountDarkGrey};
  text-underline-offset: 2px;

  &:hover {
    color: ${dark};
  }
`;
