import { FunctionComponent } from "react";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Bell } from "./Bell.svg";

interface Props {
	className?: string;
}

const IconBell: FunctionComponent<Props> = ({ className = "" }) => {
	return <SvgIcon className={className} svg={<Bell />} />;
};

export default IconBell;
