import styled from "styled-components";

export const SideMenuFooterWrapper = styled.div`
  background-color: #f8f9fa;
  width: 100%;
  height: 130px;
  padding: 24px 20px;

  padding-bottom: calc(24px + env(safe-area-inset-bottom, 0));
`;
