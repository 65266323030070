import { Image } from "./image";
import { Money } from "./price";
import { ProductOption } from "./productOption";
import { Variant } from "./variant";
import { gql } from "graphql-request";

export const ProductOnProductEdge = gql`
	fragment Product on ProductEdge {
		node {
			id
			entityId
			sku
			path
			name
			description
			prices(includeTax: true) {
				basePrice {
					...Money
				}
				price {
					...Money
				}
				salePrice {
					...Money
				}
				saved {
					...Money
				}
			}
			images(first: 20) {
				edges {
					...Image
				}
			}
			customFields {
				edges {
					node {
						entityId
						name
						value
					}
				}
			}
			variants (first: 250) {
				edges {
					...Variant
				}
			}
			availabilityV2 {
				status
			}
			categories(before: "", last: 1) {
				edges {
					node {
						name
						path
						breadcrumbs(depth: 4) {
							edges {
								node {
									entityId
									name
								}
							}
						}
					}
				}
			}
			productOptions {
				edges {
					...ProductOption
				}
			}
			inventory {
				isInStock
				hasVariantInventory
			}
		}
	}

	${Money}
	${Image}
	${Variant}
	${ProductOption}
`;

export const ProductOnProduct = gql`
	fragment Product on Product {
		id
		entityId
		sku
		path
		name
		description
		prices(includeTax: true) {
			basePrice {
				...Money
			}
			price {
				...Money
			}
			salePrice {
				...Money
			}
			saved {
				...Money
			}
		}
		images(first: 20) {
			edges {
				...Image
			}
		}
		customFields {
			edges {
				node {
					entityId
					name
					value
				}
			}
		}
		variants (first: 250) {
			edges {
				...Variant
			}
		}
		categories(before: "", last: 1) {
			edges {
				node {
					name
					path
					breadcrumbs(depth: 3) {
						edges {
							node {
								entityId
								name
							}
						}
					}
				}
			}
		}
		productOptions {
			edges {
				...ProductOption
			}
		}
		seo {
			pageTitle
			metaDescription
			metaKeywords
		}
		mpn
		brand {
			name
		}
		condition
		plainTextDescription(characterLimit: 1000)
		availabilityV2 {
			status
		}
		inventory {
			isInStock
			hasVariantInventory
		}
	}

	${Money}
	${Image}
	${Variant}
	${ProductOption}
`;
