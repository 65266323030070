import orderApi from "app/api/customer/orderApi";
import Button from "components/common/ui/Button/Button";
import FormattedDate from "../../../common/ui/Date/FormattedDate";
import Price from "components/common/ui/Price/Price";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import useCart from "hooks/cart/useCart";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import uuid from "react-uuid";
import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";
import { BulkAddCartItems, CartItem } from "types/CartTypes";
import { Order, OrderItem as OrderItemType } from "types/Order";
import { AccountOrderContainer } from "./AccountOrder.Styled";
import OrderItem from "./OrderItem/OrderItem";
import OrderItemSkeleton from "./OrderItem/OrderItemSkeleton";
import Shipments from "./Shipments";
import PathTranslationKey from "utilities/paths";

const AccountOrder = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [orderTrigger, orderResult] = orderApi.useOrderMutation();

	const order = React.useMemo(() => {
		if (orderResult.data) {
			return orderResult.data.data as Order;
		}
	}, [orderResult.data]);

	React.useEffect(() => {
		orderTrigger(id);
	}, [id, orderTrigger]);

	const [selectedItems, setSelectedItems] = React.useState<OrderItemType[]>([]);

	const onCheckboxChange = (isSelected: boolean, item: OrderItemType) => {
		if (isSelected) {
			setSelectedItems((prev) => [...prev, item]);
			return;
		}

		setSelectedItems((prev) =>
			prev.filter((prevItem) => prevItem.id !== item.id),
		);
	};

	const cart = useCart();
	const navigate = useLocaleNavigate();

	const onReorder = async () => {
		const lineItems: CartItem[] = [];

		selectedItems.forEach(({ product_id, variant_id, quantity }) => {
			lineItems.push({ product_id, variant_id, quantity });
		});

		if (lineItems.length) {
			const payload = {
				lineItems,
				options: { onSuccess: () => navigate(`${t(PathTranslationKey.CART)}`) },
			} as BulkAddCartItems;

			await cart.bulkAddCartItems(payload);
		}
	};

	useEffect(() => {
		dispatch(setAccountActiveLink("Orders"));
	}, [dispatch]);

	return (
		<AccountOrderContainer className="account-order">
			<h1>
				{t("orderTitle")}: {id}
			</h1>
			<div className="order">
				<div className="order--left">
					<div className="order-contents">
						<h4>{t("orderContentsTitle")}</h4>
						{/* Order Contents */}
						{order?.items.length ? (
							order?.items.map((item) => (
								<OrderItem
									key={item.id}
									order={order}
									item={item}
									onChange={onCheckboxChange}
								/>
							))
						) : (
							<OrderItemSkeleton />
						)}
						{/* Order Total */}
						<div className="order-total">
							<div className="order-total__field">
								<p className="bold">{t("orderSubtotal")}</p>
								<p>
									{order ? (
										<Price
											price={
												Number(order?.subtotal_inc_tax) -
													Number(order?.discount_amount) || 0
											}
										/>
									) : (
										<Skeleton width={"15ch"} />
									)}
								</p>
							</div>
							<div className="order-total__field">
								<p className="bold">{t("orderDeliveryCharge")}</p>
								<p>
									{order ? (
										<Price price={Number(order?.shipping_cost_inc_tax) || 0} />
									) : (
										<Skeleton width={"15ch"} />
									)}
								</p>
							</div>
							<div className="order-total__field">
								<p className="bold">{t("orderTotal")}</p>
								<p>
									{order ? (
										<Price price={Number(order?.total_inc_tax)} />
									) : (
										<Skeleton width={"15ch"} />
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="order--right">
					{/* Order Details */}
					<div className="order-details">
						<h4>{t("orderDetails")}</h4>
						<div className="order-details__field">
							<p className="bold">{t("orderStatus")}:</p>
							<p>
								{(order?.status && order.custom_status) || <Skeleton width={"15ch"} />}
							</p>
						</div>
						<div className="order-details__field">
							<p className="bold">{t("orderDate")}:</p>
							<p>
								{order ? (
									<FormattedDate date={new Date(order?.date_created || "")} />
								) : (
									<Skeleton width={"15ch"} />
								)}
							</p>
						</div>
						<div className="order-details__field">
							<p className="bold">{t("orderTotal")}:</p>
							<p>
								{order ? (
									<Price price={Number(order?.total_inc_tax) || 0} />
								) : (
									<Skeleton width={"15ch"} />
								)}
							</p>
						</div>
						<div className="order-details__field">
							<p className="bold">{t("orderPaymentMethod")}</p>
							<p>
								{order ? order?.payment_method : <Skeleton width={"15ch"} />}
							</p>
						</div>
					</div>
					{/* Delivery Details */}
					<div className="delivery-details">
						<h4>{t("orderDeliveryDetailsTitle")}</h4>
						{order ? (
							<>
								<p className="bold">{`${order?.billing_address.first_name} ${order?.billing_address.last_name}`}</p>
								<p>{order?.billing_address.street_1}</p>
								<p>{order?.billing_address.street_2}</p>
								<p>{`${order?.billing_address.city} ${order?.billing_address.zip}`}</p>
								<p>{order?.billing_address.country}</p>
								<p>{order?.billing_address.phone}</p>
							</>
						) : (
							<div className="delivery-loading">
								<Skeleton width={"15ch"} style={{ marginBottom: "1.5rem" }} />
								<Skeleton width={"15ch"} count={3} />
								<Skeleton width={"15ch"} style={{ marginTop: "1.5rem" }} />
							</div>
						)}
					</div>
					{/* Shipping Details */}
					{!!order?.shipments.length && (
						<div className="shipping-details">
							<h4>{t("orderShippingDetails")}</h4>
							{order?.shipments.map((shipmentData: any) => (
								<Shipments shipmentData={shipmentData} key={uuid()} />
							))}
						</div>
					)}

					{/* Actions */}
					<div className="actions">
						<h4>{t("orderActionsTitle")}</h4>
						<Button
							variant="primary_01"
							onClick={onReorder}
							loading={cart.meta.isLoading}
							disabled={selectedItems.length === 0}
						>
							{t("orderReorderButton")}
						</Button>
					</div>
				</div>
			</div>
		</AccountOrderContainer>
	);
};

export default AccountOrder;
