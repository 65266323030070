import styled from "styled-components";
import { dark } from "utilities/variables/colors";
import { HeadingSmall } from "components/common/ui/Typography/Typography.Styled";
import { productCardBadge } from "utilities/variables/zIndex";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FunctionComponent } from "react";

interface Props {
  text?: string;
  image?: string;
}

const TextBadge = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  max-width: calc(100% - 16px);
  height: 24px;
  overflow: hidden;
  white-space: nowrap;

  border: solid 1px ${dark};
  z-index: ${productCardBadge};
  display: flex;
`;

const ImageBadge = styled(TextBadge)`
  padding: 0;
  border: unset;
`;

const Text = styled(HeadingSmall)`
  margin: auto 4px;
  font-size: 12px;
  letter-spacing: 0.6px;
`;

const ProductBadge: FunctionComponent<Props> = ({ text = "", image = "" }) => {
  if (!text && !image) {
    return null;
  }

  if (text) {
    // text based product badge
    return (
      <TextBadge>
        <Text>{text}</Text>
      </TextBadge>
    );
  } else {
    // image based product badge
    return (
      <ImageBadge>
        <LazyLoadImage src={image} draggable={false} />
      </ImageBadge>
    );
  }
};

export default ProductBadge;
