import { Breakpoint } from "ts/enums";
import { useCurrentBreakpoint } from 'use-react-breakpoint';

export const breakpoints = {
  xSmall: Breakpoint.XSMALL,
  small: Breakpoint.SMALL,
  medium: Breakpoint.MEDIUM,
  large: Breakpoint.LARGE,
  xLarge: Breakpoint.XLARGE,
};

const useBreakpoints = () => {
  const breakpoint = useCurrentBreakpoint(breakpoints);

  const state = {
    screenSize: breakpoint,
    xSmall: false,
    small: false,
    medium: false,
    large: false,
    xLarge: false,
  };

  switch (state.screenSize) {
    case "xSmall":
      return {
        screenSize: breakpoint,
        xSmall: true,
        small: false,
        medium: false,
        large: false,
        xLarge: false,
      };
    case "small":
      return {
        screenSize: breakpoint,
        xSmall: true,
        small: true,
        medium: false,
        large: false,
        xLarge: false,
      };
    case "medium":
      return {
        screenSize: breakpoint,
        xSmall: true,
        small: true,
        medium: true,
        large: false,
        xLarge: false,
      };
    case "large":
      return {
        screenSize: breakpoint,
        xSmall: true,
        small: true,
        medium: true,
        large: true,
        xLarge: false,
      };
    default:
      return {
        screenSize: breakpoint,
        xSmall: true,
        small: true,
        medium: true,
        large: true,
        xLarge: true,
      };
  }
};

export default useBreakpoints;
