import React from "react";

import {
  StyledWrapper,
  StyledButton,
  StyledButtonsContainer,
  StyledTypography,
} from "./CountrySelection.Styled";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import { useMatch } from "react-router";

type Props = {
  options: { key: string; value: string }[];
  onChange: (countries: string[]) => void;
  autoSelect?: boolean;
};

const CountrySelection = ({ options, autoSelect, onChange, ...props }: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<string[]>([]);
  const match = useMatch("/:locale/*");
  const uri = match?.params["*"] || "";


  React.useEffect(() => {
    if (match && autoSelect) {
      match.pathnameBase === '/en' ? 
      setSelected([options.find(country => country.key === '/hr')?.value || ""]):
      setSelected([options.find(country => country.key === match.pathnameBase)?.value || ""])
    }
  }, [match])

  React.useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  const handleClick = (option: string) => {
    if (selected.includes(option)) {
      return setSelected((prev) => prev.filter((i) => i !== option));
    }
    return setSelected((prev) => [...prev, option]);
  };

  return (
    <StyledWrapper {...props}>
      <StyledTypography>{t("countrySelection")}</StyledTypography>
      <StyledButtonsContainer>
        {options.map((option) => (
          <StyledButton
            key={option.key}
            className={selected.includes(option.value) ? "selected" : ""}
            onClick={() => handleClick(option.value)}
          >
            {t(camelCase(option.value))}
          </StyledButton>
        ))}
      </StyledButtonsContainer>
    </StyledWrapper>
  );
};

export default CountrySelection;
