import Divider from "components/common/ui/List/Divider/Divider";
import styled from "styled-components";

import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const WrapperUl = styled.ul`
  display: inline-flex;
  padding: 0;
  margin: 0 20px;

  li {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${dark};
    text-transform: uppercase;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
      font-family: ${Roboto};
    }

    &:hover {
      color: ${darkGrey};
    }

    &:active,
    &.active {
      font-weight: 700;
    }
  }

  li:last-of-type {
    border: none;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0 12px 0 12px;
`;


export const StyledHeader = styled.header`
  width: 100%;
  height: 32px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: row;

  > div {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
}
`;

export const BrandSwitcherWrapper = styled.div`
  justify-content: start;
`

export const LanguageSwitcherWrapper = styled.div`
  justify-content: end;
`
