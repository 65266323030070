import styled from "styled-components";

export const PopoverListStyled = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    .popoverList-item {
        cursor: pointer;
        padding: 0.5rem 0.75rem;
        font-size: small;
        line-height: 30px;
        text-align: left;
        border-top: 1px solid #e6e6e6;
        color: #000;

        &.is-active:hover,
        &.is-active {
            background-color: #fafafa;
        }

        &:hover {
            background-color: #fafafa;
        }

        strong,
        span {
            display: inline-block;
            color: #000;
        }

        .highlight {
            font-weight: bold;
        }
    }
`