import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ButtonGroup from "components/common/ui/ButtonGroup/ButtonGroup";
import { FunctionComponent, useMemo } from "react";
import { BreadcrumbType } from "ts/types";
import PlpSlider from "../PlpSlider/PlpSlider";
import PlpSort from "../PlpSort/PlpSort";
import { ButtonGroupStyles, FilterSort, Wrapper } from "./PlpNavSort.Styled";
import PlpFilter from "../PlpFilter/PlpFilter";
import { Filter } from "hooks/useCategoryPagination";

interface Props {
	breadcrumbs?: BreadcrumbType[];
	categoryId?: number;
	layout: number;
	dispatch: (event: {}) => void;
	isLoading?: boolean;
	filters: Filter[];
	handleOnClearFilters: () => void;
	handleOnFilterAttributes: (filters: {
		attribute: string;
		values: string[];
	}[]) => void;
	handleOnFilterCategory: (ids: number[]) => void;
	handleOnFilterPrices: (minPrice: number | undefined, maxPrice: number | undefined) => void;
	handleSortProducts: (sort: string) => void;
}

const PlpNavSort: FunctionComponent<Props> = ({
	breadcrumbs,
	categoryId,
	layout,
	dispatch,
	isLoading,
	filters,
	handleOnClearFilters,
	handleOnFilterAttributes,
	handleOnFilterCategory,
	handleOnFilterPrices,
	handleSortProducts,
}) => {
	const onSliderChange = (value: number | number[]) => {
		window.localStorage.setItem("category_layout", value.toString());

		dispatch({
			type: "layout_change",
			payload: value,
		});
	};

	const labels = useMemo(() => {
		if (!breadcrumbs) {
			return undefined;
		}

		return breadcrumbs.map((breadcrumb) => breadcrumb.name);
	}, [breadcrumbs]);

	return (
		<Wrapper>
			<Breadcrumb
				loading={isLoading === undefined ? !breadcrumbs : isLoading}
				labels={labels}
			/>
			<FilterSort>
				<PlpSlider value={layout} onChange={onSliderChange} />
				<ButtonGroup styles={ButtonGroupStyles}>
					<PlpFilter
						{...categoryId && {
							categoryId
						}}
						filters={filters}
						handleOnClearFilters={handleOnClearFilters}
						handleOnFilterAttributes={handleOnFilterAttributes}
						handleOnFilterCategory={handleOnFilterCategory}
						handleOnFilterPrices={handleOnFilterPrices}
					/>
					<PlpSort handleSortProducts={handleSortProducts}/>
				</ButtonGroup>
			</FilterSort>
		</Wrapper>
	);
};

export default PlpNavSort;
