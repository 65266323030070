import Button from "components/common/ui/Button/Button";
import IconSort from "components/common/ui/Icons/IconSort/IconSort";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PlpAside from "../PlpAside/PlpAside";
import PlpSortInput from "./PlpSortInput";
import SortOptions, { SortOption } from "./SortOptions";
import { useTranslation } from "react-i18next";

const PlpSort: FunctionComponent<{
  handleSortProducts: (sort: string) => void;
}> = ({
  handleSortProducts
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedSort, setSelectedSort] = useState<SortOption>(SortOptions[0]);

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const onSortClick = (option: SortOption) => {
    if (selectedSort?.id !== option.id) {
      setSelectedSort(option);
      handleSortProducts(option.id)
    }

    setIsOpen(false);
  }

  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        variant="basic"
        icon={<IconSort />}
        onClick={() => setIsOpen(true)}
      >
        {selectedSort ? t(`sortBy.${selectedSort.id}`) : t("sortBy")}
      </Button>
      <PlpAside isOpen={isOpen} close={() => setIsOpen(false)} heading={t("sortBy")}>
        {SortOptions.map((option) => {
          return (
            <PlpSortInput
              key={option.id}
              option={option}
              isSelected={selectedSort?.id === option.id}
              onChange={() => onSortClick(option)}
            />
          );
        })}
      </PlpAside>
    </Fragment>
  );
};

export default PlpSort;
