import * as yup from "yup";

export type WishlistFormSchema = {
	name: string;
	is_public: boolean;
};

export const wishlistFormSchema = yup.object({
	name: yup.string().label("Wishlist Name").required("form.validation.wishlist_name.required"),
	is_public: yup.boolean().label("Share Wishlist?").required(),
});

export const wishlistFormDefaultValues = {
	name: "",
	is_public: false,
} as WishlistFormSchema;
