import Button from "components/common/ui/Button/Button";
import FormattedDate from "components/common/ui/Date/FormattedDate";
import { useTranslation } from "react-i18next";
import { Shipment } from "types/Order";
import {
	FontBold,
	FontNormal,
	TrackOrderBtnContainer,
} from "./Shipment.Styled";
import { useMatch } from "react-router";
import { useEffect, useState } from "react";

type Props = {
	shipmentData: Shipment;
};

const Shipments = ({ shipmentData }: Props) => {
	const { t } = useTranslation();

	const [trackingOrderUrl, setTrackingOrderUrl] = useState("");

	const pathMatch = useMatch("/:locale/*");

	useEffect(() => {
		if (!pathMatch?.params.locale) {
			return;
		}
		const { REACT_APP_TRACKING_ORDER_URL: URLS = "{}" } = process.env;
		setTrackingOrderUrl(
			JSON.parse(URLS)[pathMatch?.params.locale].urlTemplates.trackingTicketUrl,
		);
	}, [pathMatch]);

	return (
		<>
			<div className="shipping-details__field">
				<FontNormal className="bold">{t("date_shipped")}:</FontNormal>
				<FontNormal>
					<FormattedDate date={new Date(shipmentData?.date_created || "")} />
				</FontNormal>
			</div>
			<div className="shipping-details__field">
				<FontNormal className="bold">{t("shipping_method")}:</FontNormal>
				<FontNormal>{shipmentData.shipping_method}</FontNormal>
			</div>
			<div className="shipping-details__field">
				<FontNormal className="bold">{t("tracking_link")}:</FontNormal>
				<FontNormal>{shipmentData.tracking_number}</FontNormal>
			</div>
			<TrackOrderBtnContainer>
				<Button
					variant="primary_03"
					textLight
					onClick={() =>
						window.open(
							`${trackingOrderUrl}${shipmentData.tracking_number}`,
							"_blank",
						)
					}
				>
					{t("track_order")}
				</Button>
			</TrackOrderBtnContainer>

			<hr />
		</>
	);
};

export default Shipments;
