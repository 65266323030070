import React from "react";

import { Portal } from "react-portal";
import { useLocation } from "react-router";

import { ButtonProps } from "components/common/ui/Button/Button";


import { Wishlist } from "types/Customer";


import ShareModal from "components/common/ui/ShareModal/ShareModal";
import {
	StyledButton
} from "./WishlistShareModal.Styled";
import { useTranslation } from "react-i18next";

interface Props {
	id?: Wishlist[keyof Wishlist];
	shareURL: string;
	variant?: ButtonProps[keyof ButtonProps];
	children?: React.ReactNode;
}

const WishlistShareModal = ({
	id = "",
	variant = "primary_01",
	children,
	shareURL,
	...props
}: Props) => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = React.useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<StyledButton
				variant={variant}
				onClick={() => setIsOpen(true)}
				{...props}
			>
				{children ? children : t("wishlist.share")}
			</StyledButton>
			<Portal node={document && document.getElementById("portal__modal")}>
				<ShareModal
					isOpen={isOpen}
					handleClose={handleClose}
					url={shareURL}
					labelTranslationKey="share_wishlist"
				/>
			</Portal>
		</>
	);
};

export default WishlistShareModal;
