import { useForgotPasswordMutation } from "app/api/authApi";
import BaseForm from "components/common/form/Form/Form";
import { InputEmail } from "components/common/form/Input/Input";
import Alert, { AlertType } from "components/common/ui/Alert/Alert";
import Button from "components/common/ui/Button/Button";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Fragment, FunctionComponent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "redux/hooks";
import { setPasswordResetMessage } from "redux/UI/uiSlice";
import PathTranslationKey from "utilities/paths";
import {
	ForgotPasswordFormResolve,
	ForgotPasswordFormScheme,
} from "./ForgotPasswordForm.Schema";
import { FormStyle } from "./ForgotPasswordForm.Styled";
import { useTranslation } from "react-i18next";

interface Props {
	large: boolean; 
}

const ForgotPasswordForm: FunctionComponent<Props> = ({ large }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ForgotPasswordFormScheme>({
		resolver: ForgotPasswordFormResolve,
	});

	const navigate = useLocaleNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [forgotPasswordApi, forgotPasswordResult] = useForgotPasswordMutation();
	const [error, setError] = useState<string[]>([]);
	const [success, setSuccess] = useState("");

	const onSubmit: SubmitHandler<ForgotPasswordFormScheme> = (formData) => {
		setError([]);
		setSuccess("");

		forgotPasswordApi(formData)
			.unwrap()
			.then(({ data }) => {
				dispatch(setPasswordResetMessage(data.message));
				reset();
				navigate(t(PathTranslationKey.SIGN_IN).toString());
			})
			.catch(({ data }) => {
				setError([t(data.error.message)]);
			});
	};

	return (
		<Fragment>
			{error && (
				<Alert type={AlertType.ERROR} title={t("error").toString()} messages={error} />
			)}
			{success && <Alert type={AlertType.SUCCESS} messages={[success]} />}
			<BaseForm styles={FormStyle} onSubmit={handleSubmit(onSubmit)}>
				<InputEmail
					label={String(t("form.signInEmail"))}
					required
					{...register("email")}
					errorMsg={errors.email?.message && t(errors.email?.message) as string}
				/>
				<Button
					variant="primary_01"
					type="submit"
					fullWidth={!large}
					loading={forgotPasswordResult.isLoading}
					disabled={forgotPasswordResult.isLoading}
				>
					{t("form.resetPassword")}
				</Button>
			</BaseForm>
		</Fragment>
	);
};

export default ForgotPasswordForm;
