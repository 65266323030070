import styled from "styled-components";
import { remCalc } from "../../../../utilities/styles";
import { dark, darkGrey } from "../../../../utilities/variables/colors";

const SocialMediaLinksContainer = styled.div<{
	padding?: number;
	flexDirection?: string;
	gap?: number;
	alignItems?: string;
	justifyContent?: string;
}>`
	display: flex;
	flex-direction: ${(props) => (props.flexDirection && props.flexDirection)};
	justify-content: ${(props) => (props.justifyContent && props.justifyContent)};
	align-items: ${(props) => (props.alignItems && props.alignItems)};
	gap: ${(props) => (props.gap && remCalc(props.gap))};
	padding: ${(props) => (props.padding && remCalc(props.padding))} 0;
	width: 100%;
	
	.social-media-link svg {
		fill: ${dark};
	}
	
	.social-media-link svg:hover {
		fill: ${darkGrey};
	}
`

export {
	SocialMediaLinksContainer
}