import { Fragment, FunctionComponent } from "react";

import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";

import useBreakpoints from "hooks/useBreakpoints";
import {
	CMSContent,
	RegisterSection,
	StyledColumn,
	StyledDivider,
	StyledLink,
	StyledRow,
	StyledWideLink,
} from "./Signin.Styled";
import SigninForm from "./SigninForm/SigninForm";
import PathTranslationKey from "utilities/paths";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface SigninProps {
	pageView?: boolean;
}

const Register: FunctionComponent<{
	large: boolean;
	prefetchContent: () => void;
}> = ({ large, prefetchContent }) => {
	const pageContent = wordPressApi.useGetPageContentByUriQuery("/login");
	const { t } = useTranslation();

	return (
		<RegisterSection>
			<Typography variant="heading" size="small">
				{t("form.signInRegister")}
			</Typography>
			{pageContent.data && <CMSContent html={pageContent.data?.content} />}
			<Button
				variant="primary_03"
				textLight
				fullWidth={!large}
				link={{
					to: t(PathTranslationKey.REGISTRATION).toString(),
				}}
				onMouseEnter={prefetchContent}
			>
				{t("form.signInCreateAccount")}
			</Button>
		</RegisterSection>
	);
};

const Signin: FunctionComponent<SigninProps> = ({ pageView = false }) => {
	const { large } = useBreakpoints();

	const registerContentPrefetch = wordPressApi.usePrefetch(
		"getPageContentByUri",
	);

	const prefetchRegisterContent = () => {
		registerContentPrefetch("/registration", { force: true });
	};

	if (!pageView) {
		return (
			<div>
				<StyledRow>
					<Typography variant="heading" size="small">
						{t("form.signInSignIn")}
					</Typography>
					<StyledLink
						onMouseEnter={prefetchRegisterContent}
						to={t(PathTranslationKey.REGISTRATION).toString()}
					>
						{t("register")}
					</StyledLink>
				</StyledRow>
				<SigninForm />
				<StyledColumn>
					<StyledWideLink to={t(PathTranslationKey.FORGOT_PASSWORD).toString()}>
						{t("form.signInForgotPassword")}
					</StyledWideLink>
				</StyledColumn>
			</div>
		);
	} else {
		return (
			<Fragment>
				<Typography variant="heading" size="small">
					{t("form.signInSignIn")}
				</Typography>
				<SigninForm />
				<StyledLink to={t(PathTranslationKey.FORGOT_PASSWORD).toString()}>
					{t("form.signInForgotPassword")}
				</StyledLink>
				<StyledDivider />
				<Register large={large} prefetchContent={prefetchRegisterContent} />
			</Fragment>
		);
	}
};

export default Signin;
