import { HeaderWrapper } from "./HeadingBanner.styles";

interface data {
	imageUrl: string;
	title: string;
}

const HeadingBanner = ({ imageUrl, title }: data) => {
	return (
		<HeaderWrapper>
			<div>
				<h1>{title}</h1>
			</div>

			<img src={imageUrl} alt="heading" />
		</HeaderWrapper>
	);
};

export default HeadingBanner;
