import { useTranslation } from "react-i18next";
import { ProductCustomField } from "ts/types";
import WashingInstructionIcon from "./WashingInstructionIcon";
import { ValidWashingInstructionCodes } from "./WashingInstrcutionCodes";
import {
	StyledItem,
	StyledItemLabel,
	StyledWrapper,
} from "./WashingInstructions.Styled";

type Props = {
	field: ProductCustomField;
};

const WashingInstructions = ({ field }: Props) => {
	const { t, ready } = useTranslation();

	const codes = field.value.split(",").map((code) => code.trim());

	if (!codes.length || !ready) {
		return null;
	}

	return (
		<StyledWrapper>
			{codes.map(
				(code) =>
					ValidWashingInstructionCodes.includes(code) && (
						<StyledItem key={code}>
							<WashingInstructionIcon code={code} />
							<StyledItemLabel>
								{t(`washingInstruction.${code}`).toString()}
							</StyledItemLabel>
						</StyledItem>
					),
			)}
		</StyledWrapper>
	);
};

export default WashingInstructions;
