import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Fragment, FunctionComponent, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";
import PathTranslationKey from "utilities/paths";

interface RequireAuthProps {
  children: ReactElement;
}

const RequireNotAuth: FunctionComponent<RequireAuthProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();

  const { identifier } = useSelector((state) => state.auth);

  useEffect(() => {
    if (identifier.storeHash) {
      navigate(t(PathTranslationKey.ACCOUNT_OVERVIEW), { replace: true });
    }
  }, [identifier]);

  if (identifier.storeHash) {
    return null;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default RequireNotAuth;
