import styled, { css } from 'styled-components';
import { Roboto } from '../../../../utilities/variables/fonts';

const sharedStyles = css`
    font-family: ${Roboto};
    color: #212529;
`;

const FontBold = styled.p`
    ${sharedStyles};
    text-transform: capitalize;
    font-size: 16px;
`;

const FontNormal = styled.p`
    ${sharedStyles};
`;

const TrackOrderBtnContainer = styled.div`
    margin: 12px 0 0;
    button {
        border: 1px solid #212529;
        text-transform: capitalize;
    }
`;

export {
    FontBold,
    FontNormal,
    TrackOrderBtnContainer
}